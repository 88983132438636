import React from 'react'
import {ClipboardIcon, CalendarIcon, DocumentTextIcon, ArchiveIcon, CheckIcon, XIcon, ClockIcon, UserAddIcon, XCircleIcon} from '@heroicons/react/outline'
import DropdownMenuDots from '../../components/menus/DropdownMenuDots'
import dayjs from 'dayjs'
import {jobStatusIds, jobStatuses} from '../../helpers/enum/jobStatuses'
import { Link } from 'react-router-dom'

function JobOrderItem ({jobOrder, linkToFwC}) {

  const status = jobOrder.jobStatusLog[0]?.status

  const iconSelector = (status) => {
    switch(status) {
      case jobStatusIds.active:
        return (
          <UserAddIcon className= "m-1 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.cancelled:
        return (
          <XIcon className= "m-1 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.fulfilled:
        return (
          <CheckIcon className= "m-1 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.onHold:
        return (
          <ClockIcon className= "m-1 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.archived:
        return (
          <ArchiveIcon className= "m-1 h-4 w-4 text-gray-400"/>
        )
    }
  }

  return (
    <li>
      <div className={`block py-2`}>
        <div className="flex items-center -my-1 justify-between">
          <Link to={`/job-orders/${jobOrder.id}`} className="group sm:flex items-center text-sm text-gray-500 sm:mt-0">
            <ClipboardIcon
              className="m-1 h-4 w-4 text-gray-400 group-hover:text-thaleria-orange-700 group-hover:font-bold"
              aria-hidden="true"
            />
            <p to={`/job-orders/${jobOrder.id}`} className="truncate overflow-hidden group-hover:text-thaleria-orange-700 group-hover:font-bold">{jobOrder.title}</p>
          </Link>
        </div>
        <div className="mt-2 sm:flex sm:justify-between shrink text-sm text-gray-500">
          <div className="sm:flex items-center space-x-2 sm:mt-0">
            <div className="flex items-center">
              {iconSelector(status)}
              <p className="truncate overflow-hidden">{jobStatuses[status]}</p>
            </div>
            <div className="flex">
              <CalendarIcon
                className="m-1 mt-0.5 h-4 w-4 text-gray-400 "
                aria-hidden="true"
              />
              <p className="truncate overflow-hidden">
                {dayjs(jobOrder.created_at).format("DD/MM/YY")}
              </p>
            </div>
          </div>
          {linkToFwC ?
            <Link to={`/fw-contracts/${jobOrder.framework_contract_id}`} className="flex group items-center text-sm text-gray-500 sm:mt-0">
              <DocumentTextIcon
                className="m-1 h-4 w-4 text-gray-400 group-hover:text-thaleria-orange-700 group-hover:font-bold"
                aria-hidden="true"
              />
              <p className="truncate overflow-hidden group-hover:text-thaleria-orange-700 group-hover:font-bold">
                {jobOrder.frameworkContract.contract_ref}
              </p>
            </Link>
            :
            <div className="flex items-center text-sm text-gray-500 sm:mt-0">
              <DocumentTextIcon
                className="m-1 h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
              <p className="truncate overflow-hidden">
                {jobOrder.frameworkContract.contract_ref}
              </p>
            </div>
          }
          
        </div>
      </div>
    </li>
  );
}

export default JobOrderItem

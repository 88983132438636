import React, { useMemo } from 'react'
import {
  PaperClipIcon,
  TrashIcon,
  DownloadIcon,
  EyeIcon
} from "@heroicons/react/outline";
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import { APP_STYLES } from "../../config";
import ClipLoader from "react-spinners/ClipLoader";
import role from "../../helpers/enum/role";
import getFileExtensionAndName from '../../helpers/getFileExtensionAndName';


function File({document, previewFile, isDownloading, enableDownload, isDeleting,onRemoveHandler, onDownloadHandler, isLoadingPreview}) {

  const { verifyUserId, verifyBoolAccess } = useUserRoleVerifier();

  const canVisualizeDocument = useMemo(() => {
   const {fileType} = getFileExtensionAndName(document.name)
   const _canVisualize =
     fileType === "png" || fileType === "jpeg" || fileType === "jpg" || fileType === 'pdf';
   return _canVisualize;
  }, [document])


  return (
    <li
      key={document.key}
      className="px-2 py-3 flex items-center justify-between text-sm"
    >
      <div className="w-0 flex-1 flex items-center">
        <PaperClipIcon className="w-4 h-4 -rotate-45 text-thaleria-blue-400" />
        <span className="ml-1 flex-1 w-0 truncate">{document.name}</span>
      </div>
      <div className="ml-2 flex-shrink-0 flex space-x-1.5">
        {enableDownload && (
          <>
            {canVisualizeDocument && (
              <>
                {isLoadingPreview === document.name ? 
                  <ClipLoader
                  className="mr-2"
                  size={17}
                  color={APP_STYLES.primaryColor}
                /> :
                <button
                  onClick={() => previewFile(document)}
                  type="button"
                  className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-800"
                >
                  <EyeIcon className="w-4 h-4 text-thaleria-blue-400 hover:text-thaleria-blue-800" />
                </button>
                }
                <span className="text-gray-300" aria-hidden="true">
                  |
                </span>
              </>
            )}
            {isDownloading === document.name ? 
              <ClipLoader
                className="mr-2"
                size={17}
                color={APP_STYLES.primaryColor}
              /> :
              <button
                onClick={() => onDownloadHandler(document.key)}
                type="button"
                className="bg-white rounded-md font-medium text-thaleria-blue-400 hover:text-thaleria-blue-800"
              >
                <DownloadIcon className="w-4 h-4 text-thaleria-blue-400 hover:text-thaleria-blue-800" />
              </button>
            }
          </>
        )}
        {(verifyBoolAccess(role.j_attachments.j_3_manage_attachments) ||
          verifyUserId(document.uploaded_by)) && (
          <>
            <span className="text-gray-300" aria-hidden="true">
              |
            </span>
            {isDeleting === document.name ? 
              <ClipLoader
                  className="mr-2"
                  size={17}
                  color={APP_STYLES.primaryColor}
                /> :
              <button
                type="button"
                onClick={() => onRemoveHandler(document.key)}
                className="bg-white rounded-md font-medium text-red-600 hover:text-red-500"
              >
                <TrashIcon className="h-4 w-4 text-red-600 hover:text-red-500" />
              </button>
            }
          </>
        )}
      </div>
    </li>
  );
}

export default File
export const jobStatuses = {
  1: "Active",
  2: "Cancelled",
  3: "Fulfilled",
  4: "On hold",
  5: "Archived"
}

export const jobStatusIds = {
  active: 1, 
  cancelled: 2,
  fulfilled: 3,
  onHold: 4,
  archived: 5
}
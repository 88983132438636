const candidateProperties = {
  id: 'ID',
  first_names: 'First names',
  last_names: 'Last names',
  email: 'Email',
  phone_nb: 'Phone number',
  added_by: 'Added by',
  original_owner: 'Belongs to',
  source: 'Source',
  languages: 'Languages', //foreign association
  nationality: 'Nationality',
  country: 'Country of residence',
  is_currently_placed: 'Is currently placed',
  first_time_candidate: 'First time candidate',
  current_employer: 'Current employer',
  skills: 'Skills', //foreign association
  can_relocate: 'Can relocate',
  notice_period: 'Notice period',
  type: 'Type',
  notes: 'Notes',
  created_at: 'Creation date',
  updated_at: 'Updated at',
  deleted_at: 'Deleted at',
  blacklisted: 'Blacklist'
}

export default candidateProperties;


/* foreign associations:
- languages
- skills and technologies
- application status log
*/
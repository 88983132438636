import { PlusIcon } from '@heroicons/react/solid';
import React, { useRef, useState, useEffect } from 'react';
import ContainerCard from '../../components/cards/ContainerCard';
import WhiteButton from '../../components/buttons/WhiteButton';
import WarningModal from '../../components/modal/WarningModal';
import { APP_STYLES } from '../../config';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import useApi from '../../store/api/apiContext';
import useAppNotification from '../../store/appNotification/appNotificationContext';
import { downloadBase64File } from '../../helpers/downloadBase64File';
import role from '../../helpers/enum/role';
import PreviewBox from './PreviewBox';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import getFileExtensionAndName from '../../helpers/getFileExtensionAndName';
import {specialChars, specialCharsForReplace} from '../../helpers/specialCharactersRegex';
import File from './File'

function Attachments({id, type, enableAdd = true, enableDownload = true}) {

  const { verifyUserId, verifyBoolAccess } = useUserRoleVerifier();
  const { getFilesOnFolder, uploadCandidateFile, uploadJobOrderFile, downloadFile, deleteFile } = useApi();
  const { appNotificationTypes, showAppNotification, showErrorNotification} = useAppNotification();

  const [ showWarning, setShowWarning ] = useState(false);
  const [ documents, setDocuments ] = useState([]);
  const [ isUploading, setIsUploading ] = useState(false);
  const [ isDownloading, setIsDownloading ] = useState(false)
  const [ isDeleting, setIsDeleting ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ showPreview, setShowPreview ] = useState(false)
  const [ previewPath, setPreviewPath ] = useState();
  const [ previewType, setPreviewType ] = useState();
  const [ previewTitle, setPreviewTitle ] = useState();

  const [ isLoadingPreview, setIsLoadingPreview ] = useState(false);

  const inputFile = useRef(null);
  
  const getFiles = async (id, source) => {
    getFilesOnFolder(`${type}/${id}`, source).then(res => {
      setDocuments(res.data.map(res => res));
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    getFiles(id, source)
    return () => {
      source.cancel()
    }
  }, [id, type])


  const uploadFileHandler = (file) => {
    
    let fileInfo = file;
    let fileName = fileInfo.name
    const {fileType, docName} = getFileExtensionAndName(fileName)
    if (specialChars.test(docName)) {
      //replaces any special characters with ' ' without changing the extension

      fileName = docName.replace(specialCharsForReplace, ' ') + '.' + fileType;
      // inputFile.current.value = "";
      // return showErrorNotification("File name can't include any special character");
    }

    const duplicate = documents.some(doc => doc.name === fileName)
    setIsUploading(true);
    switch (type) {
      case 'candidates':
        uploadCandidateFile(id, fileInfo).then(res => {
          setIsUploading(false);
          if (!duplicate) setDocuments(preValue => ([...preValue, { ...res.data, name: fileName }]));
          showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Your file was uploaded successfully')
        }).catch(() => {
          setIsUploading(false);
        });
        inputFile.current.value = ""
        break;
      case 'job-orders':
        uploadJobOrderFile(id, fileInfo).then(res => {
          setIsUploading(false);
          if (!duplicate) setDocuments(preValue => ([...preValue, { ...res.data, name: fileName }]));
          showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Your file was uploaded successfully')
        }).catch(() => {
          setIsUploading(false);
        });
        inputFile.current.value = ""
        break;
      default:
        break;
    }
  }

  const downloadFileHandler = key => {
    const file = documents.find(document => document.key === key)
    const fileName = file.name
    setIsDownloading(fileName)
    downloadFile(file.key).then(res => {
      setIsDownloading(false)
      downloadBase64File(res.data, fileName)
    }).catch(() => {
      setIsDownloading(false);
    });
  }

  const removeFileHandler = key => {
    setIsDeleting(key.split('/')[key.split('/').length - 1])
    deleteFile(key).then(res => {
      setIsDeleting(false)
      setDocuments(prev => [...prev.filter(document => document.key !== key)])
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Your file was successfully deleted')
    }).catch(() => {
      setIsDeleting(false);
    });
  }

  const onChangeAdd = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    
    if(documents.some(doc => doc.name === file.name)) {
      let canOverwrite = (verifyBoolAccess(role.j_attachments.j_3_manage_attachments) ||
      verifyUserId(documents.find(doc => doc.name === file.name).uploaded_by))
      canOverwrite ? setShowWarning(true) : showErrorNotification('You cannot overwrite this file, please change the name of your file.')
      return
    } else {
      uploadFileHandler(file);
    }
  };

  function confirmReplacement() {
    setShowWarning(false)
    uploadFileHandler(inputFile.current.files[0])
    inputFile.current.value = "";
  }
  
  function cancelReplacement() {
    setShowWarning(false)
    inputFile.current.value = "";
  }

  function previewFile (doc) {
    const {docName, fileType} = getFileExtensionAndName(doc.name)
    const title = docName;
    setPreviewType(fileType)
    setPreviewTitle(title)
    setIsLoadingPreview(doc.name)
    downloadFile(doc.key).then(res => {
      setPreviewPath(res.data)
      setIsLoadingPreview(false);
    }).catch(() => {
      setIsDownloading(false)
    })
    setShowPreview(true)
  }

  const handleHideModal = () => {
    setPreviewType(null);
    setPreviewTitle(null);
    setPreviewPath(null);
    setShowPreview(false);
  };

  const onDownloadHandler = key => downloadFileHandler(key);

  const onRemoveHandler = key => removeFileHandler(key);

  return (
    <ContainerCard
      title="Attachments"
      button={
        enableAdd && (
          <WhiteButton
            icon={
              isUploading ? (
                <ClipLoader
                  className="mr-2"
                  size={17}
                  color={APP_STYLES.primaryColor}
                />
              ) : (
                <PlusIcon className="h-4 w-4" />
              )
            }
            disabled={isUploading}
            onClick={() => inputFile.current.click()}
            text={isUploading ? "" : "Add"}
          />
        )
      }
    >
      <PreviewBox
        handleHide={handleHideModal}
        showPreview={showPreview}
        setShowPreview={() => setShowPreview}
        title={previewTitle}
        filePath={previewPath}
        fileType={previewType}
      />
      {isLoading ? (
        <div className="flex justify-center">
          <ClipLoader
            className="mx-auto"
            size={30}
            color={APP_STYLES.primaryColor}
          />
        </div>
      ) : (
        <>
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={onChangeAdd}
            style={{ display: "none" }}
          />
          <WarningModal
            title="File name already exists"
            message={`By pressing Confirm you will be permanently deleting the previous file and replacing it with the one you have provided.`}
            showWarning={showWarning}
            setShowWarning={setShowWarning}
            onClick1={confirmReplacement}
            onClick2={cancelReplacement}
            buttonText="Confirm"
          />
          <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">
            {(documents && documents.length === 0) || !documents ? (
              <span className="text-sm text-gray-500">
                No documents currently uploaded.
              </span>
            ) : (
              <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                {documents &&
                  documents.length > 0 &&
                  documents.map((document) => {
                    if (document.key) {
                      return (
                        <File
                          document={document}
                          previewFile={previewFile}
                          isDownloading={isDownloading}
                          enableDownload={enableDownload}
                          isDeleting={isDeleting}
                          onRemoveHandler={onRemoveHandler}
                          onDownloadHandler={onDownloadHandler}
                          isLoadingPreview={isLoadingPreview}
                        />
                      );
                    } else return "";
                  })}
              </ul>
            )}
          </dd>
        </>
      )}
    </ContainerCard>
  );
}

export default Attachments
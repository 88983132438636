import React, { useState, useRef } from 'react'
import TextInput from '../../../components/inputs/TextInput';
import FormWithSideTitle from '../../../components/forms/FormWithSideTitle';
import WhiteButton from '../../../components/buttons/WhiteButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import Toggle from '../../../components/inputs/Toggle';
import CandidateActions from './CandidateActions';
import JobOrderActions from './JobOrderActions';
import useApi from '../../../store/api/apiContext';
import UserActions from './UserActions';
import CommentsActions from './CommentsActions';
import AttachmentsActions from './AttachmentsActions';

function AddRoleForm() {
  const navigate = useNavigate();

  const { createRole } = useApi();
  const nameInputRef = useRef(null)
  const [formErrors, setFormErrors] = useState([])

  const [newRole, setNewRole] = useState({
    a_name: null,
    b_manage_user_groups: false,
    c_manage_roles: false,
    d_manage_framework_contracts: false,
    e_manage_clients: false,
    f_1_1_allowed_user_group_ids: [],
    f_1_2_allowed_role_ids: [],
    f_1_3_viewable_properties: [],
    f_1_view_users: false,
    f_2_1_allowed_user_group_ids: [],
    f_2_2_allowed_role_ids: [],
    f_2_3_create_users: false,
    f_2_4_1_editable_properties: [],
    f_2_4_edit_users: false,
    f_2_5_delete_users: false,
    f_2_6_reset_user_password: false,
    f_2_manage_users: false,
    g_1_create_my_candidate: false,
    g_2_1_editable_properties_my_candidate: [],
    g_2_edit_my_candidate: false,
    g_3_delete_my_candidate: false,
    g_4_1_allowed_owner_user_group_ids: [],
    g_4_2_create_candidates: false,
    g_4_3_1_editable_properties: [],
    g_4_3_edit_candidates: false,
    g_4_4_delete_candidates: false,
    g_4_manage_candidates: false,
    h_1_1_viewable_properties: [],
    h_1_2_allowed_fwc: [],
    h_1_3_assign_myself_as_recruiter: false,
    h_1_view_job_orders: false,
    h_2_1_edit_created_job_orders: false,
    h_2_2_delete_created_job_orders: false,
    h_2_create_job_orders: false,
    h_3_1_update_job_order_status: false,
    h_3_2_assign_recruiters: false,
    h_3_3_assign_applicants: false,
    h_3_4_update_application_status: false,
    h_3_5_remove_applicant: false,
    h_3_6_1_editable_properties: [],
    h_3_6_edit_job_order: false,
    h_3_7_delete_job_order: false,
    h_3_manage_job_orders: false,
    i_1_view_job_order_comments: false,
    i_2_view_candidate_comments: false,
    i_3_view_client_comments: false,
    i_4_manage_comments: false,
    j_1_view_job_order_attachments: false,
    j_2_view_candidate_attachments: false,
    j_3_manage_attachments: false,
  });

  const handleCreateRole = () => {
    let errors = [];
    if (!newRole.a_name) {
      errors.push({
        field: 'Role name',
        msg: 'Please include a name for your role'
      })
    }
    if (errors.length) {
      nameInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})    
      return setFormErrors(errors);
    }
    createRole(newRole).then(() => {
      navigate(-1);
    })
  }

  const [manageAllCandidates, setManageAllCandidates] = useState(false);

  //TODO: If view job orders is on, and custom properties viewable, ensure id is always in array
  //TODO: If view users is on, and custom properties viewable, ensure id is always in array

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Name' description='Add a name to your role' >
        <div ref={nameInputRef} className="col-span-6">
          <TextInput label='Role name' errors={formErrors} value={newRole.a_name} onChange={(e) => setNewRole(prev => ({...prev, a_name: e.target.value }) )}/>
        </div>    
      </FormWithSideTitle>
      <FormWithSideTitle title='Key administrative actions' description='Set which key administrative actions your role will enable' >
        <div className="col-span-6">
          <Toggle bold label=' Manage user groups' description={'Allows to view, create, edit, delete, and associate roles to user groups'} value={newRole.b_manage_user_groups} onChange={() => setNewRole(prev => ({...prev, b_manage_user_groups: !newRole.b_manage_user_groups }) )}/>
        </div>
        <div className="col-span-6">
          <Toggle bold label='Manage roles' description={'Allows to view, create, edit and delete roles'} value={newRole.c_manage_roles} onChange={() => setNewRole(prev => ({...prev, c_manage_roles: !newRole.c_manage_roles }) )}/>
        </div>
        <div className="col-span-6">
          <Toggle bold label='Manage framework contracts' description={'Allows to view, create, edit and delete framework contracts'} value={newRole.d_manage_framework_contracts} onChange={() => setNewRole(prev => ({...prev, d_manage_framework_contracts: !newRole.d_manage_framework_contracts }) )}/>
        </div>
        <div className="col-span-6">
          <Toggle bold label='Manage clients' description={'Allows to view, create, edit and delete clients'} value={newRole.e_manage_clients} onChange={() => setNewRole(prev => ({...prev, e_manage_clients: !newRole.e_manage_clients }) )}/>
        </div>
      </FormWithSideTitle>
      <UserActions 
        newRole={newRole} 
        setNewRole={setNewRole}
        setManageAllCandidates={setManageAllCandidates}
      />
      <CandidateActions 
        newRole={newRole} 
        setNewRole={setNewRole}
        manageAllCandidates={manageAllCandidates}
        setManageAllCandidates={setManageAllCandidates}
      />
      <JobOrderActions newRole={newRole} setNewRole={setNewRole}/>
      <CommentsActions newRole={newRole} setNewRole={setNewRole}/>
      <AttachmentsActions newRole={newRole} setNewRole={setNewRole}/>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigate('/roles', {replace: true}) }/>
        <PrimaryButton text='Add' indigoRing onClick={handleCreateRole}/>
      </div>
    </div>
  )
}

export default AddRoleForm
import * as React from 'react';
import { AppNotificationContext } from './appNotificationContext.js';

function AppNotificationProvider({children}) {

  const [appNotification, setAppNotification] = React.useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  
  /* // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = React.useMemo(() => [state, dispatch], [state]) */

  //No memoization needed
  const value = [appNotification, setAppNotification];

  return (
    <AppNotificationContext.Provider value={ value }>
      { children }
    </AppNotificationContext.Provider>
  )
}

export default AppNotificationProvider;
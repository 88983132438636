import React from 'react'
import DescriptionList from '../../../components/descriptionLists/DescriptionList'
import jobOrderProperties from '../../../helpers/enum/jobOrderProperties'
import { ALL_ACCESS_UUID } from '../../../config'

function JobOrderActionDetails({ role, frameworkContracts }) {

  const returnArrayName = (array, labels) => {
    if (array[0] === ALL_ACCESS_UUID) return 'All';
    return array.map(property => labels[property]).join(', ');
  }
  const returnFrameworkContractName = (array) => {
    if (array[0] === ALL_ACCESS_UUID) return 'All';
    if (array[0] === "" && array.length === 1) return;
    return array.map(contractId => frameworkContracts.find(contract => contract.id === contractId)?.contract_ref).join(', ');
  }
 
  const items = [
    { label: 'View job orders', data: role.h_1_view_job_orders ? 'Yes' : 'No', id: 1, show: true },
    { label: 'View job orders: viewable properties', data: returnArrayName(role.h_1_1_viewable_properties, jobOrderProperties), id: 2, show: role.h_1_view_job_orders },
    { label: 'View job orders: allowed framework contracts', data: returnFrameworkContractName(role.h_1_2_allowed_fwc), id: 3, show: role.h_1_view_job_orders },
    { label: 'Viewable job orders: assign myself as recruiter', data: role.h_1_3_assign_myself_as_recruiter ? 'Yes' : 'No', id: 4, show: role.h_1_view_job_orders },
    { label: 'Create job orders', data: role.h_2_create_job_orders ? 'Yes' : 'No', id: 5, show: true },
    { label: 'Created job orders: edit', data: role.h_2_1_edit_created_job_orders ? 'Yes' : 'No', id: 6, show: role.h_2_create_job_orders },
    { label: 'Created job orders: delete', data: role.h_2_2_delete_created_job_orders ? 'Yes' : 'No', id: 7, show: role.h_2_create_job_orders },
    { label: 'Manage job orders', data: role.h_3_manage_job_orders ? 'Yes' : 'No', id: 8, show: true },
    { label: 'Manage job orders: update status', data: role.h_3_1_update_job_order_status ? 'Yes' : 'No', id: 9, show: role.h_3_manage_job_orders },
    { label: 'Manage job orders: assign recruiters', data: role.h_3_2_assign_recruiters ? 'Yes' : 'No', id: 10, show: role.h_3_manage_job_orders },
    { label: 'Manage job orders: assign applicants', data: role.h_3_3_assign_applicants ? 'Yes' : 'No', id: 11, show: role.h_3_manage_job_orders },
    { label: 'Manage job orders: update application status', data: role.h_3_4_update_application_status ? 'Yes' : 'No', id: 12, show: role.h_3_manage_job_orders },
    { label: 'Manage job orders: remove applicant', data: role.h_3_5_remove_applicant ? 'Yes' : 'No', id: 13, show: role.h_3_manage_job_orders },
    { label: 'Managed job orders: edit', data: role.h_3_6_edit_job_order ? 'Yes' : 'No', id: 14, show: role.h_3_manage_job_orders },
    { label: 'Edit job orders: editable properties', data: returnArrayName(role.h_3_6_1_editable_properties, jobOrderProperties), id: 15, show: role.h_3_6_edit_job_order },
    { label: 'Manage job orders: delete', data: role.h_3_7_delete_job_order ? 'Yes' : 'No', id: 16, show: role.h_3_manage_job_orders },
  ]
  return (
    <DescriptionList title='Job order actions' items={items}/>
  )
}

export default JobOrderActionDetails
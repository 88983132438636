const actions = {
  FETCH_ALL_JOB_ORDERS: "FETCH_ALL_JOB_ORDERS",
  FETCH_JOB_ORDER_DETAILS: "FETCH_JOB_ORDER_DETAILS",
  UPDATE_JOB_ORDER: "UPDATE_JOB_ORDER",
  DELETE_JOB_ORDER: "DELETE_JOB_ORDER",
  ADD_JOB_ORDER: "ADD_JOB_ORDER",
  BATCH_UPDATE_STATUS: "BATCH_UPDATE_STATUS",
  BATCH_UPDATE_PRIORITY: "BATCH_UPDATE_PRIORITY",
  RESET_DATA: 'RESET_DATA',
  ADD_JOB_STATUS_LOG: 'ADD_JOB_STATUS_LOG',
  DELETE_JOB_STATUS_LOG: 'DELETE_JOB_STATUS_LOG',
  ADD_APPLICATION_LOG: 'ADD_APPLICATION_LOG',
  EDIT_APPLICATION_LOG: 'EDIT_APPLICATION_LOG',
  DELETE_APPLICATION_LOG: 'DELETE_APPLICATION_LOG',
  REMOVE_JOB_ORDER_RECRUITER: 'REMOVE_JOB_ORDER_RECRUITER',
  FETCHED_APPLICATIONS_LOG_HISTORY: 'FETCHED_APPLICATIONS_LOG_HISTORY',
  ADD_APPLICATION_TO_HISTORY: 'ADD_APPLICATION_TO_HISTORY',
  EDIT_APPLICATION_IN_HISTORY: 'EDIT_APPLICATION_IN_HISTORY',
  FETCHED_JOB_STATUS_LOG_HISTORY: 'FETCHED_JOB_STATUS_LOG_HISTORY',
  ADD_JOB_STATUS_TO_HISTORY: 'ADD_JOB_STATUS_TO_HISTORY',
};

export default actions;




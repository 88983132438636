import React from 'react'
import classNames from "../../utils/classNames"

export default function Tabs({ tabs, tabHandler }) {
  
  const handleChange = (e) => {
    tabHandler(e.target.value);
  }
  
  return (
    <div className="w-full">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block w-full ">
        <div className="border-b border-gray-200 w-full">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={tab.onClick}
                className={classNames(
                  tab.current
                    ? "border-thaleria-orange-600 text-thaleria-orange-700"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "grow py-4 px-1 text-center border-b-2 font-medium text-sm",
                  "cursor-pointer"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

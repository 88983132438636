function extractObjectDifferences (originalObj, newObj) {
  let differences = {}
  
  Object.keys(originalObj).forEach(key => {
    if ((originalObj[key] !== newObj[key])) {
      differences[key] = newObj[key];
    }
  })

  if (!Object.keys(differences).length) return false
  return differences
}

export default extractObjectDifferences
import React from 'react';
import DescriptionList from '../../components/descriptionLists/DescriptionList';
import dayjs from 'dayjs';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import role from '../../helpers/enum/role';
import utc from 'dayjs/plugin/utc'

function UserDetailsForm({ isLoading, user }) {

  dayjs.extend(utc)
  
  const { verifyArrayAccess } = useUserRoleVerifier();
  
  const items = [
    { label: 'First names', data: user.first_names, id: 1, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'first_names') },
    { label: 'Last names', data: user.last_names, id: 2, show: true},
    { label: 'Email', data: user.email, id: 3, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'email')},
    { label: 'Phone number', data: user.phone_nb, id: 4, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'phone_nb')},
    { label: 'User group', data: user?.userGroup?.name, id: 5, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'user_group_id')},
    { label: 'Role', data: user?.role?.a_name, id: 6, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'role_id')}, 
    { label: 'Price margin (%)', data: user.price_margin, id: 7, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'price_margin')}, 
    { label: 'Creation date', data: dayjs.utc(user.created_at).format('DD/MM/YYYY'), id: 8, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'created_at')},    
    { label: "Active", data: user?.active ? 'Yes' : 'No', id: 9, show: verifyArrayAccess(role.f_users.f_1_3_viewable_properties, 'active')}
  ]
  
  if (isLoading) return;
  
  return (
    <DescriptionList title={'User information'} items={items}/>
  )
}

export default UserDetailsForm
import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate } from 'react-router'
import useApi from '../../store/api/apiContext'
import role from '../../helpers/enum/role'
import UserGroupsTable from '../../containers/userGroups/UserGroupsTable'

function UserGroups({ access }) {
  const navigate = useNavigate();
  const [ userGroups, setUserGroups ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchUserGroups } = useApi();

  const fetchUserGroupsFromApi = async (source) => {
    fetchUserGroups(source).then(res => {
      setUserGroups(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchUserGroupsFromApi(source);
    return () => {
      source.cancel();
    }
  }, [])

  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton access={role.b_manage_user_groups} text='Add user group' linkTo='/user-groups/add'/>
      </>
    )
  }

  return (
    <PageWrapper title={'User groups'} topSection={renderTopSection()} access={access}>
      <UserGroupsTable userGroups={userGroups} isLoading={isLoading}/>
    </PageWrapper>
  )
}

export default UserGroups
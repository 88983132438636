import React, { useEffect, useState, useRef } from 'react'
import StatCard from '../../components/cards/StatCard'
import { ClockIcon, ThumbUpIcon, BadgeCheckIcon, ChatAlt2Icon } from '@heroicons/react/outline'
import Loader from '../../components/loaders/Loader'
import useApi from '../../store/api/apiContext'

function CurrentApplicationsStat() {

  // FIXME: Candidate names should only display first name if person cannot view/manage that candidate (since current applications depend on viewable job orders that may come with candidates that are not in user’s candidates scope)
  const { editCandidate, fetchCurrentApplications } = useApi()


  const titleRef = useRef(null)
  const [titleColor, setTitleColor] = useState('text-gray-900')

  const [ submitted, setSubmitted ] = useState([])
  const [ interviewing, setInterviewing ] = useState([])
  const [ clientAccepted, setClientAccepted ] = useState([])
  const [ placed, setPlaced ] = useState([])
  const [ showDetails, setShowDetails ] = useState(false) 
  const [ isLoading, setIsLoading ] = useState([]) 

  const removeCurrentlyPlaced = (id) => {
    let candidateData = {
      is_currently_placed: false
    }
    editCandidate(id, candidateData).then(res => {
      const newApplications = placed.filter(obj => obj.applicant_id != id)
      setPlaced(newApplications)
    })
  }

  useEffect(() => {
    fetchCurrentApplications().then(res => {
      const applications = res.data;
      let submitted = []
      let interviewing = []
      let clientAccepted = []
      let placed = []
      applications.forEach(obj => {
      obj.status === 2 && submitted.push(obj)
      obj.status === 3 && interviewing.push(obj)
      obj.status === 5 && clientAccepted.push(obj)
      obj.status === 6 && placed.push(obj)
      })
      setSubmitted(submitted)
      setInterviewing(interviewing)
      setClientAccepted(clientAccepted)
      setPlaced(placed)      
      setIsLoading(false);
    })  
  
  }, [])

  
  useEffect(() => {
    let obj = document.getElementById("main-section");
    obj.addEventListener("scroll", handleScroll);
  }, [])
  
  const handleScroll = () => {
    function getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    }
    const position = getOffset(titleRef.current)
    
    if (position.top < 280) {
      setTitleColor('text-white');
    }
    if (position.top >= 280 ) {
      setTitleColor('text-gray-900');
    }
  }


  return (
    <div className='rounded-md mt-6 p-4'>
      <div className="flex justify-between mb-5">
          <h3 className={`text-2xl font-medium ${titleColor} my-0 flex`} ref={titleRef}>Current applications</h3>
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <button
              onClick={() => setShowDetails(!showDetails)}
              type="button"
              className={`relative w-32 px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium "text-gray-700" hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600`}
            >{showDetails ? 'Hide' : 'Show all'}</button>
            </span>
        </div>
        <Loader isLoading={isLoading}>
        <dl className="grid grid-cols-1 grid-row-2 gap-5 md:grid-cols-2 xl:grid-cols-4">
          <StatCard label="Submitted" applications={submitted} icon={<ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />} number={submitted.length || 0} show={showDetails}/>  
          <StatCard label="Interviewing" applications={interviewing} icon={<ChatAlt2Icon className="h-6 w-6 text-white" aria-hidden="true" />} number={interviewing.length || 0} show={showDetails} />
          <StatCard label="Client Accepted" applications={clientAccepted} icon={<ThumbUpIcon className="h-6 w-6 text-white" aria-hidden="true" />} number={clientAccepted.length || 0} show={showDetails} />
          <StatCard label="Placed" applications={placed} removeCurrentlyPlaced={removeCurrentlyPlaced} icon={<BadgeCheckIcon className="h-6 w-6 text-white" aria-hidden="true" />} number={placed.length || 0} show={showDetails} />
        </dl>
      </Loader>
    </div>
  )
}

export default CurrentApplicationsStat
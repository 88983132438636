import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './router/AppRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import AppNotificationProvider from './store/appNotification/appNotificationProvider';
import axios from 'axios';
import { API_BASE_URL } from './config';
import AuthProvider from './store/auth/authProvider';
import ApiProvider from './store/api/apiProvider';
import TokenExpiry from './containers/auth/TokenExpiry';
import JobOrdersProvider from './store/jobOrders/jobOrdersProvider';
import SocketProvider from './store/socket/socketProvider';

//axios setup
axios.defaults.baseURL = API_BASE_URL;



ReactDOM.render(
  <React.StrictMode>
    <AppNotificationProvider>
      <SocketProvider>
        <AuthProvider>
          <ApiProvider>
            <JobOrdersProvider>
              <Router>
                <TokenExpiry />
                <AppRouter />
              </Router>
            </JobOrdersProvider>
          </ApiProvider>
        </AuthProvider>
      </SocketProvider>
    </AppNotificationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
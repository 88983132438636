import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { useState, useEffect } from 'react'

function PasswordInput({label, onChange, errors, value, disabled}) {
  const [ error, setError ] = useState({});
  
  useEffect(() => {
    if (!errors?.length) return;
    let myError = errors.find(error => error.field === label);
    if (myError) setError(myError);
    if (!myError) setError({});

  }, [errors]);

  return (
    <>
      <label className="flex text-sm font-medium text-gray-700">
        {label || 'Password'}
        { error?.msg && <span className="text-red-400 flex"> <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1"/> {error.msg}</span>  }
      </label>
      <input
        name="password"
        type="password"
        onChange={onChange}
        value={value}
        autoComplete="current-password"
        required
        disabled={disabled}
        className={`appearance-none mt-1 focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 ${disabled && "bg-gray-200"} focus:shadow-inner block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
      />
    </>
  )
}

export default PasswordInput
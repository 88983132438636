import React, { useEffect, useState } from 'react'
import Checkbox from '../../../components/inputs/Checkbox';
import Toggle from '../../../components/inputs/Toggle'
import FormInputLabelAndDescription from '../../../components/forms/FormInputLabelAndDescription';
import USER_PROPERTIES from '../../../helpers/enum/userProperties';
import ReactSelect from '../../../components/inputs/ReactSelect';
import { ALL_ACCESS_UUID } from '../../../config';

function ManageUsers({ 
  newRole, 
  setNewRole,
  viewAllUserProperties,
  viewableUserProperties,
  editableUserProperties,
  setEditableUserProperties,
  setEditAllUserProperties,
  editAllUserProperties,
  userProperties,
  manageAllViewableUserGroups,
  setManageAllViewableUserGroups,
  manageAllViewableRoles,
  setManageAllViewableRoles,
  userGroups,
  roles
}) {
  
  const [manageUsersAllowedUserGroupOptions, setManageUsersAllowedUserGroupOptions] = useState([])
  const [selectedAllowedUserGroups, setSelectedAllowedUserGroups] = useState([])

  const [manageUsersAllowedRolesOptions, setManageUsersAllowedRolesOptions] = useState([])
  const [selectedAllowedRoles, setSelectedAllowedRoles] = useState([])

  useEffect(() => {
    if (!newRole.f_2_manage_users) return;
    setSelectedAllowedUserGroups([]);
    setManageUsersAllowedUserGroupOptions([]);
    setSelectedAllowedRoles([]);
    setManageUsersAllowedRolesOptions([]);
    setManageAllViewableUserGroups(true)
    setManageAllViewableRoles(true)
  }, [ newRole.f_1_1_allowed_user_group_ids,  newRole.f_1_2_allowed_role_ids ])

  const handleToggle_f_2_manage_users = () => {
    let newStatus = !newRole.f_2_manage_users;
    resetManageUsers(newStatus);
  }


  const changeHandler_f_2_4_edit_users = (e) => {
    let newStatus = !newRole.f_2_4_edit_users;
    setNewRole(prev => ({...prev, f_2_4_edit_users: newStatus }))
    if (newStatus) {
      if (viewAllUserProperties) {
        setEditAllUserProperties(true);
        setNewRole(prev => ({...prev, f_2_4_1_editable_properties: [ALL_ACCESS_UUID]}))
      } else {
        prepareEditablePropertiesOptions();
      }
    }
    if (!newStatus) {
      setEditAllUserProperties(false);
      setNewRole(prev => ({...prev, f_2_4_1_editable_properties: []}))
      setEditableUserProperties([]);
    }
  }

  const editAllUserPropertiesChangeHandler = () => {
    let newStatus = !editAllUserProperties;
    if (newStatus) setNewRole(prev => ({...prev, f_2_4_1_editable_properties: [ALL_ACCESS_UUID]}))
    if (!newStatus) prepareEditablePropertiesOptions();
    setEditAllUserProperties(newStatus);
  }

  const handleUpdateEditableUserPropertyChange = (property) => {
    let newArray = editableUserProperties.map(element  => {
      if (element === property) return { ...element, checked: !property.checked };
      return element;
    })
    setEditableUserProperties(newArray);
  }

  //effect to sync editable property checkboxes with newRole state
  useEffect(() => {
    if (!editableUserProperties.length) return;
    let editableProperties = [];
    editableUserProperties.forEach(property => {
      if (property.checked) {
        editableProperties.push(property);
      }
    })
    setNewRole(prev => ({...prev, f_2_4_1_editable_properties: editableProperties.map(property => property.id) }));
  }, [editableUserProperties])

  useEffect(() => {
    if (manageAllViewableRoles) {
      setNewRole(prev => ({...prev, f_2_2_allowed_role_ids: [ALL_ACCESS_UUID] }))
    } else {
      setNewRole(prev => ({...prev, f_2_2_allowed_role_ids: [] }))
      prepareManageRolesScope();
    }
  }, [manageAllViewableRoles])

  useEffect(() => {
    if (manageAllViewableUserGroups) {
      setNewRole(prev => ({...prev, f_2_1_allowed_user_group_ids: [ALL_ACCESS_UUID] }))
    } else {
      setNewRole(prev => ({...prev, f_2_1_allowed_user_group_ids: [] }))
      prepareManageUserGroupsScope();
    }
  }, [manageAllViewableUserGroups])
  
  const handleToggleManageViewableRoles = () => {
    setManageAllViewableRoles(!manageAllViewableRoles)
  }
  
  const handleToggleManageViewableUserGroups = () => {
    setManageAllViewableUserGroups(!manageAllViewableUserGroups)
  }

  //effect to sync allowed usergroups with newRole state
  useEffect(() => {
    if (!selectedAllowedUserGroups.length) return;
    setNewRole(prev => ({...prev, f_2_1_allowed_user_group_ids: selectedAllowedUserGroups.map(option => option.value) }));
  }, [selectedAllowedUserGroups])

  const handleChangeSelectedAllowedUserGroups = event => {
    if (!event) setSelectedAllowedUserGroups([]);
    setSelectedAllowedUserGroups(event.map((e) => e)); 
  };

  //effect to sync allowed roles with newRole state
  useEffect(() => {
    if (!selectedAllowedRoles.length) return;
    setNewRole(prev => ({...prev, f_2_2_allowed_role_ids: selectedAllowedRoles.map(option => option.value) }));
  }, [selectedAllowedRoles])

  const handleChangeSelectedAllowedRoles = event => {
    if (!event) setSelectedAllowedRoles([]);
    setSelectedAllowedRoles(event.map((e) => e)); 
  };

  //helper functions
  const resetManageUsers = (newStatus) => {
    setManageAllViewableRoles(newStatus)
    setManageAllViewableUserGroups(newStatus)
    setNewRole(prev => ({...prev,
      f_2_manage_users: newStatus,
      f_2_1_allowed_user_group_ids: [],
      f_2_2_allowed_role_ids: [],
      f_2_3_create_users: false,
      f_2_4_edit_users: false,
      f_2_4_1_editable_properties: [],
      f_2_5_delete_users: false,
      f_2_6_reset_user_password: false
    }))
    setEditableUserProperties([]);
  }

  const prepareEditablePropertiesOptions = () => {
    let viewableProperties = [];
    if (!viewAllUserProperties) {
      viewableUserProperties.forEach(property => {
        if (property.checked) {
          viewableProperties.push(property);
        }
      })
    } else {
      viewableProperties = userProperties.map(property => (
        { label: USER_PROPERTIES[property], id: property, checked: false }
      ))
    }
    let editableProperties = viewableProperties.map(property => {
      //set default option false for editable settings
      return ({ ...property, checked: false});
    }) 
    let filteredArray = editableProperties.filter(property => (
      //remove properties which aren't editable
      property.id !== 'id' && 
      property.id !== 'created_at' && 
      property.id !== 'deleted_at' && 
      property.id !== 'updated_at'
    ))
    setEditableUserProperties(filteredArray);
    setNewRole(prev => ({...prev, f_2_4_1_editable_properties: []}));
  }

  const prepareManageUserGroupsScope = () => {
    let userGroupOptions = []
    if (newRole.f_1_1_allowed_user_group_ids[0] === ALL_ACCESS_UUID) {
      userGroups.forEach(userGroup => userGroupOptions.push({ label: userGroup.name, value: userGroup.id }))  
    } else {
      userGroups.forEach(userGroup => {
        if (newRole.f_1_1_allowed_user_group_ids.includes(userGroup.id)) userGroupOptions.push({ label: userGroup.name, value: userGroup.id })
      })
    }
    setManageUsersAllowedUserGroupOptions(userGroupOptions)
  }

  const prepareManageRolesScope = () => {
    let roleOptions = [];
    if (newRole.f_1_2_allowed_role_ids[0] === ALL_ACCESS_UUID) {
      //check if there are selected 'manage user group choices' and return corresponding roles
      //FIXME: Technically this will never happen during the first setting of options?
      if (selectedAllowedUserGroups.length) {
        userGroups.forEach(group1 => {
          if (selectedAllowedUserGroups.some(group2 => group2.id === group1.id)) {
            group1.roles.forEach(role => {
              //push if not already there
              if (!roleOptions.some(role2 => role2.id === role.id)) roleOptions.push({value: role.id, label: role.a_name})
            })
          }
        })
      //else, check if there are selected 'view user group choices' and return corresponding roles
      } else if (newRole.f_1_1_allowed_user_group_ids[0] !== ALL_ACCESS_UUID) {
        userGroups.forEach(group => {
          if (newRole.f_1_1_allowed_user_group_ids.some(id => id === group.id)) {
            group.roles.forEach(role => {
              //push if not already there
              if (!roleOptions.some(role2 => role2.id === role.id)) roleOptions.push({value: role.id, label: role.a_name})
            })
          }
        })
      //else, return all roles 
      } else roles.forEach(role => roleOptions.push({ label: role.a_name, value: role.id }))  
      
    } else {
      roles.forEach(role => {
        if (newRole.f_1_2_allowed_role_ids.includes(role.id)) roleOptions.push({ label: role.a_name, value: role.id })
      })
    }
    setManageUsersAllowedRolesOptions(roleOptions)
  }

  //effect to sync allowed role options with selected allowed user groups
  useEffect(() => {
    let roleOptions = [];
    if (newRole.f_1_2_allowed_role_ids[0] === ALL_ACCESS_UUID) {
      //check if there are selected 'manage user group choices' and return corresponding roles
      if (selectedAllowedUserGroups.length) {
        userGroups.forEach(group1 => {
          if (selectedAllowedUserGroups.some(group2 => group2.value === group1.id)) {
            group1.roles.forEach(role => {
              //push if not already there
              if (!roleOptions.some(role2 => role2.id === role.id)) roleOptions.push({value: role.id, label: role.a_name})
            })
          }
        })
      //else, check if there are selected 'view user group choices' and return corresponding roles
      } else if (newRole.f_1_1_allowed_user_group_ids[0] !== ALL_ACCESS_UUID) {
        userGroups.forEach(group => {
          if (newRole.f_1_1_allowed_user_group_ids.some(id => id === group.id)) {
            group.roles.forEach(role => {
              //push if not already there
              if (!roleOptions.some(role2 => role2.id === role.id)) roleOptions.push({value: role.id, label: role.a_name})
            })
          }
        })
      //else, return all roles 
      } else roles.forEach(role => roleOptions.push({ label: role.a_name, value: role.id }))  
      
    } else {
      roles.forEach(role => {
        if (newRole.f_1_2_allowed_role_ids.includes(role.id)) roleOptions.push({ label: role.a_name, value: role.id })
      })
    }
    setManageUsersAllowedRolesOptions(roleOptions)
    setSelectedAllowedRoles([])
  }, [selectedAllowedUserGroups])


  return (
    <>
      <div className="col-span-6">
        <Toggle bold label='Manage users' description={'Allows to specify admin actions for managing users (create, edit, delete)'} value={newRole.f_2_manage_users} onChange={handleToggle_f_2_manage_users}/>
      </div>
      {newRole.f_2_manage_users && 
        <>
          <div className="col-span-6">
            <Checkbox
              label={'Manage all viewable user groups'} 
              onChange={handleToggleManageViewableUserGroups}
              checked={manageAllViewableUserGroups} 
            />
          </div>
          {!manageAllViewableUserGroups &&
            <div className="col-span-6">
              <ReactSelect 
                label={'Allowed user groups'}
                options={manageUsersAllowedUserGroupOptions}
                value={selectedAllowedUserGroups}
                onChange={handleChangeSelectedAllowedUserGroups}
                isMulti
                placeholder='Select allowed user groups'
              />
            </div>
          }
          <div className="col-span-6">
            <Checkbox
              label={'Manage all viewable roles'} 
              onChange={handleToggleManageViewableRoles}
              checked={manageAllViewableRoles} 
            />
          </div>
          {!manageAllViewableRoles &&
            <div className="col-span-6">
              <ReactSelect 
                label={'Allowed roles'}
                options={manageUsersAllowedRolesOptions}
                value={selectedAllowedRoles}
                onChange={handleChangeSelectedAllowedRoles}
                isMulti
                placeholder='Select allowed roles'
                />
            </div>
          }
          <div className="col-span-6">
            <Checkbox 
              label='Create users' 
              checked={newRole.f_2_3_create_users} 
              onChange={() => setNewRole(prev => ({ ...prev, f_2_3_create_users: !newRole.f_2_3_create_users }))}
            />
          </div>
          <div className="col-span-6">
            <Checkbox 
              label='Delete users' 
              checked={newRole.f_2_5_delete_users} 
              onChange={() => setNewRole(prev => ({ ...prev, f_2_5_delete_users: !newRole.f_2_5_delete_users }))}
            />
          </div>
          <div className="col-span-6">
            <Checkbox 
              label='Reset user password' 
              checked={newRole.f_2_6_reset_user_password} 
              onChange={() => setNewRole(prev => ({ ...prev, f_2_6_reset_user_password: !newRole.f_2_6_reset_user_password }))}
            />
          </div>
          <div className="col-span-6">
            <Checkbox 
              label='Edit users' 
              checked={newRole.f_2_4_edit_users} 
              onChange={changeHandler_f_2_4_edit_users}
            />
          </div>
          {newRole.f_2_4_edit_users && viewAllUserProperties && 
            <div className="col-span-6">
              <Checkbox
                label={'Edit all user properties'} 
                checked={editAllUserProperties} 
                onChange={editAllUserPropertiesChangeHandler}
              />
            </div>
          }
          {!!editableUserProperties.length &&
            <>
              <div className="col-span-6">
                <FormInputLabelAndDescription label={'Editable properties'}/>
              </div>
              {editableUserProperties.map((property) => (
                <div className="col-span-2" key={property.id}>
                  <Checkbox 
                    description={property.label} 
                    checked={property.checked} 
                    onChange={() => handleUpdateEditableUserPropertyChange(property)}
                  />
                </div>
              ))}
            </>
          }
        
        </>
      }
    </>
  )
}

export default ManageUsers
import React, { useState, useRef, useEffect } from 'react';
import WhiteButton from '../buttons/WhiteButton';
import PrivateImage from '../privateImage/PrivateImage';
import useApi from '../../store/api/apiContext';
import useAuth from '../../store/auth/authContext';
import useAppNotification from '../../store/appNotification/appNotificationContext';
import ProfilePicture from '../profilePicture/ProfilePicture';

/* const BlankProfilePictureIcon = props => {
  return (
    <svg className={`text-gray-300 ${props.className}`} fill="currentColor" viewBox="0 0 24 24">
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  );
}; */

function InputPhoto({ setCroppedImage, setNewPicture, newImage, onChange, label, user }) {
  const [image, setImage] = useState(newImage);
  const inputRef = useRef(null);
  const { setAuth, auth: { currentUser } } = useAuth();
  const { showSuccessNotification } = useAppNotification();
  
  const { removeProfilePicture } = useApi();

  useEffect(() => {
    setImage(newImage);
  }, [newImage]);

  const handleInputChange = event => {
    if (event.target.files.length == 0) return;

    setImage(URL.createObjectURL(event.target.files[0]));
    if (onChange) onChange(event.target.files[0]);
  };

  const onChangeClick = () => {
    inputRef.current.click();
  };

  const onRemoveClick = () => {
    if (image) {
      setCroppedImage(null)
      setNewPicture(null)
      setImage(null)
    }
    if (user?.profile_pic_url && !image) removeProfilePicture().then(() => {
      showSuccessNotification('Successfully removed profile picture')
      setAuth(prev => ({
        ...prev, 
        profilePicture: null,
        currentUser: { ...prev.currentUser, profile_pic_url: null } 
      }));
    });
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-4 flex items-center">
        {image ? 
          <img className="flex h-36 w-36 rounded-full overflow-hidden bg-gray-100" src={image}/> : <ProfilePicture user={currentUser} xxl/>
        }
        <div className='ml-4 flex flex-col space-y-2'>
          <WhiteButton 
            onClick={onChangeClick}
            text='Change'
          />
          {(image || user?.profile_pic_url) && 
            <WhiteButton 
              onClick={onRemoveClick}
              text='Remove'
            />
          }
        </div>
        <input type="file" accept="image/*" ref={inputRef} style={{ display: 'none' }} onChange={handleInputChange} />
      </div>
    </div>
  );
}

export default InputPhoto;

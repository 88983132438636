import React, { useState, useEffect } from 'react'
import CandidateActionsDetails from './CandidateActionsDetails'
import JobOrderActionsDetails from './JobOrderActionsDetails'
import UserActionsDetails from './UserActionsDetails'
import KeyAdminActionsDetails from './KeyAdminActionsDetails'
import Loader from '../../../components/loaders/Loader'
import useApi from '../../../store/api/apiContext'
import CommentActionsDetails from './CommentActionsDetails'
import AttachmentActionsDetails from './AttachmentActionsDetails'

function RoleDetails({ role }) {

  const [ userGroups, setUserGroups ] = useState(null);
  const [ roles, setRoles ] = useState(null);
  const [ frameworkContracts, setFrameworkContracts] = useState(null);

  const [isLoading, setIsLoading] = useState(true)
  const { fetchUserGroups, fetchRoles, fetchFrameworkContracts } = useApi();

  useEffect(() => {
    fetchUserGroups().then(res => setUserGroups(res.data));
    fetchRoles().then(res => setRoles(res.data));
    fetchFrameworkContracts().then(res => {
      setFrameworkContracts(res.data);
    });
  }, [])  

  useEffect(() => {
    //FIXME: If there are no userGroups nor any frameworkContracts, the page will load forever. I made default state null and loading stops when those states are updated. Even if the arrays are empty, the page will still render.
    if (userGroups && roles && frameworkContracts) setIsLoading(false);
  }, [ userGroups, roles, frameworkContracts])
  
  const items = [
    { label: 'Name', data: role.a_name, id: 1, show: true },
  ]

  return (
    <Loader isLoading={isLoading}>
      <div className='space-y-4'>
        <KeyAdminActionsDetails role={role}/>
        <UserActionsDetails role={role} userGroups={userGroups} roles={roles}/>
        <CandidateActionsDetails role={role} userGroups={userGroups}/>
        <JobOrderActionsDetails role={role} frameworkContracts={frameworkContracts}/>
        <CommentActionsDetails role={role}/>
        <AttachmentActionsDetails role={role}/>
      </div>
    </Loader>
  )
}

export default RoleDetails
import dayjs from 'dayjs';

const convertItemsToCSVFormat = (array, columns) => {
  let str = '';
  let header = '';

  columns.forEach(key => {
    if (header !== '') header += ',';

    header += key;
  });
  str += `${header}\r\n`;


  for (let i = 0; i < array.length; i++) {
    let line = '';

    columns.forEach(key => {
      if (line !== '') line += ',';
      let item = array[i][key];
    

      if (item instanceof Object || Array.isArray(item) || !item) {
        item = '-';
      }

      line += typeof item === 'string' ? item.replace(/,/g, '') : item;
    
    });

    str += `${line}\r\n`;
  } 
  return str;
};

export default (items, columns, fileTitle) => {
  // Convert Object to JSON
  const csv = convertItemsToCSVFormat(items, columns);

  var localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat)


  const exportedFilenmae =
    fileTitle ? `${fileTitle}.csv` : `contracts-dashboard-${dayjs().format('DD/MM/YYYY/HH/mm/ss')}.csv`;

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');

    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import classNames from "../../utils/classNames";
import useAppNotification from "../../store/appNotification/appNotificationContext";

function ContextMenu({
  data,
  elementClassNameTag = "context-menu",
  menuOptions,
  contextMenu,
  setContextMenu,
  setRowHighlight,
}) {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [anchorX, setAnchorX] = useState();
  const [anchorY, setAnchorY] = useState();
  const [screenClickX, setScreenClickX] = useState()
  const [screenClickY, setScreenClickY] = useState()

  const { appNotificationTypes, showAppNotification } = useAppNotification();

  const menuRef = useRef(null);

  const contentSection = document.getElementById('main-section')

  const contextMenuHandler = (event) => {
    event.preventDefault();
    let item;

    var path = event.path || (event.composedPath && event.composedPath());
    if (path) {
      item = path.find((el) => el.className?.includes(elementClassNameTag));
    } else {
      showAppNotification(
        appNotificationTypes.WARNING_YELLOW,
        "Context menu issue",
        "Your browser does not support this context menu"
      );
    }

    let id = item?.id;
    let selectedItem;
    const offset = contentSection.getBoundingClientRect();
    setAnchorX(event.clientX - offset.x);
    setAnchorY(event.clientY - offset.y);
    setScreenClickX(event.clientX);
    setScreenClickY(event.clientY);


    setAnchorPoint({ x: 0, y: 0 });

    if (data[0]?.original) {
      selectedItem = data.find((object) => id == object?.original?.id);
    } else {
      selectedItem = data.find((object) => id == object.id);
    }
    setContextMenu(menuOptions(selectedItem));
    setRowHighlight(item.id);
  }

  const handleClick = useCallback(() => {
    if (contextMenu) {
      setContextMenu(null);
      setRowHighlight(false);
      return;
    } else return null;
  }, [contextMenu]);

  useEffect(() => {
    const items = document.getElementsByClassName(elementClassNameTag);
    Array.from(items).forEach((item) => {
      item.addEventListener("contextmenu", contextMenuHandler);
    });
    document.addEventListener("click", handleClick);
    return () => {
      Array.from(items).forEach((item) => {
        item.removeEventListener("contextmenu", contextMenuHandler);
      });
      document.removeEventListener("click", handleClick);
    };
  });

  

  useEffect(() => {
    //Check if click happened close to the bottom or right edge, than add offset to anchorpoint
    let x = anchorX;
    let y = anchorY;
    if (screenClickX + menuRef.current.clientWidth > window.window.innerWidth)
      x = anchorX - menuRef.current.clientWidth;
    if (screenClickY + menuRef.current.clientHeight > window.window.innerHeight)
      y = anchorY - menuRef.current.clientHeight;
    setAnchorPoint({ x: x, y: y });
    
  }, [contextMenu]);
  
  return (
    <div
      style={{
        top: anchorPoint.y,
        left: anchorPoint.x,
      }}
      ref={menuRef}
      className={`absolute ${
        !contextMenu && "hidden"
      } border border-gray-200/50 min-w-[230px] border-dotted z-50 text-sm font-medium text-gray-300 bg-gray-800 rounded-lg shadow-md divide-y divide-gray-600`}
      >
      {contextMenu?.length &&
        contextMenu.map((array, index) => (
          <div className="p-2">
            {/* {index !== 0 && <div className="border-b border-gray-700" />} */}
            {array.map((item, index) => (
              <div>
                {item.linkTo ? (
                  <Link
                    to={item.linkTo}
                    className={classNames(
                      "p-2 group flex w-full rounded-lg text-sm hover:bg-gray-700 hover:text-white ",
                      item.show === false && "hidden"
                    )}
                    key={item.text}
                  >
                    {item?.icon && (
                      <item.icon className="mr-1 group-hover:text-white text-gray-400 w-5 h-5" />
                    )}
                    <span className="ml-1">{item.text}</span>
                  </Link>
                ) : (
                  <button
                    className={classNames(
                      "p-2 group flex w-full rounded-lg text-sm hover:bg-gray-700 hover:text-white",
                      item.show === false && "hidden"
                    )}
                    onClick={item.onClick}
                  >
                    {item?.icon && (
                      <item.icon className="mr-1 group-hover:text-white text-gray-400  w-5 h-5" />
                    )}
                    <span className="ml-1">{item.text}</span>
                  </button>
                )}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
  // }
}

export default ContextMenu;

import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from '../pages/Dashboard';
import Users from '../pages/users/Users';
import Login from '../pages/Login';
import axios from "axios";
import useAppNotification from "../store/appNotification/appNotificationContext";
import useAuth from "../store/auth/authContext";
import appNotificationTypes from "../helpers/enum/appNotificationTypes";
import UserDetails from "../pages/users/UserDetails";
import AddUser from "../pages/users/AddUser";
import role from "../helpers/enum/role";
import Roles from '../pages/roles/Roles';
import AddRole from '../pages/roles/AddRole';
import EditRole from '../pages/roles/EditRole';
import UserGroups from "../pages/userGroups/UserGroups";
import AddUserGroup from "../pages/userGroups/AddUserGroup"
import FrameworkContracts from "../pages/frameworkContracts/FrameworkContracts"
import AddFrameworkContract from "../pages/frameworkContracts/AddFrameworkContract";
import EditUser from '../pages/users/EditUser';
import Clients from '../pages/clients/Clients'
import AddClients from '../pages/clients/AddClient'
import JobOrders from '../pages/jobOrders/JobOrders'
import UserGroupDetails from '../pages/userGroups/UserGroupDetails'
import EditUserGroup from "../pages/userGroups/EditUserGroup";
import FrameworkContractDetails from "../pages/frameworkContracts/FrameworkContractDetails";
import EditFrameworkContract from "../pages/frameworkContracts/EditFrameworkContract";
import ClientDetails from "../pages/clients/ClientDetails";
import EditClient from "../pages/clients/EditClient";
import RoleDetails from "../pages/roles/RoleDetails";
import AddJobOrder from "../pages/jobOrders/AddJobOrder"
import JobOrderDetails from "../pages/jobOrders/JobOrderDetails"
import EditJobOrder from "../pages/jobOrders/EditJobOrder"
import Candidates from "../pages/candidates/Candidates"
import CandidateDetails from "../pages/candidates/CandidateDetails"
import AddCandidate from "../pages/candidates/AddCandidate"
import EditCandidate from "../pages/candidates/EditCandidate";
import MyAccount from "../pages/MyAccount";
import ReleaseNotes from "../pages/releaseNotes/ReleaseNotes";
import TemporaryPasswordPage from "../pages/TempPassword";
import Applications from "../pages/applications/Applications";
import MyNotifications from "../pages/notifications/MyNotifications";
import JobStatusHistory from "../pages/jobStatusLog/JobStatusHistory";
export default function App() {
  const { logout } = useAuth();
  const { showAppNotification } = useAppNotification();
  const token = localStorage.getItem('jwtToken');

  useEffect(() => {
    //axios setup
    axios.interceptors.response.use(
      response => response,
      error => {
        const { response } = error;
        response?.status != 403 && showAppNotification(
          appNotificationTypes.WARNING_YELLOW, 
          error.response?.data?.error?.type || 'Server Error',
          error.response?.data?.error?.message || 'An error occurred. Please try again later.',
        );
        if (response && response.status === 403) {
          logout(true);
        }
        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/temp-password" element={<TemporaryPasswordPage />} />
      {/* ACCOUNT */}
      <Route path="/my-account" element={<MyAccount />} />
      
      {/* USERS */}
      <Route path="/users" element={<Users access={role.f_users.f_1_view_users}/>} />
      <Route path="/users/add" element={<AddUser access={role.f_users.f_2_3_create_users}/>} />
      <Route path="/users/edit/:id" element={<EditUser access={role.f_users.f_2_4_edit_users}/>} />
      <Route path="/users/:id" element={<UserDetails access={role.f_users.f_1_view_users}/>} />
      {/* ROLES */}
      <Route path="/roles" element={<Roles access={role.c_manage_roles}/>} />
      <Route path="/roles/add" element={<AddRole access={role.c_manage_roles}/>} />
      <Route path="/roles/:id" element={<RoleDetails access={role.c_manage_roles}/>} />
      <Route path="/roles/edit/:id" element={<EditRole access={role.c_manage_roles}/>} />
      {/* USERGROUPS */}
      <Route path="/user-groups" element={<UserGroups access={role.b_manage_user_groups}/>} />
      <Route path="/user-groups/add" element={<AddUserGroup access={role.b_manage_user_groups}/>} />
      <Route path="/user-groups/:id" element={<UserGroupDetails access={role.b_manage_user_groups}/>} />
      <Route path="/user-groups/edit/:id" element={<EditUserGroup access={role.b_manage_user_groups}/>} />
      {/* FRAMEWORK CONTRACTS */}
      <Route path="/fw-contracts" element={<FrameworkContracts access={role.d_manage_framework_contracts}/>} />
      <Route path="/fw-contracts/add" element={<AddFrameworkContract access={role.d_manage_framework_contracts}/>} />
      <Route path="/fw-contracts/:id" element={<FrameworkContractDetails access={role.d_manage_framework_contracts}/>} />
      <Route path="/fw-contracts/edit/:id" element={<EditFrameworkContract access={role.d_manage_framework_contracts}/>} />
      {/* CLIENTS */}
      <Route path="/clients" element={<Clients access={role.e_manage_clients}/>} />
      <Route path="/clients/add" element={<AddClients access={role.e_manage_clients}/>} />
      <Route path="/clients/:id" element={<ClientDetails access={role.e_manage_clients}/>} />
      <Route path="/clients/edit/:id" element={<EditClient access={role.e_manage_clients}/>} />
      {/* JOB ORDERS */}
      <Route path="/job-orders" exact element={<JobOrders access={role.h_job_orders.h_1_view_job_orders}/>} />
      <Route path="/job-orders/:id" element={<JobOrderDetails access={role.h_job_orders.h_1_view_job_orders}/>} />
      <Route path='/job-orders/status-log' exact element={<JobStatusHistory access={role.h_job_orders.h_1_view_job_orders}/>} />
      <Route path="/job-orders/add" element={<AddJobOrder access={role.h_job_orders.h_2_create_job_orders}/>} />
      <Route path="/job-orders/edit/:id" element={<EditJobOrder access={[role.h_job_orders.h_3_6_edit_job_order, role.h_job_orders.h_2_1_edit_created_job_orders]}/>} />
      {/* CANDIDATES */}
      <Route path="/candidates" element={<Candidates access={[role.g_candidates.g_4_manage_candidates, role.g_candidates.g_1_create_my_candidate]}/>} />
      <Route path="/candidates/:id" element={<CandidateDetails access={[role.g_candidates.g_4_manage_candidates, role.g_candidates.g_1_create_my_candidate]}/>} />
      <Route path='/applications-log' exact element={<Applications access={role.h_job_orders.h_1_view_job_orders}/>} />
      <Route path="/candidates/add" element={<AddCandidate access={[role.g_candidates.g_4_2_create_candidates, role.g_candidates.g_1_create_my_candidate]}/>} />
      <Route path="/candidates/edit/:id" element={<EditCandidate access={[role.g_candidates.g_2_edit_my_candidate, role.g_candidates.g_4_3_edit_candidates]}/>} />
      {/* APPLICATIONS */}

      {/* NOTIFICATIONS */}
      <Route path='notifications' element={<MyNotifications />} />
      
    {/* RELEASE NOTES */}
      <Route path="/release-notes" element={<ReleaseNotes />} />

      <Route path="*" element={<Navigate to={token ? '/dashboard' : '/login'}/>} />
    </Routes>
  );
}
import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import EditClientForm from '../../containers/clients/EditClientForm'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import axios from 'axios'
import useNavigateBack from '../../hooks/useNavigateBack'


function EditClient({ access }) {
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const { fetchClient } = useApi();
  const [ isLoading, setIsLoading ] = useState(true);

  const [ client, setClient ] = useState(null)

  
  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchClient(id, source).then(res => {
      setClient(res.data);
      setIsLoading(false)
    })
    return () => {
      source.cancel();
    }; 
  }, [])  
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigateBack(`/clients`) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Edit client'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <EditClientForm client={client} />
      </Loader>
    </PageWrapper>
  )
}

export default EditClient
import React from 'react'
import DescriptionList from '../../components/descriptionLists/DescriptionList';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

function FrameworkContractDetailsForm({ frameworkContract }) {
  dayjs.extend(utc)

  const items = [
    { label: 'Contract reference', data: frameworkContract.contract_ref, id: 1, show: true },
    { label: 'Description', data: frameworkContract.description, id: 2, show: true },
    { label: 'Contract start', data: dayjs.utc(frameworkContract.contract_start).format('DD/MM/YYYY'), id: 3, show: true },   
    { label: 'Contract end', data: dayjs.utc(frameworkContract.contract_end).format('DD/MM/YYYY'), id: 4, show: true },
  ]

  return (
    <DescriptionList title={'Framework contract information'} items={items}/>
  )
}

export default FrameworkContractDetailsForm
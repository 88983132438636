import React from 'react';
import { useAsyncDebounce } from 'react-table';
import SearchInput from '../../../components/inputs/SearchField';

function GlobalFilter({
  globalFilter,
  preGlobalFilteredRows,
  setGlobalFilter
}) {

  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);

  return (
    <SearchInput 
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`${count} records...`}
    />
  )
}

export default GlobalFilter;

import actions from './actions';
import axios from 'axios'

export const initialState = {
  allJobOrders: [],
  jobOrderDetails: {},
  applicationHistory: [],
  jobStatusHistory: [],
  isLoading: true,
};

export function jobOrdersReducer(state, action) {
  switch (action.type) {
    case actions.RESET_DATA:
      return initialState;
      
    case actions.FETCH_ALL_JOB_ORDERS:
      return {
        ...state,
        allJobOrders: action.payload,
        isLoading: false,
      };

    case actions.FETCH_JOB_ORDER_DETAILS:
      const updatedAllJobOrdersWithJoDetails = state.allJobOrders.map(jo => jo.id === action.payload.id ? {...jo, ...action.payload} : jo)
      return {
        ...state,
        jobOrderDetails: action.payload,
        allJobOrders: updatedAllJobOrdersWithJoDetails,
      };

    case actions.ADD_JOB_ORDER:
      return {
        ...state,
        allJobOrders: [action.payload, ...state.allJobOrders],
      };

    case actions.UPDATE_JOB_ORDER:
      let updatedJobOrder = action.payload;
      const updateJobOrderNewAllJobOrdersArray = state.allJobOrders.map((jo) =>
      jo.id === updatedJobOrder.id ? {...jo, ...updatedJobOrder} : jo
      );
      const updateJobOrderNewJobOrderDetails = state.jobOrderDetails?.id === updatedJobOrder.id
      ? { ...state.jobOrderDetails, ...updatedJobOrder }
      : state.jobOrderDetails;
      return {
        ...state,
        allJobOrders: updateJobOrderNewAllJobOrdersArray,
        jobOrderDetails: updateJobOrderNewJobOrderDetails  
      };

    case actions.BATCH_UPDATE_STATUS:
      const { arrayOfSuccess } =
        action.payload;
      //update successfull ones
      const newArrayStatus = state.allJobOrders.map((order) => {
        const updatedLog = arrayOfSuccess.find(
          (log) => log.job_order_id === order.id
        );
        if (updatedLog) {
          return {
            ...order,
            jobStatusLog: [updatedLog, ...order.jobStatusLog],
          };
        } else return order;
      });

      let updatedJobOrderDetails = false;
      const updatedJobOrderDetailsLog = arrayOfSuccess.find((log) => log.job_order_id === state.jobOrderDetails?.id);
      if (updatedJobOrderDetailsLog) {
        updatedJobOrderDetails = {
          ...state.jobOrderDetails,
          jobStatusLog: [
            updatedJobOrderDetailsLog,
            ...state.jobOrderDetails.jobStatusLog,
          ],
        };
      }

      return {
        ...state,
        allJobOrders: newArrayStatus,
        jobOrderDetails: updatedJobOrderDetails ? updatedJobOrderDetails : state.jobOrderDetails,
      };

    case actions.BATCH_UPDATE_PRIORITY:
      let idArrayPriority = action.payload.idArray;
      let newPriority = action.payload.newPriority;
      let newArrayPriority = state.allJobOrders.map((jo) => {
        if (idArrayPriority.includes(jo.id)) jo.priority = newPriority;
        return jo;
      });

      return {
        ...state,
        allJobOrders: newArrayPriority,
        jobOrderDetails: idArrayPriority.includes(state.jobOrderDetails?.id) ? {...state.jobOrderDetails, priority: newPriority } : state.jobOrderDetails
      };

    case actions.DELETE_JOB_ORDER:
      const deletedId = action.payload;
      const newDeletedArray = state.allJobOrders.filter(jo => jo.id !== Number(deletedId))
      return {
        ...state,
        allJobOrders: newDeletedArray
      };

    case actions.ADD_JOB_STATUS_LOG:
      const newStatusLogDetails = Number(state.jobOrderDetails.id) ===
        Number(action.payload.id) ? {
        ...state.jobOrderDetails,
        jobStatusLog: [action.payload.data, ...state.jobOrderDetails.jobStatusLog],
      } : state.jobOrderDetails;   

      const newStatusLogAllOrderArray = state.allJobOrders.map(el => {
        if (Number(el.id) === Number(action.payload.id)) {
          return {
            ...el,
            jobStatusLog: [
              action.payload.data,
              ...el.jobStatusLog,
            ],
          };
        } else return el
      })
    
      return {
        ...state,
        jobOrderDetails: newStatusLogDetails,
        allJobOrders: newStatusLogAllOrderArray
      };

    case actions.DELETE_JOB_STATUS_LOG:
      const updatedAllJobOrdersDeletedLog = state.allJobOrders.map(jo => {
        if (Number(jo.id) === Number(action.payload.jobOrderId)) {
          const updatedDeletedLog = jo?.jobStatusLog.filter(
            (item) => item.id != action.payload.jobStatusLogId
          );
          return { ...jo, jobStatusLog: updatedDeletedLog };
        } else return jo
      });

      let updatedJobOrderDetailsDeletedLog = state.jobOrderDetails;
      if (
        Number(state.jobOrderDetails.id) === Number(action.payload.jobOrderId)
      ) {
        const updatedDeletedLog =
          updatedJobOrderDetailsDeletedLog?.jobStatusLog.filter(
            (item) => item.id != action.payload.jobStatusLogId
          );
          updatedJobOrderDetailsDeletedLog = {
            ...updatedJobOrderDetailsDeletedLog,
            jobStatusLog: updatedDeletedLog,
          };
      }
      return {
        ...state,
        jobOrderDetails: updatedJobOrderDetailsDeletedLog,
        allJobOrders: updatedAllJobOrdersDeletedLog,
        jobStatusHistory: state.jobStatusHistory.filter(jobStatus => jobStatus.id !== action.payload.jobStatusLogId)
      };

    case actions.ADD_APPLICATION_LOG:
      const { jobOrderId, newApplication } = action.payload;
      let jobOrderDetailsWithNewApplication = state.jobOrderDetails
      if (Number(state.jobOrderDetails?.id) === Number(jobOrderId)) {
        jobOrderDetailsWithNewApplication = {
          ...jobOrderDetailsWithNewApplication,
          applicationStatusLog: [
            newApplication,
            ...jobOrderDetailsWithNewApplication.applicationStatusLog,
          ],
        };
      }
      return {
        ...state,
        jobOrderDetails: jobOrderDetailsWithNewApplication,
      }
    case actions.EDIT_APPLICATION_LOG:
      const { jobOrderId: editAplicationLogJobOrderId, updatedApplications } = action.payload;
      let jobOrderDetailsWithEditedApplication = state.jobOrderDetails
      if (
        Number(state.jobOrderDetails?.id) ===
        Number(editAplicationLogJobOrderId)
      ) {
        jobOrderDetailsWithEditedApplication = {
          ...jobOrderDetailsWithEditedApplication,
          applicationStatusLog: updatedApplications,
        };
      }
      return {
        ...state,
        jobOrderDetails: jobOrderDetailsWithEditedApplication,
      }
    case actions.DELETE_APPLICATION_LOG:
      const { jobOrderId: deleteAplicationLogJobOrderId, applicationId } = action.payload;
      let jobOrderDetailsWithoutDeletedApplication = state.jobOrderDetails
      if (
        Number(state.jobOrderDetails?.id) ===
        Number(deleteAplicationLogJobOrderId)
      ) {
        const updatedApplicationLog =
          jobOrderDetailsWithoutDeletedApplication.applicationStatusLog.filter(
            (item) => item.id != applicationId
          );
        jobOrderDetailsWithoutDeletedApplication = {
          ...jobOrderDetailsWithoutDeletedApplication,
          applicationStatusLog: updatedApplicationLog,
        };
      }
      return {
        ...state,
        jobOrderDetails: jobOrderDetailsWithoutDeletedApplication,
        applicationHistory: state.applicationHistory.filter(application => application.id !== applicationId)
      };

    case actions.REMOVE_JOB_ORDER_RECRUITER: 
      const updatedJobOrderDetailsAfterRemovingRecruiter = state.jobOrderDetails;  
      if (
        Number(state.jobOrderDetails?.id) ===
        Number(action.payload.jobOrderId)
      ) {
        updatedJobOrderDetailsAfterRemovingRecruiter.recruiters = updatedJobOrderDetailsAfterRemovingRecruiter.recruiters.filter(e => e.id !== action.payload.recruiterId);
      }
      return {
        ...state,
        jobOrderDetails: updatedJobOrderDetailsAfterRemovingRecruiter,
      };
        
    case actions.FETCHED_APPLICATIONS_LOG_HISTORY:
      const applicationsHistory = action.payload
      return {
        ...state,
        applicationHistory: applicationsHistory
      }

    case actions.ADD_APPLICATION_TO_HISTORY:
      const applicationToAdd = action?.payload
      if (applicationToAdd) {
        return {
          ...state,
          applicationHistory: [
            applicationToAdd,
            ...state.applicationHistory
          ]
        }
      }

    case actions.EDIT_APPLICATION_IN_HISTORY:
      const applicationToEdit = action?.payload
      if (applicationToEdit) {
        return {
          ...state,
          applicationHistory: state.applicationHistory.map(app => {
            if (app.id === applicationToEdit.id) return applicationToEdit;
            return app
          })
        }
      }

    case actions.FETCHED_JOB_STATUS_LOG_HISTORY:
      const jobStatusHistory = action.payload
      return {
        ...state,
        jobStatusHistory: jobStatusHistory
      }

    case actions.ADD_JOB_STATUS_TO_HISTORY:
      const jobStatus = action.payload
      return {
        ...state,
        jobStatusHistory: [jobStatus, ...state.jobStatusHistory]
      }

    default:
      throw Error("Unknown action.");
  }
}

import React, { useState, useMemo, useEffect } from 'react'
import { CalendarIcon, CurrencyEuroIcon, UserIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import { useDrag } from 'react-dnd'
import useAuth from '../../../store/auth/authContext';
import UpdateApplicationStatusModal from './UpdateApplicationStatusModal'
import EditApplicationDetailsModal from './EditApplicationDetailsModal'
import useUserRoleVerifier from '../../../hooks/useUserRoleVerifier';
import DropdownMenuDots from '../../../components/menus/DropdownMenuDots';
import InfoTooltip from '../../../components/tooltips/InfoTooltip';
import AccessWrapper from '../../app/AccessWrapper';
import utc from 'dayjs/plugin/utc'
import { Link } from 'react-router-dom';
import { jobStatusIds } from '../../../helpers/enum/jobStatuses';
import { set } from 'date-fns';

function ApplicationListItem({ application, showHistoryModalHandler, jobOrder, touchUpdate, setTouchUpdate, newStatus, setNewStatus }) {

  dayjs.extend(utc)

  const { auth: { currentUser } } = useAuth();

  const { role, verifyBoolAccess } = useUserRoleVerifier();

  const [ showUpdateApplicationStatusModal, setShowUpdateApplicationStatusModal ] = useState(false);
  const [ showEditApplicationDetailsModal, setShowEditApplicationDetailsModal ] = useState(false);

  const [ updatedApplicationData, setUpdatedApplicationData ] = useState({
    applicant_id: null,
    job_order_id: null,
    cost: null, 
    status: null,
    description: null,
    created_by: null,
  })
  
  const isTouchScreen = useMemo(() => {
    let _touchScreen = window.matchMedia("(pointer: coarse)").matches
    return _touchScreen
  }, [window.matchMedia("(pointer: coarse)")])


  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BOX',
    item: { ...application },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        if (item.status == dropResult.name) return; 
        setUpdatedApplicationData({
          applicant_id: item.applicant_id,
          job_order_id: item.job_order_id,
          cost: application.cost,
          status: dropResult.name,
          description: null,
          created_by: currentUser.id,
        })
        setShowUpdateApplicationStatusModal(true)
      }
    },
		// The collect function utilizes a "monitor" instance (see the Overview for what this is)
		// to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })

  }), [application])

  useEffect(() => {
    if (!newStatus) return;
    const item = {...application}
    setUpdatedApplicationData({
      applicant_id: item.applicant_id,
      job_order_id: item.job_order_id,
      cost: application.cost,
      status: newStatus,
      description: null,
      created_by: currentUser.id,
    })
    setNewStatus(null)
    setTouchUpdate(false)
    setShowUpdateApplicationStatusModal(true)

  }, [newStatus])

  const items = [
    [
      {
        text: 'View application history',
        onClick: () => showHistoryModalHandler(application.applicant_id)
      },
      {
        text: 'Edit application details',
        onClick: () => setShowEditApplicationDetailsModal(!showEditApplicationDetailsModal),
        show: (application.candidate?.is_within_scope && verifyBoolAccess(role.h_job_orders.h_3_4_update_application_status)) || (application.created_by == currentUser.id)
      },
      {
        text: 'View candidate details',
        linkTo: `/candidates/${application.applicant_id}`,
        show: application.candidate?.is_within_scope
      }
    ],
  
  ]

  const latestStatus = jobOrder.jobStatusLog[0]?.status;
  //TODO: Now applicants full name only appears if is_within_scope (either is owner or can manage candidates and owner is within g_4_1 access). Additionnally, the original owner only appears if the originalOwner is within scope. Both in g_4_1 and also in view users f_1_1 and f_1_2. The cost only appears if both owner and candidate are within scope or
  //TODO: Changed canUpdateStatus so that it required the candidate to be within scope as well
  const canUpdateStatus =
    verifyBoolAccess(role.h_job_orders.h_3_4_update_application_status) &&
    application.candidate?.is_within_scope &&
    latestStatus != jobStatusIds.archived &&
    latestStatus != jobStatusIds.cancelled;

  let candidateName = application?.candidate.first_names
  let originalOwnerName = application?.candidate?.originalOwner?.first_names


  if (application?.candidate?.originalOwner?.id == currentUser.id) originalOwnerName = 'me'

  if (application?.candidate?.is_within_scope) candidateName = application?.candidate.first_names + ' ' + application?.candidate?.last_names


  return (
    <li className={`block`} style={{ opacity: isDragging ? 0.5 : 1}} key={application.id}>
      <UpdateApplicationStatusModal 
        show={showUpdateApplicationStatusModal} 
        hide={() => setShowUpdateApplicationStatusModal(false)} 
        updatedApplicationData={updatedApplicationData} 
        setUpdatedApplicationData={setUpdatedApplicationData}
        previousApplicationData={application} 
      />
      <EditApplicationDetailsModal 
        show={showEditApplicationDetailsModal} 
        hide={() => setShowEditApplicationDetailsModal(false)} 
        application={application} 
        jobOrder={jobOrder}
      />
    
      <div className={`block px-4 py-4 sm:px-6`} >
        <div className="flex items-center -mx-2 -my-1 justify-between">
          <div className='flex items-center space-x-1'>
            {application.candidate.is_within_scope ? <Link to={`/candidates/${application.applicant_id}`} role="Handle" ref={canUpdateStatus ? drag : undefined} className={`text-sm ${canUpdateStatus ? 'cursor-pointer hover:bg-thaleria-orange-50' : ''} font-medium text-thaleria-orange-800 truncate rounded px-2 py-1 `}>{candidateName || 'Candidate'}</Link>:
            <span className={`text-sm font-medium text-thaleria-orange-800 truncate rounded px-2 py-1`}>{candidateName || 'Candidate'}</span>}
            {application.description && 
              <InfoTooltip> 
                <div className='w-56'>
                  <p className='mb-2 font-semibold'>Description</p>
                  <p className='font-medium'>{application.description}</p>
                </div>
              </InfoTooltip>
            }
          </div>
          <DropdownMenuDots horizontal dotsClassName='h-5 w-5 text-gray-500' items={items}/>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="flex items-center text-sm text-gray-500 sm:mt-0">
              <UserIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
              Added by {application.user.first_names}
            </p>
            <AccessWrapper access={application.candidate.originalOwner.is_within_scope}>
              <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                <UserIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                Belongs to {originalOwnerName}
              </p>
            </AccessWrapper>
            {application?.cost && 
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
              <CurrencyEuroIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
              Cost is {application.cost}€/day
            </p>}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 justify-between md:space-x-4">
            <div className='flex items-center'>
              <CalendarIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
              <p>
                Added on <time dateTime={application.closeDate}>{dayjs.utc(application.created_at).format('DD/MM/YY')}</time>
              </p>
            </div>
            {isTouchScreen && (touchUpdate == application.status ? <button className='text-sm text-thaleria-orange-800 ml-4' onClick={() => setTouchUpdate(false)} >Cancel</button> : <button className='text-sm text-thaleria-orange-800' onClick={() => setTouchUpdate(application.status)} >Move to...</button>)}
          </div>
        </div>
      </div>
    </li>
  )
}

export default ApplicationListItem
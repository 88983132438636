import React from 'react'
import ContainerCard from '../../components/cards/ContainerCard'
import CandidateItem from './CandidateItem';

function UserCandidates({ candidates }) {
  return (
    <ContainerCard title='User candidates' >
      {candidates?.length ? 
          <ul className='-my-5 divide-y divide-gray-200 overflow-y-auto max-h-64'>
            {candidates.map(candidate => <CandidateItem candidate={candidate} key={candidate.id}/>)}
          </ul>
          :
          <span className='text-sm'>No viewable candidates to show for this user</span>
        }
    </ContainerCard>
  )
}

export default UserCandidates
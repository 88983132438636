import React from 'react'
import PageWrapper from '../containers/app/PageWrapper'
import CurrentApplicationsStat from '../containers/dashboard/CurrentApplicationsStat'
import useAuth from '../store/auth/authContext'
import DashboardProfileCard from '../containers/dashboard/DashboardProfileCard'
import Graphs from '../containers/dashboard/Graphs'

function Dashboard() {

  const { auth: {currentUser} } = useAuth();

  return (
    <PageWrapper title='Dashboard' noPadding>
      <DashboardProfileCard user={currentUser} />
      <CurrentApplicationsStat />
      <Graphs/>
    </PageWrapper>
  )
}

export default Dashboard
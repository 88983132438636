
import React from 'react'
import Table from '../table/Table';
import { useNavigate } from 'react-router';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import { ExternalLinkIcon, EyeIcon, PencilIcon } from "@heroicons/react/solid";

function UsersTable({ users, isLoading }) {

  const { verifyUserIsWithinScope, verifyBoolAccess, role } = useUserRoleVerifier();
  const navigate = useNavigate();

  const data = React.useMemo(
      () => []
    , [ users ]);
    
    if (users.length && !data.length) { 
      users.forEach(user => {    
        let isEditable = verifyUserIsWithinScope(user.user_group_id, user.role_id) && verifyBoolAccess(role.f_users.f_2_4_edit_users);
        data.push({
          id: user.id || '-',
          first_names: user.first_names || '',
          last_names: user.last_names || '',
          email: user.email || '',
          phoneNb: user.phone_nb || '',
          birthday: user.birthday || '',
          address: user.address || '',
          is_editable: isEditable,
          userGroup: user?.userGroup?.name || '',
          role: user?.role?.a_name || '',
          active: user?.active ? 'Yes' : 'No'
        })
      })
    }

  
  const columns = React.useMemo(
    () => {
      let columnsToShow = [];
      let dataProperties = Object.keys(users[0] || {});
      if (dataProperties.includes('first_names')) columnsToShow.push(
        {
          Header: 'First Name',
          accessor: 'first_names',
        }
      );
      if (dataProperties.includes('last_names')) columnsToShow.push(
        {
          Header: 'Last Name',
          accessor: 'last_names',
        }
      );
      if (dataProperties.includes('email')) columnsToShow.push(
        {
          Header: 'Email',
          accessor: 'email',
        }
      );
      if (dataProperties.includes('userGroup')) columnsToShow.push(
        {
          Header: 'User group',
          accessor: 'userGroup'
        }
      )
      if (dataProperties.includes('role')) columnsToShow.push(
        {
          Header: 'Role',
          accessor: 'role'
        }
      );
      if (dataProperties.includes('active')) columnsToShow.push(
        {
          Header: 'Active',
          accessor: 'active'
        }
      )
      return columnsToShow;
    }, [users]
  );

  let renderRowMenu = (row) => {
    let canEdit = row.original.is_editable;
    let buttons = [
      [
        {
          text: "View user details",
          icon: EyeIcon,
          onClick: () => navigate(`/users/${row.original.id}`),
        },
        {
          text: "View details in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/users/${row.original.id}`),
        },
      ],
    ];
    if (canEdit) buttons.push([
      {
        text: "Edit user",
        icon: PencilIcon,
        onClick: () => navigate(`/users/edit/${row.original.id}`),
        show: canEdit,
      },
      {
        text: "Edit user in new tab",
        icon: ExternalLinkIcon,
        onClick: () => window.open(`/users/edit/${row.original.id}`),
        show: canEdit,
      },
    ])
    return buttons;
  } 

  const rowOnClick = (row) => {
    navigate(`/users/${row.original.id}`)
  }
  
  return (
    <>
      <Table data={data} columns={columns} isLoading={isLoading} renderRowMenu={renderRowMenu} rowOnClick={rowOnClick}/>
    </>
  )
}

export default UsersTable

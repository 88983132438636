import React from 'react'
import DescriptionList from '../../components/descriptionLists/DescriptionList';
import AccessWrapper from '../app/AccessWrapper';
import Comments from '../comments/Comments';
import role from '../../helpers/enum/role';
import ClientJobOrders from './ClientJobOrders'
import convertCountries from '../../helpers/convertCountries';

function ClientDetailsForm({ client }) {

  const items = [
    { label: 'Name', data: client.name, id: 1, show: true },
    { label: 'Phone number', data: client.phone_nb, id: 2, show: true },
    { label: 'City', data: client.city, id: 3, show: true },
    { label: 'Sector', data: client.sector, id: 4, show: true },   
    { label: 'Country', data: convertCountries(client.country), id: 5, show: true },
  ]

  return (
    <DescriptionList title={'Client information'} items={items}/>
  )
}

export default ClientDetailsForm
import React, { useState, useEffect, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import ReactSelect from '../../components/inputs/ReactSelect';
import DateInput from '../../components/inputs/DateInput/DateInput';
import useApi from '../../store/api/apiContext';
import TextArea from '../../components/inputs/TextArea';
import axios from 'axios';
import Toggle from '../../components/inputs/Toggle';
import { CANDIDATE_SOURCE, CANDIDATE_TYPE } from '../../helpers/selectOptions';
import COUNTRIES from '../../helpers/countryList'
import { EditorState, convertToRaw } from 'draft-js'
import RichTextEditor from '../../components/inputs/RichTextEditor/RichTextEditor';
import useAuth from '../../store/auth/authContext';
import NumberInput from '../../components/inputs/NumberInput';
import ReactCreatableSelect from '../../components/inputs/ReactCreatableSelect';


function AddCandidateForm() {
  const navigate = useNavigate();

  const { addCandidate, fetchUsers, fetchLanguages, fetchSkills, addSkillsToCandidate, addLanguagesToCandidate } = useApi();

  const { auth } = useAuth();

  const canChangeOriginalOwner = auth.userRole.g_4_2_create_candidates;
  const userId = auth.currentUser.id
  const firstNamesInputRef = useRef(null)


  const { showAppNotification } = useAppNotification();
  const [newCandidate, setNewCandidate] = useState({
    can_relocate: false,
    first_time_candidate: true,
  })
  const [selectedCountry, setSelectedCountry] = useState()
  const [selectedType, setSelectedType] = useState()
  const [selectedNationality, setSelectedNationality] = useState()

  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [languageOptions, setLanguageOptions] = useState([])
  const [skillOptions, setSkillOptions] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [selectedSource, setSelectedSource] = useState()
  const [formError, setFormError] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [ownCandidate, setOwnCandidate] = useState(true)
  
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    let languages = [];
    let skills = [];
    fetchLanguages(source).then(res => {
      res.data.map(language => languages.push({
        value: language.id,
        label: language.name
      }))
    })
    fetchSkills(source).then(res => {
      res.data.map(skill => skills.push({
        value: skill.id,
        label: skill.name
      }))
    })

    setLanguageOptions(languages)
    setSkillOptions(skills)

    if (canChangeOriginalOwner) {
      fetchUsers(source).then(res => {
        const allUsers = res.data.map(user => ({
              value: user.id,
              label: user.first_names + ' ' + user.last_names
            })
        )
        setUserOptions(allUsers.filter(user => user.value != userId))
      })
    }

    return () => {
      source.cancel();
    };
  }, [])
  
  const postAddCandidate = async () => {
    let errors=[]

    if (!newCandidate.first_names) errors.push({
      field: "First names*",
      msg: "This field is required"
    })
    if (!newCandidate.last_names) errors.push({
      field: "Last names*",
      msg: "This field is required"
    })
    if (!newCandidate.email) errors.push({
      field: "Email*",
      msg: "This field is required"
    })
    if (!validateEmail(newCandidate.email)) errors.push({
      field:"Email*",
      msg: "Please provide a valid email address"
    })
    if (newCandidate.phone_nb && !(/^[\d +]+$/.test(newCandidate.phone_nb))) errors.push({
      field: 'Phone number*',
      msg: "Can only contain numbers and (+) sign."
    })
    if (!newCandidate.phone_nb) errors.push({
      field: 'Phone number*',
      msg: "This field is required"
    })

    if (!ownCandidate && !newCandidate.original_owner) errors.push({
      field: "Belongs to*",
      msg: "This field is required if this is not your own candidate"
    })
    if(errors.length) {
      firstNamesInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }

    setFormError([]);

    
    //prepare description field rich text
    var rawContentState = convertToRaw(editorState.getCurrentContent());
    var rawJSON = JSON.stringify(rawContentState)
    let data = { ...newCandidate, notes: rawJSON }

    if (data.notice_period == '') data.notice_period = null
    if (data.type == '') data.type = null
    
    if (selectedLanguages.length) data.languages = selectedLanguages
    if (selectedSkills.length) data.skills = selectedSkills
    if (ownCandidate) data.original_owner = userId
    addCandidate(data).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Candidate added successfully');
      navigate('/candidates', { replace: true })
    })
  }
  
  const changeHandlerSelectCountry = (e) => {
    setNewCandidate(prev => ({...prev, country: e.value }) )
    setSelectedCountry(e)
  }

  const changeHandlerSelectNationality = (e) => {
    setNewCandidate(prev => ({...prev, nationality: e.value }) )
    setSelectedNationality(e)
  }
  
  const changeHandlerSelectType = (e) => {
    setNewCandidate(prev => ({...prev, type: e.value }) )
    setSelectedType(e)
  }

  const changeHandlerSelectLanguages = (e) => {
    let _selectedLanguages;
    if (!e) _selectedLanguages = [];
    else _selectedLanguages = e.map(el => el.value)
    setSelectedLanguages(_selectedLanguages)
  }

  const changeHandlerSelectSkills = (e) => {
    let _selectedSkills;
    if(!e) _selectedSkills = [];
    else _selectedSkills = e.map(el => el)
    setSelectedSkills(_selectedSkills)
  }

  const changeHandlerSelectUser = (e) => {
    setNewCandidate(prev => ({...prev, original_owner: e.value}))
    setSelectedUser(e)
  }

  const changeHandlerSelectSource = (e) => {
    setNewCandidate(prev => ({...prev, type: e.value}) )
    setSelectedSource(e)
  }

  const isOwnCandidateToggleHandler = () => {
    let newStatus = !ownCandidate
    if (!newStatus) setNewCandidate(prev => ({...prev, original_owner: userId})) 
    setOwnCandidate(newStatus)
  }

  let countryOptions = []

  COUNTRIES.forEach(country => {
    countryOptions.push({
      value: country.code,
      label: country.name
    })
  })

  const typeOptions = CANDIDATE_TYPE.map(type => ({
    value: type, 
    label: type
  }))

  const sourceOptions = CANDIDATE_SOURCE.map(source => ({
    value: source,
    label: source
  }));

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Candidate information' description='Add all relevant candidate information' >
        <div ref={firstNamesInputRef} className="col-span-6 sm:col-span-3">
          <TextInput label='First names*' errors={formError} value={newCandidate.first_names} onChange={(e) => setNewCandidate(prev => ({...prev, first_names: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInput label='Last names*' errors={formError} value={newCandidate.last_names} onChange={(e) => setNewCandidate(prev => ({...prev, last_names: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-4">
          <TextInput label='Email*' errors={formError} value={newCandidate.email} onChange={(e) => setNewCandidate(prev => ({...prev, email: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-4">
          <TextInput label='Phone number*' errors={formError} value={newCandidate.phone_nb} onChange={(e) => setNewCandidate(prev => ({...prev, phone_nb: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Country of residence' errors={formError} placeholder="Countries" selectedOption={selectedCountry} options={countryOptions} onChange={changeHandlerSelectCountry}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Nationality' errors={formError} placeholder="Countries" selectedOption={selectedNationality} options={countryOptions} onChange={changeHandlerSelectNationality}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label="Languages" errors={formError} isMulti options={languageOptions} onChange={changeHandlerSelectLanguages}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInput label='Current employer' errors={formError} value={newCandidate.current_employer} onChange={(e) => setNewCandidate(prev => ({...prev, current_employer: e.target.value }) )}/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title='Profile information' description='Add all relevant profile information' >
        <div className="col-span-6">
          <Toggle label= 'First time candidate' description="Toggle off if candidate has already been placed in a job order" errors={formError} value={newCandidate.first_time_candidate} onChange={() => setNewCandidate(prev => ({...prev, first_time_candidate: !newCandidate.first_time_candidate }) )}/>
        </div>
        <div className="col-span-6">
          <Toggle label='Can relocate' description="Toggle on if the candidate is willing to relocate" errors={formError} value={newCandidate.can_relocate} onChange={() => setNewCandidate(prev => ({...prev, can_relocate: !newCandidate.can_relocate }) )}/>
        </div>
        {canChangeOriginalOwner &&
          <>
          <div className='col-span-6'>
            <Toggle label='Is this your own candidate?' description="Toggle off if you are creating this candidate on behalf of another user." value={ownCandidate} onChange={() =>  isOwnCandidateToggleHandler()}/>
          </div>
          {!ownCandidate && 
          <div className="col-span-6">
            <ReactSelect label='Belongs to*' errors={formError} selectedOption={selectedUser} placeholder="Users" options={userOptions} onChange={changeHandlerSelectUser}/>
          </div>
          }
          </>
        }
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Source' errors={formError} placeholder="Source" selectedOption={selectedSource} options={sourceOptions} onChange={changeHandlerSelectSource}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <NumberInput label='Notice period (calendar days)' errors={formError} value={newCandidate.notice_period} onChange={(e) => setNewCandidate(prev => ({...prev, notice_period: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactCreatableSelect label="Skills" errors={formError} isMulti options={skillOptions} onChange={changeHandlerSelectSkills}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Type' errors={formError} selectedOption={selectedType} options={typeOptions} onChange={changeHandlerSelectType}/>
        </div>
        <div className="col-span-6">
          <RichTextEditor label='Notes' editorState={editorState} setEditorState={setEditorState} placeholder='Write here...'/>
        </div>
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row">
        <WhiteButton text='Cancel' onClick={() => navigate(`/candidates`, {replace: true}) }/>
        <PrimaryButton text='Add' darkRing onClick={postAddCandidate}/>
      </div>
    </div>
  )
}

export default AddCandidateForm
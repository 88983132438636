import React from 'react';
import useApi from '../../store/api/apiContext';
import useAuth from '../../store/auth/authContext';

function PrivateImage ({ src, defaultImage, className, isProfilePicture, ...rest }) {
  const [imgSrc, setImageSrc] = React.useState();
  const { setAuth, auth: { profilePicture } } = useAuth();

  const { downloadFile } = useApi();

  React.useEffect(() => {
    if (!src) {
      setImageSrc(null);
      return;
    }

    if (/(http(s?)):\/\//i.test(src)) {
      setImageSrc(src);
      return;
    }

    if (isProfilePicture) {
      if (!profilePicture && src) downloadFile(src).then(res => {
        setAuth(prev => ({...prev, profilePicture: res.data }));
        setImageSrc(res.data);
      });
      if (profilePicture) setImageSrc(profilePicture)
    }

    if (!isProfilePicture) {
      downloadFile(src).then(res => {
        setImageSrc(res.data);
      });
    }
  }, [src, profilePicture]);

  if (!imgSrc && defaultImage) return defaultImage;

  return <img {...rest} className={className} src={imgSrc} />;
};

export default PrivateImage;
function getFileExtensionAndName(filename) {
  const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
        // No dot found, return the entire filename as docName and an empty string as fileType
        return { docName: filename, fileType: '' };
    }
    // Extract the substring before the last dot as docName
    const docName = filename.substring(0, lastDotIndex);
    // Extract the substring starting from the character after the last dot as fileType
    const fileType = filename.substring(lastDotIndex + 1);
    return { docName, fileType };
}
export default getFileExtensionAndName
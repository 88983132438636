const userProperties = {
  id: 'ID',
  role_id: 'Role',
  user_group_id: 'User group',
  first_names: 'First names',
  last_names: 'Last names',
  email: 'Email',
  phone_nb: 'Phone number',
  created_at: 'Creation date',
  updated_at: 'Updated at',
  deleted_at: 'Deleted at',
  profile_pic_url: 'Profile picture',
  price_margin: 'Price margin',
  active: "Active/Inactive"
}

export default userProperties;


/*
  foreign associations:
    - job orders
    - candidates
*/
import React, { useState, useEffect } from 'react'
import FormWithSideTitle from '../../../components/forms/FormWithSideTitle';
import ReactSelect from '../../../components/inputs/ReactSelect';
import Toggle from '../../../components/inputs/Toggle';
import Checkbox from '../../../components/inputs/Checkbox';
import useApi from '../../../store/api/apiContext';
import FormInputLabelAndDescription from '../../../components/forms/FormInputLabelAndDescription';
import JOB_ORDER_PROPERTIES from '../../../helpers/enum/jobOrderProperties';
import { ALL_ACCESS_UUID } from '../../../config';

function JobOrderActions({ 
  newRole,
  setNewRole,
  viewAllJobOrderProperties,
  editAllViewableJobOrderProperties,
  viewJobOrdersFromAllFrameworkContracts,
  setViewAllJobOrderProperties,
  setEditAllViewableJobOrderProperties,
  setViewJobOrdersFromAllFrameworkContracts,
 }) {

  const { fetchJobOrderProperties, fetchFrameworkContracts } = useApi();

  const [ jobOrderProperties, setJobOrderProperties ] = useState([])
  const [ frameworkContracts, setFrameworkContracts] = useState([])
  const [ viewablePropertyChoices, setViewablePropertyChoices ] = useState([]);  
  const [ editablePropertyChoices, setEditablePropertyChoices ] = useState([]);  
  const [ frameworkContractOptions, setFrameworkContractOptions ] = useState([]);  

  const [ initiallySelectedFrameworkContracts, setInitiallySelectedFrameworkContracts ] = useState([]);

  //set initial selected userGroups
  useEffect(() => {
    if (frameworkContracts?.length && newRole.h_1_2_allowed_fwc[0] !== ALL_ACCESS_UUID ) {
      let selectedContracts = [];
      frameworkContracts.forEach(contract => {
        if (newRole.h_1_2_allowed_fwc.includes(contract.id)) selectedContracts.push({ label: contract.contract_ref, value: contract.id })
      })
      setInitiallySelectedFrameworkContracts(selectedContracts);
      prepareFrameworkContractOptions();
    } 
  }, [frameworkContracts])

  useEffect(() => {
    fetchJobOrderProperties().then(res => {
      //remove properties that should not be in config
      let filtered = res.data.filter(el => el !== 'id' && el !== 'uuid')
      setJobOrderProperties(filtered);
    })
    fetchFrameworkContracts().then(res => {
      setFrameworkContracts(res.data);
    })
  }, [])

  const changeHandlerViewJobOrders = () => {
    let newStatus = !newRole.h_1_view_job_orders;
    resetJobOrderModule(newStatus);
    if (newStatus) {
      setViewAllJobOrderProperties(true);
      setViewJobOrdersFromAllFrameworkContracts(true);
      setNewRole(prev => ({...prev, 
        h_1_1_viewable_properties: [ ALL_ACCESS_UUID ],
        h_1_2_allowed_fwc: [ ALL_ACCESS_UUID ],
      }))
    }
  }

  const handleChangeViewAllJobOrderProperties = () => {
    let newStatus = !viewAllJobOrderProperties;
    setViewAllJobOrderProperties(newStatus);
    if (newStatus) {
      setNewRole(prev => ({...prev, h_1_1_viewable_properties: [ ALL_ACCESS_UUID ]}))
    }
    if (!newStatus) {
      setNewRole(prev => ({...prev, h_1_1_viewable_properties: [ ] , h_3_6_edit_job_order: false, h_3_6_1_editable_properties: []}))
      prepareViewablePropertyChoices();
    }
  }

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    //set initial checked editable properties
    if (firstRender && jobOrderProperties?.length && newRole.h_1_view_job_orders) {
      if (!viewAllJobOrderProperties) {
        let array = jobOrderProperties.map(property => {
          return {label: JOB_ORDER_PROPERTIES[property], id: property, checked: newRole.h_1_1_viewable_properties.includes(property)}
        })
        setViewablePropertyChoices(array);
      }
      return setFirstRender(false);
    }
  }, [viewablePropertyChoices, jobOrderProperties])

  const [ initialRender, setInitialRender ] = useState(true);

  useEffect(() => {
    if (initialRender && !firstRender) {
      let initial = true;
      prepareEditablePropertyChoices(initial)
      setInitialRender(false);
    }
  }, [firstRender])

  const handleUpdateViewableProperties = (property) => {
    let newArray = viewablePropertyChoices.map(element  => {
      if (element === property) return { ...element, checked: !property.checked };
      return element;
    })
    setViewablePropertyChoices(newArray);
    let checkedArray = [];
    newArray.forEach(property => {
      if (property.checked) checkedArray.push(property.id);
    })
    setNewRole(prev => ({...prev, h_1_1_viewable_properties: checkedArray, h_3_6_edit_job_order: false, h_3_6_1_editable_properties: [] }))
    setEditablePropertyChoices([])
  }

  const handleChangeEditAllViewableJobOrderProperties = () => {
    let newStatus = !editAllViewableJobOrderProperties;
    setEditAllViewableJobOrderProperties(newStatus);
    if (newStatus) {
      setNewRole(prev => ({...prev, h_3_6_1_editable_properties: [ ALL_ACCESS_UUID ]}))
      setFrameworkContractOptions([]);
    }
    if (!newStatus) {
      setNewRole(prev => ({...prev, h_3_6_1_editable_properties: [ ]}))
      prepareEditablePropertyChoices();
    }
  }

  const handleUpdateEditableProperties = (property) => {
    let newArray = editablePropertyChoices.map(element  => {
      if (element === property) return { ...element, checked: !property.checked };
      return element;
    })
    setEditablePropertyChoices(newArray);
    let checked = []; 
    newArray.forEach(property => {
      if (property.checked) checked.push(property.id);
    })
    setNewRole(prev => ({...prev, h_3_6_1_editable_properties: checked}));
  }

  const changeHandler_h_1_2_allowed_fwc = event => {
    let contracts;
    if (!event) contracts = [];
    else contracts = event.map(userGroup => userGroup.value);
    setNewRole(prev => ({ ...prev, h_1_2_allowed_fwc: contracts }));
  };

  const handleChangeViewJobOrdersFromAllFrameworkContracts = () => {
    let newStatus = !viewJobOrdersFromAllFrameworkContracts;
    setViewJobOrdersFromAllFrameworkContracts(newStatus);
    if (newStatus) {
      setNewRole(prev => ({...prev, h_1_2_allowed_fwc: [ALL_ACCESS_UUID ]}))

    }
    if (!newStatus) {
      prepareFrameworkContractOptions()
      setNewRole(prev => ({...prev, h_1_2_allowed_fwc: [ ]}))
    }
  }

  const handleChangeEditJobOrder = () => {
    let newStatus = !newRole.h_3_6_edit_job_order;
    setNewRole(prev => ({ ...prev, h_3_6_edit_job_order: newStatus}));
    if (newStatus) {
      setEditAllViewableJobOrderProperties(true);
      setNewRole(prev => ({ ...prev, h_3_6_1_editable_properties: [ ALL_ACCESS_UUID ]}));
    } else {
      setEditAllViewableJobOrderProperties(false);
      setNewRole(prev => ({ ...prev, h_3_6_1_editable_properties: []}));
    }
  }

  const handleChangeCreateJobOrders = () => {
    let newStatus = !newRole.h_2_create_job_orders;
    setNewRole(prev => ({...prev, h_2_create_job_orders: newStatus}));
    if (!newStatus) {
      setNewRole(prev => ({...prev, h_2_1_edit_created_job_orders: false, h_2_2_delete_created_job_orders: false }));
    }
  }

  //helper functions
  const resetJobOrderModule = (newStatus) => {
    setNewRole(prev => ({...prev, 
    h_1_view_job_orders: newStatus,
    h_1_1_viewable_properties: [],
    h_1_2_allowed_fwc: [],
    h_1_3_assign_myself_as_recruiter: false,
    h_2_create_job_orders: false,
    h_2_1_edit_created_job_orders: false,
    h_2_2_delete_created_job_orders: false,
    h_3_manage_job_orders: false,
    h_3_1_update_job_order_status: false,
    h_3_2_assign_recruiters: false,
    h_3_3_assign_applicants: false,
    h_3_4_update_application_status: false,
    h_3_5_remove_applicant: false,
    h_3_6_1_editable_properties: [],
    h_3_6_edit_job_order: false,
    h_3_7_delete_job_order: false,
    }))
  }

  const handleToggleManageJobOrders = () => {
    setNewRole(prev => ({...prev, 
      h_3_manage_job_orders: !newRole.h_3_manage_job_orders,
      h_3_1_update_job_order_status: false,
      h_3_2_assign_recruiters: false,
      h_3_3_assign_applicants: false,
      h_3_4_update_application_status: false,
      h_3_5_remove_applicant: false,
      h_3_6_1_editable_properties: [],
      h_3_6_edit_job_order: false,
      h_3_7_delete_job_order: false,
    }))
  }

  const prepareViewablePropertyChoices = () => {
    let properties = jobOrderProperties.map(property => ({label: JOB_ORDER_PROPERTIES[property], id: property, checked: false}))
    setViewablePropertyChoices(properties);
  }

  const prepareEditablePropertyChoices = (initial) => {
    let array = [];
    if (viewAllJobOrderProperties) {
      array = jobOrderProperties.map(property => ({label: JOB_ORDER_PROPERTIES[property], id: property, checked: initial ? newRole.h_3_6_1_editable_properties.includes(property) : false}))
    } else {
      viewablePropertyChoices.forEach(property => {
        if (property.checked) array.push({ ...property, checked: initial ? newRole.h_3_6_1_editable_properties.includes(property.id) : false});          
      })
    }
    setEditablePropertyChoices(array); 
  }
  
  const prepareFrameworkContractOptions = () => { 
    let contracts = frameworkContracts.map(contract => ({ label: contract.contract_ref, value: contract.id }))
    setFrameworkContractOptions(contracts);
  }

  return (
    <FormWithSideTitle title='Job order actions' description='Set which job order actions your role will enable'>
      <div className="col-span-6">
        <Toggle bold label='View job orders' description={'Allows user to view job orders according to specified properties and scope'} value={newRole.h_1_view_job_orders} onChange={changeHandlerViewJobOrders}/>
      </div>
      {newRole.h_1_view_job_orders && 
        <>
          <div className="col-span-6">
            <Checkbox 
              description={'View all job order properties'} 
              checked={viewAllJobOrderProperties} 
              onChange={handleChangeViewAllJobOrderProperties}
            />
          </div>
          {!viewAllJobOrderProperties && 
          <>
            <div className="col-span-6">
              <FormInputLabelAndDescription label={'Viewable properties'} disabled={!newRole.h_1_view_job_orders}/>
            </div>
            {viewablePropertyChoices.map((property) => (
              <div className="col-span-2" key={property.id}>
                <Checkbox 
                  description={property.label} 
                  disabled={!newRole.h_1_view_job_orders}
                  checked={property.checked} 
                  onChange={() => handleUpdateViewableProperties(property)}
                />
              </div>
            ))}
          </>}
          <div className="col-span-6">
            <Checkbox 
                description={'View job order from all framework contracts'} 
                checked={viewJobOrdersFromAllFrameworkContracts} 
                onChange={handleChangeViewJobOrdersFromAllFrameworkContracts}
              />
          </div>
          {!viewJobOrdersFromAllFrameworkContracts && !!frameworkContractOptions.length && 
            <div className="col-span-6">
              <ReactSelect
                isMulti
                label='View job orders for the following framework contracts' 
                options={frameworkContractOptions} 
                selectedOptions={initiallySelectedFrameworkContracts} 
                onChange={changeHandler_h_1_2_allowed_fwc}
              />
            </div>
          }
          <div className="col-span-6">
            <Toggle label='Assign myself as recruiter' description='Enables user to assign himself as a recruiter for viewable job orders' value={newRole.h_1_3_assign_myself_as_recruiter} onChange={() => setNewRole(prev => ({...prev, h_1_3_assign_myself_as_recruiter: !newRole.h_1_3_assign_myself_as_recruiter }))}/>
          </div>
          <div className="col-span-6">
            <Toggle bold label='Create job orders' disabled={!newRole.h_1_view_job_orders} description={'Allows user to create and add his own job orders'} value={newRole.h_2_create_job_orders} onChange={handleChangeCreateJobOrders}/>
          </div>
          {newRole.h_2_create_job_orders &&
          <>
            <div className="col-span-3">
              <Checkbox 
                  description={'Allow to edit created job orders'} 
                  checked={newRole.h_2_1_edit_created_job_orders} 
                  onChange={() => setNewRole(role => ({...role, h_2_1_edit_created_job_orders: !newRole.h_2_1_edit_created_job_orders}))}
                />
            </div>
            <div className="col-span-3">
              <Checkbox 
                  description={'Allow to delete created job orders'} 
                  checked={newRole.h_2_2_delete_created_job_orders} 
                  onChange={() => setNewRole(role => ({...role, h_2_2_delete_created_job_orders: !newRole.h_2_2_delete_created_job_orders}))}
                />
            </div>
          </>
          }
          <div className="col-span-6">
            <Toggle bold label='Manage job orders' disabled={!newRole.h_1_view_job_orders} description={'Enables admin rights to manage job orders'} value={newRole.h_3_manage_job_orders} onChange={handleToggleManageJobOrders}/>
          </div>
        </>
      }
      {newRole.h_3_manage_job_orders && 
        <>
          <div className="col-span-6">
            <Toggle label='Update job order status' description={'Allows user to update the status of the job order'} value={newRole.h_3_1_update_job_order_status} onChange={() => setNewRole(prev => ({...prev, h_3_1_update_job_order_status: !newRole.h_3_1_update_job_order_status}))}/>
          </div>
          <div className="col-span-6">
            <Toggle label='Assign recruiters' description={'Allows user to assign other recruiters to job orders (restricted to viewable users)'} value={newRole.h_3_2_assign_recruiters} onChange={() => setNewRole(prev => ({...prev, h_3_2_assign_recruiters: !newRole.h_3_2_assign_recruiters}))}/>
          </div>
          <div className="col-span-6">
            <Toggle label='Assign applicants' description={'Allows user to assign applicants on behalf of other recruiters'} value={newRole.h_3_3_assign_applicants} onChange={() => setNewRole(prev => ({...prev, h_3_3_assign_applicants: !newRole.h_3_3_assign_applicants}))}/>
          </div>
          <div className="col-span-6">
            <Toggle label='Update application status' description={'Allows user to update applicant status on behalf of other recruiters'} value={newRole.h_3_4_update_application_status} onChange={() => setNewRole(prev => ({...prev, h_3_4_update_application_status: !newRole.h_3_4_update_application_status}))}/>
          </div>
          <div className="col-span-6">
            <Toggle label='Remove applicant' description={'Allows user to remove applicants'} value={newRole.h_3_5_remove_applicant} onChange={() => setNewRole(prev => ({...prev, h_3_5_remove_applicant: !newRole.h_3_5_remove_applicant}))}/>
          </div>
          <div className="col-span-6">
            <Toggle label='Delete job order' description={'Allows user to delete job orders'} value={newRole.h_3_7_delete_job_order} onChange={() => setNewRole(prev => ({...prev, h_3_7_delete_job_order: !newRole.h_3_7_delete_job_order}))}/>
          </div>
          <div className="col-span-6">
            <Toggle label='Edit job order' description={'Allows user to edit job orders properties, according to the defined scope'} value={newRole.h_3_6_edit_job_order} onChange={handleChangeEditJobOrder}/>
          </div>
          {newRole.h_3_6_edit_job_order && 
            <>
              <div className="col-span-6">
                <Checkbox 
                    description={'Allow to edit all viewable properties'} 
                    checked={editAllViewableJobOrderProperties} 
                    onChange={handleChangeEditAllViewableJobOrderProperties}
                  />
              </div>
              {!editAllViewableJobOrderProperties && !!editablePropertyChoices.length &&
                <>
                  <div className="col-span-6">
                    <FormInputLabelAndDescription label={'Editable properties'} />
                  </div>
                  {editablePropertyChoices.map((property) => (
                    <div className="col-span-2" key={property.id}>
                      <Checkbox 
                        description={property.label} 
                        disabled={!newRole.h_3_6_edit_job_order}
                        checked={property.checked} 
                        onChange={() => handleUpdateEditableProperties(property)}
                      />
                    </div>
                  ))}
                </> 
              }
            </>
          }
        </>
      }
    </FormWithSideTitle>
  )
}

export default JobOrderActions
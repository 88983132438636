import React, { useState, useEffect, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import axios from 'axios';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import ReactSelect from '../../components/inputs/ReactSelect';
import useApi from '../../store/api/apiContext';
import { passwordTypes as passwordTypesEnum } from '../../helpers/enum/passwordTypes';
import validatePassword from '../../utils/validatePassword';

function AddUserForm() {
  const navigate = useNavigate();
  const { addUser, fetchUserGroupsForAssociation } = useApi();
  const { showAppNotification } = useAppNotification();
  const [newUser, setNewUser] = useState({
    first_names: null,
    last_names: null,
    email: null,
    password: null,
    phone_nb: null,
    role_id: null
  })
  const [roleOptions, setRoleOptions] = useState(null);
  const [selectedRole, setSelectedRole] = useState('')
  const [userGroupOptions, setUserGroupOptions] = useState()
  const [selectedUserGroup, setSelectedUserGroup] = useState(null)
  const [formError, setFormError] = useState([])

  const firstNamesInputRef = useRef(null);
  
  const [passwordTypes, setPasswordTypes] = useState([
    {
      label: 'Set custom password',
      value: passwordTypesEnum.customPassword
    },
    {
      label: "Send to user's email",
      value: passwordTypesEnum.newUserEmail
    },
    {
      label: 'Send to my email',
      value: passwordTypesEnum.myOwnEmail
    },
  ])
  const [selectedPasswordType, setSelectedPasswordType] = useState({
    label: 'Set custom password',
    value: passwordTypesEnum.customPassword
  })

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

 
  
  const postAddUser = async () => {
    let errors=[]

    if (!newUser.first_names) errors.push({
      field: "First names*",
      msg: "This field is required"
    })
    if (!newUser.last_names) errors.push({
      field: "Last names*",
      msg: "This field is required"
    })
    if (!newUser.email) errors.push({
      field: "Email*",
      msg: "This field is required"
    })
    if (!validateEmail(newUser.email)) errors.push({
      field:"Email*",
      msg: "Please provide a valid email address"
    })
    if (!selectedPasswordType.value) errors.push({
      field: "Password*",
      msg: "This field is required"
    })
    if (selectedPasswordType.value === passwordTypesEnum.customPassword && !newUser.password) errors.push({
      field: "Custom password*",
      msg: "This field is required"
    })
    if (selectedPasswordType.value === passwordTypesEnum.customPassword && !validatePassword(newUser.password)) errors.push({
      field: "Custom password*",
      msg: "Password must contain at least 8 characters, upper and lower case letters, a number and a special character"
    })
    if (!newUser.user_group_id) errors.push({
      field: "User group*",
      msg: "This field is required"
    })
    if (!newUser.role_id) errors.push({
      field: "User role*",
      msg: "This field is required"
    })
    if (newUser.phone_nb && !(/^[\d +]+$/.test(newUser.phone_nb))) errors.push({
      field: 'Phone number',
      msg: "Can only contain numbers and (+) sign."
    })

    if (errors.length) {
      firstNamesInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }

    setFormError([]);
    
    addUser(newUser, selectedPasswordType.value).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'User added successfully');
      navigate('/users', { replace: true })
    })
  }

  const fetchUserGroups = async (source) => {
    fetchUserGroupsForAssociation().then(res => {
      let userGroups = [];
      res.data.map(userGroup => userGroups.push({
        value: userGroup.id,
        label: userGroup.name,
        roles: userGroup.roles
      }))
      setUserGroupOptions(userGroups)
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchUserGroups(source);
    return () => {
      source.cancel();
    };
  }, [])

  
  const changeHandlerSelectUserGroup = (e) => {
    setNewUser(prev => ({...prev, user_group_id: e.value }) )
    setSelectedUserGroup(e)
    let roles = []
    e.roles.map(role => roles.push({
      value: role.id,
      label: role.a_name
    }))
    setRoleOptions(roles);
  }
  
  const changeHandlerSelectRole = (e) => {
    setNewUser(prev => ({...prev, role_id: e.value }) )
    setSelectedRole(e)
  }

  const changeHandlerSelectPasswordType = (e) => {
    setSelectedPasswordType(e)
    setNewUser(prev => ({...prev, password: null }) )
    
  }
  
  useEffect(() => {
    setSelectedRole(null)
    setNewUser(prev => ({...prev, role_id: null }) )
  }, [selectedUserGroup])

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='User information' description='Add all relevant user information' >
        <div ref={firstNamesInputRef} className="col-span-6 sm:col-span-3">
          <TextInput label='First names*' errors={formError} value={newUser.first_names} onChange={(e) => setNewUser(prev => ({...prev, first_names: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInput label='Last names*' errors={formError} value={newUser.last_names} onChange={(e) => setNewUser(prev => ({...prev, last_names: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-4">
          <TextInput label='Email*' errors={formError} value={newUser.email} onChange={(e) => setNewUser(prev => ({...prev, email: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-4">
          <TextInput label='Phone number' errors={formError} value={newUser.phone_nb} onChange={(e) => setNewUser(prev => ({...prev, phone_nb: e.target.value }) )}/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title='Account information' description='Add all relevant account information' >
        <div className="col-span-6">
          <ReactSelect label='Password*' errors={formError} selectedOption={selectedPasswordType} options={passwordTypes} onChange={changeHandlerSelectPasswordType}/>
        </div>
        {selectedPasswordType.value === passwordTypesEnum.customPassword && 
        <div className="col-span-6 sm:col-span-6">
          <TextInput label='Custom password*' errors={formError} value={newUser.password} onChange={(e) => setNewUser(prev => ({...prev, password: e.target.value }) )}/>
        </div>
        }
        <div className="col-span-6">
          <ReactSelect label='User group*' errors={formError} placeholder="Select a user group" selectedOption={selectedUserGroup} options={userGroupOptions} onChange={changeHandlerSelectUserGroup}/>
        </div>
        {roleOptions &&
        <div className="col-span-6">
          <ReactSelect label='User role*' errors={formError} selectedOption={selectedRole} options={roleOptions} onChange={changeHandlerSelectRole}/>
        </div>
        }
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigate('/users', {replace: true}) }/>
        <PrimaryButton text='Add' darkRing onClick={postAddUser}/>
      </div>
    </div>
  )
}

export default AddUserForm
import React from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import AddFrameworkContractForm from '../../containers/frameworkContracts/AddFrameworkContractForm'

function AddFrameworkContract({ access }) {
  const navigate = useNavigate();
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigate(`/fw-contracts`, {replace: true}) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Add framework contract'} topSection={renderTopSection()} access={access}>
      <AddFrameworkContractForm />
    </PageWrapper>
  )
}

export default AddFrameworkContract
import React, { useState, useEffect } from 'react';
import useAuth from '../../store/auth/authContext';
import { Link } from 'react-router-dom';
/* This example requires Tailwind CSS v2.0+ */
function SecondaryButton({ size, text, onClick, icon, access, linkTo }) {
  const { auth } = useAuth();
  const role = auth.userRole;
  
  let sizeStyle;
  switch (size) {
    case 'xs':
     sizeStyle = 'px-2.5 py-1.5 text-xs rounded '
     break;
    case 'sm':
     sizeStyle = 'px-3 py-2 text-sm leading-4 rounded-md'
     break;
    case 'md':
     sizeStyle = 'px-4 py-2 text-sm rounded-md '
     break;
    case 'lg':
     sizeStyle = 'px-4 py-2 text-base rounded-md '
     break;
    case 'xl':
     sizeStyle = 'px-6 py-3 text-base rounded-md'
     break;
    default:
      break;
  }

  const [ showButton, setShowButton ] = useState(false);

  const isArray = Array.isArray(access);
  const isBool = typeof access === 'boolean';
  const isString = typeof access === 'string';
  
useEffect(() => {
    if (access === undefined) return setShowButton(true);
    //block access if no property allows user to access (array 'OR' logic)
    if (isArray) {
      let check = false
      access.forEach(accessProperty => {
        if (role[accessProperty]) check = true;
      })
      if (check) setShowButton(true);  
    } 
    if (isString) {
      if (role[access]) setShowButton(true);
    }
    if (isBool) {
      setShowButton(access)
    }
    
  }, [ access, role ])



  const baseStyle = `${
    !showButton && "hidden"
  } truncate inline-flex justify-around items-center border border-transparent shadow-sm font-medium text-thaleria-blue-700 bg-thaleria-blue-50 hover:bg-thaleria-blue-100 focus:ring-thaleria-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 `;

  const BASE_STYLE = {
    minWidth: '100px'
  }
  
  return (
    linkTo ? 
    <Link
    to={linkTo}
    className={`${baseStyle} ${sizeStyle}`}
    style={BASE_STYLE}
    >
      {icon && icon}
      {text}
    </Link>
    : 
    <button
      type="button"
      className={`${baseStyle} ${sizeStyle}`}
      style={BASE_STYLE}
      onClick={onClick}
    >
      {icon && icon}
      {text}
    </button>
  )
}


SecondaryButton.defaultProps = {
  size: 'md',
  access: undefined,
};

export default SecondaryButton;
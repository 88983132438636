import React, { useState, useEffect } from "react";
import useApi from '../../store/api/apiContext';
import { BadgeCheckIcon, ClipboardIcon, DocumentIcon, IdentificationIcon, KeyIcon, OfficeBuildingIcon, SearchIcon, UserGroupIcon, UserIcon } from "@heroicons/react/outline";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { APP_STYLES } from "../../config";
import { Link } from "react-router-dom";
import Modal from "../../components/modal/Modal";
import PulseLoader from "react-spinners/PulseLoader";

function GlobalSearch() {
  const [value, setValue] = useState('');

  const [results, setResults] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [searchInput, setSearchInput ]= useState(null);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  const { getGlobalSearchResults } = useApi();

  const getSearchResults = () => {
    if (Number(value) || value.length > 3) {
      getGlobalSearchResults(value).then((r) => {
        Object.keys(r.data).length
          ? setResults(r.data)
          : setResults({ message: "We didn't find any match to your query. Try another term!" });
          setIsLoadingResults(false);
        });
      } else {
        setIsLoadingResults(false);
        setResults({
        message: "Your query needs to be more than 3 letters minimum, or a number.",
      });
    }
  }

  let timeoutId;
  const timeout = () => {
    timeoutId = setTimeout(getSearchResults, 500);
    return timeoutId;
  };
  const cancelTimeout = () => {
    clearTimeout(timeoutId)
  };

  useEffect(() => {
    if (Object.keys(results)) setResults({});
    if (value && !isLoadingResults) setIsLoadingResults(true);
    cancelTimeout();
    timeout();
    return () => cancelTimeout();
  }, [value]);


  useEffect(() => {
    if (showModal && searchInput) searchInput.focus();
  }, [showModal, searchInput])

  const handleHide = () => {
    setShowModal(false);
    setTimeout(() => {
      setValue('');
      setResults({});
    }, 1000)
  }


  return (
    <div>
      <button
        className="border-2 h-[2rem] border-gray-100 rounded-md items-center flex px-3 pr-8 hover:bg-gray-300 z-30 gap-x-2 border-none focus:ring-0 bg-gray-100 text-sm leading-none text-secondary w-full"
        aria-label={"Search the app"}
        onClick={() => setShowModal(true)}
      >
        <SearchIcon className="h-5 w-5" />
        Search...
      </button>
      <Modal show={showModal} hide={handleHide} size="lg" noPadding>
        <div className="py-2">
          <div className="px-5 flex space-x-4 items-center border-b-2">
            <SearchIcon className="h-6 w-6" />
            <input
              type="text"
              ref={setSearchInput}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Search our database..."
              className="border-none w-full focus:ring-0"
            />
          </div>
          {isLoadingResults && (
            <div className="text-center pt-10 pb-1">
              <PulseLoader color={APP_STYLES.primaryColor} />
            </div>
          )}
          {!value && !isLoadingResults ? (
            <div className=" text-gray-500 text-lg text-center p-8">
              Add a search term to search through our database
            </div>
          ) : (
            <div className="px-6 pb-6">
              {results?.users && (
                <>
                  <div className="text-lg font-semibold my-3">Users</div>
                  <ul className="space-y-2">
                    {results.users.map((user) => (
                      <li>
                        <Link
                          className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4"
                          to={`/users/${user.id}`}
                        >
                          <UserIcon className="w-5 h-5" />
                          {user.first_names + " " + user.last_names}
                          <ChevronRightIcon className="ml-auto w-5 h-5" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.jobOrders && (
                <>
                  <div className="text-lg font-semibold my-3">Job orders</div>
                  <ul className="space-y-2">
                    {results.jobOrders.map((order) => (
                      <li>
                        <Link
                          className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4"
                          to={`/job-orders/${order.id}`}
                        >
                          <ClipboardIcon className="w-5 h-5" />
                          <span className="">
                            {order.title}
                          </span>
                          <span className="text-sm text-gray-500">
                            ID {order.id}
                          </span>
                          <ChevronRightIcon className="ml-auto w-5 h-5" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.candidates && (
                <>
                  <div className="text-lg font-semibold my-3">Candidates</div>
                  <ul className="space-y-2">
                    {results.candidates.map((c) => (
                      <li>
                        <Link
                          className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4"
                          to={`/candidates/${c.id}`}
                        >
                          <IdentificationIcon className="w-5 h-5" />
                          {c.first_names + " " + c.last_names}
                          <ChevronRightIcon className="ml-auto w-5 h-5" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.clients && (
                <>
                  <div className="text-lg font-semibold my-3">Clients</div>
                  <ul className="space-y-2">
                    {results.clients.map((c) => (
                      <li>
                        <Link
                          className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4"
                          to={`/clients/${c.id}`}
                        >
                          <OfficeBuildingIcon className="w-5 h-5" />
                          {c.name}
                          <ChevronRightIcon className="ml-auto w-5 h-5" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.frameworkContracts && (
                <>
                  <div className="text-lg font-semibold my-3">
                    Framework contracts
                  </div>
                  <ul className="space-y-2">
                    {results.frameworkContracts.map((c) => (
                      <li>
                        <Link
                          className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4"
                          to={`/fw-contracts/${c.id}`}
                        >
                          <DocumentIcon className="w-5 h-5" />
                          {c.contract_ref}
                          <ChevronRightIcon className="ml-auto w-5 h-5" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.roles && (
                <>
                  <div className="text-lg font-semibold my-3">Roles</div>
                  <ul className="space-y-2">
                    {results.roles.map((c) => (
                      <li>
                        <Link
                          className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4"
                          to={`/roles/${c.id}`}
                        >
                          <KeyIcon className="w-5 h-5" />
                          {c.a_name}
                          <ChevronRightIcon className="ml-auto w-5 h-5" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.userGroups && (
                <>
                  <div className="text-lg font-semibold my-3">User groups</div>
                  <ul className="space-y-2">
                    {results.userGroups.map((c) => (
                      <li>
                        <Link
                          className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4"
                          to={`/user-groups/${c.id}`}
                        >
                          <UserGroupIcon className="w-5 h-5" />
                          {c.name}
                          <ChevronRightIcon className="ml-auto w-5 h-5" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.skills && (
                <>
                  <div className="text-lg font-semibold my-3">Skills</div>
                  <ul className="space-y-2">
                    {results.skills.map((c) => (
                      <li className="w-full h-10 bg-gray-50 text-base hover:bg-thaleria-orange-400 flex gap-x-4 rounded-md items-center px-4">
                        <BadgeCheckIcon className="w-5 h-5" />
                        {c.name}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {results?.message && (
                <div className=" text-gray-500 text-lg text-center pt-8 pb-2">
                  {results.message}
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default GlobalSearch
import React from 'react'

function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id, Header },
}) {
  const [min, max] = React.useMemo(() => {
    let notNullRows = preFilteredRows.filter(obj => obj.values[id] != '-')
    let min = notNullRows.length ? notNullRows[0].values[id] : 0
    let max = notNullRows.length ? notNullRows[0].values[id] : 0
    notNullRows.forEach(row => {
      if (typeof row.values[id] != 'number')
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <label className="text-sm font-medium text-gray-700 flex">
        { Header }
      </label>
      <div
        className={'flex items-center flex-col sm:flex-row'}
      >
        <input
          className="shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 block w-full sm:text-sm border-gray-300 rounded-md"
          value={filterValue[0] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
        />
        <span className="mx-2">to</span>
        <input
          className="shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 block w-full sm:text-sm border-gray-300 rounded-md"
          value={filterValue[1] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
          }}
          placeholder={`Max (${max})`}
        />
      </div>
    </>
  )
}

export default NumberRangeColumnFilter

import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import { APP_STYLES } from '../../config';


function Loader({ isLoading, children }) {
  
  if (!isLoading) return <>{children}</>;
  return (
    <div className='flex justify-around min-h-170 p-16 bg-white rounded-lg'>
      <MoonLoader color={APP_STYLES.primaryColor}/>
    </div> 
  )
}

export default Loader
import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

function TitleSection({title, resource, tableUrl = '/', children}) {
  return (
     <div className='flex flex-col lg:flex-wrap lg:flex-row lg:items-center '>
        <span className='flex flex-wrap truncate items-center'>
          <Link to={tableUrl} className='cursor-pointer hover:text-thaleria-orange-700 hover:underline underline-offset-2 '>{title}</Link> {resource && <ChevronRightIcon className=' h-8 w-8 mx-2 mt-1.5'/>}
        </span>
        {(resource || children) &&
        <span className='flex items-center'>
          {resource}
          {children}
        </span>
        }
      </div>
  )
}

export default TitleSection
import React, { useState, useEffect } from 'react'
import PrimaryButton from '../../components/buttons/PrimaryButton';
import WhiteButton from '../../components/buttons/WhiteButton';
import useApi from '../../store/api/apiContext';
import useAppNotification from '../../store/appNotification/appNotificationContext';
import Loader from '../../components/loaders/Loader';
import Toggle from '../../components/inputs/Toggle'
import { notificationSettingsStatus } from '../../helpers/enum/notificationSettings';
import DividerWithLabel from '../../components/dividers/DividerWithLabel';
import WarningModal from '../../components/modal/WarningModal';
import ClipLoader from 'react-spinners/ClipLoader'
import { APP_STYLES } from '../../config';

function SettingsSection({ }) {

  const { fetchNotificationSettings, updateSettings, resetTableSettings } = useApi();
  const { showSuccessNotification, showErrorNotification } = useAppNotification();

  const [isLoading, setIsLoading] = useState(false)
  const [resetIsLoading, setResetIsLoading] = useState(false)
  const [showResetWarning, setShowResetWarning] = useState(false)

  useEffect(() => {
    getUserSettings()
  }, [])

  const getUserSettings = () => {
    fetchNotificationSettings().then(res => {
      if (res?.data?.notification_settings) {
        const notificationSettings = JSON.parse(res.data.notification_settings)
        setJobStatusSettings(notificationSettings.jobStatus)
        setApplicationStatusSettings(notificationSettings.applicationStatus)
        setCommentsSettings(notificationSettings.comments)
      }
    })
  }
  
  const onResetSettings = () => {
    setResetIsLoading(true)
    resetTableSettings().then(res => {
      setResetIsLoading(false)
      setShowResetWarning(false)
      showSuccessNotification('Successfully reset table settings')
    }).catch(err => {
      showErrorNotification('You do not have any settings currently saved');
      setShowResetWarning(false)
    })
  }
  
  const [jobStatusSettings, setJobStatusSettings] = useState({
      push: notificationSettingsStatus.disabled,
      email: notificationSettingsStatus.disabled
  });
  const [applicationStatusSettings, setApplicationStatusSettings] = useState({
      push: notificationSettingsStatus.disabled,
      email: notificationSettingsStatus.disabled
  });
  const [commentsSettings, setCommentsSettings] = useState({
      push: notificationSettingsStatus.disabled,
      email: notificationSettingsStatus.disabled
  });


  const handleSave = () => {
    setIsLoading(true)
    const notificationSettingsData = {
      jobStatus: jobStatusSettings,
      applicationStatus: applicationStatusSettings,
      comments: commentsSettings
    }
    updateSettings(null, notificationSettingsData).then((res) => {
      showSuccessNotification('Your settings have been saved');
      setIsLoading(false)
    }).catch(err => {
      getUserSettings();
      setIsLoading(false)
    })
  }

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      {/* Password section */}
      <Loader isLoading={isLoading}>
        <WarningModal 
          title='Reset table settings?' 
          showWarning={showResetWarning} 
          setShowWarning={setShowResetWarning} 
          message="Resetting your settings will delete all saved filters, sorting and column editing. Are you sure you want to proceed?" 
          buttonText={
            <div className="relative inline-block">
              <div
                className={`absolute inset-0 flex items-center justify-center ${resetIsLoading ? 'visible' : 'invisible'}`}
              >
                <ClipLoader className="mr-2" size={17} color={'#FFF'} />
              </div>
              <span className={resetIsLoading ? 'invisible' : 'visible'}>Continue</span>
            </div>
          } 
          onClick1={() => onResetSettings()} 
          onClick2={() => setShowResetWarning(false)}
        />
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Table settings</h2>
            <p className="mt-1 text-sm text-gray-500">
            If you are encountering issues while opening tables you can try resetting your table settings. This will delete all your saved settings like filters, sorting and column ordering but can fix the table rendering issue.
            </p>
          </div>
          <div className="mt-6 flex flex-col space-y-6 w-36">
            <PrimaryButton text='Reset settings' size='md' onClick={() => setShowResetWarning(true)} />
          </div>
          <div className='mt-8'>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Notification settings</h2>
            <p className="mt-1 text-sm text-gray-500">
              You can select which notifications to receive
            </p>
          </div>
          <div className="mt-6 flex flex-col space-y-6">
            <div className='flex flex-col space-y-2'>
              <DividerWithLabel label='Job status' labelClass='text-md text-grey-700'/>
              <span className='text-grey-500 text-sm'>When toggled on, receive notifications when the status of a Job order changes.</span>
            </div>
            
            <div className="w-full sm:w-1/2 flex flex-col space-y-2">
              <Toggle label='Push notification' value={!!jobStatusSettings.push} onChange={() => setJobStatusSettings(prev => ({...prev, push: jobStatusSettings.push ? 0 : 1}))}/>
              <Toggle label='Email notification' value={!!jobStatusSettings.email} onChange={() => setJobStatusSettings(prev => ({...prev, email: jobStatusSettings.email ? 0 : 1}))}/>
            </div>
            <div className='flex flex-col space-y-2'>
              <DividerWithLabel label='Application status' labelClass='text-md text-grey-700'/>
              <span className='text-grey-500 text-sm'>When toggled on, receive notifications when the application status of one of your candidates changes.</span>
            </div>
            <div className="w-full sm:w-1/2 flex flex-col space-y-2">
              <Toggle label='Push notification' value={!!applicationStatusSettings.push} onChange={() => setApplicationStatusSettings(prev => ({...prev, push: applicationStatusSettings.push ? 0: 1}))}/>
              <Toggle label='Email notification' value={!!applicationStatusSettings.email} onChange={() => setApplicationStatusSettings(prev => ({...prev, email: applicationStatusSettings.email ? 0: 1}))}/>
            </div>
            <div className='flex flex-col space-y-2'>
              <DividerWithLabel label='Comments' labelClass='text-md text-grey-700'/>
              <span className='text-grey-500 text-sm'>When toggled on, receive notifications when a comment is added on a Job order.</span>
            </div>
            <div className="w-full sm:w-1/2 flex flex-col space-y-2">
              <Toggle label='Push notification' value={!!commentsSettings.push} onChange={() => setCommentsSettings(prev => ({...prev, push: commentsSettings.push ? 0 : 1}))}/>
              <Toggle label='Email notification' value={!!commentsSettings.email} onChange={() => setCommentsSettings(prev => ({...prev, email: commentsSettings.email ? 0 : 1}))}/>
            </div>
          </div>
        </div>
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6 space-x-2">
          <WhiteButton 
            text='Cancel'
            onClick={getUserSettings}
          />
          <PrimaryButton 
            text='Save'
            darkRing
            onClick={handleSave}
          />
        </div>
      </Loader>
    </form>
  )
}

export default SettingsSection
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import useAuth from '../../store/auth/authContext';
import classNames from '../../utils/classNames';
import ProfilePicture from '../../components/profilePicture/ProfilePicture';
import Logo from '../../components/app/Logo'
import GlobalSearch from '../app/GlobalSearch';
import NavbarNotifications from '../../containers/notifications/NavbarNotifications'
function Navbar({ }) {
  const { logout, auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  
  const role = auth.userRole;
  const user = auth.currentUser;

  const navigation = [
    { name: 'Dashboard', to: '/dashboard', current: location.pathname.includes('/dashboard'), access: true },
    { name: 'Users', to: '/users', current: location.pathname.includes('/users'), access: role ? role.f_1_view_users : false},
    { name: 'Roles', to: '/roles', current: location.pathname.includes('/roles'), access: role ? role.c_manage_roles : false},
    { name: 'User groups', to: '/user-groups', current: location.pathname.includes('/user-groups'), access: role ? role.b_manage_user_groups : false},
    { name: 'Framework contracts', to: '/fw-contracts', current: location.pathname.includes('/fw-contracts'), access: role ? role.d_manage_framework_contracts : false},
    { name: 'Clients', to: '/clients', current: location.pathname.includes('/clients'), access: role ? role.e_manage_clients : false},
    { name: 'Job orders', to: '/job-orders', current: location.pathname.includes('/job-orders'), access: role ? role.h_1_view_job_orders : false},
    { name: 'Candidates', to: '/candidates', current: location.pathname.includes('/candidates'), access: role ? role.g_1_create_my_candidate || role.g_4_manage_candidates : false},
    { name: 'Applications', to: '/applications-log', current: location.pathname.includes('/applications-log'), access: role ? role.h_1_view_job_orders : false},
  ]
  
  const userNavigation = [
    { name: 'My account', onClick: () => navigate('/my-account'), current: location.pathname.includes('/my-account') },
    { name: 'Sign out', onClick: () => logout()},
  ]

  return (
    <Disclosure as="nav" className="bg-thaleria-blue-600 ">
      {({ open }) => (
        <>
          <div className={`max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 `}>
            <div className="border-b border-gray-500 ">
              <div className="flex items-center justify-between h-16 px-4 sm:px-0 ">
                <div className="flex items-center">
                  <Link key='logo' to={`/dashboard`} className='cursor-pointer'><Logo small white /></Link>
                  <div className="hidden lg:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => {
                        if (item.access)
                          return (
                            <Link
                              key={item.name}
                              to={item.to}
                              className={classNames(
                                item.current
                                  ? "bg-thaleria-blue-500 text-white"
                                  : "text-white hover:bg-thaleria-blue-500 hover:text-white",
                                "px-3 py-2 rounded-md text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          );
                      })}
                    </div>
                  </div>
                </div>
                <div className='lg:ml-auto'>
                  <GlobalSearch />
                </div>
                <div className="hidden lg:block">
                  <div className=" ml-4 flex items-center lg:ml-6">
                    <NavbarNotifications />
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative z-20">
                      <div>
                        <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                          <span className="sr-only">Open user menu</span>
                          <ProfilePicture user={user} />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => {
                            return (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <div
                                    onClick={item.onClick}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                    )}
                                  >
                                    {item.name}
                                  </div>
                                )}
                              </Menu.Item>
                            );
                          })}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-thaleria-orange-800 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-thaleria-orange-700 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-thaleria-orange-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="border-b border-gray-700 lg:hidden">
            <div className="px-2 py-3 space-y-1 sm:px-3">
              {navigation.map((item) => {
                if (item.access)
                  return (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={classNames(
                        item.current
                          ? "bg-thaleria-blue-500 text-white"
                          : "text-white hover:bg-thaleria-blue-500 hover:bg-opacity-75",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  );
              })}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <ProfilePicture small user={user} />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white">
                    {user.name}
                  </div>
                  <div className="text-sm font-medium leading-none text-gray-400">
                    {user.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {userNavigation.map((item) => {
                  return (
                    <div
                      onClick={item.onClick}
                      className={classNames(
                        item.current
                          ? "bg-thaleria-blue-500"
                          : "hover:bg-thaleria-blue-500",
                        "block px-4 py-2 text-white cursor-pointer rounded-md"
                      )}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Navbar
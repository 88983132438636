import React, { useMemo } from "react";
import Table from "../table/Table";
import { applicationStatuses } from "../../helpers/enum/applicationStatuses";
import dayjs from "dayjs";
import { ExternalLinkIcon, EyeIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { useNavigate } from "react-router";

function ApplicationsTable({applications, isLoading}) {

  const data = useMemo(
    () => []
  , [applications]);

  const navigate = useNavigate();


  if (applications.length && !data.length) {
    applications.forEach(app => {
      data.push({
        id: app.id,
        candidate: {
          id: app.applicant_id,
          cell_content: app?.candidate?.last_names ? `${app.candidate.first_names} ${app.candidate.last_names}` : `${app.candidate.first_names}`,
          is_within_scope: app.candidate.is_within_scope
        },
        candidate_owner: app.candidate?.originalOwner?.last_names ? `${app.candidate?.originalOwner?.first_names} ${app.candidate?.originalOwner?.last_names}` : `${app.candidate?.originalOwner?.first_names}`,
        createdBy: app?.user ? `${app.user.first_names}` : '-',
        createdAt: dayjs(app.created_at).format('DD/MM/YYYY'),
        jobOrder_title: app.job_order?.title ? `${app.job_order.title}` : '-',
        jobOrder_id: app.job_order_id,
        jobOrder_client: app.job_order?.client ? `${app.job_order.client.name}` : '-',
        status: applicationStatuses[app.status],
      })
    })
  }

  const columns = useMemo(() => {
    let columnsToShow = []
    let dataProperties = Object.keys(applications[0] || {})
    if (dataProperties.includes('job_order') || applications.length === 0) {
      columnsToShow.push({
        Header: 'Job order ID',
        accessor: 'jobOrder_id',
      });
      columnsToShow.push({
        Header: 'Job order title',
        accessor: 'jobOrder_title',
      });
      columnsToShow.push({
        Header: 'Client',
        accessor: 'jobOrder_client',
      });
    }
    if (dataProperties.includes('candidate') || applications.length === 0) {
      columnsToShow.push({
        Header: 'Candidate',
        accessor: 'candidate',
        filter: 'filterCustomCells',
        Cell: ({value}) => {
          return (
            value.is_within_scope ? 
            <div className='flex gap-x-1 items-center'>
              <span>{value.cell_content}</span>
              <ExternalLinkIcon className="h-4 w-4 cursor-pointer text-thaleria-orange-700 hover:text-thaleria-orange-500"
                  onClick={() => window.open(`/candidates/${value.id}`)}/>
            </div> :
            <span>{value.cell_content}</span>
          )
        }
      });
      columnsToShow.push({
        Header: 'Recruiter',
        accessor: 'candidate_owner',
      })
    }
    if (dataProperties.includes('status') || applications.length === 0) columnsToShow.push({
      Header: 'Status',
      accessor: 'status',
    });
    if (dataProperties.includes('created_at') || applications.length === 0) columnsToShow.push({
      Header: 'Updated at',
      accessor: 'createdAt',
    });
    return columnsToShow
  }, [applications])

  const rowOnClick = (row) => {
    navigate(`/job-orders/${row.original.jobOrder_id}`)
  }

  return (
    <>
      <Table data={data} columns={columns} isLoading={isLoading} rowOnClick={rowOnClick}/>
    </>
  )
}

export default ApplicationsTable
import React from 'react'
import DescriptionList from '../../../components/descriptionLists/DescriptionList'

function CommentActionsDetails({ role }) {
 
 const items = [
    { label: 'View job order comments', data: role.i_1_view_job_order_comments ? 'Yes' : 'No', id: 1, show: true },
    { label: 'View job candidate comments', data: role.i_2_view_candidate_comments ? 'Yes' : 'No', id: 2, show: true },
    { label: 'View client comments', data: role.i_3_view_client_comments ? 'Yes' : 'No', id: 3, show: true },
    { label: 'Manage comments', data: role.i_4_manage_comments ? 'Yes' : 'No', id: 4, show: true },
    
  ]
  return (
    <DescriptionList title='Comment actions' items={items}/>
  )
}

export default CommentActionsDetails
import React from 'react'
import DescriptionList from '../../../components/descriptionLists/DescriptionList'

function AttachmentActionsDetails({ role }) {
  
  const items = [
    { label: 'View job order attachments', data: role.j_1_view_job_order_attachments ? 'Yes' : 'No', id: 1, show: true },
    { label: 'View job candidate attachments', data: role.j_2_view_candidate_attachments ? 'Yes' : 'No', id: 2, show: true },
    { label: 'Manage attachments', data: role.j_3_manage_attachments ? 'Yes' : 'No', id: 3, show: true },
    
  ]
  return (
    <DescriptionList title='Attachment actions' items={items}/>
  )
}

export default AttachmentActionsDetails
import React, { useState } from 'react'
import PageWrapper from '../containers/app/PageWrapper'
import classNames from '../utils/classNames'
import { KeyIcon, UserCircleIcon, CogIcon } from '@heroicons/react/outline'
import ProfileSection from '../containers/myAccount/ProfileSection'
import PasswordSection from '../containers/myAccount/PasswordSection'
import SettingsSection from '../containers/myAccount/SettingsSection'

function MyAccount() {
  
  const [navigation, setNavigation] = useState([
    { name: 'Profile', icon: UserCircleIcon, current: true },
    { name: 'Password', icon: KeyIcon, current: false },
    { name: 'Settings', icon: CogIcon, current: false },
  ])

  return (
    <PageWrapper title={"My account"}>
      <div className="divide-y divide-gray-200 bg-white flex-grow rounded-lg lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="py-6 lg:col-span-3">
          <nav className="space-y-1">
            {navigation.map((item) => (
              <button
                key={item.name}
                onClick={() =>
                  setNavigation(
                    navigation.map((e) => ({
                      ...e,
                      current: e.name === item.name,
                    }))
                  )
                }
                className={classNames(
                  item.current
                    ? "bg-thaleria-orange-50 border-thaleria-orange-700 text-thaleria-orange-800 hover:bg-thaleria-orange-50 hover:text-thaleria-orange-700"
                    : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                  "group border-l-4 px-3 py-2 flex items-center text-sm font-medium w-full"
                )}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? "text-thaleria-orange-700 group-hover:text-thaleria-orange-700"
                      : "text-gray-400 group-hover:text-gray-500",
                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </button>
            ))}
          </nav>
        </aside>
        {navigation.map((el) => {
          if (el.current && el.name === "Profile")
            return <ProfileSection key={el.name} />;
          if (el.current && el.name === "Password")
            return <PasswordSection key={el.name} />;
          if (el.current && el.name === "Settings")
            return <SettingsSection key={el.name} />;
          return "";
        })}
      </div>
    </PageWrapper>
  );
}

export default MyAccount;

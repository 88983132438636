import React, { useState, useEffect } from 'react'
import FormWithSideTitle from '../../../components/forms/FormWithSideTitle';
import ReactSelect from '../../../components/inputs/ReactSelect';
import Toggle from '../../../components/inputs/Toggle';
import Checkbox from '../../../components/inputs/Checkbox';
import useApi from '../../../store/api/apiContext';
import FormInputLabelAndDescription from '../../../components/forms/FormInputLabelAndDescription';
import CANDIDATE_PROPERTIES from '../../../helpers/enum/candidateProperties';
import { ALL_ACCESS_UUID } from '../../../config';

function CandidateActions({ 
  newRole,
  setNewRole,
  manageAllCandidates,
  setManageAllCandidates,
  editAllManagedCandidatesProperties,
  setEditAllManagedCandidatesProperties,
  editAllMyCandidateProperties,
  setEditAllMyCandidateProperties,
  userGroups
}) {

  const { fetchCandidateProperties } = useApi();
  const [candidateProperties, setCandidateProperties ] = useState([]) 

  useEffect(() => {
    fetchCandidateProperties().then(res => {
      //remove properties that should not be in config
      let filtered = res.data.filter(el => el !== 'id' && el !== 'uuid')
      setCandidateProperties(filtered);
    })
  }, [])

  const [ initiallySelectedUserGroups, setInitiallySelectedUserGroups ] = useState([]);

  //set initial selected userGroups
  useEffect(() => {
    if (userGroups?.length && newRole.g_4_1_allowed_owner_user_group_ids[0] !== ALL_ACCESS_UUID) {
      let selectedUsrGrp = [];
      userGroups.forEach(userGroup => {
        if (newRole.g_4_1_allowed_owner_user_group_ids.includes(userGroup.id)) selectedUsrGrp.push({ label: userGroup.name, value: userGroup.id })
      })
      setInitiallySelectedUserGroups(selectedUsrGrp);
      fetchOptions_g_4_1_allowed_owner_user_group_ids();
    } 
  }, [userGroups])

  useEffect(() => {

  })

  //====g_1_create_my_candidate====//

  const handleToggle_g_1_create_my_candidate = () => {
    let newStatus = !newRole.g_1_create_my_candidate;
    setNewRole(prev => ({...prev, g_1_create_my_candidate: newStatus }));
  }

  //====g_3_delete_my_candidate====//

  const handleToggle_g_3_delete_my_candidate = () => {
    let newStatus = !newRole.g_3_delete_my_candidate;
    setNewRole(prev => ({...prev, g_3_delete_my_candidate: newStatus }));
  }

  //====g_2_edit_my_candidate====//

  const handleToggle_g_2_edit_my_candidate = () => {
    let newStatus = !newRole.g_2_edit_my_candidate;
    setNewRole(prev => ({...prev, g_2_edit_my_candidate: newStatus }));
    if (newStatus) {
      setEditAllMyCandidateProperties(true);
      setNewRole(prev => ({...prev, g_2_1_editable_properties_my_candidate: [ ALL_ACCESS_UUID ]}));
    }
    if (!newStatus) {
      setEditableCandidateProperties([]);
      setNewRole(prev => ({...prev, g_2_1_editable_properties_my_candidate: [] }));
    }
  }

  const [ editableCandidateProperties, setEditableCandidateProperties ] = useState([])

  const handleChangeEditAllMyCandidateProperties = () => {
    let newStatus = !editAllMyCandidateProperties;
    setEditAllMyCandidateProperties(newStatus)
    if (newStatus) {
      setEditableCandidateProperties([]);
      setNewRole(prev => ({...prev, g_2_1_editable_properties_my_candidate: [ ALL_ACCESS_UUID ]}))
    } else {
      let array = candidateProperties.map(property => {
        return {label: CANDIDATE_PROPERTIES[property], id: property, checked: false}
      })
      let filteredArray = array.filter(el => (el.id !== 'id' && el.id !== 'created_at' && el.id !== 'deleted_at' && el.id !== 'updated_at'));
      setEditableCandidateProperties(filteredArray);
    }
  }

  const handleUpdateEditableCandidatePropertyChange = (property) => {
    let newArray = editableCandidateProperties.map(element  => {
      if (element === property) return { ...element, checked: !property.checked };
      return element;
    })
    setEditableCandidateProperties(newArray);
  }

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    //set initial checked editable properties
    if (firstRender && candidateProperties?.length && newRole.g_2_edit_my_candidate && !editAllMyCandidateProperties) {
      let array = candidateProperties.map(property => {
        return {label: CANDIDATE_PROPERTIES[property], id: property, checked: newRole.g_2_1_editable_properties_my_candidate.includes(property)}
      })
      setEditableCandidateProperties(array);
      return setFirstRender(false);
    }

    if (!editableCandidateProperties.length) return;
    if (newRole.g_2_edit_my_candidate) {
      let editableProperties = [];
      editableCandidateProperties.forEach(property => {
        if (property.checked) {
          editableProperties.push(property.id);
        }
      })
      setNewRole(prev => ({...prev, g_2_1_editable_properties_my_candidate: editableProperties }));
    }
  }, [editableCandidateProperties, candidateProperties])  

  //g_4_manage_candidates
  //g_4_1_allowed_owner_user_group_ids

  const manageCandidatesChangeHandler = () => {
    let newStatus = !newRole.g_4_manage_candidates
    setNewRole(prev => ({ ...prev, 
      g_4_manage_candidates: newStatus,
      g_4_1_allowed_owner_user_group_ids: [],
      g_4_2_create_candidates: false,
      g_4_3_edit_candidates: false,
      g_4_3_1_editable_properties: [],
      g_4_4_delete_candidates: false,
    }));
    if (newStatus) {
      setManageAllCandidates(true);
      setNewRole(prev => ({ ...prev, g_4_1_allowed_owner_user_group_ids: [ ALL_ACCESS_UUID ]}));
    }
  }

  const [options_g_4_1_allowed_owner_user_group_ids, setOptions_g_4_1_allowed_owner_user_group_ids] = useState([]);

  const fetchOptions_g_4_1_allowed_owner_user_group_ids = () => {
    let viewAllAccessCode = ALL_ACCESS_UUID;
    let options = [];
    if (newRole.f_1_1_allowed_user_group_ids[0] === viewAllAccessCode) {
      userGroups.forEach(userGroup => {
        options.push({
          value: userGroup.id,
          label: userGroup.name
        })
      })
    } else {
      userGroups.forEach(userGroup => {
        if (newRole.f_1_1_allowed_user_group_ids.includes(userGroup.id)) {
          options.push({
            value: userGroup.id,
            label: userGroup.name
          })
        }
      })
    }
    setOptions_g_4_1_allowed_owner_user_group_ids(options);
  }

  const handleChangeManageAllCandidates = () => {
    let newStatus = !manageAllCandidates;
    setManageAllCandidates(newStatus);
    if (newStatus) {
      setNewRole(prev => ({ ...prev, g_4_1_allowed_owner_user_group_ids: [ ALL_ACCESS_UUID ]}));
      setOptions_g_4_1_allowed_owner_user_group_ids([]);
    } else {
      setNewRole(prev => ({ ...prev, g_4_1_allowed_owner_user_group_ids: []}));
      fetchOptions_g_4_1_allowed_owner_user_group_ids();
    }
  }

  const changeHandler_g_4_1_allowed_owner_user_group_ids = event => {
    let userGroups;
    if (!event) userGroups = [];
    else userGroups = event.map(userGroup => userGroup.value);
    setNewRole(prev => ({ ...prev, g_4_1_allowed_owner_user_group_ids: userGroups }));
    setInitiallySelectedUserGroups([]);
  };

  //====g_4_3_1_editable_properties====//
  const [ editableManageCandidateProperties, setEditableManageCandidateProperties ] = useState([])
  
  const handleToggle_g_4_3_edit_candidates = () => {
    let newStatus = !newRole.g_4_3_edit_candidates;
    setNewRole(prev => ({...prev, g_4_3_edit_candidates: newStatus }));
    setEditableManageCandidateProperties([])
    if (newStatus) {
      setEditAllManagedCandidatesProperties(true);
      setNewRole(prev => ({...prev, g_4_3_1_editable_properties: [ ALL_ACCESS_UUID ]}))
    }
    if (!newStatus) {
      setEditAllManagedCandidatesProperties(false);
      setNewRole(prev => ({...prev, g_4_3_1_editable_properties: [ ]}))
    }
  }

  const handleChangeEditAllManagedCandidatesProperties = () => {
    let newStatus = !editAllManagedCandidatesProperties;
    setEditAllManagedCandidatesProperties(newStatus);
    if (newStatus) {
      setNewRole(prev => ({...prev, g_4_3_1_editable_properties: [ ALL_ACCESS_UUID ]}))
    } else {
      setNewRole(prev => ({...prev, g_4_3_1_editable_properties: [ ]}))

      let array = candidateProperties.map(property => {
        return {label: CANDIDATE_PROPERTIES[property], id: property, checked: false}
      })
      let filteredArray = array.filter(el => (el.id !== 'id' && el.id !== 'created_at' && el.id !== 'deleted_at' && el.id !== 'updated_at'));
      setEditableManageCandidateProperties(filteredArray);
      
    }
  }

  const handleUpdateEditableManageCandidatePropertyChange = (property) => {
    let newArray = editableManageCandidateProperties.map(element  => {
      if (element === property) return { ...element, checked: !property.checked };
      return element;
    })
    setEditableManageCandidateProperties(newArray);
  }

  const [ initialRender, setInitialRender ] = useState(true);

  useEffect(() => {
     //set initial checked editable properties
    if (initialRender && candidateProperties?.length && newRole.g_4_3_edit_candidates && !editAllManagedCandidatesProperties) {
      let array = candidateProperties.map(property => {
        return {label: CANDIDATE_PROPERTIES[property], id: property, checked: newRole.g_4_3_1_editable_properties.includes(property)}
      })
      setEditableManageCandidateProperties(array);
      return setInitialRender(false);
    }

    if (!editableManageCandidateProperties.length) return;
    if (newRole.g_4_3_edit_candidates) {
      let editableProperties = [];
      editableManageCandidateProperties.forEach(property => {
        if (property.checked) {
          editableProperties.push(property.id);
        }
      })
      setNewRole(prev => ({...prev, g_4_3_1_editable_properties: editableProperties }));
    }
  }, [editableManageCandidateProperties, candidateProperties])  

  return (
    <FormWithSideTitle title='Candidate actions' description='Set which candidate actions your role will enable' >
      <div className="col-span-6">
        <Toggle bold label='Create my candidates' description={'Allows user to add candidates with himself as owner'} value={newRole.g_1_create_my_candidate} onChange={handleToggle_g_1_create_my_candidate}/>
      </div>
      <div className="col-span-6">
        <Toggle bold label='Delete my candidates' description={'Allows to delete candidates for which the user is the owner'} value={newRole.g_3_delete_my_candidate} onChange={handleToggle_g_3_delete_my_candidate}/>
      </div>
      <div className="col-span-6">
        <Toggle bold label='Edit my candidates' description={'Allows to edit candidates for which the user is the owner'} value={newRole.g_2_edit_my_candidate} onChange={handleToggle_g_2_edit_my_candidate}/>
      </div>
      {newRole.g_2_edit_my_candidate && 
        (
          <>
            <div className="col-span-6">
              <Checkbox 
                description='Allow to edit all my candidate properties' 
                checked={editAllMyCandidateProperties} 
                onChange={handleChangeEditAllMyCandidateProperties}
              />
            </div>
            {!!editableCandidateProperties.length &&
            <>
              <div className="col-span-6">
                <FormInputLabelAndDescription label={'Editable properties'} disabled={!newRole.g_2_edit_my_candidate}/>
              </div>
              {editableCandidateProperties.map((property) => {
                if (property.label) return (
                <div className="col-span-2" key={property.id}>
                  <Checkbox 
                    description={property.label} 
                    disabled={!newRole.g_2_edit_my_candidate}
                    checked={property.checked} 
                    onChange={() => handleUpdateEditableCandidatePropertyChange(property)}
                  />
                </div>
              )})}
            </>}
          </>
        )
      }
      <div className="col-span-6">
        <Toggle bold label='Manage candidates' description={'Allows to add admin actions for managing candidates'} value={newRole.g_4_manage_candidates} onChange={manageCandidatesChangeHandler}/>
      </div>
      {newRole.g_4_manage_candidates && 
        <>
          <div className="col-span-6">
            <Checkbox 
              label='Candidate owners' 
              description='Allow to manage candidates belonging to any viewable user (defined in "User actions")' 
              checked={manageAllCandidates} 
              onChange={handleChangeManageAllCandidates}
            />
          </div>
          {!manageAllCandidates && !!options_g_4_1_allowed_owner_user_group_ids.length && 
            <div className="col-span-6">
              <ReactSelect
                isMulti
                label='Manage candidates from users of following userGroups' 
                options={options_g_4_1_allowed_owner_user_group_ids} 
                onChange={changeHandler_g_4_1_allowed_owner_user_group_ids}
                selectedOptions={initiallySelectedUserGroups?.length ? initiallySelectedUserGroups : undefined}
                placeholder={initiallySelectedUserGroups?.length ? undefined : 'Select user groups'}
              />
            </div>
          }
          <div className="col-span-6">
            <Toggle label='Create candidates' value={newRole.g_4_2_create_candidates} onChange={() => setNewRole(prev => ({...prev, g_4_2_create_candidates: !newRole.g_4_2_create_candidates}))}/>
          </div>
          <div className="col-span-6"> 
            <Toggle label='Delete candidates' value={newRole.g_4_4_delete_candidates} onChange={() => setNewRole(prev => ({...prev, g_4_4_delete_candidates: !newRole.g_4_4_delete_candidates}))}/>
          </div>
          <div className="col-span-6">
            <Toggle label='Edit candidates' value={newRole.g_4_3_edit_candidates} onChange={handleToggle_g_4_3_edit_candidates}/>
          </div>
          {
            newRole.g_4_3_edit_candidates && 
            <>
              <div className="col-span-6">
                <Checkbox 
                  description="Allow to edit all managed candidates' properties" 
                  checked={editAllManagedCandidatesProperties} 
                  onChange={handleChangeEditAllManagedCandidatesProperties}
                />
              </div>
              {!!editableManageCandidateProperties.length &&
                <>
                  <div className="col-span-6">
                    <FormInputLabelAndDescription label={'Editable properties'}/>
                  </div> 
                  {editableManageCandidateProperties.map((property) => {
                    if(property.label) return (
                    <div className="col-span-2" key={property.id}>
                      <Checkbox 
                        description={property.label} 
                        disabled={!newRole.g_4_3_edit_candidates}
                        checked={property.checked} 
                        onChange={() => handleUpdateEditableManageCandidatePropertyChange(property)}
                      />
                    </div>
                  )})}
                </>
              }
            </>
          }
        </>
      }
      </FormWithSideTitle>
  )
}

export default CandidateActions
import React, { useEffect, useState, useMemo, useRef } from 'react'
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router';
import useApi from '../../store/api/apiContext';
import ReactSelect from '../../components/inputs/ReactSelect';
import extractObjectDifferences from '../../helpers/enum/extractObjectDifferences';
import COUNTRIES from '../../helpers/countryList'
import useNavigateBack from '../../hooks/useNavigateBack';
import useAppNotification from '../../store/appNotification/appNotificationContext';


function EditClientForm({ client }) {

  
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const { editClient } = useApi();
  const { showSuccessNotification } = useAppNotification();
  
  const [editedClient, setEditedClient] = useState()
  const [formError, setFormError] = useState()
  const [selectedSector, setSelectedSector] = useState()
  const [selectedCountry, setSelectedCountry] = useState()
  const [ countryOptions, setCountryOptions ] = useState()

  useEffect(() => {
    setEditedClient(client)
    setSelectedSector({value: client.sector, label: client.sector})
    setSelectedCountry()
  }, [client])

  const nameInputRef = useRef(null);

  const handleEditClient = () => {
    let errors = [];

    if (!editedClient.name) errors.push({
      field: "Name*",
      msg: "Required field"
    })

    if (editedClient.phone_nb && !(/^[\d +]+$/.test(editedClient.phone_nb))) errors.push({
      field: 'Phone number',
      msg: "Can only contain numbers and (+) sign."
    })

    if (errors.length) {
      nameInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }    

    setFormError([]);

    const clientData = extractObjectDifferences(client, editedClient)
    editClient(id, clientData).then(() => {
      navigate(`/clients/${id}`, {replace: true});
      showSuccessNotification('Client edited successfully');
    })
  }

  const handleSectorChange = (e) => {
    setEditedClient(prev => ({...prev, sector: e.value }) )
    setSelectedSector(e)
  }

  const handleCountryChange = (e) => {
    setEditedClient(prev => ({...prev, country: e.value }) )
    setSelectedCountry(e)
    
  }

  const sectorOptions = [
    {
    label: "Private",
    value: "Private"
    },
    {
      label: "Public",
      value: "Public"
    },
    {
      label: "EU",
      value: "EU"
    }
  ]

  useEffect(() => {
    let options = []
    COUNTRIES.forEach(country => {
      options.push({
        label: country.name,
        value: country.code
      })
      if (country.code == client.country) {
        setSelectedCountry({
          label: country.name,
          value: country.code
        })
      }
    })  
    setCountryOptions(options)
  }, [COUNTRIES])

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Client details' description='Edit information of this contract' >
        <div ref={nameInputRef} className="col-span-6">

          {editedClient&&  <TextInput label='Name*' errors={formError} value={editedClient.name} onChange={(e) => setEditedClient(prev => ({...prev, name: e.target.value }) )}/>}
        </div>    
        <div className="col-span-6">
          {editedClient&& <TextInput label='Phone number' errors={formError} value={editedClient.phone_nb} onChange={(e) => setEditedClient(prev => ({...prev, phone_nb: e.target.value }) )}/>}
        </div>    
        <div className="col-span-6">
          {editedClient&& <ReactSelect label='Sector' errors={formError} selectedOption={selectedSector} options={sectorOptions} onChange={handleSectorChange}/>}
        </div>    
        <div className="col-span-6">
          {editedClient&& <TextInput label='City' errors={formError} value={editedClient.city} onChange={(e) => setEditedClient(prev => ({...prev, city: e.target.value }) )}/>}
        </div>    
        <div className="col-span-6">
          {editedClient&& <ReactSelect label='Country' errors={formError} selectedOption={selectedCountry} options={countryOptions} onChange={handleCountryChange}/>}
        </div>    
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigateBack(`/clients`) }/>
        <PrimaryButton text='Edit' darkRing onClick={handleEditClient}/>
      </div>
    </div>
  )
}

export default EditClientForm
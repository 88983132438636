import React, { useState, useEffect, useRef } from 'react';
import TextInput from '../../../components/inputs/TextInput';
import FormWithSideTitle from '../../../components/forms/FormWithSideTitle';
import WhiteButton from '../../../components/buttons/WhiteButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import Toggle from '../../../components/inputs/Toggle';
import CandidateActions from './CandidateActions';
import JobOrderActions from './JobOrderActions';
import useApi from '../../../store/api/apiContext';
import UserActions from './UserActions';
import Loader from '../../../components/loaders/Loader';
import useAppNotification from '../../../store/appNotification/appNotificationContext';
import appNotificationTypes from '../../../helpers/enum/appNotificationTypes';
import CommentsActions from './CommentsActions';
import AttachmentsActions from './AttachmentsActions';
import { ALL_ACCESS_UUID } from '../../../config';
import useNavigateBack from '../../../hooks/useNavigateBack';

function EditRoleForm({ newRole, setNewRole }) {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  
  const { editRole, fetchUserGroups } = useApi();
  const { showAppNotification } = useAppNotification();

  const nameInputRef = useRef(null)

  const [ userGroups, setUserGroups ] = useState();
  const [isLoading, setIsLoading ] = useState(true);
  
  useEffect(() => {
    fetchUserGroups().then(res => {
      setUserGroups(res.data);
    })
  }, [])

  const [formErrors, setFormErrors] = useState([])

  const [viewAllUserProperties, setViewAllUserProperties] = useState(false);
  const [viewUsersFromAllUserGroups, setViewUsersFromAllUserGroups] = useState(false);
  const [viewUsersWithAnyRole, setViewUsersWithAnyRole] = useState(false);
  const [editAllUserProperties, setEditAllUserProperties] = useState(false);
  const [manageAllViewableUserGroups, setManageAllViewableUserGroups] = useState(false);
  const [manageAllViewableRoles, setManageAllViewableRoles] = useState(false);
  
  const [manageAllCandidates, setManageAllCandidates] = useState(false);
  const [ editAllManagedCandidatesProperties, setEditAllManagedCandidatesProperties] = useState(false);
  const [ editAllMyCandidateProperties, setEditAllMyCandidateProperties ] = useState(false);
  
  const [viewAllJobOrderProperties, setViewAllJobOrderProperties] = useState(false);
  const [editAllViewableJobOrderProperties, setEditAllViewableJobOrderProperties] = useState(false);
  const [viewJobOrdersFromAllFrameworkContracts, setViewJobOrdersFromAllFrameworkContracts] = useState(false);

  useEffect(() => {
    let allAccess = ALL_ACCESS_UUID;
    
    if (newRole.f_1_1_allowed_user_group_ids[0] === allAccess ) setViewUsersFromAllUserGroups(true)
    if (newRole.f_1_2_allowed_role_ids[0] === allAccess ) setViewUsersWithAnyRole(true)
    if (newRole.f_1_3_viewable_properties[0] === allAccess ) setViewAllUserProperties(true)
    if (newRole.f_2_4_1_editable_properties[0] === allAccess ) setEditAllUserProperties(true)
    if (newRole.f_2_1_allowed_user_group_ids[0] === allAccess ) setManageAllViewableUserGroups(true)
    if (newRole.f_2_2_allowed_role_ids[0] === allAccess ) setManageAllViewableRoles(true)

    if (newRole.g_2_1_editable_properties_my_candidate[0] === allAccess ) setEditAllMyCandidateProperties(true)
    if (newRole.g_4_1_allowed_owner_user_group_ids[0] === allAccess ) setManageAllCandidates(true)
    if (newRole.g_4_3_1_editable_properties[0] === allAccess ) setEditAllManagedCandidatesProperties(true)

    if (newRole.h_1_1_viewable_properties[0] === allAccess ) setViewAllJobOrderProperties(true)
    if (newRole.h_1_2_allowed_fwc[0] === allAccess ) setViewJobOrdersFromAllFrameworkContracts(true)
    if (newRole.h_3_6_1_editable_properties[0] === allAccess ) setEditAllViewableJobOrderProperties(true)

    setIsLoading(false)
    
  }, [ newRole ])
  
  const handleCreateRole = () => {
    let errors = [];
    if (!newRole.a_name) {
      errors.push({
        field: 'Role name',
        msg: 'Please include a name for your role'
      })
    }
    if (errors.length) {
      nameInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})    
      return setFormErrors(errors);
    }
    let id = newRole.id
    let objectKeys = Object.keys(newRole);
    objectKeys.forEach(key => {
      if (Array.isArray(newRole[key]) && newRole[key]?.length && newRole[key][0] == '') {
        newRole[key] = []
      }
    });
    editRole(id, newRole).then(() => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Role edited successfully.')
      navigate(`/roles/${id}`, {replace: true});
    }) 
  }

  return (
    <Loader isLoading={isLoading}>
      <div className="space-y-6">
        <FormWithSideTitle title='Name' description='Add a name to your role' >
          <div ref={nameInputRef} className="col-span-6">
            <TextInput label='Role name' errors={formErrors} value={newRole.a_name} onChange={(e) => setNewRole(prev => ({...prev, a_name: e.target.value }) )}/>
          </div>    
        </FormWithSideTitle>
        <FormWithSideTitle title='Key administrative actions' description='Set which key administrative actions your role will enable' >
            <div className="col-span-6">
              <Toggle bold label=' Manage user groups' description={'Allows to view, create, edit, delete, and associate roles to user groups'} value={newRole.b_manage_user_groups} onChange={() => setNewRole(prev => ({...prev, b_manage_user_groups: !newRole.b_manage_user_groups }) )}/>
            </div>
            <div className="col-span-6">
              <Toggle bold label='Manage roles' description={'Allows to view, create, edit and delete roles'} value={newRole.c_manage_roles} onChange={() => setNewRole(prev => ({...prev, c_manage_roles: !newRole.c_manage_roles }) )}/>
            </div>
            <div className="col-span-6">
              <Toggle bold label='Manage framework contracts' description={'Allows to view, create, edit and delete framework contracts'} value={newRole.d_manage_framework_contracts} onChange={() => setNewRole(prev => ({...prev, d_manage_framework_contracts: !newRole.d_manage_framework_contracts }) )}/>
            </div>
            <div className="col-span-6">
              <Toggle bold label='Manage clients' description={'Allows to view, create, edit and delete clients'} value={newRole.e_manage_clients} onChange={() => setNewRole(prev => ({...prev, e_manage_clients: !newRole.e_manage_clients }) )}/>
            </div>
        </FormWithSideTitle>
        <UserActions 
          newRole={newRole} 
          setNewRole={setNewRole}
          setManageAllCandidates={setManageAllCandidates}
          viewAllUserProperties={viewAllUserProperties}
          viewUsersFromAllUserGroups={viewUsersFromAllUserGroups}
          editAllUserProperties={editAllUserProperties}
          viewUsersWithAnyRole={viewUsersWithAnyRole}
          setViewAllUserProperties={setViewAllUserProperties}
          setViewUsersFromAllUserGroups={setViewUsersFromAllUserGroups}
          setEditAllUserProperties={setEditAllUserProperties}
          setViewUsersWithAnyRole={setViewUsersWithAnyRole}
          userGroups={userGroups}
          manageAllViewableUserGroups={manageAllViewableUserGroups}
          setManageAllViewableUserGroups={setManageAllViewableUserGroups}
          manageAllViewableRoles={manageAllViewableRoles}
          setManageAllViewableRoles={setManageAllViewableRoles}
        />
        <CandidateActions 
          newRole={newRole} 
          setNewRole={setNewRole}
          manageAllCandidates={manageAllCandidates}
          setManageAllCandidates={setManageAllCandidates}
          editAllManagedCandidatesProperties={editAllManagedCandidatesProperties}
          setEditAllManagedCandidatesProperties={setEditAllManagedCandidatesProperties}
          editAllMyCandidateProperties={editAllMyCandidateProperties}
          setEditAllMyCandidateProperties={setEditAllMyCandidateProperties}
          userGroups={userGroups}
        />
        <JobOrderActions 
          newRole={newRole} 
          setNewRole={setNewRole}
          viewAllJobOrderProperties={viewAllJobOrderProperties}
          editAllViewableJobOrderProperties={editAllViewableJobOrderProperties}
          viewJobOrdersFromAllFrameworkContracts={viewJobOrdersFromAllFrameworkContracts}
          setViewAllJobOrderProperties={setViewAllJobOrderProperties}
          setEditAllViewableJobOrderProperties={setEditAllViewableJobOrderProperties}
          setViewJobOrdersFromAllFrameworkContracts={setViewJobOrdersFromAllFrameworkContracts}
        />
        <CommentsActions newRole={newRole} setNewRole={setNewRole}/>
        <AttachmentsActions newRole={newRole} setNewRole={setNewRole}/>
        <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
          <WhiteButton text='Cancel' onClick={() => navigateBack(`/roles/${newRole.id}`) }/>
          <PrimaryButton text='Edit' darkRing onClick={handleCreateRole}/>
        </div>
      </div>
    </Loader>
  )
}

export default EditRoleForm
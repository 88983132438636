import React, { useState, useEffect } from 'react'
import axios from 'axios';
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import useApi from '../../store/api/apiContext'
import EditJobOrderForm from '../../containers/jobOrders/EditJobOrderForm'
import Loader from '../../components/loaders/Loader';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import role from '../../helpers/enum/role';
import useAuth from '../../store/auth/authContext';
import useNavigateBack from '../../hooks/useNavigateBack';
import useJobOrders from '../../store/jobOrders/jobOrdersContext';


function EditJobOrder({ access }) {
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const { verifyBoolAccess, verifyUserId } = useUserRoleVerifier();

  const { logout } = useAuth();

  const [ jobOrder, setJobOrder ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchJobOrder } = useJobOrders();
  const fetchJobOrderFromApi = async (source, id) => {
    fetchJobOrder(id, source).then(res => {
      setJobOrder(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchJobOrderFromApi(source, id);
    return () => {
      source.cancel();
    };
  }, [id])
  
  useEffect(() => {
    if (!verifyBoolAccess(role.h_job_orders.h_3_6_edit_job_order)) {
      if (!verifyUserId(jobOrder?.created_by)){
      logout()
    }
  }
  }, [jobOrder])
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigateBack(`/job-orders`) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Edit job order'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <EditJobOrderForm jobOrder={jobOrder}/>
      </Loader>
    </PageWrapper>
  )
}

export default EditJobOrder
import React, { useEffect, useState } from 'react';
import useAuth from '../../store/auth/authContext';

function AccessWrapper({ access, children }) {
  const { auth } = useAuth();
  const role = auth.userRole;

  const [ verified, setVerified ] = useState(false);

  const isArray = Array.isArray(access);
  const isBool = typeof access === 'boolean';
  const isString = typeof access === 'string';
  
  useEffect(() => {
    //block access if no property allows user to access (array 'OR' logic)
    if (isArray) {
      let check = false
      access.forEach(accessProperty => {
        if (role[accessProperty]) check = true;
      })
      if (check) setVerified(true);  
    } 
    if (isString) {
      if (role[access]) setVerified(true);
    }
    if (isBool) {
      setVerified(access)
    }
    
  }, [ access ])
  
  if (!verified) return ''
  return (
    <>
    { children }
    </>
  )
}

AccessWrapper.defaultProps = {
  access: undefined,
}

export default AccessWrapper;

import * as React from 'react';
import axios from 'axios';
import { serialize } from 'object-to-formdata';
import { SocketContext } from '../socket/socketProvider';
import getFileExtensionAndName from '../../helpers/getFileExtensionAndName';
import {specialCharsForReplace} from '../../helpers/specialCharactersRegex';
export const ApiContext = React.createContext();

function useApi() {
  const context = React.useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  
  const socket = React.useContext(SocketContext);

  //users
  const fetchUsers = (source) => {
    if (source) return axios.get('/users/all', { cancelToken: source.token })
    return axios.get('/users/all')
  }
  const fetchUser = (source, id) => {
    if (source) return axios.get(`/users/${id}`, { cancelToken: source.token })
    return axios.get(`/users/${id}`);
  }
  const fetchUserCandidates = (source, userId) => {
    if (source) return axios.get(`/users/candidates/${userId}`, { cancelToken: source.token })
    return axios.get(`/users/candidates/${userId}`);
  }
  const addUser = (userData, passwordType) => {
    return axios.post('/users/create', { userData, passwordType });
  }
  const fetchUserProperties = () => {
    return axios.get('/users/properties');
  }
  const editUser = (id, userData) => {
    return axios.patch(`/users/${id}`, { userData });
  }
  const editMyself = (data) => {
    const formData = serialize(data);
    return axios.patch(`/users/me`, formData, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  }
  const deleteUser = (id) => {
    return axios.delete(`/users/${id}`);
  }
  const updatePassword = (data) => {
    return axios.patch('/users/update-password', data);
  }
  const resetPassword = (userId, passwordType, password) => {
    let data = {
      passwordType: passwordType,
      password: password
    }
    return axios.patch(`/users/reset-password/${userId}`, data);
  }
  
  //roles
  const fetchRoles = (source) => {
    if (source) return axios.get('roles/all', { cancelToken: source.token })
    return axios.get('roles/all');
  }
  const createRole = (role) => {
    return axios.post('roles/create', { roleData: role });
  }
  const fetchRole = (id, source) => {
    if (source) return axios.get(`roles/${id}`, { cancelToken: source.token })
    return axios.get(`roles/${id}`);
  }
  const editRole = (id, data) => {
    let roleData = data;
    delete roleData.id;
    delete roleData.created_at;
    delete roleData.deleted_at;
    delete roleData.updated_at;
    return axios.patch(`roles/${id}`, { roleData });
  }
  const deleteRole = (id) => {
    return axios.delete(`roles/${id}`);
  }

  //userGroups
  const fetchUserGroups = (source) => {
   if (source) return axios.get('/user-groups/all?manageUsers=false', { cancelToken: source.token })
   return axios.get('/user-groups/all?manageUsers=false');
  }

  const fetchUserGroupsForAssociation = (source) => {
    if (source) return axios.get('/user-groups/all?manageUsers=true', { cancelToken: source.token })
    return axios.get('/user-groups/all?manageUsers=true')
  }
  
  const fetchUserGroup = (source, id) => {
    return axios.get(`/user-groups/${id}`, { cancelToken: source.token })
  }

  const addUserGroup = (userGroup, roleIds) => {
    if (roleIds) {
      return axios.post('/user-groups/', { userGroupData: userGroup, roles: roleIds })
    }
    return axios.post('/user-groups/', { userGroupData: userGroup })
  }

  const editUserGroup = (userGroup, id, roleIds) => {
    if (roleIds) {
      return axios.patch(`/user-groups/${id}`, {userGroupData: userGroup, roles: roleIds})
    }
    return axios.patch(`/user-groups/${id}`, {userGroupData: userGroup})
  }
  
  const deleteUserGroup = (userGroupId) => {
    return axios.delete(`/user-groups/${userGroupId}`);
  }

  //candidates
  const fetchCandidateProperties = () => {
    return axios.get('/candidates/properties');
  }

  const fetchCandidates = (type) => {
    return axios.get(`/candidates/all?type=${type}`)
  } 
  
  const fetchCandidate = (id, source) => {
    if (source) return axios.get(`/candidates/${id}`, { cancelToken: source.token })
    return axios.get(`/candidates/${id}`)
  }

  const fetchCandidateApplications = (id, source) => {
    if (source) {
      const res = axios.get(`/candidates/applications/${id}`, { cancelToken: source.token })
      return res
    }
    return axios.get(`/candidates/applications/${id}`)
  }

  const fetchMyCandidates = (source) => {
    if (source) return axios.get(`/candidates/my`, { cancelToken: source.token })
    return axios.get(`/candidates/my`)
  }

  const addCandidate = (candidateData) => {
    return axios.post('/candidates/create', { candidateData: candidateData })
  }

  const editCandidate = (id, candidateData) => {
    return axios.patch(`/candidates/${id}`, { candidateData: candidateData })
  }
  
  const deleteCandidate = (id) => {
    return axios.delete(`/candidates/${id}`)
  }
  
  //framework contracts
  const fetchFrameworkContracts = (source, query) => {
    if (source) return axios.get(`/fw-contracts/all${query ? `?request=${query}` : ''}`, { cancelToken: source.token })
    return axios.get('/fw-contracts/all');
  }
  
  const addFrameworkContract = (fwContractData) => {
    return axios.post('/fw-contracts/', { fwContractData: fwContractData })
  }
  
  const fetchFrameworkContract = (id, source) => {
    return axios.get(`/fw-contracts/${id}`, { cancelToken: source.token })
  }
  
  const editFrameworkContract = (id, fwContractData) => {
    return axios.patch(`/fw-contracts/${id}`, { fwContractData: fwContractData })
  }

  const deleteFrameworkContract = (id) => {
    return axios.delete(`/fw-contracts/${id}`)
  }
  
  const getFrameworkContractJobOrders = (id) => {
    return axios.get(`/fw-contracts/job-orders/${id}`)
  }
  //clients
  
  const fetchClients = (source, request) => {
    return axios.get(`/clients/all?request=${request}` , { cancelToken: source.token })
  }
  
  const addClient = (clientData) => {
    return axios.post('/clients/create', { clientData })
  }
  
  const fetchClient = (id, source) => {
    return axios.get(`/clients/${id}`, { cancelToken: source.token })
  }
  
  const editClient = (id, clientData) => {
    return axios.patch(`/clients/${id}`, { clientData: clientData })
  }

  const deleteClient = (id) => {
    return axios.delete(`/clients/${id}`)
  }

  const getClientJobOrders = (id) => {
    return axios.get(`/clients/job-orders/${id}`)    
  }
  
  //job orders
  const fetchJobOrderProperties = () => {
    return axios.get('/job-orders/properties');
  }

  const fetchJobOrderPotentialRecruiters = (jobOrderId) => {
    return axios.get(`/job-orders/potential-recruiters/${jobOrderId}`);
  }

  //Files

  const uploadCandidateFile = (candidateId, file) => {
    const {fileType, docName} = getFileExtensionAndName(file.name)
    const newFileName = docName.replace(specialCharsForReplace, ' ') + '.' + fileType
    var formData = new FormData();
    formData.append('file', file, newFileName);
    
    return axios.post(`/files/upload/candidates/${candidateId}`, formData, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
  }
  const uploadJobOrderFile = (jobOrderUUId, file) => {
    const {fileType, docName} = getFileExtensionAndName(file.name)
    const newFileName = docName.replace(specialCharsForReplace, ' ') + '.' + fileType
    
    var formData = new FormData();
    formData.append('file', file, newFileName);
    return axios.post(`/files/upload/job-orders/${jobOrderUUId}`, formData, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
  }  

  const downloadFile = (filePath, getUrl) => {
    if (getUrl) return axios.get(`/files/download/${filePath}?getUrl=true`)
    return axios.get(`/files/download/${filePath}`)
  }

  const getFilesOnFolder = (folderPath, source) => {
    return axios.get(`/files?folderPath=${folderPath}`, { cancelToken: source.token })
  }

  const deleteFile = (path) => {
    return axios.delete(`/files?file=${path}`)
  }

  const removeProfilePicture = () => {
    return axios.delete(`/files/remove-profile-picture`)
  }

  // Comments
  
  const fetchComments = (type, id, source) => {
    return axios.get(`/comments/${type}/${id}`, { cancelToken: source.token })
  }

  const addComment = (commentData) => {
    return axios.post(`/comments`, { commentData: commentData })
  }

  const editComment = (id, commentData) => {
    return axios.patch(`/comments/${id}`, { commentData: commentData })
  }

  const deleteComment = (id) => {
    return axios.delete(`/comments/${id}`)
  }

  // Languages

  const fetchLanguages = (source) => {
    if (source) return axios.get('/languages/all', { cancelToken: source.token })
    return axios.get('/languages/all')
  }

  // Skills

  const fetchSkills = (source) => {
    if (source) return axios.get('/skills/all', { cancelToken: source.token })
    return axios.get('/skills/all')
  }

  // Settings

  const fetchTableSettings = () => {
    return axios.get(`/settings?setting=table_settings`);
  }

  const fetchNotificationSettings = () => {
    return axios.get(`/settings?setting=notification_settings`)
  }

  const updateSettings = (tableSettingsData, notificationSettingsData) => {
    let data = {}
    if (tableSettingsData) {
      data.table_settings = JSON.stringify(tableSettingsData);
    }
    if (notificationSettingsData) {
      data.notification_settings = JSON.stringify(notificationSettingsData);
    }
    let body = { settingsData: data };
    return axios.patch(`/settings`, body);
  }

  const resetTableSettings = () => {
    return axios.patch(`/settings/my/reset-table-settings`)
  }

  // Dashboard
  const fetchApplicationsByDate = (fromDate, toDate) => {
    return axios.get(`/job-orders/all-applications?from=${fromDate}&to=${toDate}`)
  }

  const fetchCurrentApplications = () => {
    return axios.get('/job-orders/current-applications')
  }

  //global search
  const getGlobalSearchResults = (query) => {
    return axios.get('/search?q=' + query)
  }

  //Notifications
  const getLatestNotifications = () => {
    return axios.get('/notifications/my/latest')
  }

  const getAllMyNotifications = (query) => {
    const { page } = query
    return axios.get(`/notifications/my/all?page=${page}`)
  }

  const setNotificationsToRead = (id) => {
    return axios.put(`/notifications/my/set-to-read/${id ? id : null}`)
  }

  return {
    fetchUsers,
    fetchUser,
    fetchUserCandidates,
    addUser,
    deleteUser,
    fetchRoles,
    fetchUserGroups,
    fetchUserGroupsForAssociation,
    addUserGroup,
    deleteUserGroup,
    addFrameworkContract,
    fetchUserProperties,
    fetchCandidateProperties,
    fetchJobOrderProperties,
    fetchFrameworkContracts,
    createRole,
    deleteRole,
    editUser,
    editMyself,
    updatePassword,
    resetPassword,
    fetchClients,
    addClient,
    fetchUserGroup,
    editUserGroup,
    fetchRole,
    fetchFrameworkContract,
    editFrameworkContract,
    deleteFrameworkContract,
    getFrameworkContractJobOrders,
    fetchClient,
    editClient,
    editRole,
    fetchCandidates,
    fetchCandidate,
    fetchCandidateApplications,
    addCandidate,
    editCandidate,
    deleteCandidate,
    fetchJobOrderPotentialRecruiters,
    fetchMyCandidates,
    uploadCandidateFile,
    uploadJobOrderFile,
    removeProfilePicture,
    getFilesOnFolder,
    downloadFile,
    deleteFile,
    fetchComments,
    addComment,
    editComment,
    deleteComment,
    fetchLanguages,
    fetchSkills,
    fetchTableSettings,
    updateSettings,
    fetchApplicationsByDate,
    fetchCurrentApplications,
    deleteClient,
    getClientJobOrders,
    getGlobalSearchResults,
    getLatestNotifications,
    getAllMyNotifications,
    setNotificationsToRead,
    fetchNotificationSettings,
    resetTableSettings
  };
}

export default useApi;
import PageWrapper from "../../containers/app/PageWrapper"
import NotificationsTable from "../../containers/notifications/NotificationsTable"

const MyNotifications = () => {
  return (
    <PageWrapper title='Notifications'>
      <NotificationsTable />
    </PageWrapper>
  )
}

export default MyNotifications
import useAuth from '../store/auth/authContext';
import role from '../helpers/enum/role';
import { ALL_ACCESS_UUID as allAccessId } from '../config';

export default function useUserRoleVerifier() {
  const { auth: { userRole, currentUser } } = useAuth();
  if (!userRole) return

  const verifyBoolAccess = (access) => {
    return userRole[access]
  }
  
  function verifyArrayAccess(access, property) {
    const array = userRole[access];
    const allAccess = allAccessId;
    if (array[0] === allAccess) return true;
    if (array.includes(property)) return true;
    return false;
  }

  function verifyUserId(id) {
    if (id === currentUser.id) return true
    return false
  }

  function verifyUserIsWithinScope(userGroupId, roleId) {
    let allowedUserGroupIds = userRole.f_2_1_allowed_user_group_ids;
    let allowedRoleIds = userRole.f_2_2_allowed_role_ids
    if (userRole.f_2_1_allowed_user_group_ids == allAccessId) allowedUserGroupIds = userRole.f_1_1_allowed_user_group_ids
    if (userRole.f_2_2_allowed_role_ids == allAccessId) allowedRoleIds = userRole.f_1_2_allowed_role_ids
    
    if (allowedUserGroupIds != allAccessId && !allowedUserGroupIds.includes(userGroupId)) return false
    if (allowedRoleIds != allAccessId && !allowedRoleIds.includes(roleId)) return false
    
    return true
  }

  return {
    userRole,
    currentUser,
    role,
    verifyBoolAccess,
    verifyArrayAccess,
    verifyUserId,
    verifyUserIsWithinScope,
    allAccessId
  }
}
import * as React from "react";
import axios from "axios";
import actions from "./actions";
import { SocketContext } from "../socket/socketProvider";

export const JobOrdersContext = React.createContext();

function useJobOrders() {
  const context = React.useContext(JobOrdersContext);
  const socket = React.useContext(SocketContext);

  if (!context) {
    throw new Error("useJobOrders must be used within an JobOrdersProvider");
  }

  const [jobOrdersState, dispatch] = context;

  const fetchJobOrder = async (id, source) => {
    return axios.get(`/job-orders/id/${id}`, { cancelToken: source.token }).then(r => {
      dispatch({
        type: actions.FETCH_JOB_ORDER_DETAILS,
        payload: r.data
      })
      return r;
    });
  };

  const addJobOrder = async (jobOrderData, jobStatusLogData) => {
    return axios
      .post("/job-orders/create", {
        jobOrderData: jobOrderData,
        jobStatusLogData: jobStatusLogData,
      })
      .then((res) => {
        socket.emit("added-job-order", res.data);
      });
  };

  const editJobOrder = async (id, jobOrderData, currentUserId) => {
    return axios
    .patch(`/job-orders/${id}`, { jobOrderData: jobOrderData })
    .then((r) => {
        socket.emit("edit-job-order", {editedData: r.data, currentUserId });
        return r;
      });
  };

  const deleteJobOrder = async (id) => {
    return axios.delete(`/job-orders/${id}`).then((r) => {
      socket.emit("delete-job-order", id);
      return r;
    });
  };

  const batchUpdateJobOrderStatus = async (idArray, newStatus) => {
    return axios
      .patch(`/job-orders/batch-edit/status`, {
        jobOrderData: { id_array: idArray, status_id: newStatus },
      })
      .then((res) => {
        const arrayOfErrors = [];
        const arrayOfSuccess = [];

        //filter errors
        res.data.forEach((obj) => {
          if (obj?.error) {
            arrayOfErrors.push({
              id: obj.job_order_id,
              title: jobOrdersState.allJobOrders.find(
                (jobOrder) => jobOrder.id == obj.job_order_id
              ).title,
              msg: obj.error,
            });
          } else {
            arrayOfSuccess.push(obj);
          }
        });

        const dataToReturn = {
          arrayOfErrors,
          arrayOfSuccess,
        };

        socket.emit("batch-update-status", dataToReturn);
        return dataToReturn;
      });
  };

  const batchUpdateJobOrderPriority = async (idArray, newPriority) => {
    return axios
      .patch(`/job-orders/batch-edit/priority`, {
        jobOrderData: { id_array: idArray, priority: newPriority },
      })
      .then(() => {
        socket.emit("batch-update-priority", {
          idArray,
          newPriority,
        });
      });
  };

  const addJobStatusLog = async (id, jobStatusLogData) => {
    return axios.post(`/job-orders/status/${id}`, {
      jobStatusLogData: jobStatusLogData,
    }).then(res => {
      if (!res.data?.error) {
        socket.emit("add-job-status-log", {
          id,
          data: res.data,
        });
      }
      return res;
    });
  };

    const deleteJobStatusLog = async (jobStatusLogId, jobOrderId) => {
      return axios.delete(`/job-orders/status/${jobStatusLogId}`).then(() => {
        socket.emit("delete-job-status-log", {
          jobStatusLogId,
          jobOrderId
        });
      });
    };
    
    const addApplicationLog = (jobOrderId, applicationData) => {
      return axios.post(`/job-orders/application/${jobOrderId}`, {
        applicationStatusData: applicationData,
      });
    };
    
    const editApplicationLog = (applicationId, applicationData) => {
      return axios.patch(`/job-orders/application/${applicationId}`, {
        applicationStatusData: applicationData,
      });
    };
    
    const deleteApplicationLog = async (applicationId, jobOrderId) => {
      return axios.delete(`/job-orders/application/${applicationId}`).then(() => {
        socket.emit("delete-application", {
          applicationId,
          jobOrderId
        });
        
      });
    };

    const addRecruitersToJobOrder = async (jobOrderId, recruiterIds) => {
      return axios.post(`/job-orders/recruiters/${jobOrderId}`, {
        recruiterIds,
      }).then(() => {
        socket.emit("add-job-order-recruiters", jobOrderId);
      });
    };

    const removeRecruiterFromJobOrder = async (jobOrderId, recruiterId) => {
      return axios
        .delete(`/job-orders/recruiter/${jobOrderId}`, {
          data: { recruiterData: { user_id: recruiterId } },
        })
        .then(() => {
          socket.emit("remove-job-order-recruiter", {jobOrderId, recruiterId});
        });
    };

    const fetchApplicationsHistory = async (source) => {
      return axios.get('/job-orders/applications-history', { cancelToken: source.token }).then(r => {
        dispatch({
          type: actions.FETCHED_APPLICATIONS_LOG_HISTORY,
          payload: r.data
        })
        return r.data
      })
    }
    
    const fetchJobStatusHistory = async (source) => {
      return axios.get('/job-orders/job-status-history', { cancelToken: source.token }).then(r => {
        dispatch({
          type: actions.FETCHED_JOB_STATUS_LOG_HISTORY,
          payload: r.data
        })
        return r.data
      })
    }
    

  

  return {
    jobOrdersState,
    dispatch,
    fetchJobOrder,
    addJobOrder,
    batchUpdateJobOrderStatus,
    batchUpdateJobOrderPriority,
    editJobOrder,
    deleteJobOrder,
    addJobStatusLog,
    deleteJobStatusLog,
    addApplicationLog,
    editApplicationLog,
    deleteApplicationLog,
    addRecruitersToJobOrder,
    removeRecruiterFromJobOrder,
    fetchApplicationsHistory,
    fetchJobStatusHistory
  };
}
export default useJobOrders;

import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import EditRoleForm from '../../containers/roles/EditRoleForm'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import { ROOT_ROLE_UUID } from '../../config'
import useNavigateBack from '../../hooks/useNavigateBack'

function EditRole({ access }) {
  const navigateBack = useNavigateBack();
  const navigate = useNavigate();
  const { id } = useParams();
  const { fetchRole } = useApi();
  const [ isLoading, setIsLoading ] = useState(true);

  const [ role, setRole ] = useState({ });

  useEffect(() => {
    if (id == ROOT_ROLE_UUID) return navigate(-1)
    fetchRole(id).then(res => {
      setRole(res.data);
      setIsLoading(false);
    })
  }, [])  
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigateBack(`/roles/`) }/>
      </>
    )
  }

  return (
    <PageWrapper title={'Edit role'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <EditRoleForm newRole={role} setNewRole={setRole} />
      </Loader>
    </PageWrapper>
  )
}

export default EditRole
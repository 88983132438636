import React, { useState, useEffect } from 'react';
import Checkbox from '../../../components/inputs/Checkbox';
import Toggle from '../../../components/inputs/Toggle';
import USER_PROPERTIES from '../../../helpers/enum/userProperties';
import FormInputLabelAndDescription from '../../../components/forms/FormInputLabelAndDescription';
import ReactSelect from '../../../components/inputs/ReactSelect';
import { ALL_ACCESS_UUID } from '../../../config';

function UserActions({ 
  newRole, 
  setNewRole,
  userGroups,
  userProperties,
  roles,
  viewAllUserProperties,
  setViewAllUserProperties,
  setViewableUserProperties,
  viewableUserProperties,
  viewUsersFromAllUserGroups,
  viewUsersWithAnyRole,
  setViewUsersFromAllUserGroups,
  setViewUsersWithAnyRole,
  setEditableUserProperties,
  setEditAllUserProperties,
  setManageAllCandidates
}) {
  

  const handleToggle_f_1_view_users = () => {
    let newStatus = !newRole.f_1_view_users;
    resetUserActions();
    if (newStatus) {
      //assume view all properties, userGroups and roles
      giveAllAccessToViewUsers();
    }
  }

  const viewAllUserPropertiesChangeHandler = () => {
    let newStatus = !viewAllUserProperties;
    setViewAllUserProperties(newStatus);
    if (newStatus) {
      setNewRole(prev => ({...prev, f_1_3_viewable_properties: [ALL_ACCESS_UUID]}))
      removeViewablePropertyChoices();
    } else {
      setNewRole(prev => ({...prev, 
        f_1_3_viewable_properties: [],
      }))
      resetViewablePropertyChoices();
    }
    resetEditUserActions();
  }

  const handleUpdateViewableUserPropertyChange = (property) => {
    let newArray = viewableUserProperties.map(element  => {
      if (element === property) return { ...element, checked: !property.checked };
      return element;
    })
    setViewableUserProperties(newArray);
  }

  //effect to sync viewable property checkboxes with newRole state
  useEffect(() => {
    if (!viewableUserProperties.length) return;
    let viewableProperties = [];
    viewableUserProperties.forEach(property => {
      if (property.checked) {
        viewableProperties.push(property);
      }
    })
    setNewRole(prev => ({...prev, f_1_3_viewable_properties: viewableProperties.map(property => property.id) }));
    resetEditUserActions();
  }, [viewableUserProperties])

  //viewable roles and userGroups

  const viewUsersFromAllUserGroupsChangeHandler = () => {
    let newStatus = !viewUsersFromAllUserGroups;
    setViewUsersFromAllUserGroups(newStatus);
    if (newStatus) setNewRole(prev => ({...prev, f_1_1_allowed_user_group_ids: [ALL_ACCESS_UUID]}));
    if (!newStatus) {
      setNewRole(prev => ({...prev, f_1_1_allowed_user_group_ids: []}));
      prepareUserGroupOptions();
    }
    imposeViewAllRoles();
    resetManageCandidateModule();
  }

  const viewUsersWithAnyRoleChangeHandler = () => {
    let newStatus = !viewUsersWithAnyRole;
    setViewUsersWithAnyRole(newStatus);
    if (newStatus) setNewRole(prev => ({...prev, f_1_2_allowed_role_ids: [ALL_ACCESS_UUID]}));
    if (!newStatus) {
      setNewRole(prev => ({...prev, f_1_2_allowed_role_ids: []}));
      prepareRoleOptions();
    }
    resetManageCandidateModule();
  }

  const [ viewableUserGroupOptions, setViewableUserGroupOptions ] = useState([]);
  
  const viewableUserGroupOptionsChangeHandler = (event) => {
    let userGroups;
    if (!event) userGroups = [];
    else userGroups = event.map(userGroup => userGroup.value);
    setNewRole(prev => ({ ...prev, f_1_1_allowed_user_group_ids: userGroups }));
    imposeViewAllRoles();
    resetManageCandidateModule();
  }
  
  const [ viewableRoleOptions, setViewableRoleOptions ] = useState([]);
  
  const viewableRoleOptionsChangeHandler = (event) => {
    let roles;
    if (!event) roles = [];
    else roles = event.map(role => role.value);
    setNewRole(prev => ({ ...prev, f_1_2_allowed_role_ids: roles }));
    resetManageCandidateModule();
  }

  //helper functions

  const resetUserActions = () => {
    setNewRole(prev => ({...prev, 
      f_1_view_users: false,
      f_1_1_allowed_user_group_ids: [],
      f_1_2_allowed_role_ids: [],
      f_1_3_viewable_properties: [],
      f_2_manage_users: false,
      f_2_1_allowed_user_group_ids: [],
      f_2_2_allowed_role_ids: [],
      f_2_3_create_users: false,
      f_2_4_edit_users: false,
      f_2_4_1_editable_properties: [],
      f_2_5_delete_users: false,
    }));
    resetManageCandidateModule();
  } 

  const resetManageCandidateModule = () => {
     setNewRole(prev => ({...prev, 
      g_4_1_allowed_owner_user_group_ids: [],
      g_4_2_create_candidates: false,
      g_4_3_1_editable_properties: [],
      g_4_3_edit_candidates: false,
      g_4_4_delete_candidates: false,
      g_4_manage_candidates: false,
    }));
    setManageAllCandidates(false);
  }

  const resetEditUserActions = () => {
    setNewRole(prev => ({...prev, 
      f_2_4_edit_users: false,
      f_2_4_1_editable_properties: [], 
    }));
    setEditableUserProperties([]);
    setEditAllUserProperties(false);
  }

  const giveAllAccessToViewUsers = () => {
    setViewAllUserProperties(true); 
    setViewUsersFromAllUserGroups(true);
    setViewUsersWithAnyRole(true); 
    setNewRole(prev => ({...prev, 
        f_1_view_users: true,
        f_1_1_allowed_user_group_ids: [ALL_ACCESS_UUID],
        f_1_2_allowed_role_ids: [ALL_ACCESS_UUID],
        f_1_3_viewable_properties: [ALL_ACCESS_UUID],
      }));
  }

  const resetViewablePropertyChoices = () => {
    let array = userProperties.map(property => {
      return {label: USER_PROPERTIES[property], id: property, checked: false}
    })
    setViewableUserProperties(array);
  }

  const removeViewablePropertyChoices = () => {
    setViewableUserProperties([]);
  }

  const prepareUserGroupOptions = () => {
    let options = [];
    userGroups.map(userGroup => {
      return options.push({
        value: userGroup.id,
        label: userGroup.name,
        roles: userGroup.roles
      })
    })
    setViewableUserGroupOptions(options);
  }

  const imposeViewAllRoles = () => {
    setViewUsersWithAnyRole(true); 
    setNewRole(prev => ({...prev, 
      f_1_2_allowed_role_ids: [ALL_ACCESS_UUID],
    }));
  }

  const prepareRoleOptions = () => {
    let roleOptions = [];
    if (viewUsersFromAllUserGroups) {
      roles.forEach(role => {
        roleOptions.push({
          label: role.a_name,
          value: role.id
        })
      })
    }
    if (!viewUsersFromAllUserGroups) {
      
      roles.forEach(role => {
        if (role.userGroups.some(userGroup => newRole.f_1_1_allowed_user_group_ids.includes(userGroup.id))) {
          roleOptions.push({
            label: role.a_name + ` (${role.userGroups.map(userGroup => userGroup.name)})`,
            value: role.id
          })
        }
      })
    }
    setViewableRoleOptions(roleOptions);
  }

  return (
    <>
      <div className="col-span-6">
        <Toggle bold label='View users' description={'Allows to view users for specified user groups and roles. Restricted to the user properties provided'} value={newRole.f_1_view_users} onChange={handleToggle_f_1_view_users}/>
      </div>
      {newRole.f_1_view_users && 
      <>
        <div className="col-span-6">
          <Checkbox
            label={'View all user properties'} 
            checked={viewAllUserProperties} 
            onChange={viewAllUserPropertiesChangeHandler}
          />
        </div>
        {!viewAllUserProperties && !!viewableUserProperties.length &&  (
          <>
            <div className="col-span-6">
              <FormInputLabelAndDescription label={'Viewable properties'}/>
            </div> 
            {viewableUserProperties.map((property) => {
            return (
              <div className="col-span-2" key={property.id}>
                <Checkbox 
                  description={property.label} 
                  disabled={!newRole.f_1_view_users}
                  checked={property.checked} 
                  onChange={() => handleUpdateViewableUserPropertyChange(property)}
                />
              </div>
            )
            })}
          </>
        )}
        <div className="col-span-6">
          <Checkbox 
            label={'View users from all user groups'} 
            checked={viewUsersFromAllUserGroups} 
            onChange={viewUsersFromAllUserGroupsChangeHandler}
          />
        </div>
        {!viewUsersFromAllUserGroups && 
        <div className="col-span-6">
          <ReactSelect
            isMulti
            label='View users from following user groups' 
            options={viewableUserGroupOptions} 
            placeholder={'Select user groups'} 
            onChange={viewableUserGroupOptionsChangeHandler}
          />
        </div>}
        <div className="col-span-6">
          <Checkbox 
            label={'View users with any role'} 
            checked={viewUsersWithAnyRole} 
            onChange={viewUsersWithAnyRoleChangeHandler}
          />
        </div>
        {!viewUsersWithAnyRole && 
        <div className="col-span-6">
          <ReactSelect
            isMulti
            label='View users with following roles' 
            options={viewableRoleOptions} 
            placeholder={'Select roles'} 
            onChange={viewableRoleOptionsChangeHandler}
          />
        </div>}
      </>
      }

    </>
  )
}

export default UserActions
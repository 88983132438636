import React, { useEffect, useState } from 'react'
import ProfilePicture from '../../components/profilePicture/ProfilePicture'
import { useNavigate } from 'react-router'
import WhiteButton from '../../components/buttons/WhiteButton'
import useApi from '../../store/api/apiContext'

function DashboardProfileCard({user}) {

  const navigate = useNavigate()
  const { fetchMyCandidates } = useApi()

  const [ myCandidates, setMyCandidates ] = useState([])
  const [ placedCandidates, setPlacedCandidates ] = useState([])

  const stats = [
    { label: 'candidates', value: myCandidates?.length },
    { label: 'assigned job orders', value: user?.jobOrders?.length },
    { label: 'placed candidates', value: placedCandidates?.length },
  ]

  useEffect(() => {
    if (user) {
      fetchMyCandidates().then(res => {
        setMyCandidates(res.data)
        setPlacedCandidates(res.data.filter(el => el.is_currently_placed))
      })
    }
  }, [user])

  return (
    <section aria-labelledby="profile-overview-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <h2 className="sr-only" id="profile-overview-title">
          Profile Overview
        </h2>
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0 justify-center">
                <ProfilePicture user={user} className="w-20 h-20 mx-auto self-center"/>
              </div>
              <div className="mt-4 flex flex-col justify-center text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">Welcome back,</p>
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">{user.first_names}</p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              <WhiteButton linkTo='/my-account' text={'View account'} />
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
          {stats.map((stat) => (
            <div key={stat.label} className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-gray-900">{stat.value}</span>{' '}
              <span className="text-gray-600">{stat.label}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default DashboardProfileCard
import React, { useState, useEffect } from 'react';
import Modal from '../../components/modal/Modal';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import Tabs from '../../components/tabs/Tabs';
import useApi from '../../store/api/apiContext'

function SettingsModal({
  showSettings,
  setShowSettings,
  headerGroups,
  setAllFilters,
  filters,
  handleHideColumns,
  userSettings,
  setUserSettings,
  setVisibleColumns,
  visibleColumns,
  table,
  downloadCsv
}) {  

  const { updateSettings } = useApi();

  const [ activeTab, setActiveTab ] = useState(null)
  const [ tabs, setTabs ] = useState([
    { name: 'Filters', onClick: () => tabHandler('Filters'), current: true },
    { name: 'Columns', onClick: () => tabHandler('Columns'), current: false },
  ])

  const tabHandler = (name) => {
    let array = tabs.map(e => {
      e.name === name ? e.current = true : e.current = false;
      return e
    }) 
    setTabs(array);
  }

  useEffect(() => {
    let active = tabs.find(tab => tab.current)
    setActiveTab(active)
  }, [tabs])
  
  const updateColumnSettings = async () => {
    let updated = { 
      ...userSettings, 
      [table]: {
        ...userSettings[table],
        columns: visibleColumns
      }
    }

    setUserSettings(updated);
    await updateSettings(updated);
  }

  const updateFiltersSettings = async () => {
     let updated = { 
      ...userSettings, 
      [table]: {
        ...userSettings[table],
        filters: filters
      }
    }
    setUserSettings(updated);
    await updateSettings(updated);
  }
  
  const handleSaveFilters = () => {
    updateFiltersSettings();
    setShowSettings(false);
  }

  const handleSaveColumns = () => {
    handleHideColumns();
    updateColumnSettings();
    setShowSettings(false);
  }

  const handleResetFilters = () => {
    setAllFilters([])
  }

  const handleColumnCheck = (column) => {
    setVisibleColumns(visibleColumns.map(e => e.id === column.id ? ({...e, isVisible: !e.isVisible }) : e ))
  }
  return (
    <Modal show={showSettings} noPadding hide={() => setShowSettings(false)}>
      <Tabs tabs={tabs} tabHandler={tabHandler}/>
      {activeTab?.name === "Filters" && (
        <div className="flex flex-col -mt-2 p-6">
          <div className="grid grid-cols-1 gap-y-1">
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) =>
                column.canFilter ? (
                  <div id={column.id}>{column.render("Filter")}</div>
                ) : null
              )
            )}
          </div>
          <div className="grid grid-cols-2 gap-x-2 mt-4">
            <SecondaryButton
              onClick={handleResetFilters}
              text="Reset filters"
            />
            <PrimaryButton
              onClick={handleSaveFilters}
              darkRing
              text="Save filters"
            />
          </div>
          <div className="grid grid-cols-1 mt-2">
            <PrimaryButton
              text="Download CSV"
              onClick={downloadCsv}
            />
          </div>
        </div>
      )}
      {activeTab?.name === "Columns" && (
        <div className="flex flex-col -mt-2  p-6">
          <span className="font-medium text-gray-700">Column selection</span>
          <div className="grid grid-cols-2">
            {visibleColumns.map((column) => (
              <div className="relative flex mt-2 justify-between ">
                <div key={column.id} className=" text-sm">
                  <label className="font-medium text-gray-500">
                    {column.Header}
                  </label>
                </div>
                <input
                  type="checkbox"
                  onChange={() => handleColumnCheck(column)}
                  checked={
                    visibleColumns.find((e) => e.id === column.id).isVisible
                  }
                  className="focus:ring-thaleria-orange-600 h-4 w-4 mr-8 text-thaleria-orange-700 border-gray-300 rounded"
                />
              </div>
            ))}
          </div>
          <div className="grid grid-cols-1 mt-6">
            <SecondaryButton
              text="Save and apply"
              onClick={handleSaveColumns}
            />
          </div>
          <div className="grid grid-cols-1 mt-2">
            <PrimaryButton
              text="Download CSV"
              onClick={downloadCsv}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default SettingsModal
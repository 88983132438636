import './RichEditor.css'
import React, { useEffect, useState } from 'react';
import { Editor,  RichUtils, getDefaultKeyBinding } from 'draft-js'
import 'draft-js/dist/Draft.css';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  // {label: 'H1', style: 'header-one'},
  // {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  // {label: 'Blockquote', style: 'blockquote'},
  {label: 'Bullet points', style: 'unordered-list-item'},
  {label: 'Numbered list', style: 'ordered-list-item'},
  // {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
  {label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

function RichTextEditor({ editorState, setEditorState, readOnly, placeholder, label, errors }) {
  
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  }

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 ) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4, 
      );
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  const toggleBlockType = (blockType) => {
    setEditorState(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    );
  }

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  }

  let className = 'RichEditor-editor';
  useEffect(() => {
    if (!editorState) return;
    var contentState = editorState.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
  }, [editorState])

  const [ error, setError ] = useState({});
  
  useEffect(() => {
    if (!errors?.length) return;
    let myError = errors.find(error => error.field === label);
    if (myError) setError(myError);
    if (!myError) setError({});

  }, [errors]);

  if (!editorState) return '';
  return (
    <>
      <label className="mb-1 text-sm font-medium text-gray-700 flex">
        { label }
        { error?.msg && <span className="text-red-400 flex"> <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1"/> {error.msg}</span>  }
      </label>
      <div className="border p-4 z-0 focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 focus:shadow-inner block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" >
        {!readOnly && 
          <>
            <BlockStyleControls
              editorState={editorState}
              onToggle={toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
            />
          </>
        }
        {!readOnly && <div className='border-b border-gray-200 my-4'/>}
        <div className={`${className} ${!readOnly ? 'max-h-96' : ''} z-0 p-2 overflow-y-auto scrollbar-none`}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={setEditorState}
            placeholder={!readOnly && placeholder}
            spellCheck={true}
            readOnly={readOnly}
          />
        </div>
      </div>
    </>
    );
}

export default RichTextEditor;
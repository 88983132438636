import React from 'react'
import Modal from '../../components/modal/Modal'
import { Worker } from "@react-pdf-viewer/core";
import PDFViewer from './PDFViewer';


function PreviewBox ({filePath, fileType, showPreview, handleHide, title}) {
  
  if (!filePath || !fileType) return '';

  return (
    <Modal
      size="xl"
      show={showPreview}
      hide={handleHide}
      title={`File preview: ${title}.${fileType}`}
    >
      {fileType === "pdf" && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
          <PDFViewer fileUrl={filePath} />
        </Worker>
      )}
      {(fileType === "png" || fileType === 'jpeg' || fileType === 'jpg') && <img height={"auto"} width={500} src={filePath} className='mx-auto'/>}
    </Modal>
  );
}

export default PreviewBox;
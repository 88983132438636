import React, { useEffect, useState } from 'react'
import ContainerCard from '../../../components/cards/ContainerCard';
import { applicationStatuses } from '../../../helpers/enum/applicationStatuses';
import WhiteButton from '../../../components/buttons/WhiteButton';
import { PlusIcon } from '@heroicons/react/solid';
import AddApplicationModal from './AddApplicationModal';
import ApplicationListSection from './ApplicationListSection';
import { jobStatusIds } from '../../../helpers/enum/jobStatuses';

function JobOrderApplications({ applications, jobOrder  }) {

  //FIXME: Not clear if access to candidate application details is being correctly managed. Which details can I see of applicants that are not in my scope? 
  let closeAllApplicants = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false
  }
  const [showApplicants, setShowApplicants] = useState(closeAllApplicants)

  const [uniqueApplications, setUniqueApplications] = useState([]);

  const [showAddApplicationModal, setShowAddApplicationModal] = useState(false);

  const [touchUpdate, setTouchUpdate] = useState(false)
  const [newStatus, setNewStatus] = useState(null)

  useEffect(() => {
    //keep only latest application for each candidate (presuposes applications are arriving ordered by latest created_at date)
    let _uniqueApplications = [];
    applications.forEach(application => {
      if (_uniqueApplications.some(_application => _application.applicant_id === application.applicant_id)) return;
      _uniqueApplications.push(application);
    })
    setUniqueApplications(_uniqueApplications);
    
    //show only those with active applications
    if (_uniqueApplications.length) {
      Object.keys(applicationStatuses).forEach(key => {
        _uniqueApplications.filter(application => application.status == key).length? setShowApplicants(prev => ({...prev, [key]: true})) : setShowApplicants(prev => ({...prev, [key]: false}))
      });
    } else {
      setShowApplicants(closeAllApplicants)
    }
  }, [applications])

  const latestStatus = jobOrder.jobStatusLog[0]?.status;
  
  return (
    <ContainerCard noBorder noYPadding noXPadding title='Candidate applications' button={latestStatus == jobStatusIds.active && <WhiteButton icon={<PlusIcon className='h-4 w-4'/>} size='sm' text='Add' onClick={() => setShowAddApplicationModal(!showAddApplicationModal)}/>} >
      <AddApplicationModal jobOrderId={jobOrder.id} applications={applications} show={showAddApplicationModal} hide={setShowAddApplicationModal} />
      {Object.keys(applicationStatuses).map(status => {
        return (
          <ApplicationListSection
            key={status}
            status={status}
            showApplicants={showApplicants}
            setShowApplicants={setShowApplicants}
            uniqueApplications={uniqueApplications}
            applications={applications}
            jobOrder={jobOrder}
            touchUpdate={touchUpdate}
            setTouchUpdate={setTouchUpdate}
            newStatus={newStatus}
            setNewStatus={setNewStatus}
          />
        )
        })}
    </ContainerCard>
  )
}

export default JobOrderApplications
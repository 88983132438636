import * as React from "react";
import { io } from "socket.io-client";
import { SERVER_BASE_URL } from "../../config.js";

export const SocketContext = React.createContext();

function SocketProvider({ children }) {
  const [socket, setSocket] = React.useState(null);

  /* // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = React.useMemo(() => [state, dispatch], [state]) */

  React.useEffect(() => {
    const socketClient = io(SERVER_BASE_URL);
    setSocket(socketClient);
  }, [])


  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}

export default SocketProvider;

import React, {useMemo} from 'react'
import Table from '../table/Table'
import { jobStatuses } from '../../helpers/enum/jobStatuses'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'

function JobStatusLogTable({jobStatusLog, isLoading}) {
  const data = useMemo(() => [], [jobStatusLog])
  const navigate = useNavigate();

  if (jobStatusLog.length && !data.length) {
    jobStatusLog.forEach(log => {
      data.push({
        jobOrder_id: log?.jobOrder?.id,
        title: log?.jobOrder?.title || '-',
        client: log?.jobOrder?.client?.name || '-',
        status: jobStatuses[log?.status],
        updated_at: dayjs(log?.created_at).format('DD/MM/YYYY'),
        updated_by: log?.user?.first_names || '-'
      })
    })
  }

  const columns = useMemo(() => {
    let columnsToShow = []
    let dataProperties = Object.keys(jobStatusLog[0] || {})
    if (dataProperties.includes('jobOrder') || jobStatusLog.length === 0) {
      columnsToShow.push({
        Header: "Job order ID",
        accessor: 'jobOrder_id'
      })
      columnsToShow.push({
        Header: 'Job order title',
        accessor: "title"
      })
      columnsToShow.push({
        Header: "Client",
        accessor: 'client'
      })
    }
    columnsToShow.push({
      Header: "Status",
      accessor: 'status'
    })
    columnsToShow.push({
      Header: "Updated at",
      accessor: "updated_at"
    })
    columnsToShow.push({
      Header: "Updated by",
      accessor: "updated_by"
    })
    return columnsToShow
  }, [jobStatusLog])

  const rowOnClick = (row) => {
    navigate(`/job-orders/${row.original.jobOrder_id}`)
  }

  return (
    <>
    <Table data={data} columns={columns} isLoading={isLoading} rowOnClick={rowOnClick} table='jobStatusHistory'/>
    </>
  )
}

export default JobStatusLogTable
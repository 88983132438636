import React from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import AddCandidateForm from '../../containers/candidates/AddCandidateForm'

function AddCandidate({ access }) {
  const navigate = useNavigate();
  const params = useParams();
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigate('/candidates', {replace: true})}/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Add candidate'} topSection={renderTopSection()} access={access}>
      <AddCandidateForm />
    </PageWrapper>
  )
}

export default AddCandidate
import React, { useState, useEffect } from 'react'
import TextInput from '../../components/inputs/TextInput'
import useAuth from '../../store/auth/authContext'
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import ModalCropImage from './ModalCropImage';
import InputProfilePicture from '../../components/inputs/InputProfilePicture';
import useApi from '../../store/api/apiContext';
import extractObjectDifferences from '../../helpers/enum/extractObjectDifferences';
import useAppNotification from '../../store/appNotification/appNotificationContext';
import Loader from '../../components/loaders/Loader';

function ProfileSection({ }) {
  
  const { auth: { currentUser }, setAuth } = useAuth();
  const [ user, setUser ] = useState(currentUser);
  const { showSuccessNotification } = useAppNotification();
  const { editMyself } = useApi();
  const [ isLoading, setIsLoading ] = useState(false);

  //profile picture
  const [showModalEditPicture, setShowModalEditPicture] = useState(false);
  const [newPicture, setNewPicture] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);


  useEffect(() => {
    if (currentUser) setUser(currentUser)
  }, [currentUser])

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  
  
  const onProfilePicChange = async file => {
    file = await readFile(file);
    setNewPicture(file);
    setShowModalEditPicture(true);
  };

  const [formError, setFormError] = useState([])
  
  const handleSave = () => {
    let edits = extractObjectDifferences(currentUser, user);
    let errors = [];
    if (edits.first_names === '') errors.push({
      field: "First names",
      msg: "This field is required"
    })
    if (edits.last_names === '') errors.push({
      field: "Last names",
      msg: "This field is required"
    })

    if (errors.length) {
      return setFormError(errors);
    }

    setFormError([]);
    let allEdits = {};
    if (Object.keys(edits).length) allEdits.data = edits
    if (croppedImage) allEdits.profilePicture = croppedImage;
    if (Object.keys(allEdits).length) {
      setIsLoading(true);
      editMyself(allEdits).then(() => {
        //FIXME: Should automatically update navbar thumbnail 
        if (croppedImage) {
          setAuth(prev => ({
            ...prev, 
            currentUser: { ...prev.currentUser, profile_pic_url: true}, 
            profilePicture: URL.createObjectURL(croppedImage) 
          }));
        }
        showSuccessNotification('Your profile was edited')
        setIsLoading(false);
      })
    }
  }

  const handleCancel = () => {
    setUser(currentUser)
    setCroppedImage(null)
  }

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      <ModalCropImage
        image={newPicture}
        setCroppedImage={setCroppedImage}
        show={showModalEditPicture}
        hide={() => setShowModalEditPicture(false)}
      />
      {/* Profile section */}
      <Loader isLoading={isLoading}>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Profile</h2>
            <p className="mt-1 text-sm text-gray-500">
              This information will only be displayed to users inside the application.
            </p>
          </div>
          <div className="mt-6 grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6">
              <TextInput label={'First names'} onChange={(e) => setUser(prev => ({...prev, first_names: e.target.value}))} value={user.first_names} errors={formError}/>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <TextInput label={'Last names'} onChange={(e) => setUser(prev => ({...prev, last_names: e.target.value}))} value={user.last_names} errors={formError}/>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <TextInput label={'Phone number'} value={user.phone_nb} onChange={(e) => setUser(prev => ({...prev, phone_nb: e.target.value}))} errors={formError}/>
            </div>
            <div className="col-span-12 sm:col-span-6">
              <TextInput label={'Email'} value={user.email} disabled/>
            </div>
            <div className="col-span-12">
              <InputProfilePicture
                label="Profile picture"
                setNewPicture={setNewPicture}
                setCroppedImage={setCroppedImage}
                newImage={croppedImage ? URL.createObjectURL(croppedImage) : false}
                onChange={onProfilePicChange}
                user={user}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6 space-x-2">
          <WhiteButton 
            text='Cancel'
            onClick={handleCancel}
          />
          <PrimaryButton 
            text='Save'
            darkRing
            onClick={handleSave}
          />
        </div>
      </Loader>
    </form>
  )
}

export default ProfileSection
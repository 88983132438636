import React, { useState, useEffect } from 'react'
import DescriptionList from '../../components/descriptionLists/DescriptionList';
import dayjs from 'dayjs'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import role from '../../helpers/enum/role';
import JobOrderApplications from './applicationsList/JobOrderApplications';
import StatusHistory from './StatusHistory';
import JobOrderRecruiters from './recruiters/JobOrderRecruiters';
import Attachments from '../../containers/attachments/Attachments'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { EditorState, convertFromRaw } from 'draft-js'
import Comments from '../comments/Comments';
import AccessWrapper from '../app/AccessWrapper';
import utc from 'dayjs/plugin/utc'
import convertCountries from '../../helpers/convertCountries';

function JobOrderDetailsForm({ jobOrder, deleteLegacyDescription }) {

  dayjs.extend(utc)

  const { verifyArrayAccess } = useUserRoleVerifier()

  const [ jobOrderDescription, setJobOrderDescription ] = useState(() => EditorState.createEmpty());
  
  useEffect(() => {
    if (jobOrder.description) {
      var convertedJSON = JSON.parse(jobOrder.description);
      setJobOrderDescription(EditorState.createWithContent(convertFromRaw(convertedJSON)))
    }
  }, [jobOrder])

  let languageNames = []
  let skillNames = []
  if (jobOrder?.languages) jobOrder.languages.forEach(language => {languageNames.push(language.name)})
  if (jobOrder?.skills) jobOrder.skills.forEach(skill => {skillNames.push(skill.name)})

  let languageString = languageNames.join(', ')
  let skillString = skillNames.join(', ')
  

  const items = [
    {label: 'ID', data: jobOrder.id, id: 1, show: true},
    {label: 'Published', data: jobOrder.is_published ? 'Yes': 'No', id: 24, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'is_published')},
    {label: 'Sector', data: jobOrder.sector, id: 3, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'sector')},
    {label: 'Placement type', data: jobOrder.placement_type, id: 4, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'placement_type')},    
    {label: 'Openings', data: jobOrder.openings, id: 5, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'openings')},    
    {label: 'Priority', data: jobOrder.priority, id: 8, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'priority')},    
    {label: 'Deadline', data: jobOrder?.deadline ? dayjs.utc(jobOrder.deadline).format('DD/MM/YYYY') : null, id: 10, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'deadline')},
    {label: 'Duration', data: jobOrder.duration, id: 11, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'duration')},
    {label: 'City', data: jobOrder.city, id: 6, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'city')},
    {label: 'Country', data: convertCountries(jobOrder.country), id: 7, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'country')},
    {label: 'Years of experience', data: jobOrder.yrs_of_exp, id: 13, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'yrs_of_exp')},
    {label: 'Education Level', data: jobOrder.education_level, id: 14, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'education_level')},
    {label: 'PSC required', data: jobOrder.psc_required ? 'Yes' : 'No', id: 9, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'psc_required')},
    {label: 'Client', data: jobOrder?.client?.name, id: 16, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'client_id')},
    {label: 'Client job ID', data: jobOrder.client_job_id, id: 17, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'client_job_id')},
    {label: 'Framework Contract', data: jobOrder?.frameworkContract?.contract_ref, id: 18, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'framework_contract_id')},
    {label: "Languages", data: languageString, id: 19, show:verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'languages')},
    {label: "Skills", data: skillString, id: 20, show:verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'skills')},
    {label: 'Sales Price', data: jobOrder.sales_price ? jobOrder.sales_price + ' €/day' : null, id: 21, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'sales_price')},
    {label: "Request type", data: jobOrder.request_type, id: 23, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'request_type')},
    {label: "Mode", data: jobOrder.mode, id: 22, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'mode')},
    {label: 'Industry', data: jobOrder.industry, id: 25, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'industry')},
    {label: "Price range", data: jobOrder.price_range, id: 26, show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'price_range')},
    {label: "Summary", data: jobOrder?.summary, htmlText: true, id: 28, cols: 'custom', custom: 'sm:col-span-2 lg:col-span-3 col-start-1 min-h-40', show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'summary')},
    {label: 'Description', data: jobOrderDescription, richText: true, id: 12, cols: 'custom', custom: 'sm:col-span-2 lg:col-span-3 col-start-1', show: verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'description')},
    {label: 'Legacy description', data: jobOrder.legacy_description, htmlText:true, id: 13, cols: 'custom', custom: 'sm:col-span-2 lg:col-span-3 col-start-1', show: (verifyArrayAccess(role.h_job_orders.h_1_1_viewable_properties, 'description') && jobOrder.legacy_description != null)},

  ]


  return (
    <div className='grid grid-cols-3 gap-x-4 gap-y-4'>    
      <div className='col-span-3 lg:col-span-2 flex flex-col space-y-4 overflow-y-auto'>  
        <DescriptionList title={'Job order information'} items={items} gridClassName='lg:grid-cols-3' canDeleteLegacyText={verifyArrayAccess(role.h_job_orders.h_3_6_1_editable_properties, 'description')} deleteLegacyText={deleteLegacyDescription}/>
        <DndProvider backend={HTML5Backend}>
          <JobOrderApplications applications={jobOrder.applicationStatusLog} jobOrder={jobOrder}/>
        </DndProvider>
      </div>
      <div className='col-span-3 lg:col-span-1 flex flex-col space-y-4 overflow-y-auto'>
        <StatusHistory title={'Status history'} jobStatusLog={jobOrder.jobStatusLog} jobOrderId={jobOrder?.id}/>
        <JobOrderRecruiters recruiters={jobOrder.recruiters} jobOrder={jobOrder} frameworkContractId={jobOrder.framework_contract_id}/>
        <AccessWrapper access={role.j_attachments.j_1_view_job_order_attachments}>
          <Attachments id={jobOrder.uuid} type={'job-orders'}/>
        </AccessWrapper>
        <AccessWrapper access={role.i_comments.i_1_view_job_order_comments}>
          <Comments/>
        </AccessWrapper>
      </div>
    </div>
  )
}

export default JobOrderDetailsForm
import React from "react";
import PageWrapper from "../../containers/app/PageWrapper";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styles from './ReleaseNotes.module.css';
import releaseNotes from '../../helpers/releaseNotes'
import PrimaryButton from "../../components/buttons/PrimaryButton";
import dayjs from "dayjs";

const ReleaseNotes = () => {


  return (
    <PageWrapper title='Release notes'>
      <section className="space-y-6 max-w-5xl mx-auto">
        {releaseNotes.map((notes, index) => {
          return (
            <div className="rounded-md inline-block bg-white p-6 w-full justify-self-center">
              <div className="flex flex-col-reverse gap-y-2 sm:flex-row justify-between">
                <div className="flex">
                  <span className="text-4xl font-bold place-self-center">v{notes.version}</span>
                  {index == 0 && (
                    <span className="border-2 text-sm rounded-3xl border-thaleria-orange-500 text-thaleria-orange-500 p-1 px-3 font-semibold my-1 ml-5 place-self-center">
                      Latest
                    </span>
                  )}
                </div>
                <span className="text-gray-500 my-auto">{dayjs(notes.date).format('D MMMM YY')}</span>
              </div>
              <ReactMarkdown
                children={notes.markdown}
                components={<PrimaryButton text="latest" />}
                remarkPlugins={[remarkGfm]}
                className={styles.releasenotes}
              />
            </div>
          );
        })}
      </section>
    </PageWrapper>
  );
};

export default ReleaseNotes
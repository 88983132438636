import React from 'react';

const Checkbox = React.forwardRef(({ label, description, checked, onChange, disabled }, ref) => {
  return (
      <div className="relative flex items-start" id='clickable'>
        <div className="flex items-center h-5 ">
          <input
            type="checkbox"
            ref={ref}
            checked={checked}
            onChange={disabled ? () => {} : onChange}
            className={`${disabled ? "focus:outline-none focus:ring-0 text-gray-300" : "focus:ring-thaleria-orange-600 text-thaleria-orange-700"}  h-4 w-4  border-gray-300 rounded`}
          />
        </div>
        {(label || description) && 
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className={`font-medium ${disabled ? "text-gray-400" : "text-gray-700"}`}>
              {label}
            </label>
            <p id="description" className={`${disabled ? "text-gray-400" : "text-gray-500"}`}>
              {description}
            </p>
          </div>
        }
      </div>
  )
})

export default Checkbox;

import React, { useState, useRef, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import useAuth from '../../store/auth/authContext';
import WarningModal from '../../components/modal/WarningModal';

const TIME_INTERVAL_TO_CHECK_TOKEN = 15 * 1000;
const TIME_DELAY_UNTIL_LOGOUT = 60;

function TokenExpiry() {
  const { logout, refreshToken, auth: { isAuthenticated }, token } = useAuth();
  const [seconds, setSeconds] = useState(null);
  const [startCountdown, setStartCountdown] = useState(null);
  
  const [tokenRemainingTime, setTokenRemainingTime] = useState(null);
  const [tokenIntervalChecks, setTokenIntervalChecks] = useState(null);
  
  const _onClickExtend = async () => {
    await refreshToken();
    setStartCountdown(null);
    setTimeout(() => setSeconds(null), 1000);
  };

  const _logout = () => {
    if (tokenIntervalChecks) clearInterval(tokenIntervalChecks);
    setTokenIntervalChecks(null);
    setStartCountdown(null);
    logout(true);
  }

  const _onClickCancel = () => setStartCountdown(false);

  const _checkExpirationTime = () => {
      if (!token || !isAuthenticated) return;
      const decoded = jwt_decode(token);
      const difference = decoded.exp - parseInt(new Date().getTime() / 1000);
      setTokenRemainingTime(difference);
    };

  const refreshSecondsTimeout = useRef(null)

  //if countdown is activated, decreases seconds and eventually logs user out; otherwise clears timer
  useEffect(() => {
    if (startCountdown === true) {
      refreshSecondsTimeout.current = setTimeout(() => setSeconds(seconds - 1), 1000);
      if (typeof seconds == 'number' && seconds <= 0) {
        _logout();
      }
    } 
    if (startCountdown === false) {
      clearTimeout(refreshSecondsTimeout.current);
      setTimeout(() => setSeconds(null), 1000);
    }
    if (startCountdown === null) return;
  }, [startCountdown, seconds])
  
  useEffect(() => {
    if (tokenRemainingTime === null) return;
    if (tokenRemainingTime <= 0) {
      _logout();
    } 
    if (tokenRemainingTime <= TIME_DELAY_UNTIL_LOGOUT) {
      setSeconds(tokenRemainingTime)
      if (startCountdown !== false) {
        setStartCountdown(true);    
        window.focus();
      }
    }
  }, [tokenRemainingTime]);

  //on each token change, sets current remaining time and sets a repeating function to calculate remaining time on each interval
  useEffect(() => {
    if (tokenIntervalChecks) clearInterval(tokenIntervalChecks);
    
    _checkExpirationTime();

    const interval = setInterval(_checkExpirationTime, TIME_INTERVAL_TO_CHECK_TOKEN);
    setTokenIntervalChecks(interval);

    return () => clearInterval(tokenRemainingTime);
  }, [token]);

  return (
    <WarningModal 
      showWarning={startCountdown || false} 
      handleHide={_onClickCancel}
      title = "Token about to expire"
      message = {`Do you want to renew your access token? If you do not extend your token, you will be logged out automatically in ${seconds} seconds.`}
      onClick1 = {_onClickExtend}
      onClick2 = {_onClickCancel}
      buttonText = "Extend"
    />
  );
}

export default TokenExpiry;

import React from 'react'
import DescriptionList from '../../../components/descriptionLists/DescriptionList'

function UserActionsDetails({ role }) {
 const items = [
    { label: 'Manage user groups', data: role.b_manage_user_groups ? 'Yes' : 'No', id: 1, show: true },
    { label: 'Manage roles', data: role.c_manage_roles ? 'Yes' : 'No', id: 2, show: true },
    { label: 'Manage framework contracts', data: role.d_manage_framework_contracts ? 'Yes' : 'No', id: 3, show: true },
    { label: 'Manage clients', data: role.e_manage_clients ? 'Yes' : 'No', id: 4, show: true }, 
  ]
  return (
    <DescriptionList title='Key administrative actions' items={items}/>
  )
}

export default UserActionsDetails
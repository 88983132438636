import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

import classNames from '../../utils/classNames'
import useAuth from '../../store/auth/authContext'

export default function DropdownMenuButton({ buttonText, items, access, hide, linkTo }) {
  //example items array
  /* const items = [
    [
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        linkTo: '/pathname',
        show: true/false
      },
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        linkTo: '/pathname',
        show: true/false
      },
    ],
    [
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        linkTo: '/pathname',
        show: true/false
      },
      {
        text: 'Edit',
        icon: <EditIcon/>,
        onClick: () => alert('hey'),
        linkTo: '/pathname',
        show: true/false
      },
    ],
  ] */

  const { auth } = useAuth();
  const role = auth.userRole;

  const [showButton, setShowButton] = useState(false);

  const isArray = Array.isArray(access);
  const isBool = typeof access === "boolean";
  const isString = typeof access === "string";
  const isOther = !isArray && !isBool && !isString;

  useEffect(() => {
    if (hide) return setShowButton(false);
    if (access === undefined) return setShowButton(true);
    //block access if no property allows user to access (array 'OR' logic)
    if (isArray) {
      let check = false;
      access.forEach((accessProperty) => {
        if (role[accessProperty]) check = true;
      });
      if (check) return setShowButton(true);
    }

    if (isString) {
      if (role[access]) return setShowButton(true);
    }
    if (isBool || isOther) {
      return setShowButton(access);
    }
  }, [access, role, hide]);

  return (
    <Menu
      as="div"
      className={`${
        !showButton && "hidden"
      } w-42 text-right relative inline-block`}
    >
      <div>
        <Menu.Button className="inline-flex justify-center w-full px-4 py-2 border border-transparent text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {buttonText || "options"}
          <ChevronDownIcon
            className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute z-50 right-0 mt-2 w-full lg:w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {items.map((array, index) => (
            <>
              {index !== 0 && <div className="border-b" />}
              {array.map((item, index) => (
                <Menu.Item>
                  {({ active }) =>
                    item.linkTo ? (
                      <Link
                        to={item.linkTo}
                        className={classNames(
                          active && "bg-gray-200",
                          "flex w-full px-4 py-2 text-sm text-gray-700",
                          index === 0 && "rounded-t-md",
                          index === array.length - 1 && "rounded-b-md",
                          item.show === false && "hidden"
                        )}
                        key={item.text}
                      >
                        {item.icon && (
                          <span className="mr-2">{item.icon}</span>
                        )}
                        <span className="ml-1">{item.text}</span>
                      </Link>
                    ) : (
                      <button
                        className={classNames(
                          active && "bg-gray-200",
                          "flex w-full px-4 py-2 text-sm text-gray-700 items-center",
                          index === 0 && "rounded-t-md",
                          index === array.length - 1 && "rounded-b-md",
                          item.show === false && "hidden"
                        )}
                        onClick={item.onClick}
                      >
                        {item.icon && (
                          <span className="mr-2">{item.icon}</span>
                        )}
                        <span className="ml-1">{item.text}</span>
                      </button>
                    )
                  }
                </Menu.Item>
              ))}
            </>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
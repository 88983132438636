import React, { useEffect, useState } from 'react'
import Modal from "../../../components/modal/Modal";
import ReactSelect from '../../../components/inputs/ReactSelect';
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import useAppNotification from '../../../store/appNotification/appNotificationContext';
import useJobOrders from '../../../store/jobOrders/jobOrdersContext';
import useApi from '../../../store/api/apiContext';


function AddRecruiterModal({ show, hide, jobOrderId, currentRecruiters }) {
  
  const { addRecruitersToJobOrder } = useJobOrders();
  const { fetchJobOrderPotentialRecruiters } = useApi();

  const { appNotificationTypes, showAppNotification } = useAppNotification();
  
  const [potentialRecruiters, setPotentialRecruiters] = useState([]);
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);

  
  useEffect(() => {
    if (!show) return;
    fetchJobOrderPotentialRecruiters(jobOrderId).then(res => {

      let filteredRecruiters = res.data.filter(e => !currentRecruiters.some(rec => rec.id == e.id));

      let options = filteredRecruiters.map(el => ({
        value: el.id,
        label: el.first_names + ' ' + el.last_names,
        first_names: el.first_names,
        last_names: el.last_names,
        is_within_scope: el.is_within_scope,
        id: el.id
      }));
      setPotentialRecruiters(options)
    })
    return () => {
      setPotentialRecruiters([]);
      setSelectedRecruiters([]);
    }
  }, [show])
  
  const handleChange = (event) => {
    let _selectedRecruiters;
    if (!event) _selectedRecruiters = [];
    else _selectedRecruiters = event.map(el => el.value);
    setSelectedRecruiters(_selectedRecruiters);
  }

  const handleAddRecruiter = () => {
    addRecruitersToJobOrder(jobOrderId, selectedRecruiters).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Added recruiters to job order');
      hide(false);
    });
  }

  return (
    <Modal show={show} hide={hide} title={'Add recruiter'}>
      <div className='h-full flex flex-col justify-between'>
        <ReactSelect 
          label='Select recruiters to add to this job order' 
          isMulti
          options={potentialRecruiters} 
          onChange={handleChange}
        />
        <div className='w-full flex flex-row-reverse mt-4'>
          <PrimaryButton show={true} disabled={!selectedRecruiters?.length} text={'Add recruiters'} onClick={handleAddRecruiter}/>
        </div>

      </div>
    </Modal>
  )
}

export default AddRecruiterModal
import React from 'react'

export default function TextColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, Header },
  
}) {
  const count = preFilteredRows.length
  
  return (
    <>
      <label className="text-sm font-medium text-gray-700 flex">
          { Header }
      </label>
      <input type="text" className="mt-1 shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 block w-full sm:text-sm border p-2 border-gray-300 rounded-md"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </>
  )
}
import React from 'react'
import {ClipboardIcon, CalendarIcon, OfficeBuildingIcon, ArchiveIcon, CheckIcon, XIcon, ClockIcon, UserAddIcon, XCircleIcon} from '@heroicons/react/outline'
import DropdownMenuDots from '../../components/menus/DropdownMenuDots'
import dayjs from 'dayjs'
import {jobStatusIds, jobStatuses} from '../../helpers/enum/jobStatuses'
import { Link } from 'react-router-dom'

function JobOrderItem ({jobOrder, linkToClients}) {

  const status = jobOrder.jobStatusLog[0]?.status

  const iconSelector = (status) => {
    switch(status) {
      case jobStatusIds.active:
        return (
          <UserAddIcon className= "m-1 mt-0.5 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.cancelled:
        return (
          <XIcon className= "m-1 mt-0.5 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.fulfilled:
        return (
          <CheckIcon className= "m-1 mt-0.5 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.onHold:
        return (
          <ClockIcon className= "m-1 mt-0.5 h-4 w-4 text-gray-400"/>
        )
      case jobStatusIds.archived:
        return (
          <ArchiveIcon className= "m-1 mt-0.5 h-4 w-4 text-gray-400"/>
        )
    }
  }

  return (
    <li>
      <div className={`block py-2`}>
          <div className="flex items-center -my-1 justify-between">
            <Link to={`/job-orders/${jobOrder.id}`} className="sm:flex items-center text-sm text-gray-500 sm:mt-0 group">
              <ClipboardIcon className="m-1 h-4 w-4 text-gray-400 group-hover:text-thaleria-orange-700 group-hover:font-bold" aria-hidden="true" />
              <p to={`/job-orders/${jobOrder.id}`} className="truncate overflow-hidden group-hover:text-thaleria-orange-700 group-hover:font-bold">{jobOrder.title}</p>
            </Link>
          </div>
          <div className="mt-2 sm:flex sm:justify-between shrink">
            <div className="sm:flex items-center space-x-2 text-sm text-gray-500 sm:mt-0">
              <div className='flex items-center'>
                {iconSelector(status)}
                <p className="truncate overflow-hidden">{jobStatuses[status]}</p>
              </div>
              <div className="flex items-center">
                <CalendarIcon className="m-1 mt-0.5 h-4 w-4 text-gray-400 " aria-hidden="true" />
                <p className="truncate overflow-hidden">{dayjs(jobOrder.created_at).format('DD/MM/YY')}</p>
              </div>
            </div>
            {linkToClients ?
              <Link to={`/clients/${jobOrder.client.id}`} className='flex text-sm items-center group'>
                <OfficeBuildingIcon className="m-1 h-4 w-4 text-gray-400 group-hover:text-thaleria-orange-700 group-hover:font-bold" aria-hidden="true" />
                <p className="truncate overflow-hidden group-hover:text-thaleria-orange-700 group-hover:font-bold text-gray-500">{jobOrder.client.name}</p>
              </Link>
              :
              <div className='flex text-sm items-center'>
                <OfficeBuildingIcon className="m-1 h-4 w-4 text-gray-400 " aria-hidden="true" />
                <p className="truncate overflow-hidden">{jobOrder.client.name}</p>
              </div>
            }
          </div>
        </div>
    </li>
  )
}

export default JobOrderItem

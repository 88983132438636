import React, { useState } from 'react';
import DateInput from '../../../components/inputs/DateInput/DateInput'; 
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat, utc)

function DateRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id, Header } }) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = dayjs.utc(row.values[id], 'DD/MM/YYYY').isBefore(dayjs.utc(min, 'DD/MM/YYYY')) ? row.values[id] : min
      max = dayjs.utc(row.values[id], 'DD/MM/YYYY').isAfter(dayjs.utc(max, 'DD/MM/YYYY')) ? row.values[id] : max
    })
    return [min, max]
  }, [id, preFilteredRows])

  const [ beginning, setBeginning ] = useState(null);
  const [ end, setEnd ] = useState(null);

  return (
    <>
      <label className="text-sm font-medium text-gray-700 flex">
        { Header }
      </label>
      <div className={'flex items-center flex-col sm:flex-row'}>
        <DateInput
          className={`${
            filterValue[0] ? '' : 'text-gray-500 '
          } shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 block w-full sm:text-sm border-gray-300 rounded-md`}
          onChange={val => {
            setBeginning(new Date(val))
            setFilter((old = []) => {
              return [val ? val : undefined, old[1]]
            });
          }}
          placeholder={`Min (${min})`}
          selected={filterValue[0] ? filterValue[0] : beginning}
        />
        <span className="mx-2">to</span>
        <DateInput
          className={`${
            filterValue[0] ? '' : 'text-gray-500 '
          } shadow-sm focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 block w-full sm:text-sm border-gray-300 rounded-md`}
          onChange={val => {
            setEnd(new Date(val))
            setFilter((old = []) => [old[0], val ? val : undefined]);
          }}
          placeholder={`Max (${max})`}
          selected={filterValue[1] ? filterValue[1] : end}
        />
      </div>
    </>
  );
}

export default DateRangeColumnFilter;

import React, { useState, useEffect } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { LockClosedIcon } from '@heroicons/react/outline';

function NumberInput({ label, onChange, value, min, max, errors, disabled }) {

  const [ error, setError ] = useState({});
  
  useEffect(() => {
    if (!errors?.length) return;
    let myError = errors.find(error => error.field === label);
    if (myError) setError(myError);
    if (!myError) setError({});

  }, [errors]);
  
  return (
    <>
      <label className="text-sm font-medium text-gray-700 flex">
        { label }
        { error?.msg && <span className="text-red-400 flex"> <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1"/> {error.msg}</span>  }
      </label>
      <div className='w-full relative'>
        <input
          type="number"
          min= {min || "1"}
          max= {max || ''}
          value={value || ''}
          className={` ${disabled && "bg-gray-200"} mt-1 focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 focus:shadow-inner block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
          onChange={onChange}
          disabled={disabled}
        />
        {disabled && <LockClosedIcon className='h-5 w-5 my-auto mr-2 text-gray-500 absolute inset-y-0 right-0'/>}
      </div>
      
    </>
  )
}

export default NumberInput
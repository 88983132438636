import React, { Fragment } from 'react'
import { Listbox, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/solid'

function DropdownSelector ({ selectedOption, onChange, options, srLabel, access= true }) {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  if (!selectedOption) return null;

  //TODO: I've changed the status button to appear even if user doesn't have access but the button should be 'disabled' by not showing the dropdown menu

  return (
    <>
      <span className="lg:ml-3 relative flex">
        <Listbox value={selectedOption} onChange={onChange}>
          {({ open }) => (
            <>
              <Listbox.Label className="sr-only">{srLabel}</Listbox.Label>
              <div className="relative w-full z-10">
                <div className="inline-flex justify-end w-full h-full shadow-sm rounded-md divide-x divide-thaleria-orange-800">
                  <div className="relative inline-flex w-full shadow-sm rounded-md divide-x divide-thaleria-orange-800">
                    <div className={`relative inline-flex justify-center w-full items-center bg-thaleria-orange-700 py-2 pl-3 pr-4 border border-transparent ${!access ? "rounded-md" : "rounded-l-md"} shadow-sm text-white`}>
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      <p className="ml-2.5 md:w-16 h-fit text-sm font-medium">{selectedOption.name}</p>
                    </div>
                    {access && 
                      <Listbox.Button className="relative inline-flex items-center bg-thaleria-orange-700 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-thaleria-orange-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-thaleria-orange-700">
                        <span className="sr-only">{srLabel}</span>
                        <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      </Listbox.Button>
                    }
                  </div>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="origin-top-right absolute left-0 mt-2 -mr-1 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.name}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-thaleria-orange-700' : 'text-gray-900',
                            'cursor-default select-none relative p-4 text-sm'
                          )
                        }
                        value={option}
                      >
                        {({ selectedOption, active }) => (
                          <div className="flex flex-col">
                            <div className="flex justify-between">
                              <p className={selectedOption || active ? 'font-bold' : 'font-normal'}>{option.name}</p>
                              {selectedOption ? (
                                <span className={active ? 'text-white' : 'text-thaleria-orange-700'}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </div>
                            <p className={classNames(active ? 'text-white' : 'text-gray-500', 'mt-2')}>
                              {option.description}
                            </p>
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </span>
    </>
  )
}

export default DropdownSelector
import React from 'react'
import DescriptionList from '../../components/descriptionLists/DescriptionList';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

function UserGroupDetailsForm({ userGroup }) {
  dayjs.extend(utc)
  let roleNames = []
  userGroup.roles.forEach(role => roleNames.push(role.a_name))
  let namesString = roleNames.join(', ')

  let price = userGroup.price_margin? userGroup.price_margin+'%' : null

  const items = [
    { label: 'Name', data: userGroup.name, id: 1, show: userGroup.name ? true : false },
    { label: 'Roles', data: namesString, id: 2, show: userGroup.roles.length ? true : false },
    { label: 'Creation date', data: dayjs.utc(userGroup.created_at).format('DD/MM/YYYY'), id: 3, show: userGroup.created_at ? true : false },    
    { label: 'Price margin', data: price, id: 4, show: userGroup.price_margin ? true : false }    
  ]

  return (
    <DescriptionList title={'User group information'} items={items}/>
  )
}

export default UserGroupDetailsForm
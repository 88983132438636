import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router'
import JobOrderDetailsList from '../../containers/jobOrders/JobOrderDetailsList'
import Loader from '../../components/loaders/Loader'
import DropdownSelector from '../../components/inputs/DropdownSelector'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import jobStatusOptions from '../../helpers/jobStatusOptions'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import TitleSection from '../../components/app/TitleSection'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'
import WarningModal from '../../components/modal/WarningModal'
import useJobOrders from '../../store/jobOrders/jobOrdersContext'
import useAuth from '../../store/auth/authContext'


function JobOrderDetails({ access }) {
  const navigate = useNavigate();
  const { id } = useParams();
  
  const { verifyBoolAccess, verifyUserId, verifyArrayAccess, role } = useUserRoleVerifier();
  const { appNotificationTypes, showAppNotification, showSuccessNotification} = useAppNotification();
  
  const [ selectedStatus, setSelectedStatus ] = useState()
  const [ showWarningModal, setShowWarningModal ] = useState(false)

  const [isLoading, setIsLoading] = useState(true);

  const {
    auth: { currentUser },
  } = useAuth();

  const {
    jobOrdersState: { jobOrderDetails: jobOrder },
    jobOrdersState,
    fetchJobOrder,
    editJobOrder,
    deleteJobOrder,
    addJobStatusLog,
  } = useJobOrders();


  const [ showEdit, setShowEdit ] = useState(false);
  const [ showDelete, setShowDelete ] = useState(false)
  const [ showDuplicate, setShowDuplicate ] = useState(false)
  const [ updateStatusAccess, setUpdateStatusAccess ] = useState(false)

  const [ cantCloseRequestModal, setCantCloseRequestModal ] = useState(false)
  const [ error, setError ] = useState()
  
  useEffect(() => {
    if (jobOrder?.jobStatusLog) {
      let optionToSet = jobStatusOptions.find((option) => option.id === jobOrder?.jobStatusLog[0]?.status)
      setSelectedStatus(
        optionToSet || {
          name: "Error",
          description: "No status found for this job order!",
          id: 0,
        }
      );
      
    }
  }, [jobOrder?.jobStatusLog])

  const items = [
    [
      {
        text: 'Edit job order',
        linkTo: `/job-orders/edit/${id}`,
        show: showEdit
      },
      {
        text: 'Duplicate job order',
        onClick: () => navigate('/job-orders/add', {state: {id: id}}),
        show: showDuplicate
      },
      {
        text: 'Delete job order',
        onClick: () => setShowWarningModal(true),
        show: showDelete
      },
    ]
  ]

  const confirmDelete = async () => {
    setShowWarningModal(false)
    deleteJobOrder(id).then(res => {
      showSuccessNotification('Job order was correctly deleted')
      navigate('/job-orders')
    })
  }

  const fetchJobOrderFromApi = async (source, id) => {
    setIsLoading(true);
    fetchJobOrder(id, source)
      .then(() => {
        setIsLoading(false);
      });
  }

  const handleChangeStatus = (e) => {
    if (e === selectedStatus) return;
    addJobStatusLog(id, { status: e.id }).then((res) => {
      if (res.data?.error) {
        setError(res.data.error)
        setCantCloseRequestModal(true)
        return;
      }
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Status updated successfully');
    })
  }

  const deleteLegacyDescription = () => {
    const jobOrderData = {
      legacy_description: null
    }
    editJobOrder(id, jobOrderData, currentUser.id);

  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchJobOrderFromApi(source, id);
    return () => {
      source.cancel();
    };
  }, [id])

  useEffect(() => {
    if ((verifyBoolAccess(role.h_job_orders.h_2_1_edit_created_job_orders) && verifyUserId(jobOrder?.created_by)) || (verifyBoolAccess(role.h_job_orders.h_3_6_edit_job_order) && verifyArrayAccess(role.h_job_orders.h_1_2_allowed_fwc, jobOrder?.framework_contract_id))) setShowEdit(true)
    if ((verifyBoolAccess(role.h_job_orders.h_2_2_delete_created_job_orders) && verifyUserId(jobOrder?.created_by)) || (verifyBoolAccess(role.h_job_orders.h_3_7_delete_job_order) && verifyArrayAccess(role.h_job_orders.h_1_2_allowed_fwc, jobOrder?.framework_contract_id))) setShowDelete(true)
    if (verifyBoolAccess(role.h_job_orders.h_2_create_job_orders) && verifyArrayAccess(role.h_job_orders.h_1_2_allowed_fwc, jobOrder?.framework_contract_id)) setShowDuplicate(true)
    if (verifyBoolAccess(role.h_job_orders.h_3_1_update_job_order_status)) setUpdateStatusAccess(true)
  }, [jobOrder])

  const renderTopSection = () => {
    let showEdit = false
    if (verifyBoolAccess(role.h_job_orders.h_3_6_edit_job_order)) showEdit = true
    if (verifyBoolAccess(role.h_job_orders.h_2_1_edit_created_job_orders) && verifyUserId(jobOrder.created_by)) showEdit = true

    return (
      <>
        <DropdownMenuButton buttonText="More actions" items={items} access={showEdit || showDelete}/>
        <DropdownSelector selectedOption={selectedStatus} access={updateStatusAccess} onChange={handleChangeStatus} options={jobStatusOptions} srLabel="Change job order status"/>
        <PrimaryButton text='Back' onClick={() => navigate(-1) }/>
        <WarningModal title="Delete job order?" showWarning={showWarningModal} message="Deleting this job order will irreversibly delete all associated applications." buttonText={'Confirm delete'} onClick1={() => confirmDelete()} onClick2={() => setShowWarningModal(false)}/>
      </>
    )
  }

  const jobOrderTitle = `${jobOrder.title} ${jobOrder?.level ? ' - ' + jobOrder.level : ''}`

  const renderTitle = () => {
    return <TitleSection title='Job orders' tableUrl='/job-orders' resource={jobOrderTitle}/>
  }
  
  return (
    <PageWrapper title={renderTitle()} tabTitle='Job order details' topSection={renderTopSection()} access={access}>
      <div className='space-y-4'>
        <Loader isLoading={isLoading}>
          <WarningModal title="Unable to close this request" showWarning={cantCloseRequestModal} message={error} buttonText={'Confirm'} onClick1={() => setCantCloseRequestModal(false)} onClick2={() => setCantCloseRequestModal(false)}/>
          <JobOrderDetailsList isLoading={isLoading} jobOrder={jobOrder} deleteLegacyDescription={deleteLegacyDescription}/>
        </Loader>
      </div>
    </PageWrapper>
  )
}

export default JobOrderDetails
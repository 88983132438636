import React from 'react'
import ContainerCard from '../../components/cards/ContainerCard'
import { jobStatuses } from '../../helpers/enum/jobStatuses'
import dayjs from 'dayjs'
import { ArchiveIcon, CheckIcon, XIcon, ClockIcon, UserAddIcon, XCircleIcon } from '@heroicons/react/outline'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { jobStatusIds } from '../../helpers/enum/jobStatuses'
import AccessWrapper from '../../containers/app/AccessWrapper';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import utc from 'dayjs/plugin/utc'
import useJobOrders from '../../store/jobOrders/jobOrdersContext'

function StatusHistory({title, jobStatusLog, jobOrderId }) {


  dayjs.extend(utc)
  const {role, verifyBoolAccess} = useUserRoleVerifier();

  dayjs.extend(advancedFormat)
  
  const { deleteJobStatusLog } = useJobOrders();
  const iconSelector = (status) => {
    switch(status) {
      case jobStatusIds.active:
        return (
          <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
            <UserAddIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case jobStatusIds.cancelled:
        return (
          <span className="h-8 w-8 rounded-full bg-red-500 flex items-center justify-center ring-8 ring-white">
            <XIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case jobStatusIds.fulfilled:
        return (
          <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
            <CheckIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case jobStatusIds.onHold:
        return (
          <span className="h-8 w-8 rounded-full bg-gray-500 flex items-center justify-center ring-8 ring-white">
            <ClockIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case jobStatusIds.archived:
        return (
          <span className="h-8 w-8 rounded-full bg-gray-500 flex items-center justify-center ring-8 ring-white">
            <ArchiveIcon className= "h-5 w-5 text-white"/>
          </span>
        )
    }
  }

  const handleClick = async (id) => {
    deleteJobStatusLog(id, jobOrderId);
  }

  return (
    <ContainerCard title= {title}>
      <ul role="list" className="max-h-60 lg:max-h-72 overflow-y-auto">
        {jobStatusLog.map((item, index) => {
          return (
            <li key={item.id}>
              <div className={`relative ${index !== jobStatusLog.length-1 ? "pb-8" : "pb-0"}`}>
                {index !== jobStatusLog.length-1 && <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
                <div className="group relative flex space-x-3 items-center">
                  <div>
                    {iconSelector(item.status)}
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-500">{item.user.first_names} updated the status to <span className="font-medium text-gray-900">{jobStatuses[item.status]}</span></p>
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500 flex space-x-1">
                      <span>{dayjs.utc(item.created_at).format('dddd, MMM Do')}</span>
                      <AccessWrapper access={verifyBoolAccess(role.h_job_orders.h_3_1_update_job_order_status)}>
                        <XCircleIcon className='h-5 w-5 text-red-400 cursor-pointer hidden group-hover:flex hover:text-red-600' onClick={() => handleClick(item.id)}/>
                      </AccessWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </ContainerCard>
  )

}

export default StatusHistory
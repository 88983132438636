import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import './DateInputForGraph.css';
import "react-datepicker/dist/react-datepicker.css";
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { LockClosedIcon } from '@heroicons/react/outline';

const DateInput = ({className, label, selected, onChange, errors, disabled, placeholder}) => {
  
  const [ error, setError ] = useState({});

  useEffect(() => {
    if (!errors?.length) return;
    let myError = errors.find(error => error.field === label);
    if (myError) setError(myError);
    if (!myError) setError({})
  }, [errors])

  const changeHandler = (e) => {
    // understand event object, find date and convert to utc, keep all else equal
    let UTCDate = new Date(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate()))
    onChange(UTCDate);
  }

  return (
    <div className={`max-w-xs overflow-hidden flex items-center mx-2 z-50 ${className}`}>
      {label && <label className="text-sm font-medium text-gray-700 flex relative min-w-max mr-2">
        {label}
        {disabled && <LockClosedIcon className='h-5 w-5 my-auto mr-2 text-gray-500 absolute inset-y-0 right-0 transform translate-y-8'/>}
      </label>}
      <DatePicker 
        className={`h-2 w-2 ${disabled && "bg-gray-200"}`} 
        selected={selected}
        disabled={disabled} 
        onChange={changeHandler} 
        dateFormat="dd/MM/yyyy" 
        placeholderText={placeholder}
        calendarStartDay={1}
      />
    </div>
  );
};


export default DateInput;

import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import useApi from '../../store/api/apiContext'
import role from '../../helpers/enum/role'
import ClientsTable from '../../containers/clients/ClientsTable'

function Clients({ access }) {
  const [ clients, setClients ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchClients } = useApi();

  const fetchClientsFromApi = async (source) => {
    fetchClients(source, 'table').then(res => {
      setClients(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchClientsFromApi(source);
    return () => {
      source.cancel();
    }
  }, [])

  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton access={role.e_manage_clients} text='Add client' linkTo='/clients/add'/>
      </>
    )
  }

  return (
    <PageWrapper title={'Clients'} topSection={renderTopSection()} access={access}>
      <ClientsTable clients={clients} isLoading={isLoading}/>
    </PageWrapper>
  )
}

export default Clients
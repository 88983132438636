//Environment variables only work locally. Deployed environment variables need to be hardcoded, or added during image build time.
//We are only doing this for REACT_APP_ENV environment variable, so the rest need to be hard coded
//TODO: Remove your IP and put it back as localhost
let apiBaseUrl = "http://localhost:8000/api";
let allAccessUuid = process.env.REACT_APP_ALL_ACCESS_UUID;
let rootRoleUuid = process.env.REACT_APP_ROOT_ROLE_UUID;
let thaleriaWebsiteUrl = ''
switch (process.env.REACT_APP_ENV) {
  case 'staging':
    apiBaseUrl = "https://cats-staging-api.thaleria.com/api";
    thaleriaWebsiteUrl = "https://website-staging.thaleria.com"
    break;
    case 'production':
      apiBaseUrl = "https://cats-api.thaleria.com/api";
      thaleriaWebsiteUrl = "https://www.thaleria.com"
    break;

  default:
    break;
}

export const API_BASE_URL = apiBaseUrl;
export const SERVER_BASE_URL = apiBaseUrl.replace('/api', '');

export const ALL_ACCESS_UUID = allAccessUuid;
export const ROOT_ROLE_UUID = rootRoleUuid;

export const RECAPTCHA_SITE_KEY = '6LdHO20pAAAAAOwIv9ceTNc_la6oIlBkoSTBortt'

export const APP_STYLES = {
  primaryColor: "#1F2F4F",
  secondaryColor: "#FCB54A",
};

export const THALERIA_WEBSITE_URL = thaleriaWebsiteUrl
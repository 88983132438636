import React from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import AddClientForm from '../../containers/clients/AddClientForm'

function AddClient({ access }) {
  const navigate = useNavigate();
  const params = useParams();
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigate(`/clients`, {replace: true}) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Add client'} topSection={renderTopSection()} access={access}>
      <AddClientForm />
    </PageWrapper>
  )
}

export default AddClient
import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import useApi from '../../store/api/apiContext'
import role from '../../helpers/enum/role'
import FrameworkContractsTable from '../../containers/frameworkContracts/FrameworkContractsTable'

function FrameworkContracts({ access }) {
  const [ frameworkContracts, setFrameworkContracts ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchFrameworkContracts } = useApi();

  const fetchFrameworkContractsFromApi = async (source) => {
    fetchFrameworkContracts(source).then(res => {
      setFrameworkContracts(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchFrameworkContractsFromApi(source);
    return () => {
      source.cancel();
    }
  }, [])

  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton access={role.d_manage_framework_contracts} text='Add contract' linkTo='/fw-contracts/add'/>
      </>
    )
  }

  return (
    <PageWrapper title={'Framework contracts'} topSection={renderTopSection()} access={access}>
      <FrameworkContractsTable frameworkContracts={frameworkContracts} isLoading={isLoading} access={access}/>
    </PageWrapper>
  )
}

export default FrameworkContracts

import React from 'react'
import Table from '../table/Table';
import dayjs from 'dayjs'
import { useNavigate } from 'react-router';
import role from '../../helpers/enum/role';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import DateRangeColumnFilter from '../table/filters/DateRangeColumnFilter';
import { ExternalLinkIcon, EyeIcon, PencilIcon } from "@heroicons/react/solid";
import utc from 'dayjs/plugin/utc'
import sortByDate from '../../helpers/reactTableSortByDate';

function FrameworkContractsTable({ frameworkContracts, isLoading, access }) {

  dayjs.extend(utc)

  const navigate = useNavigate();
  const { verifyBoolAccess } = useUserRoleVerifier();

  const data = React.useMemo(
      () => []
    , [ frameworkContracts ]);
    
    if (frameworkContracts.length && !data.length) {
      frameworkContracts.forEach(contract => {
        data.push({
          id: contract.id || '-',
          contract_ref: contract.contract_ref || '-',
          contract_start: dayjs.utc(contract.contract_start).format('DD/MM/YYYY') || '-',
          contract_end: dayjs.utc(contract.contract_end).format('DD/MM/YYYY') || '-'
        })
      })
    }

  
  const columns = React.useMemo(
    () => [
      {
        Header: "Contract reference",
        accessor: "contract_ref",
      },
      {
        Header: "Contract start",
        accessor: "contract_start",
        Filter: DateRangeColumnFilter,
        filter: "date",
        sortType: sortByDate,
      },
      {
        Header: "Contract end",
        accessor: "contract_end",
        Filter: DateRangeColumnFilter,
        filter: "date",
        sortType: sortByDate,
      },
    ],
    []
  );

  let renderRowMenu = (row) => {
    const canEdit = verifyBoolAccess(role.d_manage_framework_contracts);
    let buttons = [ 
      [
        {
          text: "View contract details",
          icon: EyeIcon,
          onClick: () => navigate(`/fw-contracts/${row.original.id}`),
        },
        {
        text: "View details in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/fw-contracts/${row.original.id}`),
        },
      ],
      
    ]

    if (canEdit) buttons.push([
      {
        text: "Edit contract",
        icon: PencilIcon,
        onClick: () => navigate(`/fw-contracts/edit/${row.original.id}`),
        show: canEdit
      },
      {
        text: "Edit contract in new tab",
        icon: ExternalLinkIcon,
        onClick: () => window.open(`/fw-contracts/edit/${row.original.id}`),
        show: canEdit
      },
    ],)
    return buttons;
  } 

  const rowOnClick = (row) => {
    navigate(`/fw-contracts/${row.original.id}`);
  }
  
  return (
    <>
      <Table data={data} columns={columns} isLoading={isLoading} renderRowMenu={renderRowMenu} rowOnClick={rowOnClick}/>
    </>
  )
}

export default FrameworkContractsTable

import React, { useEffect, useState, useRef } from 'react'
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router';
import useApi from '../../store/api/apiContext';
import ReactSelect from '../../components/inputs/ReactSelect';
import NumberInput from '../../components/inputs/NumberInput';
import useNavigateBack from '../../hooks/useNavigateBack';

function EditUserGroupForm({ userGroup, allRoles, setUserGroup }) {
  
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const { editUserGroup } = useApi();
  const nameInputRef = useRef(null);
  
  const [userGroupData, setUserGroupData] = useState({name: null})
  const [roleOptions, setRoleOptions] = useState([])
  const [defaultOptions, setDefaultOptions] = useState() 
  const [roles, setRoles] = useState()
  const [formError, setFormError] = useState()


  useEffect(() => {
    setUserGroupData({name: userGroup.name, price_margin: userGroup.price_margin})
    let allRolesValues = []

    allRoles.forEach(role => {
      allRolesValues.push(
      {
        value: role.id,
        label: role.a_name
      })
    })
    setRoleOptions(allRolesValues)
    let originalRoles = [];
    userGroup.roles.forEach(role => {
      originalRoles.push({value: role.id, label: role.a_name})
    })
    setDefaultOptions(originalRoles)
  }, [])

  const handleEditUserGroup = () => {
    let errors = [];

    if (!userGroupData.name) errors.push({
      field: "Name*",
      msg: "This field is required"
    })

    if (userGroupData.price_margin < 0 || userGroupData.price_margin > 100) errors.push({
      field: "Price margin (%)",
      msg: "Must be 0-100"
    })

    if (errors.length) {
      nameInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }    

    if (userGroupData.price_margin == "") {
      
      setUserGroupData(prev => ({...prev, price_margin: null}))
    }

    setFormError([]);

    editUserGroup(userGroupData, id, roles).then(() => {
      navigate(`/user-groups/${id}`, {replace: true});
    })
  }

  const handleEditRole = (e) => {
    let newRoles = [];

    if (!e) newRoles=[];

    newRoles = e.map(role => role.value)
    
    setRoles(newRoles)
  }

  const handlePriceMarginChange = (e) => {
    if (e.target.value == "") {
      setUserGroupData(prev => ({...prev, price_margin: null }))
    } else {
      setUserGroupData(prev => ({...prev, price_margin: e.target.value }) )
    }
  }

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Information' description='Edit the name and price margin of this user group' >
        <div ref={nameInputRef} className="col-span-6">
          <TextInput label='Name*' errors={formError} value={userGroupData.name} onChange={(e) => setUserGroupData(prev => ({...prev, name: e.target.value }) )}/>
        </div>    
        <div className="col-span-6">
          <NumberInput label="Price margin (%)" errors={formError} value={userGroupData.price_margin} onChange={(e) => handlePriceMarginChange(e)}/>
        </div>    
      </FormWithSideTitle>
      <FormWithSideTitle title='Roles' description="Set which roles this user group's users will have access to" >
        {defaultOptions && <div className="col-span-6">
          <ReactSelect 
            isMulti
            label='Edit roles' 
            selectedOptions = {defaultOptions}
            options={roleOptions}  
            onChange={handleEditRole}
          />
        </div>}
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigateBack(`/user-groups`) }/>
        <PrimaryButton text='Edit' darkRing onClick={handleEditUserGroup}/>
      </div>
    </div>
  )
}

export default EditUserGroupForm
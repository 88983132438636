import * as React from 'react';
import axios from 'axios';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';

export const AppNotificationContext = React.createContext();

function useAppNotification() {
  const context = React.useContext(AppNotificationContext);

  if (!context) {
    throw new Error('useAppNotification must be used within an AppNotificationProvider');
  }

  const [appNotification, setAppNotification] = context;

  //axios setup
  const token = localStorage.getItem('jwtToken');
  if (token) axios.defaults.headers.common['x-access-token'] = token;

  //app notification
  const hideAppNotification = () => {
    setAppNotification(prev => ({ ...prev, show: false }))
  };

  /**
   * Show a application-level notification
   *
   * @param {String} type The type of notification
   * @param {String} title The title for the notification
   * @param {String} msg The body of the notification
   */

  const showAppNotification = (type, title, msg) => {
    setAppNotification(prev => ({ ...prev, type: type || appNotificationTypes.SUCCESS , title: title, msg: msg, show: true }))
    setTimeout(function(){
      hideAppNotification();
    },3000)
  };

  const showSuccessNotification = (msg) => {
    setAppNotification(prev => ({ ...prev, type: appNotificationTypes.SUCCESS , title: 'Success', msg: msg, show: true }))
    setTimeout(function(){
      hideAppNotification();
    },3000)
  };

  const showErrorNotification = (msg) => {
    setAppNotification(prev => ({ ...prev, type: appNotificationTypes.WARNING_YELLOW , title: 'Error', msg: msg, show: true }))
    setTimeout(function(){
      hideAppNotification();
    },3000)
  };


  return {
    appNotification,
    hideAppNotification,
    showAppNotification,
    showSuccessNotification,
    appNotificationTypes,
    showErrorNotification
  }
}

export default useAppNotification;
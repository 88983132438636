import React, { useState, useEffect } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { LockClosedIcon } from '@heroicons/react/outline';

function TextArea({ label, onChange, rows, cols, errors, value, disabled, defaultValue, id, placeholder }) {

  const [ error, setError ] = useState({});
  
  useEffect(() => {
    if (!errors?.length) return;
    let myError = errors.find(error => error.field === label);
    if (myError) setError(myError);
    if (!myError) setError({});

  }, [errors]);

  useEffect(() => {
    const textarea = document.getElementById(label || id)

    const addTab = (e) => {
      if (e.key === 'Tab') {
        e.preventDefault()

        textarea.setRangeText(
          '  ',
          textarea.selectionStart,
          textarea.selectionStart,
          'end'
        )
      }
    }

    textarea.addEventListener('keydown', addTab);
    
    return () => {
      textarea.removeEventListener('keydown', addTab)
    };
  }, [])
  
  return (
    <>
      <label className="text-sm font-medium text-gray-700 flex">
        { label }
        { error?.msg && <span className="text-red-400 flex"> <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1"/> {error.msg}</span>  }
      </label>
      {label && <div className="mt-1"></div>}
      <div className='w-full relative'>
        <textarea
          id= {label || id}
          name= "textarea"
          rows= {rows}
          cols= {cols}
          defaultValue={defaultValue}
          className= {`${disabled && "bg-gray-200"} mt-1 focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 focus:shadow-inner block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
        />
        {disabled && <LockClosedIcon className='h-5 w-5 my-auto mr-2 text-gray-500 absolute inset-y-0 right-0'/>}
      </div>
      
      
    </>
  )
}

export default TextArea
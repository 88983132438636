import React from 'react'
import getUserInitials from '../../utils/getUserInitials'
import PrivateImage from '../privateImage/PrivateImage'

function ProfilePicture({
  user,
  small,
  big,
  className,
  hover=true,
  xxl,
  notMyProfilePicture
}) {

  let url = user?.profile_pic_url

  return (
    <>
      {url ?
        notMyProfilePicture ?
          <div>
            <PrivateImage className={`${!small && !big && 'h-12 w-12'} ${small && 'h-9 w-9'} ${big && 'h-20 w-20 text-3xl'} ${xxl && 'h-36 w-36 text-6xl' } ${className} rounded-full overflow-hidden transform scale-100`} src={url}/>
          </div> :
          <div>
            <PrivateImage isProfilePicture className={`${!small && !big && 'h-12 w-12'} ${small && 'h-9 w-9'} ${big && 'h-20 w-20 text-3xl'} ${xxl && 'h-36 w-36 text-6xl' } ${className} rounded-full overflow-hidden transform scale-100`} src={url}/>
          </div>
        :
        <div className={`rounded-full ${!small && !big && 'h-12 w-12 text-xl'} ${small && 'h-9 w-9 text-sm'} ${big && 'h-20 w-20 text-3xl'} ${xxl && 'h-36 w-36 text-6xl' } ${className} bg-thaleria-orange-700 ${hover && 'hover:bg-thaleria-orange-800'} flex justify-center items-center text-white font-bold`}>
          {user.first_names && user.last_names && getUserInitials(user.first_names, user.last_names)}
          {(!user.first_names || !user.last_names) && ''}
        </div>
      }
    </>
    
  )
}


export default ProfilePicture
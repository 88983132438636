import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import Modal from '../../components/modal/Modal'
import ReactSelect from '../../components/inputs/ReactSelect'
import { jobStatuses } from '../../helpers/enum/jobStatuses'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import useJobOrders from '../../store/jobOrders/jobOrdersContext'


function BatchUpdateStatusModal({ show, hide, selectedRows, setSelectedRows }) {
  const [ selectedStatus, setSelectedStatus ] = useState({value: 1, label: "Active"})
  const [ errors, setErrors ] = useState(false)
  
  const handleHide = () => {
    setErrors(false)
    hide()
  }

  const { batchUpdateJobOrderStatus } = useJobOrders();
  const { showSuccessNotification, showErrorNotification } = useAppNotification();

  let statusOptions = []

  Object.keys(jobStatuses).forEach(status => {
    statusOptions.push({
      label: jobStatuses[status],
      value: status
    })
  })

  const changeHandlerSelectStatus = (e) => {
    setSelectedStatus(e)
  }

  const handleSubmit = () => {
    let idArray = selectedRows.map(row => row.original.id)

    batchUpdateJobOrderStatus(idArray, selectedStatus.value).then((res) => {
      const { arrayOfErrors } = res;
      //keep ones that didnt succeed selected
      const updatedSelectedRows = arrayOfErrors.length
        ? selectedRows.filter((row) =>
            arrayOfErrors.some((el) => el.id == row.original.id)
          )
        : [];
      setErrors(arrayOfErrors);
      setSelectedRows(updatedSelectedRows);
      const nbOfUpdatedJobOrders = idArray.length - arrayOfErrors.length;
      let action;
      nbOfUpdatedJobOrders === 0 ? action = showErrorNotification : action = showSuccessNotification;
      action(
        `${nbOfUpdatedJobOrders} out of ${
          idArray.length
        } job orders updated successfully`
      );

      if (!arrayOfErrors.length) handleHide();
    })
  }

  return (
    <Modal show={show} hide={handleHide} title={"Update status"}>
      <div className="h-full flex flex-col justify-between">
        <ReactSelect
          label="Status"
          selectedOption={selectedStatus}
          options={statusOptions}
          onChange={changeHandlerSelectStatus}
        />
        {errors && (
          <>
            <div className="flex items-center mt-4">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-1" />
              <p className="text-md text-red-500 ">
                Issue updating the following job orders:
              </p>
            </div>
            <div className="">
                {errors.map((obj, i) => {
                  return (
                    <div className="flex flex-col text-gray-600  my-1">
                      <Link
                        className="cursor-pointer pl-2 hover:text-thaleria-orange-700 hover:font-bold"
                        to={`/job-orders/${obj.id}`}
                      >
                        {i + 1}{'. '}{obj.title} (ID #{obj.id})
                      </Link>
                      <span className="text-gray-400 text-sm ml-2">
                        {obj.msg}
                      </span>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        <div className="w-full flex flex-row-reverse mt-4">
          <PrimaryButton
            text={"Update"}
            disabled={!selectedStatus}
            onClick={handleSubmit}
            darkRing
          />
        </div>
      </div>
    </Modal>
  );
}

export default BatchUpdateStatusModal

import React from 'react'
import classNames from '../../utils/classNames'
import { Switch } from '@headlessui/react'

function FormWithSideTitle({ title, description, children, toggle, toggleValue, disabled, onChange }) {
  return ( 
    <div className="bg-white shadow px-4 py-5 rounded-lg sm:p-6">
      {toggle ? 
      <div className="grid grid-cols-2 md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-3 text-gray-900">{title}</h3>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
        <div className='col-span-1 md:col-start-3 justify-self-end self-center'>
          <Switch 
            checked={toggleValue}
            onChange={(disabled ? () => {} : onChange)}
            className={classNames(
              toggleValue && !disabled? 'bg-thaleria-orange-600' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full  transition-colors ease-in-out duration-200 focus:outline-none ',
              disabled ? 'cursor-default bg-gray-500' : 'focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-orange-600 cursor-pointer'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                toggleValue ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>
        </div>
        {children && <div className="mt-5 md:mt-0 col-span-2 md:col-start-2">
          <div className="grid grid-cols-6 gap-6">
            {children}
          </div>
        </div>}
      </div>
      :
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-3 text-gray-900">{title}</h3>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2 md:col-start-2">
          <div className="grid grid-cols-6 gap-6">
            {children}
          </div>
        </div>
      </div>}
    </div>
  )
}

export default FormWithSideTitle
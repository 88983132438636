import * as React from 'react';
import { AuthContext } from './authContext.js';

function AuthProvider({children}) {

  const [auth, setAuth] = React.useState({ 
    currentUser: {},
    userRole: {},
    profilePicture: null,
    isAuthenticated: 'pending', 
  });

  
  /* // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = React.useMemo(() => [state, dispatch], [state]) */

  //No memoization needed
  const value = [auth, setAuth];

  return (
    <AuthContext.Provider value={ value }>
      { children }
    </AuthContext.Provider>
  )
}

export default AuthProvider;
import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import EditUserGroupForm from '../../containers/userGroups/EditUserGroupForm'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import axios from 'axios'
import useNavigateBack from '../../hooks/useNavigateBack'


function EditUserGroup({ access }) {
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const { fetchUserGroup, fetchRoles } = useApi();
  const [ isLoadingRoles, setIsLoadingRoles ] = useState(true);
  const [ isLoadingUserGroup, setIsLoadingUserGroup ] = useState(true);

  const [ userGroup, setUserGroup ] = useState(null);
  const [ allRoles, setAllRoles ] = useState(null)

  
  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchUserGroup(source, id).then(res => {
      setUserGroup(res.data);
      setIsLoadingUserGroup(false)
    })
    fetchRoles(source).then(res => {
      setAllRoles(res.data)
      setIsLoadingRoles(false)
    })
    return () => {
      source.cancel();
    }; 
  }, [])  
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigateBack(`/user-groups/`) }/>
      </>
    )
  }

  const isLoading = isLoadingRoles || isLoadingUserGroup

  
  return (
    <PageWrapper title={'Edit userGroup'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <EditUserGroupForm userGroup={userGroup} allRoles={allRoles} setUserGroup={setUserGroup} />
      </Loader>
    </PageWrapper>
  )
}

export default EditUserGroup
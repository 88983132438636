import React, { useState } from 'react'
import DropdownMenuDots from '../../components/menus/DropdownMenuDots'
import { CalendarIcon, ClipboardIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs'
import { QuestionMarkCircleIcon, ClockIcon, ChatAlt2Icon, ThumbDownIcon, ThumbUpIcon, BadgeCheckIcon, UserRemoveIcon, ArchiveIcon } from '@heroicons/react/outline'
import { applicationStatusIDs } from '../../helpers/enum/applicationStatuses'
import ApplicationHistoryModal from './ApplicationHistoryModal'
import { applicationStatuses } from '../../helpers/enum/applicationStatuses'
import utc from 'dayjs/plugin/utc'
import { Link } from "react-router-dom";


function ApplicationItem({ application, applicationHistory }) {

  dayjs.extend(utc)
  const [showApplicationHistoryModal, setShowApplicationHistoryModal] = useState(false);

  const iconSelector = (status) => {
    switch(status) {
      case applicationStatusIDs.proposed:
        return (
          <QuestionMarkCircleIcon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.submitted:
        return (
          <ClockIcon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.interviewing:
        return (
          <ChatAlt2Icon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.clientDeclined:
        return (
          <ThumbDownIcon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.clientAccepted:
        return (
          <ThumbUpIcon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.placed:
        return (
          <BadgeCheckIcon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.candidateRefused:
        return (
          <ThumbDownIcon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.candidateWithdrew:
        return (
          <UserRemoveIcon className= "mr-1.5 h-4 w-4"/>
        )
      case applicationStatusIDs.notInConsideration:
        return (
          <ArchiveIcon className= "mr-1.5 h-4 w-4"/>
        )
    }
  }


  return (
    <li>
      <ApplicationHistoryModal show={showApplicationHistoryModal} hide={() => setShowApplicationHistoryModal(false)} applicationHistory={applicationHistory} />
      <div className={`block py-3`}>
          <div className="flex items-center -my-1 justify-between">
            <Link to={`/job-orders/${application.job_order_id}`} className="group flex items-center text-sm text-gray-500 sm:mt-0">
              <ClipboardIcon className="group-hover:text-thaleria-orange-700 group-hover:font-bold mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
              <p className="truncate overflow-hidden group-hover:text-thaleria-orange-700 group-hover:font-bold">{application.job_order.title}</p>
            </Link>
          </div>
          <div className="mt-3 flex justify-between shrink items-center">
            <div onClick={() => setShowApplicationHistoryModal(true)} className="group hover:text-thaleria-orange-700 hover:cursor-pointer hover:font-bold flex items-center text-sm text-gray-400 sm:mt-0">
              {iconSelector(application?.status)}
              <p  className="truncate overflow-hidden group-hover:text-thaleria-orange-700 text-gray-500">{applicationStatuses[application?.status]}</p>
            </div>
            <div className="flex items-center text-sm text-gray-500">
              <CalendarIcon className="mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
              <p>
                <time dateTime={application.closeDate}>{dayjs.utc(application.created_at).format('DD/MM/YY')}</time>
              </p>
            </div>
          </div>
        </div>
    </li>
  )
}

export default ApplicationItem
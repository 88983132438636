import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import UsersTable from '../../containers/users/UsersTable'
import useApi from '../../store/api/apiContext'
import role from '../../helpers/enum/role'

function Users({ access }) {
  const [ users, setUsers ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchUsers } = useApi();
  
  const fetchUsersFromApi = async (source) => {
    fetchUsers(source).then(res => {
      setUsers(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchUsersFromApi(source);
    return () => {
      source.cancel();
    };
  }, [])  
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton access={role.f_users.f_2_3_create_users} text='Add user' linkTo='/users/add'/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Users'} topSection={renderTopSection()} access={access} >
      <UsersTable users={users} isLoading={isLoading} />
    </PageWrapper>
  )
}

export default Users
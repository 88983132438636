import React, { useState } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import JobOrdersTable from '../../containers/jobOrders/JobOrdersTable'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'
import BatchUpdatePriorityModal from '../../containers/jobOrders/BatchUpdatePriorityModal'
import BatchUpdateStatusModal from '../../containers/jobOrders/BatchUpdateStatusModal'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import useJobOrders from '../../store/jobOrders/jobOrdersContext'
import SecondaryButton from '../../components/buttons/SecondaryButton'
function JobOrders({ access }) {

  const { jobOrdersState: { allJobOrders: jobOrders, isLoading } } = useJobOrders();

  
  const { verifyBoolAccess, verifyArrayAccess, role } = useUserRoleVerifier();
  
  const [ selectedRows, setSelectedRows ] = useState([]);

  const [ showBatchUpdateStatusModal, setShowBatchUpdateStatusModal] = useState(false);
  const [ showBatchUpdatePriorityModal, setShowBatchUpdatePriorityModal] = useState(false);

  const [showStatusLog, setShowStatusLog] = useState(false)

  let showBatchActions = false;

  const renderTopSection = () => {
    let showEditStatus = false
    if (verifyBoolAccess(role.h_job_orders.h_3_6_edit_job_order)) showEditStatus = true
    
    let showEditPriority = false
    if (verifyBoolAccess(role.h_job_orders.h_3_6_edit_job_order) && verifyArrayAccess(role.h_job_orders.h_3_6_1_editable_properties, 'priority')) showEditPriority  = true

    if ( showEditStatus || showEditPriority ) showBatchActions = true;

    const items = [
      [
        {
          text: 'Edit status',
          onClick: () => setShowBatchUpdateStatusModal(true),
          show: showEditStatus
        },
        {
          text: 'Edit priority',
          onClick: () => setShowBatchUpdatePriorityModal(true),
          show: showEditPriority
        },
      ],
    ]

    return (
      <>
        <DropdownMenuButton buttonText='Batch actions' items={items} hide={!selectedRows?.length} access={showBatchActions}/>
        <SecondaryButton text={"View status log"} linkTo='/job-orders/status-log'/>
        <PrimaryButton access={role.h_job_orders.h_2_create_job_orders} text='Add job order' linkTo='/job-orders/add'/>
      </>
    )
  }

  return (
    <PageWrapper title={'Job orders'} topSection={renderTopSection()} access={access}>
      <BatchUpdatePriorityModal selectedRows={selectedRows} setSelectedRows={setSelectedRows} show={showBatchUpdatePriorityModal} hide={() => setShowBatchUpdatePriorityModal(false)}/>
      <BatchUpdateStatusModal selectedRows={selectedRows} setSelectedRows={setSelectedRows} show={showBatchUpdateStatusModal} hide={() => setShowBatchUpdateStatusModal(false)}/>
      <JobOrdersTable jobOrders={jobOrders} isLoading={isLoading} selectedRows={selectedRows} setSelectedRows={setSelectedRows} rowCheckbox={showBatchActions}/>
    </PageWrapper>
  )
}

export default JobOrders
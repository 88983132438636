import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../store/auth/authContext';
import Logo from '../../components/app/Logo';
import useWindowSize from '../../hooks/useWindowSize';
import useApi from '../../store/api/apiContext';
import * as Yup from 'yup';

function ResetPasswordForm() {

  const navigate = useNavigate();
  const location = useLocation();
  const { updatePassword } = useApi();
  const [errors, setErrors] = useState([])

  const windowSize = useWindowSize();

  const isSmallScreen = windowSize.width <= windowSize.tailwind.sm;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[?._!@#$%^&*]/, "Password must contain at least one symbol")
      .required("This field is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Your passwords do not match.")
      .required("This field is required."),
  });

  async function handleSubmit(event) {
    event.preventDefault();
    
    const formData = new FormData(event.currentTarget);
    const password = formData.get('password');
    const confirmPassword = formData.get('confirmPassword');
    
    //check form inputs
    let errors = [];
    await validationSchema
      .validate(
        { password, confirmPassword },
        { strict: true, abortEarly: false }
      )
      .catch((err) => {
        err.inner.forEach((_err) => {
          errors.push({
            field: _err.path,
            msg: _err.errors[0],
          });
        });
      });
    
    if (errors.length) {
      return setErrors(errors)
    }
  

    updatePassword({
      newPassword: password,
      confirmNewPassword: confirmPassword,
    }).then(() => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page
      const from = location.state?.from?.pathname || "/dashboard";
      navigate(from, { replace: true });
    });
  }

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        {isSmallScreen ? <Logo white size="md" /> : <Logo white size="xl" />}
        <h2 className="mt-6 text-3xl font-extrabold text-white">
          Reset your password
        </h2>
        <p className="mb-6 text-white text-sm">
          Password must be minimum 8 characters, include upper and lower case
          letters, a number and a special character.
        </p>
      </div>
      <form method="post" onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-white"
          >
            Password
          </label>
          <div className="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              required
              className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 sm:text-sm"
            />
          </div>
          {errors.find((e) => e.field === "password") && (
            <ul>
              {errors
                .filter((e) => e.field === "password")
                .map((e) => (
                  <li>
                    <span className="text-sm text-red-500">{e.msg}</span>
                  </li>
                ))}
            </ul>
          )}
        </div>
        <div className="mt-4">
          <label
            htmlFor="confirmPassword"
            className="block text-sm font-medium text-white"
          >
            Confirm password
          </label>
          <div className="mt-1">
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              required
              className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 sm:text-sm"
            />
          </div>
          {errors.find((e) => e.field === "confirmPassword") && (
            <ul>
              {errors
                .filter((e) => e.field === "confirmPassword")
                .map((e) => (
                  <li>
                    <span className="text-sm text-red-500">{e.msg}</span>
                  </li>
                ))}
            </ul>
          )}
        </div>
        <button
          type="submit"
          className="w-full mt-6 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-thaleria-orange-700 hover:bg-thaleria-orange-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-orange-500"
        >
          Reset password
        </button>
      </form>
    </div>
  );
}

export default ResetPasswordForm
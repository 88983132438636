import React from 'react'

function ContainerCard({title, description, children, noYPadding, noXPadding, noBorder, button, className}) {
  return (
    <div className={`${className || ''} bg-white shadow overflow-hidden rounded-lg`}>
      <div className="px-4 h-16 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
        </div>
        <div>
          { button }
        </div>
      </div>
      <div className={`${noBorder ? '' : 'border-t border-gray-200'} ${noXPadding ? '' : 'px-4 sm:px-6'} ${noYPadding ? '' : 'py-5'} `}>
        {children}
      </div>
    </div>
  )
}

export default ContainerCard
import { InformationCircleIcon } from '@heroicons/react/solid'
import React from 'react'

function InfoTooltip({ children, iconClassName }) {
  return (
    <div className='group relative'>
      <InformationCircleIcon className={`${iconClassName ? iconClassName : 'h-4 w-4 text-gray-400'} cursor-pointer`}/>
      <div className='hidden group-hover:block absolute shadow-lg text-sm p-2 bg-gray-400 text-white translate-x-6 -translate-y-10 rounded-md '>
        {children}
      </div>
    </div>
  )
}

export default InfoTooltip
import React from 'react'
import ReactSelect from '../../../components/inputs/ReactSelect'

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const selectedOptions = React.useMemo(() => {
    if (!Array.isArray(filterValue)) {
      if (filterValue === undefined || filterValue === null) return null;
      return ({label: filterValue, value: filterValue})
    }
    return (filterValue.map(obj => ({label: obj, value: obj})))
  }, [filterValue])
  const options = React.useMemo(() => {
    const options = []
    preFilteredRows.forEach(row => {
      if (options.find(item => item.value === row.values[id])) {
        return;
      } else {
        return options.push({
          value: row.values[id],
          label: row.values[id],
        })
      }
    })
    return [...options]
  }, [id, preFilteredRows])

  return (
    <ReactSelect
      label={Header}
      isClearable
      value={selectedOptions}
      isMulti
      onChange={e => {
        setFilter(e.map(e => e.value || undefined))
      }}
      options={options}
    />
  )
}

export default SelectColumnFilter

import React, { useEffect } from 'react';
import FormWithSideTitle from '../../../components/forms/FormWithSideTitle';
import Toggle from '../../../components/inputs/Toggle';

function AttachmentsActions({ newRole, setNewRole }) {
  
  useEffect(() => {
    if (!newRole.h_1_view_job_orders && newRole.j_1_view_job_order_attachments) setNewRole(prev => ({...prev, i_1_view_job_order_attachments: false }))
    if (!newRole.e_manage_clients && newRole.i_3_view_client_attachments) setNewRole(prev => ({...prev, i_3_view_client_attachments: false }))
  }, [newRole])

  return (
    <FormWithSideTitle title='Attachments actions' description='Set which attachments actions your role will enable' >
      <div className="col-span-6">
        <Toggle 
          bold 
          label='View job order attachments' 
          description={'Allows to view and post job order attachments'} 
          value={newRole.j_1_view_job_order_attachments} 
          disabled={!newRole.h_1_view_job_orders}
          onChange={() => setNewRole(prev => ({...prev, j_1_view_job_order_attachments: !newRole.j_1_view_job_order_attachments}))}
        />
      </div>
      <div className="col-span-6">
        <Toggle 
          bold 
          label='View candidate attachments' 
          description={'Allows to view and post candidate attachments'} 
          value={newRole.j_2_view_candidate_attachments} 
          onChange={() => setNewRole(prev => ({...prev, j_2_view_candidate_attachments: !newRole.j_2_view_candidate_attachments}))}
        />
      </div>
      <div className="col-span-6">
        <Toggle 
          bold 
          label='Manage attachments' 
          description={'Allows to edit and delete third party attachments'} 
          value={newRole.j_3_manage_attachments} 
          onChange={() => setNewRole(prev => ({...prev, j_3_manage_attachments: !newRole.j_3_manage_attachments}))}
        />
      </div>
    </FormWithSideTitle>
  )
}

export default AttachmentsActions
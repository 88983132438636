import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import RoleDetails from '../../containers/roles/RoleDetails'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import { ChevronRightIcon } from '@heroicons/react/solid'
import TitleSection from '../../components/app/TitleSection'
import { ROOT_ROLE_UUID } from '../../config'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import WarningModal from '../../components/modal/WarningModal'
import DropdownMenuButton from '../../components/menus/DropdownMenuButton'

function RoleDetailsPage({ access }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { fetchRole, deleteRole } = useApi();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ showWarningModal, setShowWarningModal ] = useState(false)

  const [ role, setRole ] = useState({ });

  const { showSuccessNotification } = useAppNotification()

  useEffect(() => {
    fetchRole(id).then(res => {
      setRole(res.data);
      setIsLoading(false);
    })
  }, [])  

  const items = [
    [
      {
        text: 'Edit role',
        linkTo: `/roles/edit/${id}`,
        show: true
      },
      {
        text: 'Delete role',
        onClick: () => setShowWarningModal(true),
        show: true
      }
    ]
  ]

  const confirmDelete = async () => {
    setShowWarningModal(false)
    deleteRole(id).then(res => {
      showSuccessNotification('Role was correctly deleted')
      navigate('/roles')
    })
  }
  
  const renderTopSection = () => {
    return (
      <>
        <DropdownMenuButton buttonText="More actions" items={items} access={id != ROOT_ROLE_UUID}/>
        <PrimaryButton text='Back' onClick={() => navigate(-1) }/>
        <WarningModal title="Delete user group?" showWarning={showWarningModal} message="Unable to delete roles if still associated to users or user groups. Please make sure to remove it before confirming." buttonText={'Confirm delete'} onClick1={() => confirmDelete()} onClick2={() => setShowWarningModal(false)}/>
      </>
    )
  }

  const renderTitle = () => {
    return (
      <TitleSection title='Roles' tableUrl='/roles' resource={role?.a_name}/>
    )
  }

  return (
    <PageWrapper title={renderTitle()} tabTitle='Role details' topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <RoleDetails role={role}/>
      </Loader>
    </PageWrapper>
  )
}

export default RoleDetailsPage
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import useApi from '../../store/api/apiContext'
import EditUserForm from '../../containers/users/EditUserForm'
import Loader from '../../components/loaders/Loader';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import useAuth from '../../store/auth/authContext';
import useNavigateBack from '../../hooks/useNavigateBack';

function EditUser({ access }) {
  const navigateBack = useNavigateBack();
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth: { userRole } } = useAuth();

  const [ user, setUser ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const { verifyUserIsWithinScope, role, verifyBoolAccess} = useUserRoleVerifier();

  const { fetchUser } = useApi();
  const fetchUserFromApi = async (id) => {
    if (!Object.keys(userRole).length) return
    fetchUser(null, id).then(res => {
      if (!verifyUserIsWithinScope(res.data.user_group_id, res.data.role_id) || !verifyBoolAccess(role.f_users.f_2_4_edit_users)) return navigate(-1)
      setUser(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    fetchUserFromApi(id);
  }, [id, userRole])
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigateBack(`/users`) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Edit user'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <EditUserForm user={user}/>
      </Loader>
    </PageWrapper>
  )
}

export default EditUser
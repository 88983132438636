import React from 'react'
import DropdownMenuDots from '../menus/DropdownMenuDots'
import { CalendarIcon, ClipboardIcon } from '@heroicons/react/outline'
import dayjs from 'dayjs'
import role from '../../helpers/enum/role'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import utc from 'dayjs/plugin/utc'
import { Link } from 'react-router-dom'


function CandidateItem({application, removeCurrentlyPlaced}) {

  const {verifyBoolAccess} = useUserRoleVerifier();

  dayjs.extend(utc)

  let showRemoveFromPlaced = false;
  let showViewCandidate= false;

  if (verifyBoolAccess(role.h_job_orders.h_3_1_update_job_order_status && application.candidate.is_within_scope)) showRemoveFromPlaced = true;
  if (application.candidate.is_within_scope) showViewCandidate = true

  let candidateName = application?.candidate?.first_names + ' ' + application?.candidate?.last_names
  if (!application?.candidate?.is_within_scope) candidateName = application?.candidate?.first_names

  let clientName = application.job_order?.client?.name ? application.job_order?.client?.name : null

  return (
    <li>
      <div className={`block py-3`}>
          <div className="flex items-center -my-1 justify-between">
            <div className='flex items-center'>
              {application.candidate.is_within_scope ? <Link to={`/candidates/${application.applicant_id}`} role="Handle" className={`text-sm font-medium hover:text-thaleria-orange-700 hover:cursor-pointer hover:font-bold truncate rounded pl-0.5 py-1 `}>{candidateName || 'Candidate'}</Link> : 
              <span className={`text-sm font-medium truncate rounded pl-0.5 py-1 `}>{candidateName || 'Candidate'}</span>}
              <span className='font-semibold ml-1 text-sm text-gray-500'>- {clientName || 'Client'}</span>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between shrink">
            <div className="sm:flex items-center text-sm text-gray-500 sm:mt-0 xl:w-[175px] 2xl:w-[200px] group hover:cursor-pointer">
              <ClipboardIcon className="mr-1.5 h-4 w-4 text-gray-400 group-hover:text-thaleria-orange-700" aria-hidden="true" />
              <Link to={`/job-orders/${application.job_order_id}`} className="truncate overflow-hidden group-hover:text-thaleria-orange-700 group-hover:font-bold">{application.job_order.title}</Link>
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <CalendarIcon className="mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
              <p>
                <time dateTime={application.closeDate}>{dayjs.utc(application.created_at).format('DD/MM/YY')}</time>
              </p>
            </div>
          </div>
        </div>
    </li>
  )
}

export default CandidateItem
const notificationSettingsStrings = {
  0: "Disabled",
  1: "Enabled"
}

const notificationSettingsStatus = {
  disabled: 0,
  enabled: 1
}

const notificationSettingsBoolean = {
  0: false,
  1: true
}

export {notificationSettingsStrings, notificationSettingsStatus, notificationSettingsBoolean}
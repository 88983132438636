import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import SliderInput from '../../components/inputs/SliderInput';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import WhiteButton from '../../components/buttons/WhiteButton';
import { getCroppedImg } from '../../helpers/getCroppedImg.js';

const ModalCropImage = ({
  image,
  show,
  hide,
  setCroppedImage,
  aspect
}) => {
  const showComponents = show ? 'fixed' : 'hidden';

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);

      let newImage = new File([croppedImage], 'profile_picture', {
        type: croppedImage.type,
      });

      setCroppedImage(newImage);
      hide()
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <div className={`${showComponents} fixed inset-0 z-50 `}>
      <div className="flex items-end justify-center h-3/4  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>

        <div className="inline-block align-bottom bg-white rounded-lg px-5 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-2/5 h-full ">
          <h2 className="pb-10">Crop image</h2>
          <div className="space-y-4 h-5/6 ">
            <div className="flex flex-col justify-between  h-full space-y-4 ">
              <div className="relative h-5/6">
                <Cropper
                  image={image}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={aspect || 2 / 2}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className="flex flex-row space-x-4">
                <SliderInput 
                  label="Zoom"
                  step={0.1}
                  min={1}
                  max={3}
                  value={zoom}
                  defaultValue={0}
                  onChange={e => setZoom(e.target.value)}
                />
                <SliderInput  
                  label="Rotation"
                  min={0}
                  value={rotation}
                  max={360}
                  defaultValue={0}
                  onChange={e => setRotation(e.target.value)}
                />
              </div>
              <div className="flex justify-end space-x-2 ">
                <WhiteButton text="Cancel" onClick={hide} />
                <PrimaryButton darkRing text="Upload" onClick={showCroppedImage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default ModalCropImage;

import React, { useState, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import DateInput from '../../components/inputs/DateInput/DateInput'
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import useApi from '../../store/api/apiContext';


function AddFrameworkContractForm() {
  const navigate = useNavigate();


  const { addFrameworkContract } = useApi();
  const { showAppNotification } = useAppNotification();
  const [ newFrameworkContract, setNewFrameworkContract ] = useState({
    description: null,
    contract_ref: null,
    contract_start: null,
    contract_end: null
  })
  const [formError, setFormError] = useState([])
  const contractInputRef = useRef(null);
  
  const postAddFrameworkContract = async () => {
    let errors = []
    
    if (!newFrameworkContract.contract_ref) errors.push({
      field: "Contract reference*",
      msg: "This field is required"
    })

    if (newFrameworkContract.contract_end != null && newFrameworkContract.contract_start != null && newFrameworkContract.contract_end < newFrameworkContract.contract_start) errors.push({
      field: "Contract start",
      msg: "Please make sure the start and end dates are consistent"
    })

    if (errors.length) {
      contractInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }

    setFormError([]);
    
    addFrameworkContract(newFrameworkContract).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Framework contract added successfully');
      navigate('/fw-contracts', { replace: true })
    })
    
  }  

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Information' description='Contract reference and description' >
        <div ref={contractInputRef} className="col-span-6 sm:col-span-3">
          <TextInput label='Contract reference*' errors={formError} value={newFrameworkContract.contract_ref} onChange={(e) => setNewFrameworkContract(prev => ({...prev, contract_ref: e.target.value }) )}/>
        </div>
        <div className="col-span-6">
          <TextInput label='Description' errors={formError} value={newFrameworkContract.description} onChange={(e) => setNewFrameworkContract(prev => ({...prev, description: e.target.value }) )}/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title='Contract duration' description='Please define contract start and end dates'>
        <div className="col-span-6">
          <DateInput label="Contract start" errors={formError} selected={newFrameworkContract.contract_start && new Date(newFrameworkContract.contract_start)} onChange={e => setNewFrameworkContract(prev => ({ ...prev, contract_start: e}))} />
        </div>
        <div className="col-span-6">
          <DateInput label="Contract end" errors={formError} selected={newFrameworkContract.contract_end && new Date(newFrameworkContract.contract_end)} onChange={e => setNewFrameworkContract(prev => ({ ...prev, contract_end: e}))} />
        </div>
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigate(-1) }/>
        <PrimaryButton text='Add' darkRing onClick={postAddFrameworkContract}/>
      </div>
    </div>
  )
}

export default AddFrameworkContractForm
import React from 'react'
import ProfilePicture from '../profilePicture/ProfilePicture'
import TextArea from '../inputs/TextArea'
import PrimaryButton from '../buttons/PrimaryButton'

function AddComment ({user, onChange, onClick, newComment }) {

  return (
    <div className="bg-gray-50">
      <div className="flex space-x-3 p-4">
        <div className="flex-shrink-0">
          <ProfilePicture user={user} hover={false} small/>
        </div>
        <div className="min-w-0 flex-1">
          <div>
            <TextArea placeholder="Write comment..." id="addComment" onChange={(e) => onChange(e)}/>
          </div>
          <div className="mt-3 flex items-center justify-end">
            <PrimaryButton darkRing text="Send" disabled={!newComment} onClick={onClick}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddComment
import { Switch } from '@headlessui/react'
import classNames from '../../utils/classNames'

export default function Toggle({ value, onChange, label, description, disabled, bold }) {
  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label as="span" className={`${bold ? "text-base font-medium text-gray-900" : "text-sm" }  ${disabled ? 'text-gray-400' : 'font-medium text-gray-700'}`} passive>
          { label }
        </Switch.Label>
        <Switch.Description as="span" className={`text-sm ${disabled ? 'text-gray-400' : 'text-gray-500'}`}>
          { description }
        </Switch.Description>
      </span>
      <Switch 
        checked={value}
        onChange={disabled ? () => {} : onChange}
        className={classNames(
          value && !disabled? 'bg-thaleria-orange-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full  transition-colors ease-in-out duration-200 focus:outline-none ',
          disabled ? 'cursor-default bg-gray-500' : 'focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-orange-600 cursor-pointer'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}
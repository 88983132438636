import React, { useState, useEffect } from 'react';
import useAuth from '../../store/auth/authContext';
import { Link } from 'react-router-dom';
/* This example requires Tailwind CSS v2.0+ */
function WhiteButton({ size, text, onClick, icon, access, rounded, fullWidth, disabled, linkTo }) {
  const { auth } = useAuth();
  const role = auth.userRole;
  
  let sizeStyle;
  let _minWidth;
  switch (size) {
    case 'xs':
     sizeStyle = 'px-1.5 py-1.5 text-xs rounded'
     _minWidth = '70px'
     break;
    case 'sm':
     sizeStyle = 'px-3 py-2 text-sm leading-4 rounded-md'
     _minWidth = '85px'
     break;
    case 'md':
     sizeStyle = 'px-4 py-2 text-sm rounded-md '
     _minWidth = '100px'
     break;
    case 'lg':
     sizeStyle = 'px-4 py-2 text-base rounded-md '
     _minWidth = '100px'
     break;
    case 'xl':
     sizeStyle = 'px-6 py-3 text-base rounded-md'
     _minWidth = '100px'
     break;
    default:
      break;
  }

  const [ showButton, setShowButton ] = useState(false);

  const isArray = Array.isArray(access);
  const isBool = typeof access === 'boolean';
  const isString = typeof access === 'string';

  useEffect(() => {
    if (access === undefined) return setShowButton(true);
    //block access if no property allows user to access (array 'OR' logic)
    if (isArray) {
      let check = false
      access.forEach(accessProperty => {
        if (role[accessProperty]) check = true;
      })
      if (check) setShowButton(true);  
    } 
    if (isString) {
      if (role[access]) setShowButton(true);
    }
    if (isBool) {
      setShowButton(access)
    }
    
  }, [ access, role ])



  const baseStyle = `${!showButton && 'hidden'} ${rounded && 'rounded-full'}  inline-flex justify-around items-center border border-gray-300 shadow-sm font-medium text-gray-700 bg-white ${!disabled && "hover:bg-gray-100"} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-blue-500`
  
  const BASE_STYLE = {
    minWidth: fullWidth ? '100%' : _minWidth
  }
  
  return (
    linkTo ?
    <Link
    to={linkTo}
    className={`${baseStyle} ${sizeStyle}`}
    style={BASE_STYLE}
    disabled={disabled}
    >
      {icon}
      {text}
    </Link>
    :
    <button
      type="button"
      className={`${baseStyle} ${sizeStyle}`}
      style={BASE_STYLE}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {text}
    </button>
  )
}


WhiteButton.defaultProps = {
  size: 'md',
  access: undefined,
};

export default WhiteButton;
import React, { useEffect, useState, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import useApi from '../../store/api/apiContext';
import monthNames from '../../helpers/monthNames'
import dayjs from 'dayjs'
import Loader from '../../components/loaders/Loader';
import utc from 'dayjs/plugin/utc'
import DateInput from '../../components/inputs/DateInput/DateInputForGraph';
import {addMonths, endOfDay, startOfDay, startOfMonth, subMonths} from 'date-fns'
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import useWindowSize from '../../hooks/useWindowSize'


export default function Graphs () {

  dayjs.extend(utc)
  const size = useWindowSize();

  const { fetchApplicationsByDate } = useApi()

  const titleRef = useRef();

  const [ show, setShow ] = useState({
    last3Months: true,
    yearToDate: false,
    custom: false
  })
  const [ last3MonthsData, setLast3MonthsData ] = useState([])
  const [ yearToDateData, setYearToDateData ] = useState([])
  const [ customData, setCustomData ] = useState([])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ customFromDate, setCustomFromDate ] = useState()
  const [ customToDate, setCustomToDate ] = useState()
  const [ dataToShow, setDataToShow ] = useState()
  const [ currentDates, setCurrentDates ] = useState();
  const [titleColor, setTitleColor] = useState('text-gray-900')
  const [datesInvalid, setDatesInvalid] = useState()


  const arrangeData = (applications, fromDate, toDate) => {
    let data= [];

      let _startLoop = startOfMonth(new Date(fromDate))
      let _endLoop = startOfMonth(new Date(toDate))

      while (_startLoop <= _endLoop) {
        data.push({
          name: monthNames[_startLoop.getMonth()],
          year: _startLoop.getFullYear(),
          2: 0,
          3: 0,
          5: 0,
          6: 0
        })
        _startLoop = addMonths(_startLoop, 1)
      }

      applications.forEach(application => {
        if (application.status == 2 || application.status == 3 || application.status ==  5 || application.status ==  6) {
          
          let year = new Date(application.created_at).getFullYear()
          let month = new Date(application.created_at).getMonth()
          let index = data.findIndex(obj => (obj.name == monthNames[month]) && (obj.year == year))
          let targetObject = data[index]
          targetObject[application.status]+=1
        }
      })

      return data;
  }

  useEffect(() => {
    const today = new Date();
    //3 month graph

    if (!last3MonthsData.length && show.last3Months) {
      const threeMonthsPrior = startOfMonth(subMonths(today, 2))

       //removed UTC from these dates 
      let fromDate = dayjs(startOfDay(threeMonthsPrior)).format('YYYY-MM-DD,HH:mm:ss').toString();
      let toDate = dayjs(endOfDay(today)).format('YYYY-MM-DD,HH:mm:ss').toString();
      setIsLoading(true)
      fetchApplicationsByDate(fromDate, toDate).then(res => {
        let applications = res.data;
        let data = arrangeData(applications, threeMonthsPrior, today);
        setLast3MonthsData(data);
        setDataToShow(data);
        setIsLoading(false);
      })
    } else if (show.last3Months && last3MonthsData.length) {
      setDataToShow(last3MonthsData)
    }

    if (!yearToDateData.length && show.yearToDate) {
      const firstDayOfTheYear = new Date('Jan 01,' + today.getFullYear())
      let fromDate = dayjs(startOfDay(firstDayOfTheYear)).format('YYYY-MM-DD,HH:mm:ss').toString()
      let toDate = dayjs.utc(endOfDay(today)).format('YYYY-MM-DD,HH:mm:ss').toString()
      setIsLoading(true)
      fetchApplicationsByDate(fromDate, toDate).then(res => {
        let applications = res.data
        let data = arrangeData(applications, firstDayOfTheYear, today)
        setYearToDateData(data);
        setDataToShow(data);
        setIsLoading(false);    
      })
    } else if (show.yearToDate && yearToDateData.length) {
      setDataToShow(yearToDateData)
    }

    if (show.custom && customToDate && customFromDate) {
      if (customToDate < customFromDate) setDatesInvalid(true)
      if (customToDate > customFromDate) setDatesInvalid(false)
      let fromDate = dayjs(startOfDay(customFromDate)).format('YYYY-MM-DD,HH:mm:ss').toString()
      let toDate = dayjs(endOfDay(customToDate)).format('YYYY-MM-DD,HH:mm:ss').toString()
      if ((customFromDate != currentDates?.from || customToDate != currentDates?.to)) {
        setIsLoading(true)
        fetchApplicationsByDate(fromDate, toDate).then(res => {
          let applications = res.data 
          let data = arrangeData(applications, customFromDate, customToDate)
          setCustomData(data)
          setDataToShow(data)
          setIsLoading(false)
          setCurrentDates({
            from: customFromDate,
            to: customToDate
          })
        })
      } else {
        setDataToShow(customData)
      }
    }
  }, [show, customToDate, customFromDate])

  useEffect(() => {
    let obj = document.getElementById("main-section");
    obj.addEventListener("scroll", handleScroll);
  }, [])

  const handleScroll = () => {
    function getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    }
    const position = getOffset(titleRef.current)
    
    if (position.top < 280) {
      setTitleColor('text-white');
    }
    if (position.top >= 280 ) {
      setTitleColor('text-gray-900');
    }
  }


  class CustomizedLabel extends React.Component{
    render () {
      const {x, y, fill, value} = this.props;
       return (
        <text 
          x={x+13} 
          y={y+30} 
          dy={-4} 
          fontSize='16' 
          fontFamily='sans-serif'
          fill={fill}
        >
          {!value ? '' : value}
        </text>
       )
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white rounded-md py-3 shadow-lg">
          <p className="label pb-2 border-b-2 border-gray-200 px-3">{`${label}`}</p>
          <ul className="mt-2">
            {payload.map(el => <li className="text-gray-500 px-4 py-1">{el.name} : {el.value}</li>)}
          </ul>
        </div>
      );
    }
  
    return null;
  };

    return (
      <div className="w-auto flex flex-col rounded-md mt-6 p-4">
        <div className="flex flex-col md:flex-row md:justify-between mb-5">
          <h3
            className={`text-2xl font-medium ${titleColor} text-gray-900 my-0 flex`}
            ref={titleRef}
          >
            Past applications
          </h3>
          <div className="mt-2 md:mt-0 relative z-0 inline-flex shadow-sm rounded-md">
            <button
              onClick={() =>
                setShow({ last3Months: false, yearToDate: false, custom: true })
              }
              type="button"
              className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
                show.custom ? "text-thaleria-orange-700" : "text-gray-700"
              } hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-thaleria-orange-700 focus:border-thaleria-orange-700`}
            >
              Custom
            </button>
            <button
              onClick={() =>
                setShow({ last3Months: false, yearToDate: true, custom: false })
              }
              type="button"
              className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${
                show.yearToDate ? "text-thaleria-orange-700" : "text-gray-700"
              } hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-thaleria-orange-700 focus:border-thaleria-orange-700`}
            >
              Year to date
            </button>
            <button
              onClick={() =>
                setShow({ last3Months: true, yearToDate: false, custom: false })
              }
              type="button"
              className={`-mr-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
                show.last3Months ? "text-thaleria-orange-700" : "text-gray-700"
              } hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-thaleria-orange-700 focus:border-thaleria-orange-700`}
            >
              Last 3 months
            </button>
          </div>
        </div>
        <Loader isLoading={isLoading}>
          <div className="h-auto w-auto relative">
            <div
              className={`bg-white h-96 w-full rounded-xl py-6  ${
                show.custom && "pb-10 md:pb-6"
              }`}
            >
              <div className="flex flex-col lg:absolute top-0 left-0.5 lg:top-3 lg:left-7 w-full">
                {datesInvalid && (
                  <span className="flex text-red-500 items-center text-sm ml-3">
                    <ExclamationCircleIcon className="h-4 w-4 mr-1" />
                    Dates are invalid
                  </span>
                )}
                <span
                  className={`flex lg:m-2 max-w-max ml-8 ${
                    !show.custom && "hidden"
                  }`}
                >
                  <DateInput
                    label="From:"
                    selected={customFromDate && customFromDate}
                    onChange={(e) => setCustomFromDate(e)}
                  />
                  <DateInput
                    label="To:"
                    selected={customToDate && customToDate}
                    onChange={(e) => setCustomToDate(e)}
                  />
                </span>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={dataToShow}
                  margin={{
                    top: 20,
                    right: 40,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend
                    verticalAlign={
                      size.width > size.tailwind.lg ? "top" : "bottom"
                    }
                    align={size.width > size.tailwind.lg ? "right" : "center"}
                    wrapperStyle={
                      size.width > size.tailwind.lg
                        ? { top: 0, right: 30 }
                        : { bottom: 4, margin: 2 }
                    }
                    formatter={(value, entry, index) => (
                      <span className="text-gray-500">{value}</span>
                    )}
                  />
                  <Bar
                    name="Submitted"
                    dataKey={2}
                    minPointSize={3}
                    label={<CustomizedLabel />}
                    fill="#ede9fe"
                    barSize={35}
                  />
                  <Bar
                    name="Interviewing"
                    dataKey={3}
                    minPointSize={3}
                    label={<CustomizedLabel />}
                    fill="#e0f2fe"
                    barSize={35}
                  />
                  <Bar
                    name="Client accepted"
                    dataKey={5}
                    minPointSize={3}
                    label={<CustomizedLabel />}
                    fill="#fee2e2"
                    barSize={35}
                  />
                  <Bar
                    name="Placed"
                    dataKey={6}
                    minPointSize={3}
                    label={<CustomizedLabel />}
                    fill="#ffedd5"
                    barSize={35}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Loader>
      </div>
    );
  
}
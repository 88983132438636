import React, { useEffect, useState } from "react"
import CandidateItem from "../descriptionLists/CandidateItem";
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'

function StatCard ({number, icon, label, applications, removeCurrentlyPlaced, show}) {

  const [ pages, setPages ] = useState([1])
  const [ showPage, setShowPage ] = useState(1)

  const pageDown = () => {
    if (showPage == 1) {
      setShowPage(pages.length)
    } else {
      setShowPage(showPage-1)
    }
  }

  const pageUp = () => {
    if (showPage == pages.length) {
      setShowPage(1) 
    } else {
      setShowPage(showPage+1)
    }
  }

  useEffect(() => {
    const requiredPages = Math.ceil(applications.length/5)
    if (requiredPages != pages.length) {
      let array = [1]
      for (let i=2; i<requiredPages+1; i++) {
        array.push(i)
      }
      setPages(array)
    }
  }, [applications])

  return (
    <div className={`relative bg-white shadow rounded-lg overflow-hidden`}>
      <div className={`${show && "border-b-2"} pt-5 px-4 pb-3 sm:pt-6 sm:px-6`}>
        <dt>
          <div className="absolute bg-thaleria-orange-600 rounded-md p-3">
            {icon}
          </div>
          <p className="ml-16 text-sm font-medium text-gray-500 truncate">{label} candidates</p>
        </dt>
        <dd className="ml-16 pb-6 flex items-baseline sm:pb-2">
          <p className="text-2xl font-semibold text-gray-900">{number}</p>
        </dd>
      </div>
        {show && <>
          <ul className="divide-y px-4 pb-9 h-[410px]">
            {applications.slice((showPage-1)*5, showPage*5).map(obj => <CandidateItem application={obj} removeCurrentlyPlaced={removeCurrentlyPlaced} />)}
          </ul>
          <div className="absolute bottom-0 h-10 inset-x-0 bg-gray-50 px-4 py-2 sm:px-6 flex justify-between">
            <div>
              <p className="text-grey-600">Page {showPage} of {pages.length}</p>
            </div>
            {pages.length > 1 &&
            <div className="flex">
              <ChevronLeftIcon className="h-6 w-6 text-thaleria-orange-700 hover:cursor-pointer pr-0.5 hover:bg-thaleria-orange-700 hover:text-white rounded-full" onClick={pageDown}/>
              <ChevronRightIcon className="h-6 w-6 text-thaleria-orange-700 hover:cursor-pointer pl-0.5 rounded-full hover:bg-thaleria-orange-700 hover:text-white" onClick={pageUp}/>
            </div>}
          </div>
        </>}
    </div>
  )
}

export default StatCard
import React, { useEffect, useState } from 'react';
import AppNotification from '../../components/app/AppNotification';
import RequireAuth from './RequireAuth';
import usePageTitle from '../../hooks/usePageTitle';
import Navbar from '../navbar/Navbar';
import { Link } from 'react-router-dom';
import { differenceInWeeks } from 'date-fns';
import releaseNotes from '../../helpers/releaseNotes'
import PreviewBox from '../attachments/PreviewBox';
import { THALERIA_WEBSITE_URL } from '../../config';

function PageWrapper({ children, mainClass, childrenClass, mainStyle, childrenStyle, override, requireAuth, title, topSection, access, tabTitle }) {
  const defaultFont = 'text-gray-600';
  const defaultBg = 'bg-gray-100';
  const currentYear = new Date().getFullYear()
  const isNewRelease = differenceInWeeks(new Date(), new Date(releaseNotes[0].date)) <= 2;
  
  const [showToSModal, setShowToSModal] = useState(false);

  const { changeTitle } = usePageTitle();

  useEffect(() => {
    changeTitle(`ThaleriaCATS - ${tabTitle || title}` || 'ThaleriaCATS');
  }, [])

  if (override) return (
    <div className={`relative w-screen h-screen overflow-hidden ${defaultFont} ${defaultBg} ${mainClass}`} style={mainStyle}>
      {requireAuth ? <RequireAuth/> : <RequireAuth authenticate={false}/>}
      <AppNotification/>
      {children && <div className={childrenClass} style={childrenStyle}>{children}</div>}
    </div>
  );



  return (
    <div
      className={`relative w-screen flex flex-col min-h-screen overflow-x-hidden ${defaultFont} ${defaultBg}`}
    >
      
      {requireAuth ? (
        <RequireAuth access={access} />
      ) : (
        <RequireAuth authenticate={false} />
      )}
      <PreviewBox
        showPreview={showToSModal}
        handleHide={() => setShowToSModal(false)}
        fileType={'pdf'}
        filePath={'https://thaleria-files-public.s3.eu-west-2.amazonaws.com/cats/terms-of-service.pdf'}
        title="Terms of Service"
      />
      <AppNotification />
      <div className={`bg-thaleria-blue-600 h-[300px] w-screen absolute `} />
      <header className="py-2 2xl:py-4 z-10">
        <Navbar />
        <div
          className={`w-full 2xl:max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row lg:justify-between`}
        >
          <h1 className="text-3xl font-bold text-white ">{title || ""}</h1>
          {topSection && (
            <div className="flex flex-col space-y-2 mt-4 lg:flex-row lg:space-y-0 lg:space-x-2 lg:my-auto">
              {topSection}
            </div>
          )}
        </div>
      </header>
      <main
        className="no-scrollbar relative max-w-screen-2xl pb-6 px-4 sm:px-6 lg:px-8 flex flex-col mx-auto w-full"
        id="main-section"
      >
        {children}
      </main>
      <footer
        className="w-full 2xl:max-w-screen-2xl mx-auto mt-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row lg:justify-between"
        id="footer"
      >
        <div
          className={`w-full border-t border-gray-200 py-4 text-sm text-gray-500 text-center sm:flex sm:justify-between`}
        >
          <div>
            <span className="block sm:inline">
              &copy; {currentYear} Thaleria S.A.
            </span>{" "}
            <span className="block sm:inline">all rights reserved</span>
          </div>
          <div className='flex gap-x-4'>
            <div  onClick={() => setShowToSModal(!showToSModal)} className="text-gray-500 hover:text-blue-400 cursor-pointer hover:underline">
              Terms of Service
            </div>
            <a href={`${THALERIA_WEBSITE_URL}/privacy-policy`} className="text-gray-500 hover:text-blue-400 cursor-pointer hover:underline">
              Privacy policy
            </a>
            {isNewRelease && (
              <span className="border-2 text-xs rounded-3xl border-thaleria-orange-500 text-thaleria-orange-500 p-1 px-3 font-semibold mr-2 place-self-center">
                New Release
              </span>
            )}
            <Link
              to={"/release-notes"}
              className="text-gray-500 hover:text-blue-400 hover:underline"
            >
              v{releaseNotes[0].version}
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

PageWrapper.defaultProps = {
  requireAuth: true,
}

export default PageWrapper

import React, { useState, useEffect, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import axios from 'axios';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import Toggle from '../../components/inputs/Toggle';
import TextInput from '../../components/inputs/TextInput';
import NumberInput from '../../components/inputs/NumberInput'
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import ReactSelect from '../../components/inputs/ReactSelect';
import useApi from '../../store/api/apiContext';


function AddUserGroupForm() {
  const navigate = useNavigate();
  const { addUserGroup } = useApi();
  const { showAppNotification } = useAppNotification();
  const [newUserGroup, setNewUserGroup] = useState({
    name: null,
  })
  const [roles, setRoles] = useState(null);
  const [roleOptions, setRoleOptions] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [associateRoles, setAssociateRoles] = useState(false)
  const [roleIds, setRoleIds] = useState();
  const [formError, setFormError] = useState([])
  const nameInputRef = useRef(null);
  
  const postAddUserGroup = async () => {
    let errors = [];

    if (!newUserGroup.name) errors.push({
      field: "Name*",
      msg: "This field is required"
    })

    if (newUserGroup.price_margin < 0 || newUserGroup.price_margin > 100) errors.push({
      field: "Price margin (%)",
      msg: "Must be 0-100"
    })
    
    if (errors.length) {
      nameInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }    

    setFormError([]);
    
    addUserGroup(newUserGroup, roleIds).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'User group added successfully');
      navigate('/user-groups', { replace: true })
    })
  }

  const fetchRoles = (source) => {
      axios.get('/roles/all', { cancelToken: source.token }).then(res => {
      
      setRoles(res.data);
    });
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchRoles(source);
    return () => {
      source.cancel();
    };
  }, [])

  useEffect(() => {
    if (roles) {
      const temp = []
      roles.map(role => temp.push({
        value: role.id,
        label: role.a_name
      }))
      setRoleOptions(temp);
    }
  }, [roles])

  //====Add Roles====//
  const handleToggleAssociateRoles = () => {
    let newStatus = !associateRoles;
    setAssociateRoles(newStatus);
  }

  const changeHandlerAssociateRoles = event => {
    let roles;
    if (!event) roles=null;
    else roles = event.map(role => role.value);
    setRoleIds(roles)
  }

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Information' description='Add user group name and price margin' >
        <div ref={nameInputRef} className="col-span-6 sm:col-span-3">
          <TextInput label='Name*' value={newUserGroup.name} errors={formError} onChange={(e) => setNewUserGroup(prev => ({...prev, name: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <NumberInput label='Price margin (%)' min={0} max={100} value={newUserGroup.price_margin} errors={formError} onChange={(e) => setNewUserGroup(prev => ({...prev, price_margin: e.target.value}))}/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title='Roles' description='Associate roles with this user group' >
        <div className="col-span-6">
          <Toggle label='Add roles' description={'Check if you wish to associate roles to this user group'} value={associateRoles} onChange={handleToggleAssociateRoles}/>
        </div>
        {associateRoles && 
        <>
          <div className="col-span-6">
            <ReactSelect 
              isMulti
              label='View available roles' 
              options={roleOptions} 
              placeholder={'Select roles'} 
              onChange={changeHandlerAssociateRoles}
            />
          </div>
        </>
        }
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigate('/user-groups', {replace: true}) }/>
        <PrimaryButton text='Add' darkRing onClick={postAddUserGroup}/>
      </div>
    </div>
  )
}

export default AddUserGroupForm
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

export default function Modal({ show, hide, title, children, size, noPadding, backgroundColor }) {
  
  let renderSize;
  switch (size) {
    case 'sm':
      renderSize = 'max-w-md'
      break;
    case 'md':
      renderSize = 'max-w-xl'
      break;
    case 'lg':
      renderSize = 'max-w-3xl'
      break;
    case 'xl':
      renderSize = 'max-w-5xl'
      break;
    default:
      renderSize = 'max-w-md'
      break;
  }

  let bgColor;
  switch (backgroundColor) {
    case 'white':
      bgColor = 'bg-white'
      break;
    case 'dark':
      bgColor = "bg-gray-800"
      break;
    case undefined:
      bgColor = 'bg-white'
      break;
    default:
      bgColor = backgroundColor
      break;
  }
  
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10  overflow-y-auto"
        onClose={hide}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black  bg-opacity-30 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={`inline-block w-full ${renderSize} ${noPadding ? '' : 'p-6'} overflow-visible text-left align-middle transition-all transform ${bgColor} shadow-xl rounded-2xl`}>
              {title && <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              }
              <div className={`${title && 'mt-4'}`}>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

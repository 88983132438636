
import React, { useState } from 'react'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import Modal from '../../components/modal/Modal'
import ReactSelect from '../../components/inputs/ReactSelect'
import useAppNotification from '../../store/appNotification/appNotificationContext'
import useJobOrders from '../../store/jobOrders/jobOrdersContext'


function BatchUpdatePriorityModal({ show, hide, selectedRows, setSelectedRows }) {
  const [ selectedPriority, setSelectedPriority ] = useState([
    {
      value: 'Medium', label: 'Medium'
    },
  ])

  const { showSuccessNotification } = useAppNotification();

  const { batchUpdateJobOrderPriority } = useJobOrders();

  let priorityOptions = [
    {
      value: 'High', label: 'High'
    },
    {
      value: 'Medium', label: 'Medium'
    },
    {
      value: 'Low', label: 'Low'
    }
  ]

  const changeHandlerSelectPriority = (e) => {
    setSelectedPriority(e)
  }

  const handleSubmit = () => {
    let idArray = selectedRows.map(row => row.original.id)
    batchUpdateJobOrderPriority(idArray, selectedPriority.value).then(() => {
      showSuccessNotification('Job orders successfully updated');
      setSelectedRows([])
      hide();
    })
  }

  return (
    <Modal show={show} hide={hide} title={'Update priority'}>
      <div className='h-full flex flex-col justify-between'>        
        <ReactSelect 
          label='Priority' 
          selectedOption={selectedPriority} 
          options={priorityOptions} 
          onChange={changeHandlerSelectPriority}
        />
        <div className='w-full flex flex-row-reverse mt-4'>
          <PrimaryButton text={'Update'} disabled={!selectedPriority} onClick={handleSubmit}/>
        </div>
      </div>
    </Modal>
  )
}

export default BatchUpdatePriorityModal
import React from 'react'

function FormInputLabelAndDescription({ bold, disabled, label, description}) {
  return (
    <>
      <span className={`${bold ? "text-base font-medium text-gray-900" : "text-sm" }  ${disabled ? 'text-gray-400' : 'font-medium text-gray-700'}`}>
        { label }
      </span>
      <span className={`text-sm ${disabled ? 'text-gray-400' : 'text-gray-500'}`}>
        { description }
      </span>
    </>
  )
}

export default FormInputLabelAndDescription
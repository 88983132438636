
import React from 'react'
import Table from '../table/Table';
import { useNavigate } from 'react-router';
import role from '../../helpers/enum/role';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import SelectColumnFilter from '../table/filters/SelectColumnFilter';
import { ExternalLinkIcon, EyeIcon, PencilIcon } from "@heroicons/react/solid";

function ClientsTable({ clients, isLoading }) {

  const navigate = useNavigate();
  const { verifyBoolAccess } = useUserRoleVerifier();

  const data = React.useMemo(
      () => []
    , [ clients ]);
    
    if (clients.length && !data.length) {
      clients.forEach(client => {
        data.push({
          id: client.id || '-',
          name: client.name || '-',
          country: client.country || '-',
          city: client.city || '-',
          sector: client.sector || '-'
        })
      })
    }

  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      {
        Header: 'Sector',
        accessor: 'sector',
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
    ], []
  );


  const renderRowMenu = (row) => {
    let canEdit = verifyBoolAccess(role.e_manage_clients);
    let buttons = [
      [
        {
          text: "View client details",
          icon: EyeIcon,
          onClick: () => navigate(`/clients/${row.original.id}`),
        },
        {
          text: "View details in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/clients/${row.original.id}`),
        },
      ],
    ]

    if (canEdit) buttons.push([
      {
        text: "Edit client",
        icon: PencilIcon,
        onClick: () => navigate(`/clients/edit/${row.original.id}`),
        show: canEdit,
      },
      {
        text: "Edit client in new tab",
        icon: ExternalLinkIcon,
        onClick: () => window.open(`/clients/edit/${row.original.id}`),
        show: canEdit,
      },
    ],)
    return buttons;
  } 

  const rowOnClick = (row) => {
    navigate(`/clients/${row.original.id}`);
  }
  
  return (
    <>
      <Table data={data} columns={columns} isLoading={isLoading} renderRowMenu={renderRowMenu} rowOnClick={rowOnClick}/>
    </>
  )
}

export default ClientsTable

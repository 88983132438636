import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import RolesTable from '../../containers/roles/RolesTable'
import { useNavigate } from 'react-router'
import useApi from '../../store/api/apiContext'
import role from '../../helpers/enum/role'

function Roles({ access }) {
  const navigate = useNavigate();
  const [ roles, setRoles ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchRoles } = useApi();
  
  const fetchRolesFromApi = async (source) => {
    fetchRoles(source).then(res => {
      setRoles(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchRolesFromApi(source);
    return () => {
      source.cancel();
    };
  }, [])  
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Add role' linkTo='/roles/add' access={role.c_manage_roles}/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Roles'} topSection={renderTopSection()} access={access}>
      <RolesTable roles={roles} isLoading={isLoading}/>
    </PageWrapper>
  )
}

export default Roles

import React, { useEffect, useState, useRef } from 'react';
import useApi from '../../store/api/apiContext';
import axios from 'axios';
import ContainerCard from '../../components/cards/ContainerCard';
import { useParams, useLocation } from 'react-router';
import CommentCard from './CommentCard';
import AddComment from '../../components/inputs/AddComment';
import Loader from '../../components/loaders/Loader';
import useAuth from '../../store/auth/authContext';
import useAppNotification from '../../store/appNotification/appNotificationContext';
import { SocketContext } from '../../store/socket/socketProvider';

function Comments () {
  const socket = React.useContext(SocketContext);
  const { addComment, fetchComments, editComment, deleteComment } = useApi()
  const {id} = useParams();
  const { pathname } = useLocation()
  const type = pathname.split('/')[1]; 
  const { auth: {currentUser} } = useAuth();
  const { showSuccessNotification } = useAppNotification();

  const [ comments, setComments ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true)
  const [ newComment, setNewComment ] = useState(null)

  const fetchCommentsFromApi = async (id, source) => {   
    fetchComments(type, id, source).then(res => {
      setComments(res.data)
      setIsLoading(false)
    })
  }

  //FIXME: Change handler doesn't have to prepare the body structure of the request. Change handler can only update the text and store in useState. The body format for the request shuold be prepared at the level of the addCommentHandler function
  const changeCommentHandler = (e) => {
    setNewComment(e.target.value)
  }

  const addCommentHandler = async () => {
    let commentData = {
      added_by: currentUser.id,
      message: newComment,
    }

    switch (type) {
      case 'job-orders':
        var key = "job_order_id"
        break;
      case 'candidates':
        var key = "candidate_id"
        break;
      case 'clients':
        var key = "client_id"
        break;
      default:
        return
    }

    commentData[key] = id

    addComment(commentData).then(res => {
      res.data.user = {
        first_names: currentUser.first_names,
        last_names: currentUser.last_names,
        profile_pic_url: currentUser.profile_pic_url
      }
      socket.emit('new-comment', res.data)
      setComments(prev => [...prev, res.data])
      setNewComment(null)
      document.getElementById("addComment").value = ""
    })
  }

  const editCommentHandler = async (id, commentData) => {
    editComment(id, commentData).then(res => {
      showSuccessNotification('Comment edited')
      let commentToBroadcast
      let tempComments = comments.map(comment => {
        if (comment.id == res.data.id) {
          comment.message = res.data.message
          commentToBroadcast = comment
        }
        return comment
      })
      setComments(tempComments)
      socket.emit('new-comment', commentToBroadcast)
    })
  }

  const deleteCommentHandler = async (comment) => {
    deleteComment(comment.id).then(res => {
      showSuccessNotification('Comment deleted')
      socket.emit('delete-comment', comment)
      setComments(comments.filter(el => el.id != comment.id))
    })
  }

  const addSocketComment = (data) => {
    let displayComment = false
    if (type === 'job-orders' && data.job_order_id == id) displayComment = true
    if (type === 'candidates' && data.candidate_id == id) displayComment = true
    if (type === 'clients' && data.client_id == id) displayComment = true
    if (displayComment) {
      if (comments.find(comment => comment.id == data.id)) {
        setComments(prev => prev.map(el => el.id == data.id ? data : el))
      } else {
        setComments(prev => [...prev, data])
      }
    }   
  }

  const deleteSocketComment = (data) => {
    let displayComment = false
    if (type === 'job-orders' && data.job_order_id == id) displayComment = true
    if (type === 'candidates' && data.candidate_id == id) displayComment = true
    if (type === 'clients' && data.client_id == id) displayComment = true
    if (displayComment) {
      setComments(comments.filter(comment => comment.id != data.id))
    }
  }

  useEffect(() => {
    if (!socket) return;
    let source = axios.CancelToken.source();
    socket.on('receive-comment', (data) => {
      addSocketComment(data)
    })
    socket.on('deleted-comment', (data) => {
      deleteSocketComment(data)
    })
    fetchCommentsFromApi(id, source)
    return () => {
      socket.removeListener("receive-comment");
      socket.removeListener("deleted-comment");
      source.cancel();
    }
  }, [socket])

  return (
    <Loader isLoading={isLoading}>
      <ContainerCard title="Comments" noXPadding noYPadding>
        <ul role="list" className="space-y-8 p-4">
        {!comments.length ? 
        <span className="text-sm text-gray-500">No comments yet.</span>
        :
        comments.map(comment => (
          <CommentCard comment={comment} key={comment.created_at} onEdit={editCommentHandler} onDelete={deleteCommentHandler}/>
        ))}
        </ul>
        <AddComment user={currentUser} newComment={newComment} onChange={changeCommentHandler} onClick={addCommentHandler}/>
      </ContainerCard>
    </Loader>
  )

}

export default Comments
import React, { useEffect, useState, useRef } from 'react'
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router';
import useApi from '../../store/api/apiContext';
import DateInput from '../../components/inputs/DateInput/DateInput';
import extractObjectDifferences from '../../helpers/enum/extractObjectDifferences';
import useNavigateBack from '../../hooks/useNavigateBack';

function EditFrameworkContractForm({ frameworkContract }) {
  
  const navigateBack = useNavigateBack();
  const navigate = useNavigate();
  const { id } = useParams();
  const { editFrameworkContract } = useApi();
  
  const [editedFwContract, setEditedFwContract] = useState()
  const [formError, setFormError] = useState([]);
  const contractInputRef = useRef(null)

  useEffect(() => {
    setEditedFwContract(frameworkContract)
  }, [])

  const handleEditFrameworkContract = () => {
    let errors = [];
    const fwContractData = extractObjectDifferences(frameworkContract, editedFwContract)

    if (editedFwContract.contract_ref === "" || !editedFwContract.contract_ref) errors.push({
      field: "Contract reference*",
      msg: "This field cannot be empty"
    })

    if (errors.length) {
      contractInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }    

    setFormError([]);

    editFrameworkContract(id, fwContractData).then(() => {
      navigate(`/fw-contracts/${frameworkContract.id}`, {replace: true});
    })
  }  

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Framework contract details' description='Edit information of this contract' >
        <div ref={contractInputRef} className="col-span-6">
          {editedFwContract&&  <TextInput label='Contract reference*' errors={formError} value={editedFwContract.contract_ref} onChange={(e) => setEditedFwContract(prev => ({...prev, contract_ref: e.target.value }) )}/>}
        </div>    
        <div className="col-span-6">
          {editedFwContract&& <TextInput label='Description' errors={formError} value={editedFwContract.description} onChange={(e) => setEditedFwContract(prev => ({...prev, description: e.target.value }) )}/>}
        </div>    
      </FormWithSideTitle>
      <FormWithSideTitle title='Framework contract duration' description='Edit duration of this contract' >
        <div className="col-span-6">
        {editedFwContract&& <DateInput label='Contract start' errors={formError} selected={new Date(editedFwContract.contract_start)} onChange={(e) => setEditedFwContract(prev => ({...prev, contract_start: e }) )}/>}
        </div>    
        <div className="col-span-6">
        {editedFwContract&& <DateInput label='Contract end' errors={formError} selected={new Date(editedFwContract.contract_end)} onChange={(e) => setEditedFwContract(prev => ({...prev, contract_end: e }) )}/>}
        </div>    
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigateBack(`/fw-contracts`) } />
        <PrimaryButton text='Edit' darkRing onClick={handleEditFrameworkContract} />
      </div>
    </div>
  )
}

export default EditFrameworkContractForm
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useFilters,
  useSortBy,
  useColumnOrder,
} from "react-table";
import {
  SortAscendingIcon,
  SortDescendingIcon,
  AdjustmentsIcon,
  FilterIcon
} from "@heroicons/react/outline";
import SettingsModal from "./SettingsModal";
import TableControlPanel from "./TableControlPanel";
import TextColumnFilter from "./filters/TextColumnFilter";
import Loader from "../../components/loaders/Loader";
import Checkbox from "../../components/inputs/Checkbox";
import classNames from "../../utils/classNames";
import dayjs from "dayjs";
import ContextMenu from "../../components/menus/ContextMenu";
import useApi from "../../store/api/apiContext";
import { useLocation } from "react-router";
import utc from 'dayjs/plugin/utc'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from 'react-dnd'
import downloadCsvFile from '../../utils/downloadCsvFile'

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween, utc);


function Table({
  data,
  columns,
  isLoading,
  renderRowMenu,
  rowCheckbox,
  selectedRows,
  setSelectedRows,
  rowOnClick,
}) {

  
  const { fetchTableSettings, updateSettings } = useApi();
  const { pathname, state: stateFromLink } = useLocation();
  const [showSettings, setShowSettings] = useState(false);
  const [userSettings, setUserSettings] = useState({});
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  const [contextMenu, setContextMenu] = useState(false);
  const [rowHighlight, setRowHighlight] = useState(false);

  const defaultColumn = useMemo(() => {
    return {
      Filter: TextColumnFilter,
    };
  }, []);

  const filterTypes = {
    date: (rows, id, filterValue) => {
      let start = dayjs.utc(filterValue[0]).subtract(1, "day");
      let end = dayjs.utc(filterValue[1]).add(1, "day");
      return rows.filter((val) =>
        dayjs.utc(val.original[id], "DD/MM/YYYY").isBetween(start, end)
      );
    },
    includes: (rows, columnIds, filterValue) => {

      return (
        filterValue.length === 0 ? rows : rows.filter((row) => filterValue.includes(String(row.original[columnIds[0]])))
      )
    },
    exactMatchMultiple: (rows, id, filterValue) => {
      //allows for text input filters to search for exact matches of each input separated by a comma
      if (!filterValue) return rows;
      const filterValues = filterValue.split(',').map(val => val.trim());
      return rows.filter(row => filterValues.includes(String(row.values[id])));
    },
    filterCustomCells: (rows, id, filterValue) => {
      return rows.filter(row => {
        const obj = row.values[id];
        return obj && obj.cell_content.toLowerCase().includes(filterValue.toLowerCase());
      });
    }
  };

  const table = React.useMemo(() => {
    let tempTable = "";
    switch (pathname) {
      case "/users":
        tempTable = "users";
        break;
      case "/roles":
        tempTable = "roles";
        break;
      case "/user-groups":
        tempTable = "userGroups";
        break;
      case "/fw-contracts":
        tempTable = "fwContracts";
        break;
      case "/clients":
        tempTable = "clients";
        break;
      case "/job-orders":
        tempTable = "jobOrders";
        break;
      case "/job-orders/status-log":
        tempTable = "statusLogs";
        break;
      case "/candidates/application-log":
        tempTable = "applicationLogs";
        break;
      default:
        break;
    }
    return tempTable
  }, [pathname]);
                
  useEffect(() => {
    setAllFilters([])
    
    if (!data.length) return;
    
    if (!columns.length) {
      setIsLoadingSettings(false)
      return;
    }
    
    let visibleColumnsArray = [];
    allColumns.forEach((el) => {
      visibleColumnsArray.push({
        Header: el.Header,
        id: el.id,
        isVisible: el.isVisible,
    });
  });
    
    
    fetchTableSettings()
    .then((res) => {
      if (res.data) {
        let allTableSettings = JSON.parse(res.data?.table_settings);
        let thisTableSettings = allTableSettings[table];
        if (!thisTableSettings) {
          const updated = {
            ...allTableSettings,
            [table]: {}
          }
          updateSettings(updated)
          thisTableSettings = {}
          allTableSettings = updated;
        }
        setUserSettings(allTableSettings);
        if (thisTableSettings?.pageSize) {
          setPageSize(new Number(thisTableSettings?.pageSize).valueOf());
        } else {
          setPageSize(10)
        }
        if (thisTableSettings?.columns?.length) {
          setVisibleColumns(thisTableSettings?.columns);
          handleHideColumns(thisTableSettings?.columns);
        } else {
          setVisibleColumns(visibleColumnsArray);
        } 
        
        if (thisTableSettings?.customColumnOrder?.length) {
          setColumnOrder(thisTableSettings?.customColumnOrder);
        } 
        if (thisTableSettings?.sortBy?.length) {
          setSortBy(thisTableSettings?.sortBy);
        } 
        if (thisTableSettings?.filters?.length) {
          let filtersToApply = thisTableSettings?.filters;
          //if it is a date filter, convert date string to date object
          filtersToApply = filtersToApply.map((el) => {
            if (
              columns.find((col) => col.accessor === el.id)?.filter === "date"
            ) {
              let formattedArray = el.value.map((unformattedDate) => {
                if (unformattedDate) {
                  let formattedDate = new Date(unformattedDate);
                  return formattedDate;
                }
              });
              el.value = formattedArray;
              return el;
            }
            return el;
          });
          //only apply filters if no state was brought in by link
          if (!stateFromLink) setAllFilters(filtersToApply);
        }
      } else {
        setVisibleColumns(visibleColumnsArray);
      }
      setIsLoadingSettings(false);
    })
    .catch((err) => {
      setVisibleColumns(visibleColumnsArray);
      setIsLoadingSettings(false);
    });
  }, [columns, table, data]);

  const customGlobalFilter = (rows, cols, filterValue) => {
    return rows.filter(row => {
      // Iterate through each column to check for the filter value
      return Object.values(row.values).some(columnValue => {
          // If columnvalue is a string just compare, otherwise access the cell_content property of the object
          return String(columnValue?.cell_content || columnValue).toLowerCase().includes(filterValue.toLowerCase());
      });
    });
  }
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    allColumns,
    pageOptions,
    setPageSize,
    setSortBy,
    setColumnOrder,
    gotoPage,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize, filters, sortBy },
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      globalFilter: customGlobalFilter
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useColumnOrder,
  );

  useEffect(() => {
    if (stateFromLink?.filters) setAllFilters(stateFromLink.filters)
  }, [stateFromLink])
  
  const checkboxAll = useRef();
  
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    if (!checkboxAll.current) return;
    const isIndeterminate =
      selectedRows.length > 0 && selectedRows.length < page.length;
    setChecked(selectedRows.length === page.length);
    setIndeterminate(isIndeterminate);
    checkboxAll.current.indeterminate = isIndeterminate;
  }, [selectedRows]);

  const savePageSize = (pageSize) => {
    let updated = {
      ...userSettings,
      [table]: {
        ...userSettings[table],
        pageSize: pageSize,
      }
    }
    setUserSettings(updated);
    updateSettings(updated)
  }

  useEffect(() => {
    let thisTableSettings = userSettings[table];
    if (!thisTableSettings) return;

    if (JSON.stringify(thisTableSettings?.sortBy) != JSON.stringify(sortBy)) {
      let updated = {
        ...userSettings,
        [table]: {
          ...thisTableSettings,
          sortBy: sortBy,
        }
      }
      
      setUserSettings(updated);
      updateSettings(updated)
    }
  }, [sortBy])

  function toggleAll() {
    setSelectedRows(checked || indeterminate ? [] : page);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const handleCheckRowChange = (event, row) => {
    event.stopPropagation();
    selectedRows?.some((el) => el.original?.id == row.original?.id)
      ? setSelectedRows(selectedRows.filter((r) => r.original?.id !== row.original?.id))
      : setSelectedRows((prev) => [...prev, row]);
  };

  const handleRowClick = (event, row) => {
    if (event.target.id !== "clickable") return;
    event.stopPropagation();
    if (contextMenu) {
      setContextMenu(null);
      setRowHighlight(false);
      return
    }
    rowOnClick(row);
  };

  const handleHideColumns = (data) => {
    let columns;
    if (data) columns = data;
    else columns = visibleColumns;
    
    allColumns.map((column) => {
      let found = columns.find((col) => col.Header === column.Header);
      found.isVisible ? column.toggleHidden(false) : column.toggleHidden(true);
    });
  };

  if (!data || !columns) return;

  const moveColumn = async (dragIndex, hoverIndex) => {
    let newColumnOrder = [];
    allColumns.forEach(column => {if (column.isVisible) newColumnOrder.push(column.id)})
    newColumnOrder.splice(hoverIndex, 0 , newColumnOrder.splice(dragIndex, 1)[0])
    setColumnOrder(newColumnOrder)
  }

  const saveColumnOrderSettings = (column, index) => {
    const prevIndex = allColumns.findIndex(col => col.id === column.id)
    let newColumnOrder = allColumns.map(col => col.id)
    newColumnOrder.splice(index, 0, newColumnOrder.splice(prevIndex, 1)[0])
    let updated = {
      ...userSettings,
      [table]: {
        ...userSettings[table],
        customColumnOrder: newColumnOrder,
      }
    }
    setUserSettings(updated);
    updateSettings(updated)    
  }
  
  const ColumnHead = ({column, index, moveColumn}) => {
    const dropRef = useRef();
    const dragRef = useRef();
    
    const [{ canDrop, isOverCurrent }, drop] = useDrop(() => ({
      // The type (or types) to accept - strings or symbols
      accept: 'BOX',
      hover(item, monitor) {
        if (!dropRef.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        
        if (dragIndex === hoverIndex) return

        const hoverBoundingRect = dropRef.current.getBoundingClientRect()
        const hoverMiddleX = (hoverBoundingRect.width) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientX = clientOffset.x - hoverBoundingRect.left

        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return

        moveColumn(dragIndex, hoverIndex)

        item.index = hoverIndex;
      },
      drop: (item) => ({ column: column, index: index }),
      // Props to collect
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      })
    }))
  
    const [{ isDragging }, drag, preview] = useDrag(() => ({
      type: 'BOX',
      item: { index },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        saveColumnOrderSettings(dropResult.column, dropResult.index)
      },
    	// The collect function utilizes a "monitor" instance (see the Overview for what this is)
    	// to pull important pieces of state from the DnD system.
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      })  
    }))
    
    preview(drop(dropRef.current))
    drag(dragRef)
    return (
      <th
        key={column.id}
        {...column.getHeaderProps(column.getSortByToggleProps())}
        className={`${index === 0 ? "pl-4 sm:pl-6" : "px-3"}
        py-3.5 text-left text-sm font-semibold text-gray-900`}
        ref={dropRef}
      >
        <span
          className={`${
            isOverCurrent ? "border-x-1 border-black" : ""
          } flex items-center`}
          ref={dragRef}
        >
          <div className="flex">
            <span>{column.render("Header")}</span>
            {
              column.filterValue ?
                Array.isArray(column.filterValue) ?
                !!column.filterValue.length &&
                  <FilterIcon
                    className={"transform h-5 w-5 text-thaleria-orange-700"}
                  />
                :
                <FilterIcon
                    className={"transform h-5 w-5 text-thaleria-orange-700"}
                />
              :
              ''
            }            
            {column.isSorted ? (
              column.isSortedDesc ? (
                <SortAscendingIcon
                  className={"transform h-5 w-5 text-thaleria-orange-700"}
                />
              ) : (
                <SortDescendingIcon
                  className={"transform h-5 w-5 text-thaleria-orange-700"}
                />
              )
            ) : (
              ""
            )}
          </div>
        </span>
      </th>
    );
  }

  const handleDownloadCsvClick = () => {
    const downloadData = [];
    const columnsTitles = {};

    let visibleColumn = allColumns.find(column => column.isVisible);
    visibleColumn.filteredRows.map(row => {
      downloadData.push(row.values)

    });

    allColumns.map(column => {
      if (column.isVisible) {
        columnsTitles[column.id] = column.id;
      }
    });

    downloadCsvFile(downloadData, Object.keys(columnsTitles), 'test');
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="w-full rounded-lg border-2 border-transparent bg-white shadow "
      >
        <SettingsModal
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          headerGroups={headerGroups}
          setAllFilters={setAllFilters}
          filters={filters}
          handleHideColumns={handleHideColumns}
          userSettings={userSettings}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          table={table}
          setSortBy={setSortBy}
          setUserSettings={setUserSettings}
          downloadCsv={handleDownloadCsvClick}
        />
        <ContextMenu
          data={page}
          menuOptions={renderRowMenu}
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          setRowHighlight={setRowHighlight}
        />
        <Loader isLoading={isLoading || isLoadingSettings}>
          <div className="shadow overflow-x-auto">
            <table
              {...getTableProps()}
              className="min-w-full table-fixed  divide-y divide-gray-300 "
            >
              <thead className="bg-gray-50">
                {headerGroups.length ? (
                  headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {rowCheckbox && (
                        <th className="pl-3">
                          <Checkbox
                            ref={checkboxAll}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                      )}
                      {headerGroup.headers.map((column, index) => (
                        <ColumnHead
                          key={column.id}
                          column={column}
                          index={index}
                          moveColumn={moveColumn}
                        />
                      ))}
                      <th className="pr-3">
                        <AdjustmentsIcon
                          className="ml-auto rounded-full p-1 h-7 w-7 bg-thaleria-orange-700 hover:bg-thaleria-orange-800 cursor-pointer"
                          stroke={"#fff"}
                          onClick={() => setShowSettings(true)}
                        />
                      </th>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <th className="pr-3  py-2.5">
                      <AdjustmentsIcon
                        className="ml-auto rounded-full p-1 h-7 w-7 bg-thaleria-orange-700 hover:bg-thaleria-orange-800 cursor-pointer"
                        stroke={"#fff"}
                        onClick={() => setShowSettings(true)}
                      />
                    </th>
                  </tr>
                )}
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="divide-y divide-gray-100"
              >
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      id={row.original.id}
                      key={row.original.id}
                      {...row.getRowProps()}
                      className={classNames(
                        selectedRows?.some(
                          (el) => el.original?.id == row.original?.id
                        )
                          ? "bg-gray-50"
                          : undefined,
                        "context-menu",
                        rowOnClick && !rowHighlight
                          ? `cursor-pointer ${
                              row.original.blacklisted
                                ? "hover:bg-red-100"
                                : "hover:bg-gray-50"
                            }`
                          : undefined,
                        rowHighlight == row.original.id
                          ? `${
                              row.original.blacklisted
                                ? "bg-red-100"
                                : "bg-gray-50"
                            }`
                          : undefined,
                        row.original.blacklisted && "bg-red-50"
                      )}
                      onClick={
                        rowOnClick ? (e) => handleRowClick(e, row) : undefined
                      }
                    >
                      {rowCheckbox && (
                        <td className={`relative pl-3 z-10`}>
                          {selectedRows?.some(
                            (el) => el.original?.id == row.original?.id
                          ) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-thaleria-orange-600" />
                          )}
                          <Checkbox
                            checked={selectedRows?.some(
                              (el) => el.original?.id == row.original?.id
                            )}
                            onChange={(e) => handleCheckRowChange(e, row)}
                          />
                        </td>
                      )}
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={cell.id}
                            className={`${
                              index === 0
                                ? `pl-4 sm:pl-6 pr-3 ${
                                    row.original.blacklisted
                                      ? "text-red-500"
                                      : "text-gray-900"
                                  } font-medium`
                                : `px-3 ${
                                    row.original.blacklisted
                                      ? "text-red-400"
                                      : "text-gray-500"
                                  }`
                            } whitespace-nowrap py-3 text-sm ${
                              selectedRows?.some(
                                (el) => el.original?.id == row.original?.id
                              )
                                ? index === 0
                                  ? "text-thaleria-orange-900 font-medium"
                                  : "text-thaleria-orange-800"
                                : undefined
                            }`}
                            id="clickable"
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      {/* Empty cell to ensure clickable area includes area under settings button */}
                      <td
                        className={`px-3 text-gray-500 whitespace-nowrap py-3 text-sm ${
                          selectedRows?.some(
                            (el) => el.original?.id == row.original?.id
                          ) && "text-thaleria-orange-700"
                        }`}
                        id="clickable"
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TableControlPanel
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            gotoPage={gotoPage}
            setShowSettings={setShowSettings}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            savePageSize={savePageSize}
          />
        </Loader>
      </div>
    </DndProvider>
  );
}

export default Table;

import React, { useState, useEffect, useMemo } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useLocation } from 'react-router'
import AddJobOrderForm from '../../containers/jobOrders/AddJobOrderForm'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader';
import axios from 'axios';
import useJobOrders from '../../store/jobOrders/jobOrdersContext'

function AddJobOrder({access}) {
  const navigate = useNavigate();
  const location = useLocation();

  const id = useMemo(() => location?.state?.id, [])
  
  const { fetchJobOrder } = useJobOrders();

  const [jobOrder, setJobOrder] = useState(null)
  const [isLoading, setIsLoading] = useState(id ? true : false)

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (id) fetchJobOrderFromApi(source, id);
    return () => {
      source.cancel();
    };
  }, [id])

  const fetchJobOrderFromApi = async (source, id) => {
    fetchJobOrder(id, source).then(res => {
      let jobOrder = res.data
      delete jobOrder?.jobStatusLog
      delete jobOrder?.recruiters
      delete jobOrder?.client
      delete jobOrder?.id
      delete jobOrder?.applicationStatusLog
      delete jobOrder?.frameworkContract
      delete jobOrder?.created_at
      delete jobOrder?.updated_at
      delete jobOrder?.deleted_at
      delete jobOrder?.legacy_id
      delete jobOrder?.legacy_description
      delete jobOrder?.uuid
      delete jobOrder?.created_by
      setJobOrder(jobOrder);
      setIsLoading(false);
    })
  }
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigate('/job-orders', {replace: true}) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Add job order'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <AddJobOrderForm template={jobOrder}/>
      </Loader>
    </PageWrapper>
  )
}

export default AddJobOrder
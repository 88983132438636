import React, { useState, useEffect } from 'react'
import ContainerCard from '../../components/cards/ContainerCard'
import ApplicationItem from './ApplicationItem';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

function CandidateApplications({ applications }) {

  const [ pages, setPages ] = useState([1])
  const [ showPage, setShowPage ] = useState(1)

  const pageDown = () => {
    if (showPage == 1) {
      setShowPage(pages.length)
    } else {
      setShowPage(showPage-1)
    }
  }

  const pageUp = () => {
    if (showPage == pages.length) {
      setShowPage(1) 
    } else {
      setShowPage(showPage+1)
    }
  }

  const [latestApplications, setLatestApplications] = useState([]);
  
  useEffect(() => {
    //keep only latest application for each job order (presuposes applications are arriving ordered by latest created_at date)
    let latestApplications = [];
    applications.forEach(application => {
      if (latestApplications.some(latestApplication => latestApplication.job_order_id === application.job_order_id)) {
        //add to application history
        let applicationToUpdate = latestApplications[latestApplications.findIndex(_latestApplication => _latestApplication.job_order_id === application.job_order_id)]
        applicationToUpdate.applicationHistory.push(application)
        return;
      } else {
        application.applicationHistory = [ application ];
        latestApplications.push(application);
      }
    })
    setLatestApplications(latestApplications);
    
  }, [applications])
  
  useEffect(() => {
    const requiredPages = Math.ceil(latestApplications.length/4)
    if (requiredPages != pages.length) {
      let array = [1]
      for (let i=2; i<requiredPages+1; i++) {
        array.push(i)
      }
      setPages(array)
    }
  }, [latestApplications])

  
  return (
    <ContainerCard title='Candidate applications' className="relative">
      {latestApplications?.length ? 
        <div>
          <ul className='-my-5 divide-y divide-gray-200 h-[297px]'>
            {latestApplications.slice((showPage-1)*4, showPage*4).map(application => <ApplicationItem setLatestApplications={setLatestApplications} latestApplications={latestApplications} application={application} applicationHistory={application?.applicationHistory} key={application.id}/>)}
          </ul>
          <div className="absolute bottom-0 h-10 inset-x-0 bg-gray-50 px-4 py-2 sm:px-6 flex justify-between">
            <div>
              <p className="text-grey-600">Page {showPage} of {pages.length}</p>
            </div>
            {pages.length > 1 &&
            <div className="flex">
              <ChevronLeftIcon className="h-6 w-6 text-thaleria-orange-700 hover:cursor-pointer pr-0.5 hover:bg-thaleria-orange-600 hover:text-white rounded-full" onClick={pageDown}/>
              <ChevronRightIcon className="h-6 w-6 text-thaleria-orange-700 hover:cursor-pointer pl-0.5 rounded-full hover:bg-thaleria-orange-600 hover:text-white" onClick={pageUp}/>
            </div>}
          </div>
        </div>
        :
        <span className='text-sm'>No viewable applications to show for this candidate</span>
      }
    </ContainerCard>
  )
}

export default CandidateApplications
import React from 'react'
import { SearchIcon } from '@heroicons/react/solid'
import { APP_STYLES } from '../../config';

function SearchInput({
  value,
  onChange,
  onKeyPress,
  placeholder,
  id,
  divClass,
  inputClass,
  onFocus,
  onBlur
}) {
  return (
    <div
      id={id}
      className={
        divClass
          ? divClass
          : "bg-white border-2 border-gray-100 rounded-md items-center flex px-2 w-full"
      }
    >
      <SearchIcon className="h-5 w-5" fill={APP_STYLES.primaryColor} />
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        aria-label={"Input search terms"}
        type="text"
        value={value}
        placeholder={placeholder || "Search"}
        onChange={onChange}
        onKeyPress={onKeyPress}
        className={inputClass ? inputClass : "border-none focus:ring-0 rounded bg-white text-sm leading-none text-secondary w-full pl-3"}
      />
    </div>
  );
}

export default SearchInput



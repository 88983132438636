import countryList from "./countryList"

function convertCountries(code) {
  const countryName = countryList.find(country => country.code == code)?.name
  
  if (!countryName) return code

  return countryName
}

export default convertCountries; 
import { useNavigate } from 'react-router-dom';
import React from 'react'

function useNavigateBack() {
  
  const navigate = useNavigate();
  
  const navigateBack  = React.useMemo(() => {
    if (!navigate) return () => {};
    if (window.history.length === 1) {
      const myFunction = (url) => navigate(url, {replace: true})
      return myFunction 
    } else {
      const myFunction = () => navigate(-1)
      return myFunction
    }
  }, [window, navigate])

  return navigateBack
}

export default useNavigateBack

import React from 'react'
import { useNavigate } from 'react-router';
import Table from '../table/Table';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import role from '../../helpers/enum/role';
import { ExternalLinkIcon, EyeIcon, PencilIcon } from "@heroicons/react/solid";

function UserGroupsTable({ userGroups, isLoading }) {

  const navigate = useNavigate();
  const { verifyBoolAccess } = useUserRoleVerifier();


  const data = React.useMemo(
      () => []
    , [ userGroups ]);
    
    if (userGroups.length && !data.length) {
      userGroups.forEach(userGroup => {
        data.push({
          id: userGroup.id,
          name: userGroup.name || '',
        })
      })
    }

  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
    ], []
  );


  let renderRowMenu = (row) => {
    let canEdit = verifyBoolAccess(role.b_manage_user_groups);
    let buttons = [
      [
        {
          text: "View user group details",
          icon: EyeIcon,
          linkTo: `/user-groups/${row.original.id}`,
        },
        {
          text: "View details in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/user-groups/${row.original.id}`),
        },
      ],
    ];

    if (canEdit) buttons.push([
      {
        text: "Edit user group",
        icon: PencilIcon,
        linkTo: `/user-groups/edit/${row.original.id}`,
        show: canEdit
      },
      {
        text: "Edit user group in new tab",
        icon: ExternalLinkIcon,
        onClick: () => window.open(`/user-groups/edit/${row.original.id}`),
        show: canEdit
      },
    ],)

    return buttons;
  } 
  
 const rowOnClick = (row) => {
    navigate(`/user-groups/${row.original.id}`);
  }
  
  return (
    <>
      <Table data={data} columns={columns} isLoading={isLoading} renderRowMenu={renderRowMenu} rowOnClick={rowOnClick}/>
    </>
  )
}

export default UserGroupsTable

import React from 'react'
import Table from '../table/Table';
import { useNavigate } from 'react-router';
import { ROOT_ROLE_UUID } from '../../config';
import { ExternalLinkIcon, EyeIcon, PencilIcon } from "@heroicons/react/solid";

function RolesTable({ roles, isLoading }) {

  const navigate = useNavigate();

  const data = React.useMemo(
      () => []
    , [ roles ]);
    
    if (roles.length && !data.length) {
      roles.forEach(role => {
        data.push({
          id: role.id || '-',
          name: role.a_name || '',
        })
      })
    }

  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Role Name',
        accessor: 'name',
      },
    ], []
  );


  let renderRowMenu = (row) => {
    return [
      [
        {
          text: "View role details",
          icon: EyeIcon,
          onClick: () => navigate(`/roles/${row.original.id}`),
          show: true,
        },
        {
          text: "View details in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/roles/${row.original.id}`),
        },
      ],
      [
        {
          text: "Edit role",
          icon: PencilIcon,
          onClick: () => navigate(`/roles/edit/${row.original.id}`),
          show: row.original.id == ROOT_ROLE_UUID ? false : true,
        },
        {
          text: "Edit role in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/roles/edit/${row.original.id}`),
          show: row.original.id == ROOT_ROLE_UUID ? false : true,
        },
      ],
    ];
  } 

  const rowOnClick = (row) => {
    navigate(`/roles/${row.original.id}`);
  }
  
  return (
    <>
      <Table data={data} columns={columns} isLoading={isLoading} renderRowMenu={renderRowMenu} rowOnClick={rowOnClick}/>
    </>
  )
}

export default RolesTable

import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import axios from 'axios'
import ApplicationsTable from '../../containers/applications/ApplicationsTable'
import useJobOrders from '../../store/jobOrders/jobOrdersContext'

function Applications({access}) {
  const [isLoading, setIsLoading] = useState(true)

  const {jobOrdersState: {applicationHistory: applications}, fetchApplicationsHistory} = useJobOrders();

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (!applications.length) {
      fetchApplicationsHistory(source).then(res => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
    return () => {
      source.cancel();
    }
  }, [])

  return (
    <PageWrapper title='Applications' access={access} >
      <ApplicationsTable applications={applications} isLoading={isLoading} />
    </PageWrapper>
  )
}

export default Applications
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const sortByDate = (rowA, rowB, id) => {
  return dayjs
    .utc(rowA.values[id], "DD/MM/YYYY")
    .isAfter(dayjs.utc(rowB.values[id], "DD/MM/YYYY"))
    ? 1
    : -1;
};

export default sortByDate;

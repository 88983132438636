import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import axios from 'axios'
import useJobOrders from '../../store/jobOrders/jobOrdersContext'
import JobStatusLogTable from '../../containers/jobStatusLog/JobStatusLogTable'
import SecondaryButton from '../../components/buttons/SecondaryButton'

function JobStatusHistory({access}) {
  const [isLoading, setIsLoading] = useState(true)

  const {jobOrdersState: {jobStatusHistory: jobStatusLog}, fetchJobStatusHistory} = useJobOrders();
  useEffect(() => {
    let source = axios.CancelToken.source();
    if (!jobStatusLog.length) {
      fetchJobStatusHistory(source).then(res => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
    return () => {
      source.cancel();
    }
  }, [])

  const renderTopSection = () => {
    
    return (
      <>
        <SecondaryButton text={"View job orders"} linkTo='/job-orders'/>
      </>
    )
  }

  return (
    <PageWrapper title='Job order status log' access={access} topSection={renderTopSection()}>
      <JobStatusLogTable jobStatusLog={jobStatusLog} isLoading={isLoading} />
    </PageWrapper>
  )
}

export default JobStatusHistory
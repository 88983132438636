import React, { useState } from 'react'
import PrimaryButton from '../../components/buttons/PrimaryButton';
import WhiteButton from '../../components/buttons/WhiteButton';
import PasswordInput from '../../components/inputs/PasswordInput';
import useApi from '../../store/api/apiContext';
import useAppNotification from '../../store/appNotification/appNotificationContext';
import Loader from '../../components/loaders/Loader';
import validatePassword from '../../utils/validatePassword';

function PasswordSection({ }) {

  const { updatePassword } = useApi();

  const { showSuccessNotification } = useAppNotification();

  const [isLoading, setIsLoading] = useState(false)
  
  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [formError, setFormError] = useState([]);

  const handleSave = () => {
    setFormError([]);
    let errors = [];
    if (!passwordInfo.currentPassword) {
      errors.push({
        field: "Current password",
        msg: "Please enter your current password."
      })
    }
    if (!passwordInfo.newPassword) {
      errors.push({
        field: "New password",
        msg: "Please enter a new password."
      })
    }
    if (passwordInfo.newPassword !== passwordInfo.confirmNewPassword) {
      errors.push({
        field: "Confirm new password",
        msg: "Passwords don't match."
      })
    }

    if (!validatePassword(passwordInfo.newPassword)) {
      errors.push({field: 'New password', msg: 'Passwords need to be 8 characters or more and contain at least one upper and lowercase character, one number and one special character.'})
    }
    if (errors.length) {
      return setFormError(errors);
    }
    setIsLoading(true)
    updatePassword(passwordInfo).then((res) => {
      showSuccessNotification('Your password was updated');
      resetForm();
      setIsLoading(false)
    }).catch(err => {
      resetForm();
      setIsLoading(false)
    })
  }

  const resetForm = () => {
    setFormError([]);
    setPasswordInfo({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
  }

  return (
    <form className="divide-y divide-gray-200 lg:col-span-9">
      {/* Password section */}
      <Loader isLoading={isLoading}>
        <div className="py-6 px-4 sm:p-6 lg:pb-8">
          <div>
            <h2 className="text-lg leading-6 font-medium text-gray-900">Update password</h2>
            <p className="mt-1 text-sm text-gray-500">
              You can reset your password here
            </p>
          </div>
          <div className="mt-6 flex flex-col space-y-6">
            <div className="w-full sm:w-1/2">
              <PasswordInput label={'Current password'} onChange={(e) => setPasswordInfo(prev => ({...prev, currentPassword: e.target.value}))} value={passwordInfo.currentPassword} errors={formError}/>
            </div>
            <div className="w-full sm:w-1/2">
              <PasswordInput label={'New password'} onChange={(e) => setPasswordInfo(prev => ({...prev, newPassword: e.target.value}))} value={passwordInfo.newPassword} errors={formError}/>
            </div>
            <div className="w-full sm:w-1/2">
              <PasswordInput label={'Confirm new password'} onChange={(e) => setPasswordInfo(prev => ({...prev, confirmNewPassword: e.target.value}))} value={passwordInfo.confirmNewPassword} errors={formError}/>
            </div>
          </div>
        </div>
        <div className="mt-4 py-4 px-4 flex justify-end sm:px-6 space-x-2">
          <WhiteButton 
            text='Cancel'
            onClick={resetForm}
          />
          <PrimaryButton 
            text='Save'
            darkRing
            onClick={handleSave}
          />
        </div>
      </Loader>
    </form>
  )
}

export default PasswordSection
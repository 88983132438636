import React, {useState} from 'react'
import ContainerCard from '../cards/ContainerCard'
import RichTextEditor from '../inputs/RichTextEditor/RichTextEditor'
import InfoTooltip from '../tooltips/InfoTooltip'
import WarningModal from '../modal/WarningModal'
import AccessWrapper from '../../containers/app/AccessWrapper'

function DescriptionList({ title, items, dataClassName, gridClassName, canDeleteLegacyText, deleteLegacyText }) {

  const [showWarning, setShowWarning] = useState(false);

  const handleConfirm = () => {
    setShowWarning(false);
    deleteLegacyText();
  }

  const returnCols = (item) => {
    switch (item?.cols) {
      case 1:
        return 'sm:col-span-1'
      case 2:
        return 'sm:col-span-2'
      case 'custom':
        return item?.custom
      default:
        return 'sm:col-span-1'
    }
  }
  
  return (
    <ContainerCard title={title}>
      <dl className={`grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 ${gridClassName}`}>
        {items.map(item => {
          if (!item.show) return '';
          if (item.richText) return (
            <div key={item.id} className={returnCols(item)}>
              <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
              <RichTextEditor readOnly editorState={item.data}/>
            </div>
          )
          if (item.htmlText) return (
            <div key={item.id} className={`${returnCols(item)}`}>
              <WarningModal title='Deleting legacy description' message="Are you sure you want to delete legacy description?" buttonText="Delete" setShowWarning={setShowWarning} showWarning={showWarning}  onClick1={handleConfirm} onClick2={() => setShowWarning(false)}/>
              {item.id == 13 ? 
              <div className='flex justify-between pr-1'>
                <div className="flex">
                  <dt className="text-sm font-medium text-gray-500 pr-1">{item.label}</dt>
                  <AccessWrapper access={canDeleteLegacyText}>
                    <InfoTooltip iconClassName={'h-4 w-4 mt-[2.5px] text-gray-400 text-xs'}>
                      <div className="w-80 h-max">
                      This text was imported from OpenCATS and is deprecated. We recommend copying the content to the description box above when editing and deleting this one for future use.
                      </div>
                    </InfoTooltip>
                  </AccessWrapper>
                </div>
                <AccessWrapper access={canDeleteLegacyText}><span>[<button className="justify-self-end text-sm text-gray-500 hover:cursor-pointer hover:text-red-500" onClick={() => setShowWarning(true)}>remove</button>]</span></AccessWrapper>
              </div>
              :
              <div className='flex justify-between pr-1'>
                <dt className="text-sm font-medium text-gray-500 pr-1">{item.label}</dt>
              </div>}
              {/* TODO: Need to add min-heigth here when there is no content */}
              <dd className={`${dataClassName} rounded-md mt-1 text-sm text-gray-900`}  dangerouslySetInnerHTML={{__html: item.data || '-'}}></dd>
            </div>
          )
          return (
            <div key={item.id} className={returnCols(item)}>
              <dt className="text-sm font-medium text-gray-500">{item.label}</dt>
              <dd className={`${dataClassName} mt-1 text-sm text-gray-900`}>{item.data || '-'}</dd>
            </div>
          )
        })}
      </dl>
    </ContainerCard>
  )
}

export default DescriptionList
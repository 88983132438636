import { useEffect, useState } from "react";

const usePageTitle = () => {
  const [title, setTitle] = useState('ThaleriaCATS');

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  const changeTitle = (title) => {
    setTitle(title);
  };

  return {
    changeTitle
  }
};

export default usePageTitle;
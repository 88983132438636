import React from 'react'
import WhiteButton from '../../../components/buttons/WhiteButton'
import { XCircleIcon } from '@heroicons/react/outline'
import useAppNotification from '../../../store/appNotification/appNotificationContext'
import AccessWrapper from '../../app/AccessWrapper'
import useUserRoleVerifier from '../../../hooks/useUserRoleVerifier'
import role from '../../../helpers/enum/role'
import useJobOrders from '../../../store/jobOrders/jobOrdersContext'

function RecruiterItem({ jobOrder, recruiter }) {
  const { removeRecruiterFromJobOrder } = useJobOrders();
  const {verifyBoolAccess, verifyUserId} = useUserRoleVerifier();
  const { appNotificationTypes: types, showAppNotification } = useAppNotification();
  
  const handleRemoveClick = () => {
    removeRecruiterFromJobOrder(jobOrder.id, recruiter.id).then(res => {
      
      showAppNotification(types.SUCCESS, 'Success', 'Recruiter removed successfully');
    })
  }

  let recruiterName = recruiter.first_names

  if (recruiter.is_within_scope) recruiterName = recruiterName + " " + recruiter?.last_names
  //FIXME: add access wrapper to view details depending on if user is viewable
  //temporary fix, consider adding is_within_scope to the recruiter in the response
  //FIXME: confirm whether or not we should display whole name of non-viewable user (or just first name for instance)

  //TODO: Check changes here. Recruiter's full name and View button now only appears if is_within_scope. ADditionnally, user can only delete recruiter from job order if has h_3_2 access AND recruiter is within scope

  return (
    <li key={recruiter.id} className="py-4 group">
      <div className="flex items-center space-x-4">
        <div className="flex-1 min-w-0 flex items-center">
          <AccessWrapper access={(verifyBoolAccess(role.h_job_orders.h_3_2_assign_recruiters) && recruiter.is_within_scope) || (verifyUserId(recruiter.id) && verifyBoolAccess(role.h_job_orders.h_1_3_assign_myself_as_recruiter))}>
            <XCircleIcon onClick={handleRemoveClick} className='h-7 w-7 hidden group-hover:block mr-2 text-red-400 cursor-pointer hover:text-red-600'/>
          </AccessWrapper>
          <p className="text-sm font-medium text-gray-900 truncate">{recruiterName}</p>
        </div>
        <AccessWrapper access={recruiter.is_within_scope}>
          <div className='flex items-center space-x-2'>
            <WhiteButton text='View' linkTo={'/users/' + recruiter.id} size='xs' rounded/>            
          </div>
        </AccessWrapper>      
      </div>
    </li>
  )
}

export default RecruiterItem
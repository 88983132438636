import * as React from 'react';
import { ApiContext } from './apiContext.js';

function ApiProvider({children}) {

  const [api, setApi] = React.useState({});

  
  /* // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = React.useMemo(() => [state, dispatch], [state]) */

  //No memoization needed
  const value = [api, setApi];

  return (
    <ApiContext.Provider value={ value }>
      { children }
    </ApiContext.Provider>
  )
}

export default ApiProvider;
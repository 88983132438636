import React, { useState, useEffect, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import axios from 'axios';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import { useNavigate } from 'react-router';
import ReactSelect from '../../components/inputs/ReactSelect';
import useApi from '../../store/api/apiContext';
import Loader from '../../components/loaders/Loader';
import extractObjectDifferences from '../../helpers/enum/extractObjectDifferences';
import AccessWrapper from '../app/AccessWrapper';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import ResetPasswordModal from './ResetPasswordModal';
import Toggle from '../../components/inputs/Toggle';
import NumberInput from '../../components/inputs/NumberInput';
import useNavigateBack from '../../hooks/useNavigateBack';


function EditUserForm({ user, isLoading }) {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const { editUser, fetchUserGroupsForAssociation } = useApi();
  const { showAppNotification } = useAppNotification();
  const { verifyArrayAccess, role, verifyBoolAccess } = useUserRoleVerifier();

  
  const [userEdits, setUserEdits] = useState({});
  const [roleOptions, setRoleOptions] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null)
  const [userGroupOptions, setUserGroupOptions] = useState([])
  const [selectedUserGroup, setSelectedUserGroup] = useState(null)
  const [formError, setFormError] = useState([])
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [customPriceMargin, setCustomPriceMargin] = useState();
  const [showEditButton, setShowEditButton] = useState(false);
  const firstNamesInputRef = useRef(null)

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  useEffect(() => {
    setUserEdits(user);
    setSelectedRole({
      value: user.role_id,
      label: user.role.a_name
    })
    setSelectedUserGroup({
      value: user.user_group_id,
      label: user.userGroup.name
    })
    setCustomPriceMargin(user.price_margin != null ? true : false)
    
  }, [user])
  
  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchUserGroups(source);
    return () => {
      source.cancel();
    };
  }, [])

  useEffect(() => {
    if (!extractObjectDifferences(user, userEdits)) {
      setShowEditButton(false)
    } else {
      setShowEditButton(true)
    }
  }, [userEdits])
  
  const postEditUser = async () => {
    let errors = [];

    if (!userEdits.first_names) errors.push({
      field: "First names*",
      msg: "This field is required"
    })
    if (!userEdits.last_names) errors.push({
      field: "Last names*",
      msg: "This field is required"
    })
    if (!userEdits.email) errors.push({
      field: "Email*",
      msg: "This field is required"
    })
    if (!validateEmail(userEdits.email)) errors.push({
      field:"Email*",
      msg: "Please provide a valid email address"
    })
    if (!userEdits.user_group_id) errors.push({
      field: "User group*",
      msg: "This field is required"
    })
    if (!userEdits.role_id) errors.push({
      field: "User role*",
      msg: "This field is required"
    })

    if (userEdits.phone_nb && !(/^[\d +]+$/.test(userEdits.phone_nb))) errors.push({
      field: 'Phone number',
      msg: "Can only contain numbers and (+) sign."
    })

    if (customPriceMargin && !userEdits.price_margin) errors.push({
      field: "Price margin (%)",
      msg: "This field is required"
    })

    if (customPriceMargin && !(0 < userEdits.price_margin < 100)) errors.push({
      field: "Price margin (%)",
      msg: "Must be 0-100"
    })

    if (errors.length) {
      firstNamesInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }

    setFormError([]);

    const updateData = extractObjectDifferences(user, userEdits)
    Object.keys(updateData).length && editUser(user.id, updateData).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'User editted successfully');
      navigate(`/users/${user.id}`, {replace: true});
    })

    showAppNotification(appNotificationTypes.WARNING_YELLOW, 'No change', 'No changes made to user')
  }

  const fetchUserGroups = async (source) => {
    fetchUserGroupsForAssociation().then(res => {
      let userGroups = [];
      res.data.map(userGroup => userGroups.push({
        value: userGroup.id,
        label: userGroup.name,
        roles: userGroup.roles
      }))
      setUserGroupOptions(userGroups)
      const currentUserGroup = userGroups.filter(userGroup => userGroup.value === user.user_group_id);
      const currentRoles = [];
      for (const item of currentUserGroup[0].roles) {currentRoles.push({value: item.id, label: item.a_name})}
      setRoleOptions(currentRoles)
    })
  }


  const changeHandlerSelectUserGroup = (e) => {
    setUserEdits(prev => ({...prev, user_group_id: e.value, role_id: null }) )
    setSelectedRole(null)
    setSelectedUserGroup(e)
    let roles = []
    e.roles.map(role => roles.push({
      value: role.id,
      label: role.a_name
    }))
    setRoleOptions(roles);
  }

  const changeHandlerSelectRole = (e) => {
    setUserEdits(prev => ({...prev, role_id: e.value }) )
    setSelectedRole(e)
  }

  const handleCustomPriceToggle = () => {
    let newStatus = !customPriceMargin
    if (!newStatus) {
      setUserEdits(prev => ({...prev, price_margin: null}))
    } else {
      setUserEdits(prev => ({...prev, price_margin: user.price_margin}))
    }
    setCustomPriceMargin(newStatus)
  }

  return (
    <div className="space-y-6">
      <Loader isLoading={isLoading}>
        <ResetPasswordModal show={showResetPasswordModal} hide={() => setShowResetPasswordModal(false)} userId={user.id}/>
        <FormWithSideTitle title='User information' description='Edit basic user information' >
          <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "first_names")}>
            <div ref={firstNamesInputRef} className="col-span-6 sm:col-span-3">
              <TextInput label='First names*' disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "first_names")} errors={formError} value={userEdits.first_names} onChange={(e) => setUserEdits(prev => ({...prev, first_names: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "last_names")}>
            <div className="col-span-6 sm:col-span-3">
              <TextInput label='Last names* ' disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "last_names")} errors={formError} value={userEdits.last_names} onChange={(e) => setUserEdits(prev => ({...prev, last_names: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "email")}>
            <div className="col-span-6 sm:col-span-4">
              <TextInput label='Email*' disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "email")} errors={formError} value={userEdits.email} onChange={(e) => setUserEdits(prev => ({...prev, email: e.target.value }) )}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "phone_nb")}>
            <div className="col-span-6 sm:col-span-4">
              <TextInput label='Phone number' disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "phone_nb")} errors={formError} value={userEdits.phone_nb} onChange={(e) => setUserEdits(prev => ({...prev, phone_nb: e.target.value }) )}/>
            </div>
          </AccessWrapper>
        </FormWithSideTitle>
        <FormWithSideTitle title='Account information' description='Edit account information' >
          <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "user_group_id")}>
            <div className="col-span-6">
              <ReactSelect label='User group*' disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "user_group_id")} errors={formError} placeholder="Select a userGroup" selectedOption={selectedUserGroup} options={userGroupOptions} onChange={changeHandlerSelectUserGroup}/>
            </div>
          </AccessWrapper>
          <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "price_margin")}>
            <div className="col-span-6">
              <Toggle label="Custom price margin" disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "price_margin")} description="Customize price margin for this user" value={customPriceMargin} onChange={handleCustomPriceToggle}/>
            </div>
              {customPriceMargin && <div className="col-span-3">
              <NumberInput label="Price margin (%)" disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "price_margin")} errors={formError} value={userEdits.price_margin} onChange={(e) => setUserEdits(prev => ({...prev, price_margin: e.target.value }) )}/>
            </div>}
          </AccessWrapper>
          {roleOptions &&
            <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "role_id")}>
              <div className="col-span-6">
                <ReactSelect label='User role*' disabled={!verifyArrayAccess("f_2_4_1_editable_properties", "role_id")} errors={formError} selectedOption={selectedRole} options={roleOptions} onChange={changeHandlerSelectRole}/>
              </div>
            </AccessWrapper>
          }
          <AccessWrapper access={verifyArrayAccess("f_1_3_viewable_properties", "active")}>
            <div className='col-span-6'>
              <Toggle label="Active" disabled={!verifyArrayAccess('f_2_4_1_editable_properties', "active")} description="Deactivated users can't login and do not receive notifications" value={userEdits.active} onChange={(e) => setUserEdits(prev => ({...prev, active: !prev.active}))} />
            </div>
          </AccessWrapper>
        </FormWithSideTitle>
        <div className="flex justify-between space-y-2 lg:space-y-0 flex-col lg:flex-row  ">
          <div>
            <AccessWrapper access={verifyBoolAccess(role.f_users.f_2_6_reset_user_password)}>
              <SecondaryButton text='Reset password' onClick={() => setShowResetPasswordModal(true)}/>
            </AccessWrapper>
          </div>
          <div className='lg:space-y-0 space-y-2 lg:space-x-2 flex-col lg:flex-row'>
            <WhiteButton text='Cancel' onClick={() => navigateBack(`/users/`)}/>
            <PrimaryButton text='Edit' darkRing onClick={postEditUser} disabled={!showEditButton}/>
          </div>
        </div>
      </Loader>
    </div>
  )
}
export default EditUserForm;
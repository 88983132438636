import React from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import AddUserForm from '../../containers/users/AddUserForm'

function AddUser({ access }) {
  const navigate = useNavigate();
  const params = useParams();
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigate('/users', {replace: true}) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Add user'} topSection={renderTopSection()} access={access}>
      <AddUserForm />
    </PageWrapper>
  )
}

export default AddUser
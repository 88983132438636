import React from 'react'
import Modal from "../../../components/modal/Modal";
import { applicationStatuses } from '../../../helpers/enum/applicationStatuses';
import TextArea from '../../../components/inputs/TextArea';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import WhiteButton from '../../../components/buttons/WhiteButton';
import useAppNotification from '../../../store/appNotification/appNotificationContext';
import useAuth from '../../../store/auth/authContext';
import DescriptionItem from '../../../components/descriptionLists/DescriptionItem';
import useJobOrders from '../../../store/jobOrders/jobOrdersContext';
import { SocketContext } from '../../../store/socket/socketProvider';

function UpdateApplicationStatusModal({show, hide, previousApplicationData, setUpdatedApplicationData, updatedApplicationData}) {

  const { addApplicationLog } = useJobOrders();
  const { showAppNotification, appNotificationTypes } = useAppNotification();
  const { auth: { currentUser } } = useAuth();

  const handleCancel = () => {
    hide(false);
    setTimeout(() => {
      setUpdatedApplicationData({
        applicant_id: null,
        job_order_id: null,
        cost: null, 
        status: null,
        description: null,
        created_by: null,
      })
    }, 300)
  }


  const socket = React.useContext(SocketContext);

  const handleUpdate = () => {
    addApplicationLog(updatedApplicationData.job_order_id, updatedApplicationData).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Updated candidate application');
      let newApplication = {
        ...res.data,
        candidate: {
          ...previousApplicationData.candidate
        },
        user: {
          first_names: currentUser.first_names,
          id: currentUser.id,
        }
      }
      socket.emit("add-application", {
        jobOrderId: updatedApplicationData.job_order_id,
        newApplication,
        currentUserId: currentUser.id
      });
      
      hide(false);
    });
  }
  
  return (
    <Modal show={show} hide={hide} title={'Update application'}>
      <div className="grid grid-cols-2 ">
         <div className='col-span-1'>
          <DescriptionItem 
            label={'Old status'}
            data={applicationStatuses[previousApplicationData.status]}
          />
         </div>
         <div className='col-span-1'>
           <DescriptionItem 
             label={'New status'}
             data={applicationStatuses[updatedApplicationData.status]}
           />
         </div>
         <div className='col-span-2 mt-4'>
          <TextArea 
            label='Update description'
            onChange={(e) => setUpdatedApplicationData(prev => ({...prev, description: e.target.value}))}
          />
         </div>
         <div className='mt-4 col-span-2 flex flex-row-reverse gap-x-4'>
          <PrimaryButton darkRing text='Update' onClick={handleUpdate}/>          
          <WhiteButton text='Cancel' onClick={handleCancel}/>
         </div>
      </div>
    </Modal>
  )

}

export default UpdateApplicationStatusModal
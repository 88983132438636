import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import EditFrameworkContractForm from '../../containers/frameworkContracts/EditFrameworkContractForm'
import useApi from '../../store/api/apiContext'
import Loader from '../../components/loaders/Loader'
import axios from 'axios'
import useNavigateBack from '../../hooks/useNavigateBack'


function EditFrameworkContract({ access }) {
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const { fetchFrameworkContract } = useApi();
  const [ isLoading, setIsLoading ] = useState(true);

  const [ frameworkContract, setFrameworkContract ] = useState(null)

  
  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchFrameworkContract(id, source).then(res => {
      setFrameworkContract(res.data);
      setIsLoading(false)
    })
    return () => {
      source.cancel();
    }; 
  }, [])  
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigateBack(`/fw-contracts`) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Edit framework contract'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <EditFrameworkContractForm frameworkContract={frameworkContract} />
      </Loader>
    </PageWrapper>
  )
}

export default EditFrameworkContract
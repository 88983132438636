import React, {useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../store/auth/authContext';
import Logo from '../../components/app/Logo';
import useWindowSize from '../../hooks/useWindowSize';
import { RECAPTCHA_SITE_KEY } from '../../config';
function LoginForm() {

  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const windowSize = useWindowSize();

  const isSmallScreen = windowSize.width <= windowSize.tailwind.sm;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Get the reCAPTCHA token from the window object
    
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');
    const captchaToken = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY.toString(), { action: 'submit' });

    login({ email, password, captchaToken }).then(() => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page
      const from = location.state?.from?.pathname || "/dashboard";
      navigate(from, { replace: true });
    })
  }

  useEffect(() => {

    //Add recaptcha api script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        {isSmallScreen ? <Logo white size="md" /> : <Logo white size="xl" />}
        <h2 className="mt-6 text-3xl font-extrabold text-white">
          Sign in to your account
        </h2>
      </div>
      <form method="submit" onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-white"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 sm:text-sm"
            />
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-white"
          >
            Password
          </label>
          <div className="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="appearance-none block w-full border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-thaleria-orange-600 focus:border-thaleria-orange-600 sm:text-sm"
            />
          </div>
        </div>
        
        <button
          class="g-recaptcha"
          data-sitekey={RECAPTCHA_SITE_KEY.toString()}
          type="submit"
          className="w-full mt-6 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-thaleria-orange-700 hover:bg-thaleria-orange-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-thaleria-orange-500"
        >
          Sign in
        </button>
      </form>
    </div>
  );
}

export default LoginForm
import React from "react";
import PageWrapper from "../containers/app/PageWrapper.js";
import ResetPasswordForm from "../containers/auth/ResetPasswordForm.js";
import useAuth from "../store/auth/authContext.js";
import { Navigate, useLocation } from "react-router";

function TemporaryPasswordPage() {

  const { auth } = useAuth();

  let location = useLocation();


    if (auth.isAuthenticated && !auth.currentUser.temp_password)
      return (
        <Navigate to="/dashboard" state={{ from: location }} replace />
      );

  
  return (
    <PageWrapper
      override
      title="Reset your password"
      childrenClass="h-full flex bg-thaleria-blue-700 w-full items-center justify-middle"

    >
        <ResetPasswordForm />
    </PageWrapper>
  );
}

export default TemporaryPasswordPage;

import React, { useState, useEffect } from 'react'
import Modal from "../../../components/modal/Modal";
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import NumberInput from '../../../components/inputs/NumberInput'
import TextArea from '../../../components/inputs/TextArea'
import useAppNotification from '../../../store/appNotification/appNotificationContext';
import DescriptionItem from '../../../components/descriptionLists/DescriptionItem';
import useJobOrders from '../../../store/jobOrders/jobOrdersContext';
import useAuth from '../../../store/auth/authContext';
import { SocketContext } from '../../../store/socket/socketProvider';

function EditApplicationDetailsModal({ show, hide, application, jobOrder }) {
  
  const [editedApplication, setEditedApplication ] = useState({});

  const { editApplicationLog } = useJobOrders();

  const socket = React.useContext(SocketContext);

  const {
    auth: {
      currentUser: { id: currentUserId },
    },
  } = useAuth();
  
  const { showAppNotification, appNotificationTypes } = useAppNotification();

  useEffect(() => {
    setEditedApplication(application)
  }, [application])

  const handleEditApplication = () => {
    let data = {
      description: editedApplication.description,
      cost: editedApplication.cost === "" ? null : editedApplication.cost,
    };
    editApplicationLog(application.id, data, currentUserId).then((res) => {
      showAppNotification(
        appNotificationTypes.SUCCESS,
        "Success",
        "Application details edited"
      );
      let updatedApplication = {
        ...application,
        ...res.data,
        candidate: {
          first_names: application.candidate.first_names,
          last_names: application.candidate.last_names,
          is_within_scope: true,
          originalOwner: {
            first_names: application.candidate.owner_first_names,
            last_names: application.candidate.owner_last_names,
            is_within_scope: true,
          },
        },
        user: {
          first_names: application.candidate.first_names,
        },
      };

      let newArray = jobOrder.applicationStatusLog.map((e) => {
        if (e.id === application.id) return updatedApplication;
        return e;
      });

      socket.emit("edit-application", {
        jobOrderId: jobOrder.id,
        updatedApplications: newArray,
        currentUserId,
        updatedApplicationId: application.id
      });
    });
    hide(false);
  }

  return (
    <Modal show={show} hide={hide} title={'Add candidate application'}>
      <div className='h-full flex flex-col justify-between space-y-2'>
        <div>
          <DescriptionItem 
            data={application?.candidate?.originalOwner?.first_names}
            label={'Belongs to'}
          />
        </div>
        <div>
          <NumberInput 
            label='Cost (€/day)'
            onChange={(e) => setEditedApplication(prev => ({...prev, cost: e.target.value}))}
            value={editedApplication.cost}
          />
        </div>
        <div>
          <TextArea
            label='Description'
            onChange={(e) => setEditedApplication(prev => ({...prev, description: e.target.value}))}
            value={editedApplication.description}
            rows={3}
          />
        </div>
        <div className='w-full flex flex-row-reverse mt-4'>
          <PrimaryButton text={'Edit application'} onClick={handleEditApplication}/>
        </div>
      </div>
    </Modal>
  )
}

export default EditApplicationDetailsModal
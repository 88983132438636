import React, { useState } from 'react'
import relativeTimeAgo from '../../utils/relativeTimeAgo'
import ProfilePicture from '../../components/profilePicture/ProfilePicture'
import { TrashIcon, PencilIcon } from '@heroicons/react/outline'
import AccessWrapper from '../app/AccessWrapper'
import role from '../../helpers/enum/role'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier'
import TextArea from '../../components/inputs/TextArea'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import RedButton from '../../components/buttons/RedButton'
import WarningModal from '../../components/modal/WarningModal'
import PrivateImage from '../../components/privateImage/PrivateImage'


function CommentCard ({comment, onEdit, onDelete}) {
  const name = `${comment.user.first_names} ${comment.user.last_names}`


  const { verifyUserId } = useUserRoleVerifier();
  let editCommentAccess = verifyUserId(comment.added_by) ? true : role.i_comments.i_4_manage_comments

  const [ showEditMessage, setShowEditMessage ] = useState(false)
  const [ editedMessage, setEditedMessage ] = useState(comment.message)
  const [ showDeleteWarning, setShowDeleteWarning ] = useState(false)
  
  const handleEdit = () => {
    setShowEditMessage(false)
    const commentData = {
      message: editedMessage
    }
    onEdit(comment.id, commentData)
  }

  const cancelEdit = () => {
    setEditedMessage(comment.message)
    setShowEditMessage(false)
  }

  return (
    <>
      <li key={comment.created_at} className="group">
        <div className="flex space-x-3 ">
          <div className="flex-shrink-0">
            <ProfilePicture notMyProfilePicture={!verifyUserId(comment.added_by)} user={comment.user} hover={false} small/>
          </div>
          <div className="w-full">
            <div className="text-sm w-full flex justify-between">
              <span className="font-medium text-gray-900">
                {name}
              </span>
              {!showEditMessage && <AccessWrapper access={editCommentAccess}>
                <div className="flex space-x-1">
                  <PencilIcon className="text-gray-500 -mb-0.5 hover:text-gray-700 hidden group-hover:block hover:cursor-pointer self-center w-3.5 h-3.5" onClick={() => setShowEditMessage(true)}/>
                  <TrashIcon className="text-gray-500 -mb-0.5 hover:text-red-700 hidden group-hover:block hover:cursor-pointer self-center w-3.5 h-3.5" onClick={() => setShowDeleteWarning(true)}/>
                </div>
                </AccessWrapper>}
            </div>
            <div className="mt-1 text-sm text-gray-700">
              {!showEditMessage && <p>{comment.message}</p>}
              {showEditMessage && <TextArea value={editedMessage} id="editComment" onChange={(e) => setEditedMessage(e.target.value)}/>}
            </div>
            <div className="mt-2 text-sm space-x-6 flex justify-between">
              <span className="text-gray-500 font-medium">{relativeTimeAgo(comment.created_at)}</span>{' '}
              <div className="flex space-x-1">
                {showEditMessage && <>
                  <RedButton size={'xs'} removeMinWidth text="Cancel" onClick={() => cancelEdit()}/>
                  <PrimaryButton size={'xs'} text='Edit' removeMinWidth onClick={() => handleEdit()}/>
                </>}
              </div>
            </div>
          </div>
        </div>
      </li>
      <WarningModal 
      title="Delete comment?" 
      message="Permanently erase this message by pressing confirm" 
      showWarning={showDeleteWarning} 
      buttonText="Confirm"
      onClick1={() => onDelete(comment)}
      onClick2={() => setShowDeleteWarning(false)}
      />
    </>
  )
}

export default CommentCard
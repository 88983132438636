import React, { useState, useRef } from 'react'
import useAppNotification from '../../store/appNotification/appNotificationContext';
import appNotificationTypes from '../../helpers/enum/appNotificationTypes';
import TextInput from '../../components/inputs/TextInput';
import FormWithSideTitle from '../../components/forms/FormWithSideTitle';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router';
import ReactSelect from '../../components/inputs/ReactSelect';
import useApi from '../../store/api/apiContext';
import COUNTRIES from '../../helpers/countryList'

function AddClientForm() {
  const navigate = useNavigate();
  const { addClient } = useApi();
  const { showAppNotification } = useAppNotification();
  const [ newClient, setNewClient ] = useState({
    name: null,
    city: null,
    country: null,
    phone_nb: null,
    sector: null,
  })
  const [ formError, setFormError ] = useState()
  const [ selectedCountry, setSelectedCountry ] = useState()
  const [ selectedSector, setSelectedSector ] = useState()

  const nameInputRef = useRef(null)
  
  const postAddClient = async () => {
    let errors = [];

    if (!newClient.name) errors.push({
      field: "Name*",
      msg: "This field is required"
    })

    if (newClient.phone_nb && !(/^[\d +]+$/.test(newClient.phone_nb))) errors.push({
      field: 'Phone number',
      msg: "Can only contain numbers and (+) sign."
    })
    
    if (errors.length) {
      nameInputRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: 'nearest'})
      return setFormError(errors);
    }

    setFormError([]);
    
    addClient(newClient).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Client added successfully');
      navigate('/clients', { replace: true })
    })
  }

  const changeHandlerSelectCountry = (e) => {
    setNewClient(prev => ({...prev, country: e.value }))
    setSelectedCountry(e)
  }

  const changeHandlerSelectSector = (e) => {
    setNewClient(prev => ({...prev, sector: e.value }))
    setSelectedSector(e)
  }

  const sectorOptions = [
    {
    label: "Private",
    value: "Private"
    },
    {
      label: "Public",
      value: "Public"
    }
  ]

  let countryOptions = []

  COUNTRIES.forEach(country => {
    countryOptions.push({
      label: country.name,
      value: country.code
    })
  })

  return (
    <div className="space-y-6">
      <FormWithSideTitle title='Client information' description='Add client information' >
        <div ref={nameInputRef} className="col-span-6 sm:col-span-3">
          <TextInput label='Name*' value={newClient.name} errors={formError} onChange={(e) => setNewClient(prev => ({...prev, name: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <TextInput label='Phone number' value={newClient.phone_nb} errors={formError} onChange={(e) => setNewClient(prev => ({...prev, phone_nb: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Sector' selectedOption={selectedSector} errors={formError} options={sectorOptions} onChange={changeHandlerSelectSector}/>
        </div>
      </FormWithSideTitle>
      <FormWithSideTitle title='Location' description='Add country and city' >
      <div className="col-span-6 sm:col-span-3">
          <TextInput label='City' value={newClient.city} errors={formError} onChange={(e) => setNewClient(prev => ({...prev, city: e.target.value }) )}/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <ReactSelect label='Country' selectedOption={selectedCountry} errors={formError} options={countryOptions} onChange={changeHandlerSelectCountry}/>
        </div>
      </FormWithSideTitle>
      <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
        <WhiteButton text='Cancel' onClick={() => navigate(-1) }/>
        <PrimaryButton text='Add' darkRing onClick={postAddClient}/>
      </div>
    </div>
  )
}

export default AddClientForm
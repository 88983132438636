import React from 'react'
import dayjs from 'dayjs'
import { ArchiveIcon, ThumbDownIcon, UserRemoveIcon, ClockIcon, ThumbUpIcon, BadgeCheckIcon, ChatAlt2Icon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import Modal from "../../../components/modal/Modal";
import { applicationStatusIDs, applicationStatuses } from '../../../helpers/enum/applicationStatuses';
import useUserRoleVerifier from '../../../hooks/useUserRoleVerifier';
import AccessWrapper from '../../app/AccessWrapper';
import role from '../../../helpers/enum/role';
import utc from 'dayjs/plugin/utc'

function ApplicationHistoryModal({show, hide, applicationHistory, deleteApplication}) {

  
  dayjs.extend(advancedFormat, utc)
  const {verifyBoolAccess, verifyUserId} = useUserRoleVerifier();
  const iconSelector = (status) => {
    switch(status) {
      case applicationStatusIDs.proposed:
        return (
          <span className="h-8 w-8 rounded-full bg-yellow-500 flex items-center justify-center ring-8 ring-white">
            <QuestionMarkCircleIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.submitted:
        return (
          <span className="h-8 w-8 rounded-full bg-yellow-500 flex items-center justify-center ring-8 ring-white">
            <ClockIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.interviewing:
        return (
          <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
            <ChatAlt2Icon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.clientDeclined:
        return (
          <span className="h-8 w-8 rounded-full bg-gray-500 flex items-center justify-center ring-8 ring-white">
            <ThumbDownIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.clientAccepted:
        return (
          <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
            <ThumbUpIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.placed:
        return (
          <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
            <BadgeCheckIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.candidateRefused:
        return (
          <span className="h-8 w-8 rounded-full bg-gray-500 flex items-center justify-center ring-8 ring-white">
            <ThumbDownIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.candidateWithdrew:
        return (
          <span className="h-8 w-8 rounded-full bg-gray-500 flex items-center justify-center ring-8 ring-white">
            <UserRemoveIcon className= "h-5 w-5 text-white"/>
          </span>
        )
      case applicationStatusIDs.notInConsideration:
        return (
          <span className="h-8 w-8 rounded-full bg-gray-500 flex items-center justify-center ring-8 ring-white">
            <ArchiveIcon className= "h-5 w-5 text-white"/>
          </span>
        )
    }
  }

  return (
    <Modal show={show} hide={hide} title={'Application history'} size='lg'>
      <div className="flow-root h-60 overflow-y-auto bottom-0">
        <ul role="list" className="-mb-8">
          {applicationHistory.map((item, index) => {
            return (
              <li key={item.id}>
                <div className={`relative ${index !== applicationHistory.length-1 ? "pb-8" : "pb-0"}`}>
                  {index !== applicationHistory.length-1 && <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
                  <div className="relative flex space-x-3 group">
                    <div>
                      {iconSelector(item.status)}
                    </div>
                    <div className="min-w-0 flex-1 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">{item.user.first_names} updated the status to <span className="font-medium text-gray-900">{applicationStatuses[item.status]}</span></p>
                        <p className="text-sm text-gray-900">{item.description}</p>
                      </div>
                      {/* FIXME: Is access management to remove application missing here? */}
                      <div className="text-right text-sm whitespace-nowrap flex space-x-1 text-gray-500">
                        <span>{dayjs.utc(item.created_at).format('dddd, MMM Do')}</span>
                        <AccessWrapper access={verifyBoolAccess(role.h_job_orders.h_3_4_update_application_status) || (item.status == applicationStatusIDs.proposed && verifyUserId(item.created_by))}>
                          <XCircleIcon className='h-5 w-5 text-red-400 cursor-pointer hidden group-hover:flex hover:text-red-600' onClick={() => deleteApplication(item.id)}/>
                        </AccessWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </Modal>
  )

}

export default ApplicationHistoryModal
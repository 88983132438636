const role = {
  a_name: 'a_name',
  b_manage_user_groups: 'b_manage_user_groups',
  c_manage_roles: 'c_manage_roles',
  d_manage_framework_contracts: 'd_manage_framework_contracts',
  e_manage_clients: 'e_manage_clients',
  f_users: {
    f_1_view_users: 'f_1_view_users',
    f_1_1_allowed_user_group_ids: 'f_1_1_allowed_user_group_ids',
    f_1_2_allowed_role_ids: 'f_1_2_allowed_role_ids',
    f_1_3_viewable_properties: 'f_1_3_viewable_properties',
    f_2_manage_users: 'f_2_manage_users',
    f_2_1_allowed_user_group_ids: 'f_2_1_allowed_user_group_ids',
    f_2_2_allowed_role_ids: 'f_2_2_allowed_role_ids',
    f_2_3_create_users: 'f_2_3_create_users',
    f_2_4_edit_users: 'f_2_4_edit_users',
    f_2_4_1_editable_properties: 'f_2_4_1_editable_properties',
    f_2_5_delete_users: 'f_2_5_delete_users',
    f_2_6_reset_user_password: 'f_2_6_reset_user_password'
  },
  g_candidates: {
    g_1_create_my_candidate: 'g_1_create_my_candidate',
    g_2_edit_my_candidate: 'g_2_edit_my_candidate',
    g_2_1_editable_properties_my_candidate: 'g_2_1_editable_properties_my_candidate',
    g_3_delete_my_candidate: 'g_3_delete_my_candidate',
    g_4_manage_candidates: 'g_4_manage_candidates',
    g_4_1_allowed_owner_user_group_ids: 'g_4_1_allowed_owner_user_group_ids',
    g_4_2_create_candidates: 'g_4_2_create_candidates',
    g_4_3_edit_candidates: 'g_4_3_edit_candidates',
    g_4_3_1_editable_properties: 'g_4_3_1_editable_properties',
    g_4_4_delete_candidates: 'g_4_4_delete_candidates',
  },
  h_job_orders: {
    h_1_view_job_orders: 'h_1_view_job_orders',
    h_1_1_viewable_properties: 'h_1_1_viewable_properties',
    h_1_2_allowed_fwc: 'h_1_2_allowed_fwc',
    h_1_3_assign_myself_as_recruiter: 'h_1_3_assign_myself_as_recruiter',
    h_2_create_job_orders: 'h_2_create_job_orders',
    h_2_1_edit_created_job_orders: 'h_2_1_edit_created_job_orders',
    h_2_2_delete_created_job_orders: 'h_2_2_delete_created_job_orders',
    h_3_manage_job_orders: 'h_3_manage_job_orders',
    h_3_1_update_job_order_status: 'h_3_1_update_job_order_status',
    h_3_2_assign_recruiters: 'h_3_2_assign_recruiters',
    h_3_3_assign_applicants: 'h_3_3_assign_applicants',
    h_3_4_update_application_status: 'h_3_4_update_application_status',
    h_3_5_remove_applicant: 'h_3_5_remove_applicant',
    h_3_6_edit_job_order: 'h_3_6_edit_job_order',
    h_3_6_1_editable_properties: 'h_3_6_1_editable_properties',
    h_3_7_delete_job_order: 'h_3_7_delete_job_order',
  },
  i_comments: {
    i_1_view_job_order_comments: 'i_1_view_job_order_comments',
    i_2_view_candidate_comments: 'i_2_view_candidate_comments',
    i_3_view_client_comments: 'i_3_view_client_comments',
    i_4_manage_comments: 'i_4_manage_comments',
  },
  j_attachments :{
    j_1_view_job_order_attachments: 'j_1_view_job_order_attachments',
    j_2_view_candidate_attachments: 'j_2_view_candidate_attachments',
    j_3_manage_attachments: 'j_3_manage_attachments'
  }
}

export default role;
export const applicationStatuses = {
  1: 'Proposed',
  2: 'Submitted',
  3: 'Interviewing',
  4: 'Client declined',
  5: 'Client accepted', 
  6: 'Placed',
  7: 'Candidate refused', 
  8: 'Candidate withdrew',
  9: 'Not in consideration',
}

export const applicationStatusIDs = {
  proposed: 1,
  submitted: 2,
  interviewing: 3,
  clientDeclined: 4,
  clientAccepted: 5,
  placed: 6,
  candidateRefused: 7,
  candidateWithdrew: 8,
  notInConsideration: 9,
}

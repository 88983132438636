import React, { useState, useEffect } from 'react'
import axios from 'axios';
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate, useParams } from 'react-router'
import useApi from '../../store/api/apiContext'
import EditCandidateForm from '../../containers/candidates/EditCandidateForm'
import Loader from '../../components/loaders/Loader';
import useNavigateBack from '../../hooks/useNavigateBack';

function EditCandidate({ access }) {
  const navigateBack = useNavigateBack();
  const { id } = useParams();

  const [ candidate, setCandidate ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchCandidate } = useApi();
  
  const fetchCandidateFromApi = async (id, source) => {
    fetchCandidate(id, source).then(res => {
      setCandidate(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchCandidateFromApi(id, source);
    return () => {
      source.cancel();
    };
  }, [id])
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigateBack(`/candidates`) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Edit candidate'} topSection={renderTopSection()} access={access}>
      <Loader isLoading={isLoading}>
        <EditCandidateForm candidate={candidate}/>
      </Loader>
    </PageWrapper>
  )
}

export default EditCandidate
import React from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router'
import AddRoleForm from '../../containers/roles/AddRoleForm'


function AddRole({ access }) {
  const navigate = useNavigate();
  
  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton text='Cancel' onClick={() => navigate('/roles', {replace: true}) }/>
      </>
    )
  }
  
  return (
    <PageWrapper title={'Add role'} topSection={renderTopSection()} access={access}>
      <AddRoleForm />
    </PageWrapper>
  )
}

export default AddRole
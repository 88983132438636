import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import ApplicationListItem from './ApplicationListItem';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { applicationStatuses } from '../../../helpers/enum/applicationStatuses';
import { UserAddIcon } from '@heroicons/react/solid';
import ApplicationHistoryModal from './ApplicationHistoryModal';
import useJobOrders from '../../../store/jobOrders/jobOrdersContext';

function ApplicationListSection({showApplicants, setShowApplicants, status, uniqueApplications, applications, jobOrder, touchUpdate, setTouchUpdate, newStatus, setNewStatus }) {

  const { deleteApplicationLog } = useJobOrders();

  const [showApplicationHistoryModal, setShowApplicationHistoryModal] = useState(false)
  const [applicationHistory, setApplicationHistory] = useState([])

  const deleteApplication = async (applicationId) => {
    deleteApplicationLog(applicationId, jobOrder.id).then(res => {
      setApplicationHistory(
        applicationHistory.filter(
          (_application) => _application.id != applicationId
        )
      );
    })
  }

  
  const [{ canDrop, isOverCurrent }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    drop: () => ({ name: status }),
    // Props to collect
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    })
  }))

  const handleShowApplicants = () => {
    setShowApplicants(prev => ({...prev, [status]: !showApplicants[status]}))
  }  

  const renderStyle = () => {
    if (!canDrop) return;
    if (isOverCurrent) {
      return ({
        backgroundColor: 'rgb(240 253 244)'
      })
    } else return ({
      backgroundColor: 'rgb(249 250 251)'
    })
  }

  const showHistoryModalHandler = (id) => {
    setApplicationHistory(applications.filter(_application => _application.applicant_id === id))
    setShowApplicationHistoryModal(!showApplicationHistoryModal)
  }

  return (
    <div>
      <ApplicationHistoryModal show={showApplicationHistoryModal} hide={() => setShowApplicationHistoryModal(false)} applicationHistory={applicationHistory} deleteApplication={deleteApplication}/>
      <div 
        ref={drop} role='Dustbin'
        onClick={handleShowApplicants} 
        className={`flex justify-between hover:bg-gray-100 cursor-pointer border-t ${showApplicants[status] && 'border-b'} border-gray-200 bg-gray-50 px-6 py-1 font-medium ${isOverCurrent ? 'text-green-700' : 'text-gray-500'}`}
        style={renderStyle()}
        key={status}
      >
        <div className='flex space-x-2'>
          <span>
            {applicationStatuses[status]}  
          </span> 
          {canDrop && 
          <>
            {isOverCurrent ? <UserAddIcon className={`h-5 w-5 text-green-600`}/> : <UserAddIcon className={`h-5 w-5 text-gray-400`}/>}
          </> 
          }
          {touchUpdate && touchUpdate != status*1 && <UserAddIcon className={`h-5 w-5 text-green-600`} onClick={() => setNewStatus(status)}/>}
        </div>
        <div className='flex space-x-2'>
          <span className='w-12 bg-gray-200 text-gray-500 rounded-full text-center'>{!!uniqueApplications.length && uniqueApplications.filter(application => application.status == status).length || '0'}</span>
          {showApplicants[status] && <ChevronDownIcon className=' h-5 w-5'/>}
          {!showApplicants[status] && <ChevronRightIcon className='h-5 w-5'/>}
        </div>
      </div>
      {showApplicants[status] && 
      <ul className='divide-y'>
      {!!uniqueApplications.length && uniqueApplications.some(application => application.status == status) ? uniqueApplications.map(application => {
        if (application.status == status) {
          return (
            <ApplicationListItem 
              jobOrder={jobOrder} 
              application={application} 
              showHistoryModalHandler={showHistoryModalHandler}
              key={application.id}
              touchUpdate={touchUpdate}
              setTouchUpdate={setTouchUpdate}
              newStatus={newStatus}
              setNewStatus={setNewStatus}
            />
          )
        }
        }) : <li className="px-4 py-4 sm:px-6 text-sm">No applicants to show</li>}

      </ul>
      }
    </div>
  )
}

export default ApplicationListSection
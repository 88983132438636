import React, { useState, useEffect } from 'react';
import FormWithSideTitle from '../../../components/forms/FormWithSideTitle';
import useApi from '../../../store/api/apiContext';
import ViewUsers from './ViewUsers';
import ManageUsers from './ManageUsers';

function UserActions({ newRole, setNewRole, setManageAllCandidates }) {
  
  //fetch initial data
  const { fetchUserGroups, fetchUserProperties, fetchRoles } = useApi();
  const [ userGroups, setUserGroups ] = useState();
  const [ userProperties, setUserProperties ] = useState()
  const [ roles, setRoles ] = useState();

  useEffect(() => {
    fetchUserGroups().then(res => {
      setUserGroups(res.data);
    })
    fetchUserProperties().then(res => {
      //remove properties that should not be in config
      let filtered = res.data.filter(el => el !== 'id' && el !== 'uuid')
      setUserProperties(filtered);
    })
    fetchRoles().then(res => {
      setRoles(res.data);
    })
  }, [])
  
  const [viewAllUserProperties, setViewAllUserProperties] = useState(false);
  const [viewableUserProperties, setViewableUserProperties] = useState([]);

  const [viewUsersFromAllUserGroups, setViewUsersFromAllUserGroups] = useState(false);
  const [viewUsersWithAnyRole, setViewUsersWithAnyRole] = useState(false);

  const [ editableUserProperties, setEditableUserProperties ] = useState([]);
  const [ editAllUserProperties, setEditAllUserProperties ] = useState(false);

  const [ manageAllViewableUserGroups, setManageAllViewableUserGroups ] = useState(false);
  const [ manageAllViewableRoles, setManageAllViewableRoles ] = useState(false);

  return (
    <FormWithSideTitle title='User actions' description='Set which user actions your role will enable' >
      <ViewUsers 
        userGroups={userGroups}
        roles={roles}
        userProperties={userProperties}
        viewAllUserProperties={viewAllUserProperties}
        setViewAllUserProperties={setViewAllUserProperties}
        setViewableUserProperties={setViewableUserProperties}
        viewableUserProperties={viewableUserProperties}
        viewUsersFromAllUserGroups={viewUsersFromAllUserGroups}
        setViewUsersFromAllUserGroups={setViewUsersFromAllUserGroups}
        setViewUsersWithAnyRole={setViewUsersWithAnyRole}
        viewUsersWithAnyRole={viewUsersWithAnyRole}
        newRole={newRole}
        setNewRole={setNewRole}
        setEditableUserProperties={setEditableUserProperties}
        setEditAllUserProperties={setEditAllUserProperties}
        setManageAllCandidates={setManageAllCandidates}
      />
      {newRole.f_1_view_users &&
        <ManageUsers 
          newRole={newRole}
          setNewRole={setNewRole}
          viewAllUserProperties={viewAllUserProperties}
          viewableUserProperties={viewableUserProperties}
          editableUserProperties={editableUserProperties}
          setEditableUserProperties={setEditableUserProperties}
          editAllUserProperties={editAllUserProperties}
          setEditAllUserProperties={setEditAllUserProperties}
          userProperties={userProperties}
          userGroups={userGroups}
          roles={roles}
          manageAllViewableUserGroups={manageAllViewableUserGroups}
          setManageAllViewableUserGroups={setManageAllViewableUserGroups}
          manageAllViewableRoles={manageAllViewableRoles}
          setManageAllViewableRoles={setManageAllViewableRoles}
        />
      }

    </FormWithSideTitle>
  )
}

export default UserActions
import PageWrapper from "../containers/app/PageWrapper"
import LoginForm from '../containers/auth/LoginForm';
import img from '../resources/eu-commission.jpeg'

export default function Login() {
 
  return (
    <PageWrapper
      override
      title="Login"
      childrenClass="h-full flex"
      requireAuth={false}
    >
      <div className="flex-1 bg-thaleria-blue-700 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <LoginForm />
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={img}
          alt=""
        />
      </div>
    </PageWrapper>
  );
}

import React, {useEffect, useState} from 'react'
import useApi from '../../store/api/apiContext'
import NotificationsDropdown from '../../components/notifications/NotificationsDropdown'

const UserNotifications = () => {

const [show, setShow] = useState(false)
const [notifications, setNotifications] = useState()

const {getLatestNotifications} = useApi()

useEffect(() => {
    getLatestNotifications().then(res => {
      setNotifications(res.data)
    })
    .catch(err => {
      console.log(err, 'error')
    })
  }, [show])

    
  return (
    <>
      <NotificationsDropdown items={notifications} id="userNotifications" />
    </>
  )
}

export default UserNotifications
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { APP_STYLES } from '../../config';
import { ExclamationCircleIcon } from '@heroicons/react/solid'  
import { LockClosedIcon } from '@heroicons/react/outline';


function ReactSelect({ label, options, isMulti, onChange, selectedOption, selectedOptions, customStyles, labelClass, inputClass, id, placeholder, errors, disabled, ...rest }) {  
  useEffect(() => {
    if (selectedOptions?.length && placeholder) alert('React Select dropdown cannot have a placeholder and selectedOptions at the same time')
  })

  const [error, setError] = useState({});

  useEffect(() => {
    if (!errors?.length) return;
    let myError = errors.find(error => error.field === label);
    if (myError) setError(myError)
    if (!myError) setError({})
  }, [errors])

  const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '' : '',
        backgroundColor: state.isSelected ? 'rgba(229, 231, 235)' : '',
        ':hover': {
          backgroundColor: state.isSelected ? 'rgb(229 231 235)' : 'rgb(229 231 235)',
        }
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: `${disabled ? "rgb(229 231 235)" : "white"}`,
      outline: state.isFocused ? '0px solid red' : '',
      outlineOffset: state.isFocused ? '16px' : '8px',
      borderRadius: '0.5rem',
      fontSize: '0.85rem',
      lineHeight: 1,
      // paddingLeft: '0.75rem',
      marginTop: '0.25rem',
      height: 'auto',
      boxShadow: 'none',
      borderStyle: '1px solid rgb(209 213 219)',
      border: state.isFocused ? `1px solid ${APP_STYLES.secondaryColor}` : '1px solid rgb(209 213 219)',
      boxShadow: state.isFocused ? `0 0 0 1px ${APP_STYLES.secondaryColor}` : '',
      '&:hover': {
       border: state.isFocused ? `1px solid ${APP_STYLES.secondaryColor}` : '1px solid rgb(209 213 219)' 
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingTop: '5px',
      paddingBottom: '5px'
    }),
    singleValue: (provided) => ({
      ...provided,
      lineHeight: '1.25',
      marginTop: '1px'
    }),
  };

  /* Expected options format
  const options = [{
    value: 'value',
    label: 'label',
  }] */
  
  return (
    <>
      <label id={id} className={`text-sm font-medium text-gray-700 flex ${labelClass}`}>
        { label }
        {error?.msg && <span className="text-red-400 flex"> <ExclamationCircleIcon className="h-5 w-5 ml-2 mr-1"/> {error.msg}</span>}
      </label>
      <div className={`${inputClass} w-full relative`}>
        {!isMulti && <Select 
          options={options} 
          placeholder={placeholder} 
          styles={customStyles ? customStyles : selectStyles} 
          value={selectedOption || { label: ''}} 
          onChange={onChange}
          isDisabled={disabled} 
          { ...rest } 
        />}
        {isMulti && <Select 
          options={options}
          placeholder={placeholder} 
          defaultValue={selectedOptions} 
          styles={customStyles ? customStyles : selectStyles} 
          isMulti 
          onChange={onChange} 
          className="basic-multi-select"
          classNamePrefix="select"
          isDisabled={disabled}
          { ...rest } 
        />}
        {disabled && <LockClosedIcon className='h-5 w-5 my-auto mr-2 text-gray-500 absolute inset-y-0 right-0'/>}
      </div>
    </>
  )
}

export default ReactSelect

const jobOrderProperties = {
  id: 'ID',
  uuid: 'UUID',
  title: 'Title',
  description: 'Description',
  yrs_of_exp: 'Years of experience',
  education_level: 'Education level',
  client_job_id: 'Client Job ID',
  client_id: 'Client',
  framework_contract_id: 'Framework Contract',
  legacy_id: 'Legacy id',
  legacy_description: 'Legacy description',
  city: 'City',
  country: 'Country',
  sector: 'Sector',
  deadline: 'Deadline',
  duration: 'Duration',
  openings: 'Openings',
  placement_type: 'Placement type',
  request_type: 'Request type',
  mode: 'Mode',
  recruiter: 'Recruiter',
  sales_price: 'Sales price',
  skills: 'Skills', //foreign association
  languages: 'Languages', //foreign association
  priority: 'Priority',
  psc_required: 'PSC required',
  created_by: 'Created by',
  is_published: 'Published',
  industry: 'Industry',
  price_range: "Price range",
  summary: 'Summary',
  created_at: 'Creation date',
  updated_at: 'Updated at',
  deleted_at: 'Deleted at'
}

export default jobOrderProperties;
import React from 'react'
import WhiteButton from '../../components/buttons/WhiteButton'
import { useNavigate } from 'react-router'

function CandidateItem({ candidate }) {
  const navigate = useNavigate();
  
  return (
    <li key={candidate.id} className="py-4 group">
      <div className="flex items-center space-x-4">
        <div className="flex-1 min-w-0 flex items-center">
          <p className="text-sm font-medium text-gray-900 truncate">{candidate.first_names + ' ' + candidate.last_names}</p>
        </div>
        <div className='flex items-center space-x-2'>
          <WhiteButton text='View' linkTo={'/candidates/' + candidate.id} size='xs' rounded/>            
        </div>        
      </div>
    </li>
  )
}

export default CandidateItem
import React from 'react'
import DescriptionList from '../../../components/descriptionLists/DescriptionList'
import userProperties from '../../../helpers/enum/userProperties';
import { ALL_ACCESS_UUID } from '../../../config';

function UserActionsDetails({ role, userGroups, roles }) {

  const returnLabel = (array, labels) => {
    if (array[0] === ALL_ACCESS_UUID) return 'All';
    return array.map(property => labels[property]).join(', ');
  }
  const returnName = (array, otherArray, propertyToReturn) => {
    if (array[0] === ALL_ACCESS_UUID) return 'All';
    if (array[0] === "" && array.length === 1) return;
    return array.map(thingId => otherArray.find(thing => thing.id === thingId)[propertyToReturn]).join(', ');
  }

 const items = [
    { label: 'View users', data: role.f_1_view_users ? 'Yes' : 'No', id: 1, show: true },
    { label: 'View users: allowed user groups', data: returnName(role.f_1_1_allowed_user_group_ids, userGroups, 'name'), id: 2, show: role.f_1_view_users },
    { label: 'View users: allowed roles', data: returnName(role.f_1_2_allowed_role_ids, roles, 'a_name'), id: 3, show: role.f_1_view_users },
    { label: 'View users: viewable properties', data: returnLabel(role.f_1_3_viewable_properties, userProperties), id: 4, show: role.f_1_view_users },
    { label: 'Manage users', data: role.f_2_manage_users ? 'Yes' : 'No', id: 5, show: role.f_1_view_users },
    { label: 'Manage users: allowed user groups', data: returnName(role.f_2_1_allowed_user_group_ids, userGroups, 'name'), id: 6, show: role.f_2_manage_users },
    { label: 'Manage users: allowed roles', data: returnName(role.f_2_2_allowed_role_ids, roles, 'a_name'), id: 7, show: role.f_2_manage_users },
    { label: 'Manage users: create users', data: role.f_2_3_create_users ? 'Yes' : 'No', id: 8, show: role.f_2_manage_users },
    { label: 'Manage users: edit users', data: role.f_2_4_edit_users ? 'Yes' : 'No', id: 9, show: role.f_2_manage_users },
    { label: 'Edit users: editable properties', data: returnLabel(role.f_2_4_1_editable_properties, userProperties), id: 10, show: role.f_2_manage_users },
    { label: 'Manage users: delete users', data: role.f_2_5_delete_users ? 'Yes' : 'No', id: 11, show: role.f_2_manage_users },
  ]
  return (
    <DescriptionList title='User actions' items={items}/>
  )
}

export default UserActionsDetails
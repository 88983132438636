import React, { useState } from 'react'
import Modal from '../../components/modal/Modal';
import validatePassword from '../../utils/validatePassword';
import useApi from '../../store/api/apiContext';
import { passwordTypes as passwordTypesEnum } from '../../helpers/enum/passwordTypes';
import useAppNotification from '../../store/appNotification/appNotificationContext';
import TextInput from '../../components/inputs/TextInput';
import WhiteButton from '../../components/buttons/WhiteButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import ReactSelect from '../../components/inputs/ReactSelect';

function ResetPasswordModal({ show, hide, userId }) {
  
  const { showSuccessNotification } = useAppNotification();

  const { resetPassword } = useApi();

  const [formError, setFormError] = useState([])

  const [ password, setPassword ] = useState(null)
  
  const [passwordTypes, setPasswordTypes] = useState([
    {
      label: 'Set custom password',
      value: passwordTypesEnum.customPassword
    },
    {
      label: "Send to user's email",
      value: passwordTypesEnum.newUserEmail
    },
    {
      label: 'Send to my email',
      value: passwordTypesEnum.myOwnEmail
    },
  ])
  const [selectedPasswordType, setSelectedPasswordType] = useState({
    label: 'Set custom password',
    value: passwordTypesEnum.customPassword
  })

  const handleSubmit = () => {
    let errors=[]
    if (!selectedPasswordType.value) errors.push({
      field: "Password",
      msg: "This field is required"
    })
    if (selectedPasswordType.value === passwordTypesEnum.customPassword && !password) errors.push({
      field: "Custom password",
      msg: "This field is required"
    })
    if (selectedPasswordType.value === passwordTypesEnum.customPassword && !validatePassword(password)) errors.push({
      field: "Custom password",
      msg: "Password must contain at least 8 characters, upper and lower case letters, a number and a special character"
    })
    if (errors.length) {
      return setFormError(errors);
    }
    setFormError([]);

    resetPassword(userId, selectedPasswordType.value, password).then(() => {
      showSuccessNotification('Password was reset');
      handleHide();
    })
  }

  const handleHide = () => {
    hide()
    setTimeout(() => {
      setPassword(null);
      setSelectedPasswordType({
        label: 'Set custom password',
        value: passwordTypesEnum.customPassword
      })
      setFormError([]);
    }, 800);
  }
  
  const changeHandlerSelectPasswordType = (e) => {
    setSelectedPasswordType(e)
    setPassword(null)  
  }


  return (
    <Modal show={show} hide={handleHide} title='Reset user password'>
      <div className='flex flex-col space-y-2'>
        <div>
          <ReactSelect label='Password' errors={formError} selectedOption={selectedPasswordType} options={passwordTypes} onChange={changeHandlerSelectPasswordType}/>
        </div>
        {selectedPasswordType.value === passwordTypesEnum.customPassword && 
          <div>
            <TextInput label='Custom password' errors={formError} value={password} onChange={(e) => setPassword(e.target.value)}/>
          </div>
        }
        <div className="flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 flex-col lg:flex-row  ">
          <WhiteButton text='Cancel' onClick={handleHide}/>
          <PrimaryButton text='Reset' onClick={handleSubmit}/>
        </div>
      </div>
    </Modal>
  )
}

export default ResetPasswordModal
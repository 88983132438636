import React from 'react'
import Table from '../table/Table';
import { useNavigate } from 'react-router';
import role from '../../helpers/enum/role'
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import truncateString from '../../helpers/truncateStrings';
import { ExternalLinkIcon, EyeIcon, PencilIcon } from "@heroicons/react/solid";
import SelectColumnFilter from '../table/filters/SelectColumnFilter';
import NumberRangeColumnFilter from '../table/filters/NumberRangeColumnFilter';
import DateRangeColumnFilter from '../table/filters/DateRangeColumnFilter';
import sortByDate from '../../helpers/reactTableSortByDate';
import dayjs from 'dayjs';

function CandidatesTable({ candidates, isLoading }) {

  const { verifyBoolAccess, verifyUserId } = useUserRoleVerifier();
  const navigate = useNavigate();

  const data = React.useMemo(
      () => []
    , [ candidates ]);
    
    if (candidates.length && !data.length) {
      candidates.forEach(candidate => {   
        let skills = candidate.skills.map(skill => skill.name)
        let languages = candidate.languages.map(language => language.name)
        data.push({
          id: candidate.id,
          first_names: candidate.first_names || "-",
          last_names: candidate.last_names || "-",
          email: candidate.email || "-",
          country: candidate.country || "-",
          original_owner: candidate.originalOwner || "-",
          nationality: candidate.nationality || "-",
          notice_period: candidate.notice_period || "-",
          type: candidate.type || "-",
          can_relocate: candidate.can_relocate ? "Yes" : "No",
          skills: truncateString(skills.join("; "), 30) || "-",
          languages: truncateString(languages.join("; "), 30) || "-",
          blacklisted: candidate.blacklisted,
          createdAt: candidate?.created_at
            ? dayjs(candidate?.created_at).format("DD/MM/YYYY")
            : "-",
        });
      })
    }

  
  const columns = React.useMemo(
    () => {
      let columnsToShow = [];
      let dataProperties = Object.keys(candidates[0] || {});
      if (dataProperties.includes('first_names') || candidates.length === 0) columnsToShow.push(
        {
          Header: 'First Name',
          accessor: 'first_names',
        }
      );
      if (dataProperties.includes('last_names')  || candidates.length === 0) columnsToShow.push(
        {
          Header: 'Last Name',
          accessor: 'last_names',
        }
      );
      if (dataProperties.includes("created_at") || candidates.length === 0)
        columnsToShow.push({
          Header: "Added",
          sortType: sortByDate,
          accessor: "createdAt",
          Filter: DateRangeColumnFilter,
          filter: "date",
        });
      if (dataProperties.includes('email')  || candidates.length === 0) columnsToShow.push(
        {
          Header: 'Email',
          accessor: 'email',
        }
      );
      if (dataProperties.includes('country')  || candidates.length === 0) columnsToShow.push(
        {
          Header: 'Country',
          accessor: "country",
          Filter: SelectColumnFilter,
          filter: 'includes'
        }
      );
      if (dataProperties.includes('nationality')) columnsToShow.push(
        {
          Header: 'Nationality',
          accessor: "nationality",
          Filter: SelectColumnFilter,
          filter: 'includes'
        }
      );
      if (dataProperties.includes('can_relocate')) columnsToShow.push(
        {
          Header: 'Can relocate',
          accessor: "can_relocate",
          Filter: SelectColumnFilter,
          filter: 'includes'
        }
      );
      if (dataProperties.includes('notice_period')) columnsToShow.push(
        {
          Header: 'Notice period',
          accessor: "notice_period",
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        }
      );
      if (dataProperties.includes('type')) columnsToShow.push(
        {
          Header: 'Type',
          accessor: "type",
          Filter: SelectColumnFilter,
          filter: 'includes'
        }
      );
      if (dataProperties.includes('originalOwner')) columnsToShow.push(
        {
          Header: 'Belongs to',
          accessor: "original_owner.first_names"
        }
      ); 
      if (dataProperties.includes('skills')) columnsToShow.push(
        {
          Header: 'Skills',
          accessor: "skills"
        }
      );
      if (dataProperties.includes('languages')) columnsToShow.push(
        {
          Header: 'Languages',
          accessor: "languages"
        }
      );
      return columnsToShow;
    }, [candidates]
  );

  let renderRowMenu = (row) => {
    let showEdit= false
    if (verifyBoolAccess(role.g_candidates.g_4_3_edit_candidates)) showEdit= true
    if (verifyBoolAccess(role.g_candidates.g_2_edit_my_candidate) && verifyUserId(row.original.original_owner)) showEdit= true
    let buttons = [
      [
        {
          text: "View candidate details",
          icon: EyeIcon,
          onClick: () => navigate(`/candidates/${row.original.id}`),
        },
        {
          text: "View details in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/candidates/${row.original.id}`),
        },
      ],
    ]

    if (showEdit) buttons.push([
      {
        text: "Edit candidate",
        icon: PencilIcon,
        onClick: () => navigate(`/candidates/edit/${row.original.id}`),
        show: showEdit,
      },
      {
        text: "Edit candidate in new tab",
        icon: ExternalLinkIcon,
        onClick: () => window.open(`/candidates/edit/${row.original.id}`),
        show: showEdit,
      },
    ],)

    return buttons;
  } 
  
  const rowOnClick = (row) => {
    navigate(`/candidates/${row.original.id}`);
  }
  
  return (
    <>
      <Table data={data} columns={columns} isLoading={isLoading} renderRowMenu={renderRowMenu} rowOnClick={rowOnClick}/>
    </>
  )
}

export default CandidatesTable


import React  from 'react'
import Table from '../table/Table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { useNavigate } from 'react-router';
import useUserRoleVerifier from '../../hooks/useUserRoleVerifier';
import role from '../../helpers/enum/role';
import { jobStatuses } from '../../helpers/enum/jobStatuses';
import DateRangeColumnFilter from '../table/filters/DateRangeColumnFilter';
import NumberRangeColumnFilter from '../table/filters/NumberRangeColumnFilter';
import SelectColumnFilter from '../table/filters/SelectColumnFilter';
import { ExternalLinkIcon, EyeIcon, PencilIcon, DuplicateIcon } from '@heroicons/react/solid';
import sortByDate from '../../helpers/reactTableSortByDate';

function JobOrdersTable({ jobOrders, isLoading, selectedRows, setSelectedRows, rowCheckbox }) {

  const { verifyBoolAccess, verifyUserId, verifyArrayAccess } = useUserRoleVerifier();


  dayjs.extend(utc)
  const navigate = useNavigate();

  const data = React.useMemo(
    () => {
      let _data = [];
      if (jobOrders.length) {
        jobOrders.forEach(jobOrder => {
          let jobOrderTitle = `${jobOrder.title} ${jobOrder?.level ? ' - ' + jobOrder.level : ''}`
          let country = jobOrder?.country
          let city = jobOrder?.city
          let location;
          if (country && city) location = `${city}, ${country}`
          if (country && !city) location = `${country}`
          if (!country && city) location = `${city}`
          _data.push({
            id: jobOrder?.id || '-',
            uuid: jobOrder?.uuid || '-',
            title: jobOrder?.title ? jobOrderTitle : '-',
            sector: jobOrder?.sector || '-',
            clientName: jobOrder?.client?.name || '-',
            deadline: jobOrder?.deadline ? dayjs.utc(jobOrder?.deadline).format('DD/MM/YYYY') : '-',
            openings: jobOrder?.openings || '-',
            location: location || '-',
            priority: jobOrder?.priority || '-',
            salesPrice: jobOrder?.sales_price || '-',
            status: jobStatuses[jobOrder?.jobStatusLog[0]?.status] || '-',
            createdAt: jobOrder?.created_at ? dayjs(jobOrder?.created_at).format('DD/MM/YYYY') : '-',
            frameworkContract: jobOrder?.frameworkContract?.contract_ref || '-',
            applications: jobOrder?.applications || '0',
            clientJobId: jobOrder?.client_job_id || '-',
            mode: jobOrder?.mode || '-',
            requestType: jobOrder?.request_type|| '-'
          })
        })
      }      
      return _data;
    }
  , [ jobOrders ]);

    
  
  const columns = React.useMemo(
    () => {
      let columnsToShow = [];
      let dataProperties = Object.keys(jobOrders[0] || {});
      if (dataProperties.includes('id') || jobOrders.length == 0) columnsToShow.push(
        {
          Header: 'ID',
          accessor: 'id',
          filter: 'exactMatchMultiple'
        }
      );
      if (dataProperties.includes('title') || jobOrders.length == 0) columnsToShow.push(
        {
          Header: 'Title',
          accessor: 'title',
        }
      );
      if (dataProperties.includes('client')) columnsToShow.push(
        {
          Header: 'Client',
          accessor: 'clientName',
          Filter: SelectColumnFilter,
          filter: 'includes',
        }
      );
      if (dataProperties.includes('country') || dataProperties.includes('city') || jobOrders.length == 0) columnsToShow.push(
        {
          Header: 'Location',
          accessor: 'location',
        }
      );
      if (dataProperties.includes('sector')) columnsToShow.push(
         {
          Header: 'Sector',
          accessor: 'sector',
          Filter: SelectColumnFilter,
          filter: 'includes',
        }
      );
      if (dataProperties.includes('openings')) columnsToShow.push(
        {
          Header: 'Openings',
          accessor: 'openings',
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        }
      );
      if (dataProperties.includes('deadline')) columnsToShow.push(
        {
          Header: 'Deadline',
          accessor: 'deadline',
          Filter: DateRangeColumnFilter,
          filter: 'date',
          sortType: sortByDate,
        }
      );
      if (dataProperties.includes('priority')) columnsToShow.push(
       {
          Header: 'Priority',
          accessor: 'priority',
          Filter: SelectColumnFilter,
          filter: 'includes',
        }
      );
      if (dataProperties.includes('sales_price')) columnsToShow.push(
        {
          Header:'Price',
          accessor: 'salesPrice',
          Filter: NumberRangeColumnFilter,
          filter: 'between'
        }
      )
      if (dataProperties.includes("frameworkContract"))
        columnsToShow.push({
          Header: "FWC",
          accessor: "frameworkContract",
          Filter: SelectColumnFilter,
          filter: "includes",
        });
      if (dataProperties.includes("jobStatusLog"))
        columnsToShow.push({
          Header: "Status",
          accessor: "status",
          Filter: SelectColumnFilter,
          filter: "includes",
        });
      if (dataProperties.includes("created_at"))
        columnsToShow.push({
          Header: "Created",
          sortType: sortByDate,
          accessor: "createdAt",
          Filter: DateRangeColumnFilter,
          filter: "date",
        });
      if (dataProperties.includes("client_job_id"))
        columnsToShow.push({
          Header: "Client job ID",
          accessor: "clientJobId",
        });
      if (dataProperties.includes("applications"))
        columnsToShow.push({
          Header: "Applications",
          accessor: "applications",
          Filter: NumberRangeColumnFilter,
          filter: "between",
        });
      if (dataProperties.includes('mode'))
        columnsToShow.push({
          Header: 'Mode',
          accessor:'mode',
          Filter: SelectColumnFilter,
          filter: 'includes'
        })
      if (dataProperties.includes('request_type'))
        columnsToShow.push({
          Header: 'Request type',
          accessor: 'requestType',
          Filter: SelectColumnFilter,
          filter: 'includes'
        })
      return columnsToShow
    }, [jobOrders]
  );
    
  let renderRowContextMenu = (row) => {
    let showEdit = false;
    if (verifyBoolAccess(role.h_job_orders.h_3_6_edit_job_order)) showEdit=true
    if (verifyUserId(row.original.created_by) && verifyBoolAccess(role.h_job_orders.h_2_1_edit_created_job_orders)) showEdit=true
    
    let showDuplicate = false;
    if (verifyBoolAccess(role.h_job_orders.h_2_create_job_orders) && verifyArrayAccess(role.h_job_orders.h_1_2_allowed_fwc, row.original.framework_contract_id)) showDuplicate= true
    
    let contextMenuButtons = [
      [
        {
          text: "View job order details",
          icon: EyeIcon,
          onClick: () => navigate(`/job-orders/${row.original.id}`),
        },
        {
          text: "View details in new tab",
          icon: ExternalLinkIcon,
          onClick: () => window.open(`/job-orders/${row.original.id}`),
        },
      ],
    ];

    if (showEdit) contextMenuButtons.push([
      {
        text: "Edit job order",
        icon: PencilIcon,
        onClick: () => navigate(`/job-orders/edit/${row.original.id}`),
        show: showEdit,
      },
      {
        text: "Edit job order in new tab",
        icon: ExternalLinkIcon,
        onClick: () => window.open(`/job-orders/edit/${row.original.id}`),
        show: showEdit,
      },
    ])

    if (showDuplicate) contextMenuButtons.push([
      {
        text: 'Duplicate job order',
        icon: DuplicateIcon,
        onClick: () => navigate(`/job-orders/add`, {state: {id: row.original.id}}),
        show: showDuplicate
      }
    ])
    return (
      contextMenuButtons
    )
  }

  const rowOnClick = (row) => {
    navigate(`/job-orders/${row.original.id}`)
  }

  
  return (
    <>
      <Table 
        rowCheckbox={rowCheckbox} 
        data={data}
        columns={columns}
        isLoading={isLoading}
        renderRowMenu={renderRowContextMenu} 
        selectedRows={selectedRows} 
        setSelectedRows={setSelectedRows}
        rowOnClick={rowOnClick}
      />
    </>
  )
}

export default JobOrdersTable

export const COUNTRIES = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Vietnam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

export const newCountryList = [
  { code: "AF", name: "Afghanistan" }, 
  { code: 'AX', name: 'Åland Islands' },
  { code: "AL", name: "Albania" }, 
  { code: "DZ", name: "Algeria" }, 
  { code: "AS", name: "American Samoa" }, 
  { code: "AD", name: "Andorra" }, 
  { code: "AO", name: "Angola" }, 
  { code: "AI", name: "Anguilla" }, 
  { code: "AQ", name: "Antarctica" }, 
  { code: "AG", name: "Antigua and Barbuda" }, 
  { code: "AR", name: "Argentina" }, 
  { code: "AM", name: "Armenia" }, 
  { code: "AW", name: "Aruba" }, 
  { code: "AU", name: "Australia" }, 
  { code: "AT", name: "Austria" }, 
  { code: "AZ", name: "Azerbaijan" }, 
  { code: "BS", name: "Bahamas (the)" }, 
  { code: "BH", name: "Bahrain" }, 
  { code: "BD", name: "Bangladesh" }, 
  { code: "BB", name: "Barbados" }, 
  { code: "BY", name: "Belarus" }, 
  { code: "BE", name: "Belgium" }, 
  { code: "BZ", name: "Belize" }, 
  { code: "BJ", name: "Benin" }, 
  { code: "BM", name: "Bermuda" }, 
  { code: "BT", name: "Bhutan" }, 
  { code: "BO", name: "Bolivia (Plurinational State of)" }, 
  { code: "BA", name: "Bosnia and Herzegovina" }, 
  { code: "BW", name: "Botswana" }, 
  { code: "BV", name: "Bouvet Island" }, 
  { code: "BR", name: "Brazil" }, 
  { code: "IO", name: "British Indian Ocean Territory (the)" }, 
  { code: "BN", name: "Brunei Darussalam" }, 
  { code: "BG", name: "Bulgaria" }, 
  { code: "BF", name: "Burkina Faso" }, 
  { code: "BI", name: "Burundi" }, 
  { code: "KH", name: "Cambodia" }, 
  { code: "CM", name: "Cameroon" }, 
  { code: "CA", name: "Canada" }, 
  { code: "KY", name: "Cayman Islands (the)" }, 
  { code: "CF", name: "Central African Republic (the)" }, 
  { code: "TD", name: "Chad" }, 
  { code: "CL", name: "Chile" }, 
  { code: "CN", name: "China" }, 
  { code: "CX", name: "Christmas Island" }, 
  { code: "CC", name: "Cocos (Keeling) Islands (the)" }, 
  { code: "CO", name: "Colombia" }, 
  { code: "KM", name: "Comoros (the)" }, 
  { code: "CD", name: "Congo (the Democratic Republic of the)" }, 
  { code: "CG", name: "Congo (the)" }, 
  { code: "CK", name: "Cook Islands (the)" }, 
  { code: "CR", name: "Costa Rica" }, 
  { code: "HR", name: "Croatia" }, 
  { code: "CU", name: "Cuba" }, 
  { code: "CY", name: "Cyprus" }, 
  { code: 'CZ', name: 'Czech Republic' }, 
  { code: "DK", name: "Denmark" }, 
  { code: "DJ", name: "Djibouti" }, 
  { code: "DM", name: "Dominica" }, 
  { code: "DO", name: "Dominican Republic (the)" }, 
  { code: "EC", name: "Ecuador" }, 
  { code: "EG", name: "Egypt" }, 
  { code: "SV", name: "El Salvador" }, 
  { code: "GQ", name: "Equatorial Guinea" }, 
  { code: "ER", name: "Eritrea" }, 
  { code: "EE", name: "Estonia" }, 
  { code: "ET", name: "Ethiopia" }, 
  { code: 'FK', name: 'Falkland Islands (Malvinas)' }, 
  { code: "FO", name: "Faroe Islands (the)" }, 
  { code: "FJ", name: "Fiji" }, 
  { code: "FI", name: "Finland" }, 
  { code: "FR", name: "France" }, 
  { code: "GF", name: "French Guiana" }, 
  { code: "PF", name: "French Polynesia" }, 
  { code: "TF", name: "French Southern Territories (the)" }, 
  { code: "GA", name: "Gabon" }, 
  { code: "GM", name: "Gambia (the)" }, 
  { code: "GE", name: "Georgia" }, 
  { code: "DE", name: "Germany" }, 
  { code: "GH", name: "Ghana" }, 
  { code: "GI", name: "Gibraltar" }, 
  { code: "GR", name: "Greece" }, 
  { code: "GL", name: "Greenland" }, 
  { code: "GD", name: "Grenada" }, 
  { code: "GP", name: "Guadeloupe" }, 
  { code: "GU", name: "Guam" }, 
  { code: "GT", name: "Guatemala" }, 
  { code: "GG", name: "Guernsey" }, 
  { code: "GN", name: "Guinea" }, 
  { code: "GN", name: "Guinea-Bissau" }, 
  { code: "GW", name: "Guinea-Bissau" }, 
  { code: "GY", name: "Guyana" }, 
  { code: "HT", name: "Haiti" }, 
  { code: 'VA', name: 'Vatican City'}, 
  { code: "HN", name: "Honduras" }, 
  { code: "HK", name: "Hong Kong" }, 
  { code: "HU", name: "Hungary" }, 
  { code: "IS", name: "Iceland" }, 
  { code: "IN", name: "India" }, 
  { code: "ID", name: "Indonesia" }, 
  { code: "IR", name: "Iran (Islamic Republic of)" },
  { code: "IQ", name: "Iraq" }, 
  { code: "IE", name: "Ireland" }, 
  { code: "IM", name: "Isle of Man" }, 
  { code: "IL", name: "Israel" }, 
  { code: "IT", name: "Italy" }, 
  { code: "JM", name: "Jamaica" }, 
  { code: "JP", name: "Japan" }, 
  { code: "JE", name: "Jersey" }, 
  { code: "JO", name: "Jordan" }, 
  { code: "KZ", name: "Kazakhstan" }, 
  { code: "KE", name: "Kenya" }, 
  { code: "KI", name: "Kiribati" }, 
  { code: "KP", name: "Korea (the Democratic People's Republic of)" },
  { code: "KR", name: "Korea (the Republic of)" }, 
  { code: "KW", name: "Kuwait" }, 
  { code: "KG", name: "Kyrgyzstan" }, 
  { code: "LA", name: "Lao People's Democratic Republic (the)" },
  { code: "LV", name: "Latvia" }, 
  { code: "LB", name: "Lebanon" }, 
  { code: "LS", name: "Lesotho" }, 
  { code: "LR", name: "Liberia" }, 
  { code: 'LY', name: 'Libya' }, 
  { code: "LI", name: "Liechtenstein" }, 
  { code: "LT", name: "Lithuania" }, 
  { code: "LU", name: "Luxembourg" }, 
  { code: "MO", name: "Macao" }, 
  { code: 'MK', name: "Republic of North Macedonia" },
  { code: "MG", name: "Madagascar" }, 
  { code: "MW", name: "Malawi" }, 
  { code: "MY", name: "Malaysia" }, 
  { code: "MV", name: "Maldives" }, 
  { code: "ML", name: "Mali" }, 
  { code: "MT", name: "Malta" }, 
  { code: "MH", name: "Marshall Islands (the)" }, 
  { code: "MQ", name: "Martinique" }, 
  { code: "MR", name: "Mauritania" }, 
  { code: "MU", name: "Mauritius" }, 
  { code: "YT", name: "Mayotte" }, 
  { code: "MX", name: "Mexico" }, 
  { code: "MC", name: "Monaco" }, 
  { code: "MN", name: "Mongolia" }, 
  { code: "MS", name: "Montserrat" }, 
  { code: "MA", name: "Morocco" }, 
  { code: "MZ", name: "Mozambique" }, 
  { code: "MM", name: "Myanmar" }, 
  { code: "NA", name: "Namibia" }, 
  { code: "NR", name: "Nauru" }, 
  { code: "NP", name: "Nepal" }, 
  { code: "NL", name: "Netherlands (the)" }, 
  { code: "NC", name: "New Caledonia" }, 
  { code: "NZ", name: "New Zealand" }, 
  { code: "NI", name: "Nicaragua" }, 
  { code: "NE", name: "Niger (the)" }, 
  { code: "NG", name: "Nigeria" }, 
  { code: "NU", name: "Niue" }, 
  { code: "NF", name: "Norfolk Island" }, 
  { code: "MP", name: "Northern Mariana Islands (the)" }, 
  { code: "NO", name: "Norway" }, 
  { code: "OM", name: "Oman" }, 
  { code: "PK", name: "Pakistan" }, 
  { code: "PW", name: "Palau" }, 
  { code: "PS", name: "Palestine, State of", },
  { code: "PA", name: "Panama" }, 
  { code: "PG", name: "Papua New Guinea" }, 
  { code: "PY", name: "Paraguay" }, 
  { code: "PE", name: "Peru" }, 
  { code: "PH", name: "Philippines (the)" }, 
  { code: "PN", name: "Pitcairn" }, 
  { code: "PL", name: "Poland" }, 
  { code: "PT", name: "Portugal" }, 
  { code: "PR", name: "Puerto Rico" }, 
  { code: "QA", name: "Qatar" }, 
  { code: "RO", name: "Romania" }, 
  { code: "RU", name: "Russian Federation (the)" }, 
  { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" }, 
  { code: "KN", name: "Saint Kitts and Nevis" }, 
  { code: "LC", name: "Saint Lucia" }, 
  { code: "PM", name: "Saint Pierre and Miquelon" }, 
  { code: "VC", name: "Saint Vincent and the Grenadines" }, 
  { code: "WS", name: "Samoa" }, 
  { code: "SM", name: "San Marino" }, 
  { code: "ST", name: "Sao Tome and Principe" }, 
  { code: "SA", name: "Saudi Arabia" }, 
  { code: "SN", name: "Senegal" }, 
  { code: "CS", name: "Serbia"},
  { code: "SC", name: "Seychelles" }, 
  { code: "SL", name: "Sierra Leone" }, 
  { code: "SG", name: "Singapore" }, 
  { code: "SK", name: "Slovakia" }, 
  { code: "SI", name: "Slovenia" }, 
  { code: "SB", name: "Solomon Islands" }, 
  { code: "SO", name: "Somalia" }, 
  { code: "ZA", name: "South Africa" }, 
  { code: "GS", name: "South Georgia and the South Sandwich Islands" }, 
  { code: "SS", name: "South Sudan" }, 
  { code: "ES", name: "Spain" }, 
  { code: "LK", name: "Sri Lanka" }, 
  { code: "SD", name: "Sudan (the)" }, 
  { code: "SR", name: "Suriname" }, 
  { code: "SJ", name: "Svalbard and Jan Mayen" }, 
  { code: "SE", name: "Sweden" }, 
  { code: "CH", name: "Switzerland" }, 
  { code: "SY", name: "Syrian Arab Republic" }, 
  { code: 'TW', name: "Taiwan" },
  { code: "TJ", name: "Tajikistan" }, 
  { code: "TZ", name: "Tanzania, United Republic of" }, 
  { code: "TH", name: "Thailand" }, 
  { code: "TL", name: "Timor-Leste" }, 
  { code: "TG", name: "Togo" }, 
  { code: "TK", name: "Tokelau" }, 
  { code: "TO", name: "Tonga" }, 
  { code: "TT", name: "Trinidad and Tobago" }, 
  { code: "TN", name: "Tunisia" }, 
  { code: "TR", name: "Turkey" }, 
  { code: "TM", name: "Turkmenistan" }, 
  { code: "TC", name: "Turks and Caicos Islands (the)" }, 
  { code: "TV", name: "Tuvalu" }, 
  { code: "UG", name: "Uganda" }, 
  { code: "UA", name: "Ukraine" }, 
  { code: "AE", name: "United Arab Emirates (the)" }, 
  { code: "GB", name: "United Kingdom of Great Britain and Northern Ireland (the)" }, 
  { code: "UM", name: "United States Minor Outlying Islands (the)" }, 
  { code: "US", name: "United States of America (the)" }, 
  { code: "UY", name: "Uruguay" }, 
  { code: "UZ", name: "Uzbekistan" }, 
  { code: "VU", name: "Vanuatu" }, 
  { code: "VE", name: "Venezuela (Bolivarian Republic of)" }, 
  { code: "WF", name: "Wallis and Futuna" }, 
  { code: "EH", name: "Western Sahara" }, 
  { code: "YE", name: "Yemen" }, 
  { code: "ZM", name: "Zambia" }, 
  { code: "ZW", name: "Zimbabwe" }, 
]

export const SECTORS = [
  "Private",
  "Public"
]

export const EDUCATION_LEVELS = [
  "MA",
  "PHD",
  "Bachelor's degree"
]

export const JOB_ORDER_PLACEMENT_TYPES = [
  "Direct",
  "Subco",
  "Margin"
]
 
export const JOB_ORDER_REQUEST_TYPES = [
  'Official',
  'Proactive'
]

export const JOB_ORDER_MODES = [
  'Onsite',
  'Near-site',
  'Near-site (flexible)',
  'Remote'
]

export const JOB_ORDER_PRIORITY = [
  "High",
  "Medium",
  "Low"
]

export const CANDIDATE_TYPE = [
  'Employee',
  'Freelancer',
  'Undecided'
]

export const CANDIDATE_SOURCE = [
  'Company database',
  'Recruiter database',
  'LinkedIn',
  'Job Post',
  'Referral'
]

export const INDUSTRY_OPTIONS = [
  'Public',
  'FMCG',
  'Retail industry',
  'Banking',
  'Insurance',
  'Energy',
  'Other'
]


import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../store/auth/authContext';

function RequireAuth({ authenticate, access }) {
  let location = useLocation();
  const { auth, getCurrentUser, removeCurrentUser } = useAuth();
  const role = auth.userRole;

  useEffect(() => {
    if (authenticate) return getCurrentUser()
    if (!authenticate) return removeCurrentUser();
  }, [])

  const [ verified, setVerified ] = useState(true);

  const isArray = Array.isArray(access);
  const isBool = typeof access === 'boolean';
  const isString = typeof access === 'string';
  
  useEffect(() => {  
    if (auth.isAuthenticated === 'pending') return;
    //block access if no property allows user to access (array 'OR' logic)
    if (isArray) {
      let check = false
      access.forEach(accessProperty => {
        if (role[accessProperty]) check = true;
      })
      if (!check) {
        setVerified(false);  
      }
    } 
    if (isString) {
      if (!role[access]) {
        setVerified(false);
      }
    }
    if (isBool) {
      setVerified(access)
    }
    
  }, [ access, role ])

  if (!authenticate) return '';
  if (auth.isAuthenticated === 'pending') return ''
  if (auth.isAuthenticated && access === undefined) return ''  
  if (auth.isAuthenticated && verified && !auth.currentUser.temp_password)
    return "";
  if (auth.isAuthenticated && verified && auth.currentUser.temp_password) return <Navigate to="/temp-password" state={{ from: location }} replace />;
  if (auth.isAuthenticated && !verified) return <Navigate to="/login" state={{ from: location }} replace />
  if (!auth.isAuthenticated) return <Navigate to="/login" state={{ from: location }} replace />
}

RequireAuth.defaultProps = {
  authenticate: true,
  access: undefined,
}

export default RequireAuth;

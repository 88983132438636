import React, { useRef, useState, useEffect } from "react"
import {usePopper} from 'react-popper'
import { Menu, Transition } from '@headlessui/react';
import { Portal } from 'react-portal';
import useApi from "../../store/api/apiContext"
import { Link } from 'react-router-dom';
import { BsCheck2All } from 'react-icons/bs';
import NotificationMessage from "./NotificationMessage";
import NotificationIcon from './NotificationIcon'

const NotificationsDropdown = ({items, id, xOffset = 0}) => {

  const { setNotificationsToRead } = useApi()

  const popperElRef = useRef(null)
  const menuButtonRef = useRef(null)
  const menuItemsRef = useRef(null)
  const [targetElement, setTargetElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [popperPosition, setPopperPosition] = useState(null)

  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (!items) return;
    setUnreadNotifications(items?.unreadCounter[0].total_unread);
    setNotifications(items?.latestNotifications);
  }, [items]);

  const offset = React.useMemo(() => {
    let _offset;
    switch (popperPosition) {
      case 'bottom':
        _offset = [20 + xOffset, 0];
        break;
      case 'top':
        _offset = [20 + xOffset, 0];
        break;
      default:
        break;
    }
    return _offset;
  }, [popperPosition]);
  
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: popperPosition,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
    ],
  });

  useEffect(() => {
    //Check if click happened close to the bottom or right edge, than add offset to anchorpoint
    let menuButtonXPosition = menuButtonRef?.current?.getBoundingClientRect().left;
    let menuButtonYPosition = menuButtonRef?.current?.getBoundingClientRect().top;
    let menuHeight = menuItemsRef?.current?.clientHeight + 40;
    let menuWidth = menuItemsRef?.current?.clientWidth;
    if (menuButtonXPosition + menuWidth > window?.window?.innerWidth) {
      //TODO: If needed, add a position to 'left' side to offset against right edge
    }

    if (menuButtonYPosition + menuHeight > window?.window?.innerHeight) {
      return setPopperPosition('top');
    } else {
      return setPopperPosition('bottom');
    }
  });

  const setToRead = index => {
    let _notifications = notifications;
    if (_notifications[index].read) return;
    setNotificationsToRead(_notifications[index].id);
    _notifications[index].read = true;
    setNotifications(_notifications);
    setUnreadNotifications(unreadNotifications - 1);
  };

  const setAllToRead = () => {
    let _notifications = notifications;
    let currentlyUnreadNotifications = unreadNotifications;
    setNotificationsToRead().then(res => {
      for (const notification of _notifications) {
        if (!notification.read) {
          notification.read = true;
          currentlyUnreadNotifications -= 1;
        }
      }
      setNotifications(_notifications);
      setUnreadNotifications(currentlyUnreadNotifications);
    }).catch(err => {
      throw err
    })
  };

  return (
    <Menu>
      {({ open }) => (
        <>
          <div ref={setTargetElement} key={id}>
            <Menu.Button
              ref={menuButtonRef}
              className="cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <NotificationIcon numberOfNotifications={unreadNotifications} />
            </Menu.Button>
          </div>

          <Portal>
            <div ref={popperElRef} style={styles.popper} {...attributes.popper} className='z-50'>
              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items
                  className="p-4 origin-bottom-right z-50 absolute right-0 mt-2 w-60 md:w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  ref={menuItemsRef}
                >
                  <div className="w-full flex justify-between pb-4">
                    <p className="text-lg font-semibold ">Notifications</p>
                    {notifications?.some(item => !item.read) && (
                      <p
                        className="text-xs flex items-center text-thaleria-orange-700 cursor-pointer font-semibold hover:text-thaleria-orange-500 self-center"
                        onClick={() => setAllToRead()}
                      >
                        <BsCheck2All className="w-5 h-5 mr-1" />
                        Mark all as read
                      </p>
                    )}
                  </div>
                  <ul className=" overflow-hidden">
                    {notifications?.length ? (
                      notifications.map(
                        (item, index) => (
                          <Menu.Item key={item.text}>
                            {({ active }) => (
                              <NotificationMessage index={index} item={item} setToRead={setToRead} active={active} />
                            )}
                          </Menu.Item>
                        ),
                        // }))
                      )
                    ) : (
                      <p className="p-4 mx-auto w-full text-gray-600 text-sm">No new notifications to show</p>
                    )}
                  </ul>
                  <hr className="border-t-2 border-gray-100 my-4 "></hr>
                  <div className=" flex items-center justify-start">
                    <Link
                      className="text-blue-400 hover:text-blue-500 hover:underline decoration-dotted text-sm "
                      to="/notifications"
                    >
                      View all notifications
                    </Link>
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Menu>
  );
}

export default NotificationsDropdown
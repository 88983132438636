import * as React from "react";
import axios from "axios";
import { jobOrdersReducer, initialState } from "./jobOrdersReducer";
import actions from "./actions";
import { JobOrdersContext } from "./jobOrdersContext";
import { AuthContext } from "../auth/authContext";
import { SocketContext } from "../socket/socketProvider";


function JobOrdersProvider({ children }) {
  /* // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = React.useMemo(() => [state, dispatch], [state]) */

  const [jobOrdersState, dispatch] = React.useReducer(jobOrdersReducer, initialState);
  const authContext = React.useContext(AuthContext);
  const socket = React.useContext(SocketContext);

  const [auth] = authContext;

  React.useEffect(() => {
    if (!auth?.isAuthenticated) {
      dispatch({
        type: actions.RESET_DATA,
      });
      return;
    }
    if (!socket || auth?.isAuthenticated === "pending") return;

    let source = axios.CancelToken.source();
    if (!jobOrdersState?.allJobOrders.length) loadJobOrders(source);

    socket.on("refresh-job-orders", () => loadJobOrders(source));

    return () => {
      socket.removeListener("refresh-job-orders");
      source.cancel();
    }

  }, [auth?.isAuthenticated, socket])


  React.useEffect(() => {
    if (!socket || auth?.isAuthenticated === "pending") return;

    socket.on("added-job-order", addedJobOrder);
    socket.on("batch-update-priority", batchUpdatedPriority);
    socket.on("batch-update-status", batchUpdatedStatus);
    socket.on("delete-job-order", deleteJobOrder);
    socket.on("add-job-status-log", addJobStatusLog);
    socket.on("delete-job-status-log", deleteJobStatusLog);
    socket.on("delete-application", deleteApplication);
    socket.on("remove-job-order-recruiter", removeJobOrderRecruiter);
    socket.on("edit-job-order", editJobOrder);
    socket.on("add-application", addApplication);
    socket.on("edit-application", editApplication);
    socket.on("add-job-order-recruiters", addJobOrderRecruiters);

    return () => {
      socket.removeListener("added-job-order");
      socket.removeListener("batch-update-priority");
      socket.removeListener("batch-update-status");
      socket.removeListener("delete-job-order");
      socket.removeListener("add-job-status-log");
      socket.removeListener("delete-job-status-log");
      socket.removeListener("delete-application");
      socket.removeListener("remove-job-order-recruiter");
      socket?.removeListener("edit-job-order");
      socket?.removeListener("add-application");
      socket?.removeListener("edit-application");
      socket?.removeListener("add-job-order-recruiters");
    };
  }, [auth?.isAuthenticated, socket, jobOrdersState.jobStatusHistory, jobOrdersState.jobOrderDetails?.id, jobOrdersState.applicationHistory]);


  const loadJobOrders = async (source) => {
    axios.get("/job-orders/all", { cancelToken: source.token }).then((res) => {
      dispatch({
        type: actions.FETCH_ALL_JOB_ORDERS,
        payload: res.data,
      });
    });
  };

  const addedJobOrder = (data) => {
     dispatch({
       type: actions.ADD_JOB_ORDER,
       payload: data,
     });
  }
  const batchUpdatedPriority = (data) => {
     dispatch({
       type: actions.BATCH_UPDATE_PRIORITY,
       payload: data,
     });
  }
  const batchUpdatedStatus = (data) => {
    dispatch({
       type: actions.BATCH_UPDATE_STATUS,
       payload: data,
     });
  }
  const editJobOrder = (data) => {
    const { currentUserId } = data; 
    if (currentUserId === auth.currentUser.id) {
      dispatch({
         type: actions.UPDATE_JOB_ORDER,
         payload: data.editedData,
       });
    } else {
      refreshJobOrderDetails(data.editedData.id, true);
    }
  }
  const deleteJobOrder = (id) => {
    dispatch({
       type: actions.DELETE_JOB_ORDER,
       payload: id,
     });
  }
  const addJobStatusLog = (data) => {
    dispatch({
       type: actions.ADD_JOB_STATUS_LOG,
       payload: data,
    });
    if (jobOrdersState.jobStatusHistory.length) {
      getJobStatusEntry(data.data.id)
    }
  }
  const deleteJobStatusLog = (data) => {
    dispatch({
       type: actions.DELETE_JOB_STATUS_LOG,
       payload: data,
     });
  }
  const addApplication = async (data) => {
    const { currentUserId, jobOrderId } = data;
    if (currentUserId === auth.currentUser.id) {
      dispatch({
        type: actions.ADD_APPLICATION_LOG,
        payload: data,
      });
    } else {
      refreshJobOrderDetails(jobOrderId);
    }
    if (jobOrdersState.applicationHistory.length) {
      getApplication(data.newApplication.id, true)
    }
  };
  const editApplication = (data) => {
    const { currentUserId, jobOrderId } = data;
    if (currentUserId === auth.currentUser.id) {
      dispatch({
        type: actions.EDIT_APPLICATION_LOG,
        payload: data,
      });
    } else {
      refreshJobOrderDetails(jobOrderId);
    }

    if (jobOrdersState.applicationHistory.length) {
      getApplication(data.updatedApplicationId, false)
    }
  };

  const deleteApplication = (data) => {
    dispatch({
      type: actions.DELETE_APPLICATION_LOG,
      payload: data,
    });
  };

  const addJobOrderRecruiters = (jobOrderId) => {
    refreshJobOrderDetails(jobOrderId);
  };
  const removeJobOrderRecruiter = (data) => {
    dispatch({
      type: actions.REMOVE_JOB_ORDER_RECRUITER,
      payload: data,
    });
  };

  const refreshJobOrderDetails = async (id, bypass) => {
    if (jobOrdersState.jobOrderDetails?.id === id || bypass) {
      await axios.get(`/job-orders/id/${id}`).then((r) => {
        dispatch({
          type: actions.FETCH_JOB_ORDER_DETAILS,
          payload: r.data,
        });
      })
    }
  }

  const getApplication = async (applicationId, applicationAdded) => {
    await axios.get(`/job-orders/application/${applicationId}`).then(r => {
      const actionToDispatch = applicationAdded ? actions.ADD_APPLICATION_TO_HISTORY : actions.EDIT_APPLICATION_IN_HISTORY
      dispatch({
        type: actionToDispatch,
        payload: r?.data
      })
    })
  }

  const getJobStatusEntry = async (id) => {
    await axios.get(`/job-orders/job-status/${id}`).then(r => {
      dispatch({
        type: actions.ADD_JOB_STATUS_TO_HISTORY,
        payload: r?.data
      })
    })
  }

  return (
    <JobOrdersContext.Provider value={[jobOrdersState, dispatch]}>
      {children}
    </JobOrdersContext.Provider>
  );
}

export default JobOrdersProvider;

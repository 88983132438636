import React from 'react'

function DescriptionItem({label, data}) {
  return (
     <>
      <label className="text-sm font-medium text-gray-700 flex">
          {label}
        </label>
        <dd className="mt-1 text-sm text-gray-500">{data}</dd>
     </>
  )
}

export default DescriptionItem
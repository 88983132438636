import React from 'react'
import DescriptionList from '../../../components/descriptionLists/DescriptionList'
import candidateProperties from '../../../helpers/enum/candidateProperties';
import { ALL_ACCESS_UUID } from '../../../config';

function CandidateActionDetails({ role, userGroups }) {
 
 
  const returnLabel = (array, labels) => {
    if (array[0] === ALL_ACCESS_UUID) return 'All';
    return array.map(property => labels[property]).join(', ');
  }
  const returnName = (array, otherArray, propertyToReturn) => {
    if (array[0] === ALL_ACCESS_UUID) return 'All';
    if (array[0] === "" && array.length === 1) return;
    return array.map(thingId => otherArray.find(thing => thing.id === thingId)[propertyToReturn]).join(', ');
  }
 
  const items = [
    { label: 'Create my candidate', data: role.g_1_create_my_candidate ? 'Yes' : 'No', id: 1, show: true },
    { label: 'Edit my candidate', data: role.g_2_edit_my_candidate ? 'Yes' : 'No', id: 2, show: true },
    { label: 'Edit my candidate: editable properties', data: returnLabel(role.g_2_1_editable_properties_my_candidate, candidateProperties), id: 3, show: role.g_2_edit_my_candidate },
    { label: 'Delete my candidate', data: role.g_3_delete_my_candidate ? 'Yes' : 'No', id: 4, show: true },
    { label: 'Manage candidates', data: role.g_4_manage_candidates ? 'Yes' : 'No', id: 5, show: true },
    { label: 'Manage candidates: allowed user groups', data: returnName(role.g_4_1_allowed_owner_user_group_ids, userGroups, 'name'), id: 6, show: role.g_4_manage_candidates },
    { label: 'Manage candidates: create candidates', data: role.g_4_2_create_candidates ? 'Yes' : 'No', id: 8, show: role.g_4_manage_candidates },
    { label: 'Manage candidates: edit candidates', data: role.g_4_3_edit_candidates ? 'Yes' : 'No', id: 9, show: role.g_4_manage_candidates },
    { label: 'Edit candidates: editable properties', data: returnLabel(role.g_4_3_1_editable_properties, candidateProperties), id: 10, show: role.g_4_3_edit_candidates },
    { label: 'Manage candidates: delete candidates', data: role.g_4_4_delete_candidates ? 'Yes' : 'No', id: 11, show: role.g_4_manage_candidates },
  ]
  return (
    <DescriptionList title='Candidate actions' items={items}/>
  )
}

export default CandidateActionDetails
import * as React from 'react';
import axios from 'axios';
import removeKeyFromObject from '../../utils/removeKeyFromObject';
import { useNavigate } from 'react-router';

export const AuthContext = React.createContext();

function useAuth() {

  const context = React.useContext(AuthContext);

  const navigate = useNavigate();

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const [auth, setAuth] = context;

   //axios setup
  const token = localStorage.getItem('jwtToken');
  if (token) axios.defaults.headers.common['x-access-token'] = token;

  //auth
  const login = async ({ email, password, captchaToken }) => {
    return axios.post(`/auth/login`, { email, password, captchaToken }).then(res => {
      const token = res?.data?.token
      
      axios.defaults.headers.common['x-access-token'] = token;
      localStorage.setItem('jwtToken', token);

      setAuth(state => ({
          ...state,
          isAuthenticated: true,
        }))
    })
  }
  
  const logout = async (dontDispatchToServer) => {
    if (dontDispatchToServer) {
      localStorage.removeItem("jwtToken");
      axios.defaults.headers.common["x-access-token"] = "";
      setAuth((state) => ({
        ...state,
        currentUser: {},
        isAuthenticated: false,
        profilePicture: null,
      }));
      navigate("/login");
    } else {
      return axios.get('/auth/logout').then(() => {
        localStorage.removeItem('jwtToken');
        axios.defaults.headers.common['x-access-token'] = '';
        setAuth(state => ({
          ...state,
          currentUser: {},
          isAuthenticated: false,
          profilePicture: null,
        }))
        navigate('/login')
      })

    }
  }

  const getCurrentUser = async () => {
    return axios
      .get(`/users/me`)
      .then(res => {
        setAuth(state => ({
          ...state,
          currentUser: removeKeyFromObject('role', res.data),
          userRole: res.data.role,
          isAuthenticated: true,
        }))
      })
      .catch(err => {
        setAuth(state => ({
          ...state,
          currentUser: {},
          isAuthenticated: false,
        }))
      });
  };

  const removeCurrentUser = () => {
    setAuth(state => ({
      ...state,
      currentUser: {},
      isAuthenticated: false,
    }))
  };

  const refreshToken = async () => {
    return axios.get(`/auth/refresh`).then(res => {
      const token = res.data.token;
      axios.defaults.headers.common['x-access-token'] = token;
      localStorage.setItem('jwtToken', token);
    });
  }

  return {
    auth,
    setAuth,
    token,
    login,
    logout,
    refreshToken,
    getCurrentUser,
    removeCurrentUser
  }
}
export default useAuth;
import React, { useState, useEffect } from 'react'
import PageWrapper from '../../containers/app/PageWrapper'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useNavigate } from 'react-router'
import useApi from '../../store/api/apiContext'
import role from '../../helpers/enum/role'
import CandidatesTable from '../../containers/candidates/CandidatesTable'

function Candidates({ access }) {
  const navigate = useNavigate();
  const [ candidates, setCandidates ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const { fetchCandidates } = useApi();

  const fetchCandidatesFromApi = async (source) => {
    fetchCandidates('table').then(res => {
      setCandidates(res.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchCandidatesFromApi(source);
    return () => {
      source.cancel();
    }
  }, [])

  const renderTopSection = () => {
    return (
      <>
        <PrimaryButton access={[role.g_candidates.g_1_create_my_candidate, role.g_candidates.g_4_2_create_candidates]} text='Add candidate' linkTo='/candidates/add'/>
      </>
    )
  }

  return (
    <PageWrapper title={'Candidates'} topSection={renderTopSection()} access={access}>
      <CandidatesTable candidates={candidates} isLoading={isLoading}/>
    </PageWrapper>
  )
}

export default Candidates
import React, { useEffect, useState } from 'react'
import ContainerCard from '../../../components/cards/ContainerCard'
import { PlusIcon } from '@heroicons/react/solid'
import AddRecruiterModal from './AddRecruiterModal'
import WhiteButton from '../../../components/buttons/WhiteButton'
import RecruiterItem from './RecruiterItem'
import AccessWrapper from '../../app/AccessWrapper'
import role from '../../../helpers/enum/role'
import useAppNotification from '../../../store/appNotification/appNotificationContext'
import useAuth from '../../../store/auth/authContext'
import useJobOrders from '../../../store/jobOrders/jobOrdersContext'

function JobOrderRecruiters({ recruiters, jobOrder, frameworkContractId }) {

  const { auth: { currentUser } } = useAuth();
  
  const {addRecruitersToJobOrder } = useJobOrders();
  const { showAppNotification, appNotificationTypes } = useAppNotification();
  const [showAddRecruiterModal, setShowAddRecruiterModal] = useState(false)
  const [currentUserIsRecruiter, setCurrentUserIsRecruiter] = useState()

  const handleAssignMyself = () => { 
    addRecruitersToJobOrder(jobOrder.id, [ currentUser.id ]).then(res => {
      showAppNotification(appNotificationTypes.SUCCESS, 'Success', 'Added recruiters to job order');    
    });
  }

  useEffect(() => {
    setCurrentUserIsRecruiter(recruiters.some(el => el.id === currentUser.id))
  }, [recruiters])

  return (
    <ContainerCard title='Recruiters' className='max-h-60 lg:max-h-72' noBorder button={<WhiteButton access={role.h_job_orders.h_3_2_assign_recruiters} icon={<PlusIcon className='h-4 w-4'/>} size='sm' text='Add' onClick={() => setShowAddRecruiterModal(!showAddRecruiterModal)}/>} >
      <AddRecruiterModal 
        currentRecruiters={recruiters} 
        show={showAddRecruiterModal} 
        hide={setShowAddRecruiterModal} 
        jobOrderId={jobOrder.id} 
        frameworkContractId={frameworkContractId} 
      />
      {recruiters?.length ? 
        <ul className='-my-5 divide-y divide-gray-200 overflow-y-auto'>
          {recruiters.map(recruiter => <RecruiterItem recruiter={recruiter} jobOrder={jobOrder} key={recruiter.id}/>)}
        </ul>
        :
        <span className='text-sm'>No recruiters set for this job order</span>
      }
      {!currentUserIsRecruiter &&
        <AccessWrapper access={role.h_job_orders.h_1_3_assign_myself_as_recruiter}>
          <div className='w-full mt-6'>
            <WhiteButton text='Assign myself as recruiter' onClick={handleAssignMyself} fullWidth/>
          </div>
        </AccessWrapper>
      }
    </ContainerCard>
  )
}

export default JobOrderRecruiters
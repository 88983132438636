import React, { useState, useEffect } from 'react';
import DescriptionList from '../../components/descriptionLists/DescriptionList';
import dayjs from 'dayjs';
import { EditorState, convertFromRaw } from 'draft-js'
import utc from 'dayjs/plugin/utc'
import convertCountries from '../../helpers/convertCountries';


function CandidateDetailsForm({ candidate, deleteLegacyNotes }) {

  dayjs.extend(utc)

  const userFullname = `${candidate.originalOwner.first_names} ${candidate.originalOwner.last_names}`

  const [ candidateNotes, setCandidateNotes ] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    if (candidate.notes) {
      var convertedJSON = JSON.parse(candidate.notes);
      setCandidateNotes(EditorState.createWithContent(convertFromRaw(convertedJSON)))
    }
  }, [candidate])

  let languageNames = []
  let skillNames = []

  candidate.languages.forEach(language => {languageNames.push(language.name)})
  candidate.skills.forEach(skill => {skillNames.push(skill.name)})

  let skillString = skillNames.join(', ')
  let languageString = languageNames.join(', ')

  const candidateInfoItems = [
    { label: 'First names', data: candidate.first_names, id: 1, show: true },
    { label: 'Last names', data: candidate.last_names, id: 2, show: true },
    { label: 'Email', data: candidate.email, id: 3, show: true },
    { label: 'Phone number', data: candidate.phone_nb, id: 4, show: true },
    { label: 'Country', data: convertCountries(candidate.country), id: 5, show: true },
    { label: 'Languages', data:languageString, id: 6, show: true },
    { label: 'Nationality', data: candidate.nationality, id: 7, show: true },
    { label: 'Current employer', data: candidate.current_employer, id: 8, show: true },
    { label: 'Can relocate', data: candidate.can_relocate ? 'Yes' : 'No', id: 15, show: true},
    { label: 'Creation date', data: dayjs.utc(candidate.created_at).format('DD/MM/YYYY'), id: 16, show: true },    
  ]
  
  const profileInfoItems = [
    { label: 'Original recruiter', data: userFullname, id: 9, show: true },
    { label: 'Source', data: candidate.source, id: 10, show: true },
    { label: 'Skills', data: skillString, id: 11, show: true },
    { label: 'Notice period (calendar days)', data: candidate.notice_period, id: 12, show: true },
    { label: 'Type', data: candidate.type, id: 13, show: true },
    { label: 'Notes', data: candidateNotes, richText: true, id: 14, show: true, cols: 2 },
  ]

  return (
    <>
      <DescriptionList title={'Candidate information'} items={candidateInfoItems} />
      <DescriptionList title={'Profile information'} items={profileInfoItems} deleteLegacyText={deleteLegacyNotes}/>
    </>
  )
}

export default CandidateDetailsForm
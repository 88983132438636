import React, { useEffect } from 'react';
import FormWithSideTitle from '../../../components/forms/FormWithSideTitle';
import Toggle from '../../../components/inputs/Toggle';

function CommentsActions({ newRole, setNewRole }) {
  
  useEffect(() => {
    if (!newRole.h_1_view_job_orders && newRole.i_1_view_job_order_comments) setNewRole(prev => ({...prev, i_1_view_job_order_comments: false }))
    if (!newRole.e_manage_clients && newRole.i_3_view_client_comments) setNewRole(prev => ({...prev, i_3_view_client_comments: false }))
  }, [newRole])

  return (
    <FormWithSideTitle title='Comments actions' description='Set which comments actions your role will enable' >
      <div className="col-span-6">
        <Toggle 
          bold 
          label='View job order comments' 
          description={'Allows to view and post job order comments'} 
          value={newRole.i_1_view_job_order_comments} 
          disabled={!newRole.h_1_view_job_orders}
          onChange={() => setNewRole(prev => ({...prev, i_1_view_job_order_comments: !newRole.i_1_view_job_order_comments}))}
        />
      </div>
      <div className="col-span-6">
        <Toggle 
          bold 
          label='View candidate comments' 
          description={'Allows to view and post candidate comments'} 
          value={newRole.i_2_view_candidate_comments} 
          onChange={() => setNewRole(prev => ({...prev, i_2_view_candidate_comments: !newRole.i_2_view_candidate_comments}))}
        />
      </div>
      <div className="col-span-6">
        <Toggle 
          bold 
          disabled={!newRole.e_manage_clients}
          label='View client comments' 
          description={'Allows to view and post client comments'} 
          value={newRole.i_3_view_client_comments} 
          onChange={() => setNewRole(prev => ({...prev, i_3_view_client_comments: !newRole.i_3_view_client_comments}))}
        />
      </div>
      <div className="col-span-6">
        <Toggle 
          bold 
          label='Manage comments' 
          description={'Allows to edit and delete third party comments'} 
          value={newRole.i_4_manage_comments} 
          onChange={() => setNewRole(prev => ({...prev, i_4_manage_comments: !newRole.i_4_manage_comments}))}
        />
      </div>
    </FormWithSideTitle>
  )
}

export default CommentsActions